import React, { useEffect, useState } from "react";
import useAdminContext from "src/hooks/private/useAdminContext";
import useModal from "src/hooks/useModal";
import useViewport from "src/hooks/useViewport";
import AdminApi from "src/api/admin";
import useAlert from "src/hooks/useAlert";
import NewAdminModal from "src/components/modals/NewAdminModal";
import EditAdminModal from "src/components/modals/EditAdminModal";
import { Admin } from "src/interfaces/admin";
import EditButton from "src/components/buttons/EditButton";
import { Navigate } from "react-router-dom";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import CircularActionButton from "src/components/buttons/CircularActionButton";
import copyTextToClipboard from "src/helpers/copyTextToClipboard";
import useAdminRedirect from "src/hooks/private/useAdminRedirect";

const Admins = () => {
  const { admin } = useAdminContext();

  const { isMobile } = useViewport();

  const { openModalWith, setShowModal } = useModal();

  const { setAlert } = useAlert();

  const [admins, setAdmins] = useState<Admin[]>([]);

  const fetchAdmins = async () => {
    const resp = await AdminApi.getAdmins();
    setAdmins(resp);
  };

  useEffect(() => {
    fetchAdmins();
  }, [admin]);

  const redirectPath = useAdminRedirect();

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  const handleNewAdmin = () => {
    openModalWith({
      title: "Invite New Admin",
      body: <NewAdminModal fetchAdmins={fetchAdmins} />,
      hideButtons: true,
    });
  };

  const handleEditAdmin = (adminToEdit: Admin) => {
    if (admin && admin.id === adminToEdit.id) {
      openModalWith({
        title: "Error",
        body: <p>You cannot edit yourself as an admin.</p>,
        hideButtons: true,
      });
    } else {
      openModalWith({
        title: "Edit Admin",
        body: <EditAdminModal admin={adminToEdit} fetchAdmins={fetchAdmins} />,
        hideButtons: true,
      });
    }
  };

  const handleDeleteAdminSubmit = async (adminID: number) => {
    setShowModal(false);
    try {
      await AdminApi.deleteAdmin(adminID);
      setAlert({
        display: true,
        type: "success",
        message: "Admin was permanently deleted",
      });
    } catch (e: any) {
      console.error(e);
      setAlert({
        display: true,
        type: "error",
        message: e.message || "There was an error deleting this admin",
      });
    } finally {
      fetchAdmins();
    }
  };

  const handleDeleteAdmin = (adminID: number) => {
    if (admin && adminID === admin.id) {
      openModalWith({
        title: "Error",
        body: <p>You cannot delete yourself as an admin.</p>,
        hideButtons: true,
      });
    } else {
      openModalWith({
        title: "Delete Admin",
        body: <p>Are you sure you want to permanently delete this admin?</p>,
        onSubmit: () => handleDeleteAdminSubmit(adminID),
        submitLabel: "Delete",
      });
    }
  };

  return (
    <>
      <div>
        <button
          className="btn-secondary btn-sm btn mb-2 w-full text-text-light"
          onClick={handleNewAdmin}
        >
          + New Admin
        </button>
      </div>
      <table
        className={
          isMobile
            ? "table-compact block table w-full shadow-lg"
            : "block table w-full shadow-lg"
        }
      >
        <thead className="">
          <tr className="sticky top-0 border-b-2 border-secondary text-secondary">
            <th className="sticky top-0 rounded-tl-lg bg-card-light text-xs dark:bg-card-dark sm:text-sm">
              Name
            </th>
            <th className="sticky top-0 bg-card-light text-xs dark:bg-card-dark sm:text-sm">
              Email
            </th>
            <th className="sticky top-0 bg-card-light text-xs dark:bg-card-dark sm:text-sm">
              Security Level
            </th>
            <th className="sticky top-0 rounded-tr-lg bg-card-light text-center text-xs dark:bg-card-dark sm:text-sm">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {admins &&
            admins.map((_admin: Admin) => (
              <tr key={_admin.id}>
                <td className="bg-card-light text-left text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">
                  <button
                    className=" btn-ghost btn-sm btn cursor-pointer normal-case"
                    onClick={() =>
                      copyTextToClipboard(
                        `${_admin.firstName} ${_admin.lastName}`
                      )
                    }
                  >
                    {_admin.firstName + " " + _admin.lastName}
                  </button>
                </td>
                <td className="bg-card-light text-left text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">
                  <button
                    className=" btn-ghost btn-sm btn cursor-pointer normal-case"
                    onClick={() => copyTextToClipboard(_admin.email)}
                  >
                    {_admin.email}
                  </button>
                </td>
                <td className="bg-card-light text-left text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">
                  {_admin.securityLevel === 1 ? "Owner" : "Custom"}
                </td>
                <td className="bg-card-light dark:bg-card-dark">
                  {_admin.id !== admin?.id && (
                    <div className="flex items-center justify-center gap-2 bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">
                      <EditButton onClick={() => handleEditAdmin(_admin)} />
                      <CircularActionButton
                        icon={faTrash}
                        onClick={() => handleDeleteAdmin(_admin.id)}
                      />
                    </div>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default Admins;
