import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCircleExclamation,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useEffect } from "react";
import type { Alert } from "src/contexts/AlertContext";
import useAlert from "src/hooks/useAlert";

interface AlertErrorProps {
  alert: Alert;
  onClose?: () => void;
  _className?: string;
  color?: {
    bg?: string;
    text?: string;
  };
  removeBackground?: boolean
}

const AlertComponent = ({
  alert,
  color,
  onClose,
  _className,
  removeBackground = false
}: AlertErrorProps) => {

  const { clearAlert } = useAlert();

  let className = {
    bg: "#f3f4f6",
    text: "#3a4453",
  };

  switch (alert.type) {
    case "info":
      className = { ...className, bg: "bg-[#3abff88d] border border-info" };
      className = { ...className, text: "text-text-dark]" };
      break;
    case "error":
      className = { ...className, bg: "bg-[#ed606078] border border-error" };
      className = { ...className, text: "text-text-dark" };
      break;
    case "success":
      className = { ...className, bg: "bg-[#36d39976] border border-success" };
      className = { ...className, text: "text-text-dark" };
      break;
    case "warning":
      className = { ...className, bg: "bg-[#fbbe2382] border border-warning" };
      className = { ...className, text: "text-text-dark" };
      break;
  }

  useEffect(() => {
    // Display the alert for 5 seconds then close
    const timeoutId = setTimeout(() => {
      if (alert.display) clearAlert();
    }, alert.duration || 1000 * 8);

    return () => clearTimeout(timeoutId);
  }, [alert, clearAlert]);

  return (
    <div
      className={
        removeBackground
          ? "bg-white mr-0  rounded-md"
          : "bg-white dark:bg-back-dark mr-0 rounded-md"
      }
    >
      <div
        className={classNames(
          [
            "flex p-2 items-center rounded-md h-[48px] lg:h-[40px]",
            color?.bg,
            _className,
          ],
          {
            [className.bg]: !color?.bg,
          }
        )}
        role="alert"
      >
        <FontAwesomeIcon
          icon={
            alert.type === "success"
              ? faCircleCheck
              : alert.type === "error"
              ? faCircleXmark
              : alert.type === "warning"
              ? faCircleExclamation
              : faCircleInfo
          }
          className={"text-text-dark text-lg"}
        />
        <span className="sr-only">Info</span>
        <div
          className={classNames(
            ["ml-3 text-sm font-medium text-text-dark", color?.text],
            {
              [className.text]: !color?.text,
            }
          )}
        >
          <span>{alert.message}</span>
        </div>
        {Boolean(onClose) && (
          <button
            type="button"
            onClick={onClose}
            className=" ml-auto -mx-1.5 -my-1.5 rounded-lg p-1.5 inline-flex h-8 w-8"
            data-dismiss-target="#alert-2"
            aria-label="Close"
          >
            <span className="sr-only">Close</span>

            <svg
              className={classNames(["w-5 h-5", color?.text], {
                [className.text]: !color?.text,
              })}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default AlertComponent;
