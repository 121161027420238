import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TeamList } from "src/interfaces/team";
import SubscriptionBadge from "../badges/SubscriptionBadge";
import copyTextToClipboard from "src/helpers/copyTextToClipboard";

interface TeamRowProps {
  team: TeamList;
  index: number;
}

const TeamRow = ({ team }: TeamRowProps) => {

  const {
    id,
    companyName,
    returnPhoneNumber,
    email,
    hasFailedPayments,
    hasFailedPaymentDays,
    coinsPurchased,
    coinsSubscription,
  } = team;

  const navigate = useNavigate();

  const handleAccountClick = (team: TeamList) => {
    navigate(`/teams/${id}`);
  };

  const [isHovering, setIsHovering] = useState(false);

  const coins = coinsSubscription || "0" + coinsPurchased || "0";

  

  return (
    <>
      <tr
        className="my-0.5 cursor-pointer bg-card-light text-base  font-medium text-text-dark dark:bg-card-dark dark:text-text-light"
        onClick={() => handleAccountClick(team)}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <td
          className={
            isHovering
              ? "bg-secondary text-text-light dark:bg-secondary"
              : "bg-card-light dark:bg-card-dark"
          }
        >
          <button
            style={{ textTransform: "none", transition: "color 0s" }}
            className="btn btn-ghost btn-sm"
            onClick={() => copyTextToClipboard(id.toString())}
          >
            {team.id}
          </button>
        </td>
        <td
          className={
            isHovering
              ? "bg-secondary text-text-light dark:bg-secondary"
              : "bg-card-light dark:bg-card-dark"
          }
        >
          <button
            style={{ textTransform: "none", transition: "color 0s" }}
            className="btn btn-ghost btn-sm"
            onClick={() => copyTextToClipboard(companyName || "")}
          >
            {team.companyName}
          </button>
        </td>
        <td
          className={
            isHovering
              ? "bg-secondary text-text-light dark:bg-secondary"
              : "bg-card-light dark:bg-card-dark"
          }
        >
          <button
            style={{ textTransform: "none", transition: "color 0s" }}
            className="btn btn-ghost btn-sm"
            onClick={() => copyTextToClipboard(returnPhoneNumber)}
          >
            {returnPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}
          </button>
        </td>
        <td
          className={
            isHovering
              ? "bg-secondary text-text-light dark:bg-secondary"
              : "bg-card-light dark:bg-card-dark"
          }
        >
          <button
            style={{ textTransform: "none", transition: "color 0s" }}
            className="btn btn-ghost btn-sm"
            onClick={() => copyTextToClipboard(email)}
          >
            {email}
          </button>
        </td>
        <td
          className={
            isHovering
              ? "bg-secondary dark:bg-secondary"
              : "bg-card-light dark:bg-card-dark"
          }
        >
          <div className="flex items-center justify-start">
          <SubscriptionBadge
              team={team}
              error={hasFailedPayments}
              days={hasFailedPaymentDays}
            />
          </div>
        </td>
        <td
          className={
            isHovering
              ? "bg-secondary text-text-light dark:bg-secondary"
              : "bg-card-light dark:bg-card-dark"
          }
        >
          {coins}
        </td>
      </tr>
    </>
  );
};

export default TeamRow;
