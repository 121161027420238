import React from "react";
import classNames from "classnames";
import { Option } from "./SelectInput";
import { ChangeEventHandler, ReactNode } from "react";

interface FloatingSelectProps {
  name: string;
  options: Option[];
  title?: string;
  label?: string | ReactNode;
  type?: string;
  placeholder?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  error?: string | null;
  labelProps?: {
    className?: string;
    htmlFor?: string;
  };
  inputProps?: {
    className?: string;
  };
  errorProps?: {
    className?: string;
  };
}

const FloatingSelect = ({
  name,
  label,
  title,
  options,
  placeholder,
  value,
  error,
  labelProps,
  inputProps,
  errorProps,
  onChange,
}: FloatingSelectProps) => {
  const labelClassName = classNames(
    "absolute text-sm text-secondary duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-back-light dark:bg-base-100 px-2 left-2.5 font-bold dark:font-medium",
    labelProps?.className
  );

  let inputClassName;

  if (error) {
    inputClassName = classNames(
      "border-2 border-error px-3 py-3 placeholder-gray-400 text-secondary font-bold placeholder:font-normal rounded text-sm shadow focus:outline-none w-full",
      inputProps?.className
    );
  } else {
    inputClassName = classNames(
      "px-3 py-3 placeholder-gray-400 text-secondary font-bold placeholder:font-normal rounded text-sm shadow border border-secondary w-full h-[48px] focus:outline-0",
      inputProps?.className
    );
  }

  const errorClassName = classNames(
    "text-error text-2xs md:text-xs italic ml-1",
    errorProps?.className
  );

  return (
    <div className="relative w-full">
      {label && (
        <label htmlFor={name} {...labelProps} className={labelClassName}>
          {label}
        </label>
      )}
      <select
        title={title}
        name={name}
        className={inputClassName}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      >
        {options.map((opt: Option) => (
          <option key={`op-${opt.value}`} value={opt.value}>
            {opt.label}
          </option>
        ))}
        ;
      </select>
      {error && (
        <p {...errorProps} className={errorClassName}>
          {error}
        </p>
      )}
    </div>
  );
};
export default FloatingSelect;
