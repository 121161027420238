import { capitalize } from "src/helpers/parseStrings";

interface StatusBadgeProps {
  status: string;
}

const StatusBadge = ({ status }: StatusBadgeProps) => {
  return (
    <>
      <div
        style={{
          outline: "none",
          textTransform: "none",
        }}
        className={
          status === "researching"
            ? "text-text-light dark:text-back-dark btn btn-sm bg-theme1 dark:bg-theme1 border-none"
            : status === "contacted"
            ? "text-text-light dark:text-back-dark btn btn-sm bg-theme4 dark:bg-theme4 border-none"
            : status === "negotiating"
            ? "text-text-light dark:text-back-dark btn btn-sm bg-theme5 dark:bg-theme5 border-none"
            : status === "acquired"
            ? "text-text-light dark:text-back-dark btn btn-sm bg-theme2 dark:bg-theme2 border-none"
            : "text-text-light dark:text-back-dark btn btn-sm bg-theme3 dark:bg-theme3 border-none"
        }
      >
        {capitalize(status)}
      </div>
    </>
  );
};

export default StatusBadge;
