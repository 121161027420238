import { ReactNode } from "react";
import SwiperButton from "./SwiperButton";
import SwiperTitleDropdown from "./SwiperTitleDropdown";

interface SlideProps {
  title: string;
  content: ReactNode;
  slides: any;
}

const SwiperSlideCanvas = ({ title, content, slides }: SlideProps) => {
  return (
    <div className="">
      <div className="flex items-center justify-between bg-card-light dark:bg-card-dark h-[32px] lg:h-[40px] rounded-md z-50 w-full shadow-xl mb-2">
        <div className="ml-2 mt-0.5">
          <SwiperButton direction="left" />
        </div>
        <SwiperTitleDropdown title={title} slides={slides} />
        <div className="mr-2 mt-0.5">
          <SwiperButton direction="right" />
        </div>
      </div>
      <div className="">{content}</div>
    </div>
  );
};

export default SwiperSlideCanvas;
