import React, { useEffect, useState } from "react";
import { adminApi } from "src/api";
import LoadingTable from "src/components/loading/LoadingTable";
import NewAffiliateModal from "src/components/modals/NewAffiliateModal";
import useModal from "src/hooks/useModal";
import Affiliate from "src/interfaces/affiliate";
import AffiliateTable from "src/components/affiliates/AffiliateTable";

export interface AffiliatesTable extends Affiliate {
  teamCount: number;
}

const Affiliates = () => {
  const { openModalWith } = useModal();

  const [affiliates, setAffiliates] = useState<AffiliatesTable[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchAffiliates = async () => {
    setIsLoading(true);
    try {
      const resp = await adminApi.getAffiliates();
      setAffiliates(resp);
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAffiliates();
  }, []);

  const handleNewAffiliate = () => {
    openModalWith({
      title: "New Affiliate",
      body: <NewAffiliateModal fetchAffiliates={fetchAffiliates} />,
      hideButtons: true,
    });
  };

  return (
    <>
      <div>
        <div>
          <button
            className="btn-secondary btn-sm btn mb-2 w-full text-text-light"
            onClick={handleNewAffiliate}
          >
            + New Affiliate
          </button>
        </div>
        {isLoading ? (
          <div className="flex w-full items-center justify-center gap-2">
            <LoadingTable label={"Affiliates"} />
          </div>
        ) : (
          <AffiliateTable
            affiliates={affiliates}
            fetchAffiliates={fetchAffiliates}
          />
        )}
      </div>
    </>
  );
};

export default Affiliates;
