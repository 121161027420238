import React from "react";
import FloatingInput from "../fields/FloatingInputText";

interface NotesDrawerProps {
  note: string;
}

const NotesDrawer = ({ note }: NotesDrawerProps) => {
  return (
    <>
      <div className="container w-full rounded-lg bg-card-light px-2 py-2 text-text-dark dark:bg-card-dark dark:text-text-light ">
        <FloatingInput
          name="message"
          type="textarea"
          inputProps={{
            className: "text-sm border-none p-2",
          }}
          rows={7}
          disabled
          value={note}
        />
      </div>
    </>
  );
};

export default NotesDrawer;
