import React, { useState } from "react";
import LoadingTable from "src/components/loading/LoadingTable";
import ChurnTab from "src/components/reports/ChurnTab";
import ContinuityTab from "src/components/reports/ContinuityTab";
import ReportsToolbar from "src/components/reports/ReportsToolbar";

const Reports = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isActiveLinks, setIsActive] = useState({
    Churn: true,
    Continuity: false,
  });

  const handleActive = (title: string) => {
    setIsLoading(true);
    setIsActive({
      Churn: false,
      Continuity: false,
      [title]: true,
    });
  };

  const ChurnTabRender = () => {
    return <ChurnTab isLoading={isLoading} setIsLoading={setIsLoading} />;
  };

  const ContinuityTabRender = () => {
    return <ContinuityTab isLoading={isLoading} setIsLoading={setIsLoading} />;
  };

  const { Churn, Continuity } = isActiveLinks;

  return (
    <div>
      <ReportsToolbar
        isActiveLinks={isActiveLinks}
        handleActive={handleActive}
      />
      <div>
        {isLoading && (
          <div className="mt-1 flex w-full items-center justify-center gap-2">
            <LoadingTable label={"Report"} />
          </div>
        )}
        {Churn && ChurnTabRender()}
        {Continuity && ContinuityTabRender()}
      </div>
    </div>
  );
};

export default Reports;
