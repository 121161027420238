import React, { useState } from "react";
import { Admin, Permissions } from "src/interfaces/admin";
import useModal from "src/hooks/useModal";
import FloatingSelect from "../fields/FloatingSelect";
import AdminApi from "src/api/admin";
import useAlert from "src/hooks/useAlert";
import Toggle from "../buttons/ToggleX";
import ButtonBlock from "./ButtonBlock";

interface EditAdminModalProps {
  admin: Admin;
  fetchAdmins: () => void;
}

const EditAdminModal = ({ admin, fetchAdmins }: EditAdminModalProps) => {
  const { setShowModal } = useModal();

  const [newSecurityLevel, setNewSecurityLevel] = useState<number>(
    admin.securityLevel
  );

  const [permissions, setPermissions] = useState<Permissions>({
    canDashboard: admin.canDashboard,
    canTeams: admin.canTeams,
    canAdmins: admin.canAdmins,
    canSupport: admin.canSupport,
    canSettings: admin.canSettings,
    canTokens: admin.canTokens,
    canDemo: admin.canDemo,
    canOnboarding: admin.canOnboarding,
    canMigration: admin.canMigration,
    canDash: admin.canDash,
    canUpdateDealioSitePlan: admin.canUpdateDealioSitePlan,
    canReports: admin.canReports
  });

  const { setAlert } = useAlert();

  const handleChange = (e: any) => {
    const { value } = e.target;
    setNewSecurityLevel(parseInt(value));
  };

  const handleToggleChange = (permissionKey: string) => {
    setPermissions({
      ...permissions,
      [permissionKey]: !permissions[permissionKey],
    });
  };

  const handleSubmit = async () => {
    try {
      await AdminApi.update(admin.id, {
        securityLevel: newSecurityLevel,
        ...permissions,
      });
      setAlert({
        display: true,
        type: "success",
        message: `${admin.firstName} was updated successfully`,
      });

      setShowModal(false);
    } catch (e) {
      console.error(e);
      setShowModal(false);
      setAlert({
        display: true,
        type: "error",
        message: `There was an error updating ${admin.firstName}`,
      });
    } finally {
      fetchAdmins();
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const removeCan = (key: string) => {
    return key.split("").slice(3).join("");
  };

  return (
    <>
      <div className="flex w-full items-center justify-center">
        <div className="w-full sm:w-2/3">
          <div className="my-3">
            <FloatingSelect
              name="securityLevel"
              title={"Security Level"}
              label={"Security Level"}
              labelProps={{
                className: "text-text-dark dark:text-text-light",
              }}
              inputProps={{
                className:
                  "border border-primary bg-back-light dark:bg-base-100 text-text-dark dark:text-text-light rounded-lg",
              }}
              options={[
                { label: "Owner", value: 1 },
                { label: "Custom", value: 2 },
              ]}
              value={newSecurityLevel.toString()}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      {newSecurityLevel !== 1 && (
        <div className="flex flex-wrap justify-between">
          {Object.keys(permissions).map((permissionKey) => (
            <div className="w-full p-3 sm:w-1/3" key={permissionKey}>
              <label
                htmlFor={`new${permissionKey}`}
                className="block text-text-dark dark:text-text-light"
              >
                {removeCan(permissionKey)}
              </label>
              <Toggle
                id={`new${permissionKey}`}
                title={permissionKey}
                name={permissionKey}
                className="toggle-secondary toggle toggle-md"
                checked={permissions[permissionKey as keyof typeof permissions]}
                onChange={() => handleToggleChange(permissionKey)}
              />
            </div>
          ))}
        </div>
      )}
      <div className="mt-4">
        <ButtonBlock
          handleSubmit={handleSubmit}
          onCancel={handleCancel}
          submitLabel={"Submit"}
        />
      </div>
    </>
  );
};

export default EditAdminModal;
