const parsePhone = (phone: string) => {
  if (!phone) return "";
  if ((phone || "").length === 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  } else {
    return phone.replace(/\D/g, "");
  }
};

export default parsePhone;
