import React, { useState } from "react";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import { TeamPageData } from "src/interfaces/TeamPageData";
import FloatingInput from "../fields/FloatingInputText";
import { adminApi } from "src/api";
import ButtonBlock from "./ButtonBlock";

interface EditTeamModalProps {
  team: TeamPageData;
  fetchTeam: () => void;
}

const EditTeamModal = ({ team, fetchTeam }: EditTeamModalProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  interface FormState {
    companyName: string;
    teamLeaderEmail: string;
  }

  const initialFormState = {
    companyName: team.team.companyName || "",
    teamLeaderEmail: team.user.email || "",
  };

  const initialFormErrors: {
    companyName: string;
    teamLeaderEmail: string;
  } = {
    companyName: "",
    teamLeaderEmail: "",
  };

  interface FormErrors {
    companyName: string | null;
    teamLeaderEmail: string | null;
  }

  const [formData, setFormData] = useState<FormState>(initialFormState);
  const [onSubmitting, setSubmitting] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    setFormData({ ...formData, [name]: checked || value });
  };

  const validate: any = (data: any) => {
    const errors: {
      companyName?: string;
      teamLeaderEmail?: string;
    } = {};
    if (!data.companyName) {
      errors.companyName = "First name is required";
    }
    if (!data.teamLeaderEmail) {
      errors.teamLeaderEmail = "Last name is required";
    }
    return errors;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let errors = validate(formData);
    if (errors.companyName || errors.teamLeaderEmail) {
      return setFormErrors(errors);
    } else {
      setSubmitting(true);
      try {
        const { companyName, teamLeaderEmail } = formData;
        await adminApi.updateTeam({
          ...team.team,
          companyName: companyName,
        });
        await adminApi.updateTeamUsers(team.user.id, {
          toChange: "email",
          newValue: teamLeaderEmail,
        });
        setShowModal(false);
        setAlert({
          display: true,
          message: `${companyName} has been updated`,
          type: "success",
        });
      } catch (err: any) {
        setAlert({
          display: true,
          message: err?.message || "Unable to update team",
          type: "error",
        });
      } finally {
        fetchTeam();
        setShowModal(false);
      }
      setSubmitting(false);
    }
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  return (
    <>
      <div>
        <div className="mb-3 flex w-full items-center justify-center">
          <div className="w-full sm:w-2/3">
            <div className="my-3">
              <FloatingInput
                name={"companyName"}
                type={"text"}
                value={formData.companyName}
                onChange={handleChange}
                error={formErrors.companyName}
                label={"Company Name"}
              />
            </div>
            <div className="my-3">
              <FloatingInput
                name={"teamLeaderEmail"}
                type={"text"}
                value={formData.teamLeaderEmail}
                onChange={handleChange}
                error={formErrors.teamLeaderEmail}
                label={"Team Leader Email"}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <ButtonBlock
            submitting={onSubmitting}
            handleSubmit={handleSubmit}
            onCancel={handleCancel}
            submitLabel={"Save"}
            disabled={onSubmitting}
          />
        </div>
      </div>
    </>
  );
};

export default EditTeamModal;
