import { ReactNode } from "react";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import SwiperSlideCanvas from "./SwiperSlideCanvas";
import SwiperCore, { Keyboard, Mousewheel } from "swiper";
import useViewport from "src/hooks/useViewport";

interface Slide {
  title: string;
  content: ReactNode;
}

interface SwiperTableProps extends SwiperProps {
  slides: Slide[];
}

const SwiperTable = ({ slides, ...props }: SwiperTableProps) => {
  const { isMobile } = useViewport();

  SwiperCore.use([Keyboard, Mousewheel]);

  return (
    <>
      <Swiper
        spaceBetween={30}
        allowTouchMove={isMobile ? true : false}
        speed={700}
        keyboard={true}
        mousewheel={{ forceToAxis: true, sensitivity: 0, releaseOnEdges: true }}
        modules={[Keyboard, Mousewheel]}
        {...props}
      >
        {slides.map((slide: Slide, index: number) => {
          return (
            <SwiperSlide
              className="swiper-slide"
              key={index}
              id={`${slide.title}`}
            >
              <SwiperSlideCanvas
                slides={slides}
                title={slide.title}
                content={slide.content}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default SwiperTable;
