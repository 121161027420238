import { Configs } from "src/interfaces/configs";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";

class ConfigApi extends ApiTokenSupport {
  async getConfigs(): Promise<Configs[]> {
    const resp = await axios.get<Configs[]>(`/api/config/configs`, this.withAuthorization());
    return resp.data;
  }

  async toggleConfig(id: number): Promise<Configs> {
    const resp = await axios.put<any>(
      `/api/config/update-configs`,
      { id },
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new ConfigApi();
