import React from "react";
import { AffiliatesTable } from "src/pages/private/Affiliates";
import AffiliateRow from "./AffiliateRow";

interface AffiliateTableProps {
  affiliates: AffiliatesTable[];
  fetchAffiliates: () => void;
}

const AffiliateTable = ({
  affiliates,
  fetchAffiliates,
}: AffiliateTableProps) => {
  return (
    <>
      <table className={"block table w-full shadow-xl"}>
        <thead>
          <tr className="sticky top-0 border-separate border-b-2 border-secondary text-secondary">
            <th className="sticky top-[115px] max-w-full whitespace-normal rounded-tl-lg bg-card-light text-base dark:bg-card-dark lg:top-0">
              App
            </th>
            <th className="sticky top-[115px] bg-card-light text-base  dark:bg-card-dark lg:top-0">
              Name
            </th>
            <th className="sticky top-[115px] bg-card-light text-base  dark:bg-card-dark lg:top-0">
              Email
            </th>
            <th className="sticky top-[115px] bg-card-light text-base  dark:bg-card-dark lg:top-0 ">
              Link
            </th>
            <th className="sticky top-[115px] bg-card-light text-base  dark:bg-card-dark lg:top-0 ">
              Teams
            </th>
            <th className="select-none rounded-tr-lg bg-card-light dark:bg-card-dark">
              Active
            </th>
          </tr>
        </thead>
        <tbody className="">
          {affiliates.length ? (
            affiliates
              ?.sort(
                (a: AffiliatesTable, b: AffiliatesTable) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              )
              .map((affiliate: AffiliatesTable) => (
                <AffiliateRow
                  key={`affiliate-row-${affiliate.id}`}
                  affiliate={affiliate}
                  fetchAffiliates={fetchAffiliates}
                />
              ))
          ) : (
            <tr>
              <td
                className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                colSpan={6}
              >
                No Affiliates Yet
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default AffiliateTable;
