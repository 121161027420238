import React, { useState } from "react";
import { parseMonthDayYear } from "src/helpers/parseEpochs";
import useAdminContext from "src/hooks/private/useAdminContext";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import AdminApi from "src/api/admin";
import ToggleSupportTicketDetails from "../support/ToggleSupportTicketDetails";
import NotesDrawer from "./NotesDrawer";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import CircularActionButton from "../buttons/CircularActionButton";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import TeamNote from "src/interfaces/teamNote";
import NewTeamNoteModal from "../modals/NewTeamNoteModal";

interface NotesRowProps {
  team: any;
  note: any;
  fetchNotes: any;
}

const NotesRow = ({ team, note, fetchNotes }: NotesRowProps) => {
  const { teamID } = team;
  const { openModalWith, setShowModal } = useModal();
  const { admin } = useAdminContext();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { setAlert } = useAlert();

  const handleDelete = (noteID: number) => {
    openModalWith({
      title: "Delete Team Note",
      body: "Are you sure you want to delete this Team Note?",
      onSubmit: () => handleDeleteSubmit(noteID),
      submitLabel: "Delete",
    });
  };

  const handleEdit = (note: TeamNote) => {
    openModalWith({
      title: "Edit Team Note",
      body: (
        <NewTeamNoteModal
          teamID={teamID}
          fetchNotes={fetchNotes}
          teamNote={note}
        />
      ),
      hideButtons: true,
    });
  };

  const handleDeleteSubmit = async (noteID: number) => {
    setShowModal(false);
    try {
      if (teamID) {
        await AdminApi.deleteTeamNote(teamID, noteID);
      }
    } catch (err: any) {
      setAlert({
        display: true,
        message: "There was an error deleting this Team Note",
        type: "error",
      });
    } finally {
      return fetchNotes();
    }
  };

  const handleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <tr>
        <td className="bg-card-light text-left text-xs font-medium text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">
          {parseMonthDayYear(note.createdAt)}
        </td>
        <td className="bg-card-light text-left text-xs font-medium text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">
          {note.author}
        </td>
        <td className="flex items-center justify-between bg-card-light text-left text-xs font-medium text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">
          <div className="flex gap-1">
            {note.author === `${admin?.firstName} ${admin?.lastName}` && (
              <CircularActionButton
                icon={faPenToSquare}
                onClick={() => handleEdit(note)}
              />
            )}
            {note.author === `${admin?.firstName} ${admin?.lastName}` && (
              <CircularActionButton
                icon={faTrash}
                onClick={() => handleDelete(note.id)}
              />
            )}
          </div>
          <ToggleSupportTicketDetails
            onClick={handleDrawer}
            isToggled={isDrawerOpen}
          />
        </td>
      </tr>
      <tr
        className={
          isDrawerOpen
            ? "relative bg-card-light transition-all duration-300 dark:bg-card-dark "
            : "absolute h-0 bg-card-light duration-300 dark:bg-card-dark"
        }
      >
        {isDrawerOpen && (
          <td
            colSpan={3}
            className="overflow-x-hidden border-x bg-back-light dark:border-x dark:border-card-dark dark:bg-back-dark"
            style={{
              overflowWrap: "break-word",
            }}
          >
            <NotesDrawer note={note.note} />
          </td>
        )}
      </tr>
    </>
  );
};

export default NotesRow;
