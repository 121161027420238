import React from "react";
import NotesRow from "./NotesRow";
import useModal from "src/hooks/useModal";
import NewTeamNoteModal from "../modals/NewTeamNoteModal";

interface NotesTableProps {
  team: any;
  notes: any;
  fetchNotes: any;
}

const NotesTable = ({ team, notes, fetchNotes }: NotesTableProps) => {

  const { id } = team.team;

  const { openModalWith } = useModal();

  const handleNewNote = () => {
    if (id) {
      openModalWith({
        title: "New Team Note",
        body: <NewTeamNoteModal teamID={id} fetchNotes={fetchNotes} />,
        hideButtons: true,
      });
    }
  };

  return (
    <>
      <table className={"block table w-full shadow-lg"}>
        <thead className="">
          <tr className="sticky top-0 border-b-2 border-secondary text-secondary">
            <th className="sticky top-0 rounded-tl-lg bg-card-light text-xs dark:bg-card-dark sm:text-sm">
              Date
            </th>
            <th className="sticky top-0 bg-card-light text-xs dark:bg-card-dark sm:text-sm">
              Author
            </th>
            <th className="sticky top-0 rounded-tr-lg bg-card-light text-xs dark:bg-card-dark sm:text-sm flex justify-end">
              <button
                className="btn-secondary btn-sm btn w-40 text-text-light"
                onClick={handleNewNote}
              >
                + New Team Note
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {notes.length ? (
            notes
              ?.sort(
                (a: any, b: any) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              )
              .map((note: any, index: number) => (
                <NotesRow
                  team={team}
                  key={index}
                  note={note}
                  fetchNotes={fetchNotes}
                />
              ))
          ) : (
            <tr>
              <td
                className="bg-card-light text-center text-xs font-medium text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                colSpan={4}
              >
                No Notes on this team yet
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default NotesTable;
