import React, { useState } from "react";
import CreateNewRealeflowID from "../CreateNewRealeflowID";
import { CreateFormErrors } from "./EditRealeflowID";
import { TeamPageData } from "src/interfaces/TeamPageData";
import { adminApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import ButtonBlock from "./ButtonBlock";

interface CreateRealeflowModalProps {
  team: TeamPageData;
  fetchTeam: () => void;
}

const CreateRealeflowModal = ({
  team,
  fetchTeam,
}: CreateRealeflowModalProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const [onSubmitting, setSubmitting] = useState<boolean>(false);

  const initialFormErrors: {
    realeflowID: string;
  } = {
    realeflowID: "",
  };

  interface FormErrors {
    realeflowID: string | null;
  }

  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const [createFormData, setCreateFormData] = useState({
    FirstName: team.user.firstName || "",
    LastName: team.user.lastName || "",
    Email: team.user.email || "",
    Address: team.teamSettings.mailingAddressLine1 || "",
    City: team.teamSettings.mailingAddressCity || "",
    State: team.teamSettings.mailingAddressState || "",
    ZipCode: team.teamSettings.mailingAddressZip || "",
    Phone: team.user.phoneNumber ? team.user.phoneNumber.toString() : "",
  });

  const handleCreateFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreateFormData({ ...createFormData, [e.target.name]: e.target.value });
  };

  const initialCreateFormErrors: CreateFormErrors = {
    FirstName: "",
    LastName: "",
    Email: "",
    Address: "",
    City: "",
    State: "",
    ZipCode: "",
    Phone: "",
  };

  const [createFormErrors, setCreateFormErrors] = useState(
    initialCreateFormErrors
  );

  const validateCreateForm = (data: typeof createFormData) => {
    const errors: typeof initialCreateFormErrors = {
      ...initialCreateFormErrors,
    };

    if (!data.FirstName.trim()) errors.FirstName = "First name is required";
    if (!data.LastName.trim()) errors.LastName = "Last name is required";
    if (!data.Email.trim()) errors.Email = "Email is required";
    if (!data.Address.trim()) errors.Address = "Address is required";
    if (!data.City.trim()) errors.City = "City is required";
    if (!data.State.trim()) errors.State = "State is required";
    if (!data.ZipCode.trim()) errors.ZipCode = "Zipcode is required";
    if (data.ZipCode.trim().length !== 5)
      errors.ZipCode = "Zipcode must be 5 digits";
    if (!data.Phone.trim()) errors.Phone = "Phone number is required";
    if (data.Phone.trim().length !== 10)
      errors.Phone = "Phone number must be 10 digits";

    // Additional validations like email format, phone number format, etc., can be added here

    return errors;
  };

  const handleCreateNewAccount = async () => {
    const errors = validateCreateForm(createFormData);
    setCreateFormErrors(errors);

    if (Object.values(errors).some((error) => error !== "")) {
      return; // Stop the function if there are errors
    }

    try {
      setSubmitting(true);
      const res = await adminApi.createRealeflowID({
        ...createFormData,
        ExternalAccountId: team.team.keapID.toString(),
        PlanId: "896",
        teamID: team.team.id,
      });
      if (res.success) {
        fetchTeam();
        setShowModal(false);
        setSubmitting(false);
        setAlert({
          display: true,
          type: "success",
          message: "Team's RealeflowID was successfully created",
        });
      } else {
        const errors = {
          realeflowID: res.error,
        };
        setFormErrors(errors);
        setSubmitting(false);
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="px-28">
        <CreateNewRealeflowID
          formData={createFormData}
          handleChange={handleCreateFormChange}
          errors={createFormErrors}
        />
        <div className="my-4">
          <span className="text-error">{formErrors.realeflowID}</span>
        </div>
      </div>
      <div className="mt-4">
        <ButtonBlock
          submitting={onSubmitting}
          handleSubmit={handleCreateNewAccount}
          onCancel={handleCancel}
          submitLabel={"Submit"}
          disabled={onSubmitting}
        />
      </div>
    </>
  );
};

export default CreateRealeflowModal;
