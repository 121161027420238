const SearchBarMobile = () => {
  

  return (
    <>
      {/* <div ref={ref} className="bg-back-light dark:bg-back-dark">
        <div
          onClick={handleClick}
          className="fixed flex items-center justify-center w-full h-24 bg-back-light dark:bg-back-dark left-0 top-20 z-20"
        >
          <div className="flex items-center w-full mx-4 shadow-md border-solid bg-card-blend-light dark:bg-card-dark rounded-lg ">
            <div className="">
              <FontAwesomeIcon
                className="text-purple pl-4 text-xl md:text-3xl text-center"
                icon={faMagnifyingGlass}
              />
            </div>
            <div className="min-w-screen">
              <form onSubmit={handleSubmit}>
                <input
                  id="searchproperties"
                  ref={searchInput}
                  type="text"
                  name="search"
                  placeholder="Search properties"
                  className={
                    "ml-2 placeholder:italic placeholder:text-lg bg-card-blend-light dark:bg-card-dark font-medium h-16 focus:outline-none border-none text-slate-dark dark:text-white text-xs md:text-3xl mx-12 w-64"
                  }
                  value={value}
                  onChange={handleInput}
                  disabled={!ready}
                />
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default SearchBarMobile;
