import React, { useEffect, useState } from "react";
import { statisticsApi } from "src/api";

export interface DashStats {
  teams: number;
  drives: number;
  properties: number;
  propertiesAdded: number;
}

const DashChart = () => {
  const [dashStats, setDashStats] = useState<DashStats>();

  const fetchDashStats = async () => {
    try {
      const res = await statisticsApi.getDashStats();
      setDashStats(res);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchDashStats();
  }, []);
  return (
    <>
      <div className="stats stats-vertical bg-card-light shadow dark:bg-card-dark">
        <div className="stat">
          <div className="stat-title text-zinc-600 dark:text-gray-400">
            Teams With Dash
          </div>
          <div className="stat-value text-zinc-500 dark:text-slate-300">
            {dashStats?.teams || 0}
          </div>
        </div>

        <div className="stat">
          <div className="stat-title text-zinc-600 dark:text-gray-400">
            Drives
          </div>
          <div className="stat-value text-zinc-500 dark:text-slate-300">
            {dashStats?.drives || 0}
          </div>
        </div>

        <div className="stat">
          <div className="stat-title text-zinc-600 dark:text-gray-400">
            Properties Found
          </div>
          <div className="stat-value text-zinc-500 dark:text-slate-300">
            {dashStats?.properties || 0}
          </div>
        </div>
        <div className="stat">
          <div className="stat-title text-zinc-600 dark:text-gray-400">
            Properties Added
          </div>
          <div className="stat-value text-zinc-500 dark:text-slate-300">
            {dashStats?.propertiesAdded || 0}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashChart;
