/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";
import Property from "src/interfaces/property";
import useViewport from "src/hooks/useViewport";

interface PropertyLimited {
  houseNumber: number;
  preDirectional: string;
  street: string;
  suffix: string;
  city: string;
  state: string;
}

interface PropertyButtonProps {
  property: PropertyLimited | Property;
}

const PropertyButton = ({ property }: PropertyButtonProps) => {
  const { width } = useViewport();

  const { houseNumber, preDirectional, street, suffix, city, state } = property;

  const calculateLettersToRemove = () => {
    let num = 20 - Math.floor((430 - width) / 10);
    return num;
  };
  const coerceButtonSize = useMemo(() => {
    const fullAddress = `${houseNumber || ""} ${preDirectional || ""} ${
      street || ""
    } ${suffix || ""}`;
    if (width < 430) {
      if (fullAddress.length > 20 - calculateLettersToRemove()) {
        return fullAddress.slice(0, calculateLettersToRemove());
      } else {
        return fullAddress;
      }
    } else {
      return fullAddress;
    }
  }, [width]);

  const addCityState = useMemo(() => {
    const fullCityState = `${city}, ${state}`;
    if (width < 430) {
      if (fullCityState.length > 20 - calculateLettersToRemove()) {
        return fullCityState.slice(0, calculateLettersToRemove());
      } else {
        return fullCityState;
      }
    } else {
      return fullCityState;
    }
  }, [width]);

  return (
    <>
      <div>
        <label
          style={{
            textTransform: "none",
          }}
          tabIndex={0}
          className="btn btn-ghost mr-0.5 px-0 text-sm hover:bg-hover-card-light hover:dark:bg-hover-card-dark sm:px-4 md:text-base"
        >
          <div>
            <div className="text-xs xs:text-sm lg:text-base">
              {coerceButtonSize}
            </div>
            <div className="text-xs xs:text-sm lg:text-base">
              {addCityState}
            </div>
          </div>
        </label>
      </div>
    </>
  );
};

export default PropertyButton;
