/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import useAdminContext from "src/hooks/private/useAdminContext";
import { useLocation } from "react-router-dom";
import useAuth from '../useAuth';

const useAdminRedirect = () => {

  const { admin } = useAdminContext();

  const location = useLocation();

  const [redirectPath, setRedirectPath] = useState<string | null>(null);
  
  const { logout } = useAuth();

  const permissions = [
    { prop: "canDashboard", route: "/dashboard" },
    { prop: "canTeams", route: "/teams" },
    { prop: "canAdmins", route: "/admins" },
    { prop: "canSupport", route: "/support" },
    { prop: "canSettings", route: "/settings" },
    { prop: "canTokens", route: "/tokens" },
    { prop: "canDemo", route: "/demo" },
    { prop: "canOnboarding", route: "/onboarding" },
    { prop: "canMigration", route: "/migration" }
  ];

  useEffect(() => {
    if (admin && admin?.securityLevel !== 1) {
      const currentRouteAllowed = permissions.some(permission => location.pathname.startsWith(permission.route) && (admin as any)[permission.prop]);

      if (!currentRouteAllowed) {
        const accessibleRoute = permissions.find(permission => (admin as any)[permission.prop]);
        if (accessibleRoute) {
          setRedirectPath(accessibleRoute.route);
        } else {
          logout();
        }
      }
    }
  }, [admin, location.pathname, logout]);

  return redirectPath;
};

export default useAdminRedirect;
