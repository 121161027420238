import React from "react";
import { App } from "src/interfaces/app";
import FloatingInput from "../fields/FloatingInputText";

interface AppInfoModalProps {
  app: App;
}

const AppInfoModal = ({ app }: AppInfoModalProps) => {
  return (
    <>
      <div className="flex items-center justify-center gap-2">
        <div>
          <FloatingInput
            name={"appName"}
            value={app.name}
            label={"App Name"}
            disabled={true}
          />
        </div>
        <a href={app.url} className="cursor-pointer" rel="noreferrer" target="_blank">
          <FloatingInput name={"appURL"} value={app.url} label={"URL"}  />
        </a>
        <div>
        <FloatingInput name={"coinName"} value={app.coinName} label={"Coin Name"}  />

        </div>
      </div>
    </>
  );
};

export default AppInfoModal;
