import React, { useState } from "react";
import dealioSitePlans, { SitePlan } from "src/data/DealioSitePlans";
import useModal from "src/hooks/useModal";
import { realeflowApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import { UpdateSitePlanResponse } from "src/api/realeflow";
import ButtonBlock from "./ButtonBlock";

interface ChangeDealioSitePlanModalProps {
  realeflowID: number;
  currentDealioSitePlanID: number;
}

const ChangeDealioSitePlanModal = ({
  realeflowID,
  currentDealioSitePlanID,
}: ChangeDealioSitePlanModalProps) => {
  const { setAlert } = useAlert();

  const [isSelectedSitePlan, setIsSelectedSitePlan] = useState<number | null>(
    null
  );

  const handleSubmit = async () => {
    setSubmitting(true);
    if (isSelectedSitePlan) {
      try {
        const res: UpdateSitePlanResponse = await realeflowApi.updateSitePlan({
          realeflowID,
          sitePlanID: isSelectedSitePlan,
        });
        if (res.Success) {
          setAlert({
            display: true,
            type: "success",
            message: res.Notes,
          });
        } else {
          setAlert({
            display: true,
            type: "error",
            message: res.Notes,
          });
        }
      } catch (err: any) {
        console.error(err);
        setAlert({
          display: true,
          type: "error",
          message: err.message,
        });
      } finally {
        setSubmitting(false);
        setShowModal(false);
      }
    }
  };

  const { setShowModal } = useModal();

  const handleCancel = () => {
    setShowModal(false);
  };

  const [submitting, setSubmitting] = useState<boolean>(false);

  return (
    <>
      <div className="flex w-full flex-col items-center justify-center">
        <div className="flex w-full justify-center">
          <div className="my-4 flex w-full flex-col items-center ">
            {dealioSitePlans.map((plan: SitePlan) => (
              <div
                key={plan.id}
                className="mb-1 flex w-[485px] items-center gap-2"
              >
                <input
                  type="radio"
                  name="sitePlan"
                  className="radio-secondary radio"
                  onChange={() => setIsSelectedSitePlan(plan.id)}
                  checked={isSelectedSitePlan === plan.id}
                  disabled={currentDealioSitePlanID === plan.id}
                />
                <div className="w-12">
                  <span
                    className={
                      isSelectedSitePlan === plan.id
                        ? "font-semibold text-secondary"
                        : currentDealioSitePlanID === plan.id
                        ? "text-zinc-500"
                        : "text-text-dark dark:text-text-light"
                    }
                  >
                    {plan.id}
                  </span>
                </div>
                <div>
                  <span
                    className={
                      isSelectedSitePlan === plan.id
                        ? "font-semibold text-secondary"
                        : currentDealioSitePlanID === plan.id
                        ? "text-zinc-500"
                        : "text-text-dark dark:text-text-light"
                    }
                  >
                    {plan.description}
                  </span>
                </div>
                {plan.hasDash && (
                  <div>
                    <div className="badge badge-secondary">Dash</div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="mt-4">
          <ButtonBlock
            submitting={submitting}
            handleSubmit={handleSubmit}
            onCancel={handleCancel}
            submitLabel={"Submit"}
            disabled={!isSelectedSitePlan}
          />
        </div>
      </div>
    </>
  );
};

export default ChangeDealioSitePlanModal;
