/* eslint-disable react-hooks/exhaustive-deps */
import Property from "src/interfaces/property";
import { timeAgo } from "src/helpers/parseEpochs";
import { capitalize } from "src/helpers/parseStrings";
import UserAvatar from "../buttons/UserAvatar";
import useViewport from "src/hooks/useViewport";
import ActivityBadge from "../swiper/property/ActivityBadge";
import { PropertyUpdate } from "src/interfaces/propertyUpdate";
import { useEffect, useState } from "react";
import AdminApi from "src/api/admin";

interface PropertyUpdateTableProps {
  property: Property;
}

const PropertyUpdateTable = ({ property }: PropertyUpdateTableProps) => {
  const { id } = property;

  const [propertyUpdates, setPropertyUpdates] = useState<PropertyUpdate[]>([]);

  const fetchPropertyUpdates = async () => {
    if (id) {
      const response = await AdminApi.getPropertyUpdates(id);
      setPropertyUpdates(response);
    }
  };

  useEffect(() => {
    fetchPropertyUpdates();
  }, [property]);

  const { isMobile } = useViewport();
  return (
    <table
      className={
        isMobile || propertyUpdates.length > 5
          ? "table-compact block table w-full shadow-lg"
          : "block table w-full shadow-lg"
      }
    >
      <thead className="">
        <tr className="sticky top-0 border-b-2 border-secondary text-secondary">
          <th className="sticky top-0 bg-card-light text-xs dark:bg-card-dark sm:text-sm">
            When
          </th>
          <th className="sticky top-0 bg-card-light text-xs dark:bg-card-dark sm:text-sm">
            Team Member
          </th>
          <th className="sticky top-0 bg-card-light text-xs dark:bg-card-dark sm:text-sm">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        {propertyUpdates.length ? (
          propertyUpdates?.map((update: any, index: number) => (
            <tr key={index}>
              <td className="bg-card-light text-left font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                {timeAgo(update.createdAt)}
              </td>
              <td className="bg-card-light text-left font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                <UserAvatar
                  user={{
                    id: update.userID,
                    teamID: update.teamID,
                    email: update.email,
                    firstName: update.firstName,
                    lastName: update.lastName,
                    fullName: update.fullName,
                    isLeader: update.isLeader,
                  }}
                />
              </td>
              <td className="bg-card-light text-left font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                <ActivityBadge title={capitalize(update.key)} />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td
              className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
              colSpan={3}
            >
              No activity on this property yet
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default PropertyUpdateTable;
