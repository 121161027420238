interface Fips {
  fips: string;
  name: string;
  state: string;
}

const fips: Fips[] = [
  { fips: "01001", name: "Autauga County", state: "Alabama" },
  { fips: "01003", name: "Baldwin County", state: "Alabama" },
  { fips: "01005", name: "Barbour County", state: "Alabama" },
  { fips: "01007", name: "Bibb County", state: "Alabama" },
  { fips: "01009", name: "Blount County", state: "Alabama" },
  { fips: "01011", name: "Bullock County", state: "Alabama" },
  { fips: "01013", name: "Butler County", state: "Alabama" },
  { fips: "01015", name: "Calhoun County", state: "Alabama" },
  { fips: "01017", name: "Chambers County", state: "Alabama" },
  { fips: "01019", name: "Cherokee County", state: "Alabama" },
  { fips: "01021", name: "Chilton County", state: "Alabama" },
  { fips: "01023", name: "Choctaw County", state: "Alabama" },
  { fips: "01025", name: "Clarke County", state: "Alabama" },
  { fips: "01027", name: "Clay County", state: "Alabama" },
  { fips: "01029", name: "Cleburne County", state: "Alabama" },
  { fips: "01031", name: "Coffee County", state: "Alabama" },
  { fips: "01033", name: "Colbert County", state: "Alabama" },
  { fips: "01035", name: "Conecuh County", state: "Alabama" },
  { fips: "01037", name: "Coosa County", state: "Alabama" },
  { fips: "01039", name: "Covington County", state: "Alabama" },
  { fips: "01041", name: "Crenshaw County", state: "Alabama" },
  { fips: "01043", name: "Cullman County", state: "Alabama" },
  { fips: "01045", name: "Dale County", state: "Alabama" },
  { fips: "01047", name: "Dallas County", state: "Alabama" },
  { fips: "01049", name: "DeKalb County", state: "Alabama" },
  { fips: "01051", name: "Elmore County", state: "Alabama" },
  { fips: "01053", name: "Escambia County", state: "Alabama" },
  { fips: "01055", name: "Etowah County", state: "Alabama" },
  { fips: "01057", name: "Fayette County", state: "Alabama" },
  { fips: "01059", name: "Franklin County", state: "Alabama" },
  { fips: "01061", name: "Geneva County", state: "Alabama" },
  { fips: "01063", name: "Greene County", state: "Alabama" },
  { fips: "01065", name: "Hale County", state: "Alabama" },
  { fips: "01067", name: "Henry County", state: "Alabama" },
  { fips: "01069", name: "Houston County", state: "Alabama" },
  { fips: "01071", name: "Jackson County", state: "Alabama" },
  { fips: "01073", name: "Jefferson County", state: "Alabama" },
  { fips: "01075", name: "Lamar County", state: "Alabama" },
  { fips: "01077", name: "Lauderdale County", state: "Alabama" },
  { fips: "01079", name: "Lawrence County", state: "Alabama" },
  { fips: "01081", name: "Lee County", state: "Alabama" },
  { fips: "01083", name: "Limestone County", state: "Alabama" },
  { fips: "01085", name: "Lowndes County", state: "Alabama" },
  { fips: "01087", name: "Macon County", state: "Alabama" },
  { fips: "01089", name: "Madison County", state: "Alabama" },
  { fips: "01091", name: "Marengo County", state: "Alabama" },
  { fips: "01093", name: "Marion County", state: "Alabama" },
  { fips: "01095", name: "Marshall County", state: "Alabama" },
  { fips: "01097", name: "Mobile County", state: "Alabama" },
  { fips: "01099", name: "Monroe County", state: "Alabama" },
  { fips: "01101", name: "Montgomery County", state: "Alabama" },
  { fips: "01103", name: "Morgan County", state: "Alabama" },
  { fips: "01105", name: "Perry County", state: "Alabama" },
  { fips: "01107", name: "Pickens County", state: "Alabama" },
  { fips: "01109", name: "Pike County", state: "Alabama" },
  { fips: "01111", name: "Randolph County", state: "Alabama" },
  { fips: "01113", name: "Russell County", state: "Alabama" },
  { fips: "01115", name: "St. Clair County", state: "Alabama" },
  { fips: "01117", name: "Shelby County", state: "Alabama" },
  { fips: "01119", name: "Sumter County", state: "Alabama" },
  { fips: "01121", name: "Talladega County", state: "Alabama" },
  { fips: "01123", name: "Tallapoosa County", state: "Alabama" },
  { fips: "01125", name: "Tuscaloosa County", state: "Alabama" },
  { fips: "01127", name: "Walker County", state: "Alabama" },
  { fips: "01129", name: "Washington County", state: "Alabama" },
  { fips: "01131", name: "Wilcox County", state: "Alabama" },
  { fips: "01133", name: "Winston County", state: "Alabama" },
  { fips: "02013", name: "Aleutians East Borough", state: "Alaska" },
  { fips: "02016", name: "Aleutians West Census Area", state: "Alaska" },
  { fips: "02020", name: "Anchorage", state: "Alaska" },
  { fips: "02050", name: "Bethel Census Area", state: "Alaska" },
  { fips: "02060", name: "Bristol Bay Borough", state: "Alaska" },
  { fips: "02063", name: "Chugach Census Area", state: "Alaska" },
  { fips: "02066", name: "Copper River Census Area", state: "Alaska" },
  { fips: "02068", name: "Denali Borough", state: "Alaska" },
  { fips: "02070", name: "Dillingham Census Area", state: "Alaska" },
  { fips: "02090", name: "Fairbanks North Star Borough", state: "Alaska" },
  { fips: "02100", name: "Haines Borough", state: "Alaska" },
  { fips: "02105", name: "Hoonah–Angoon Census Area", state: "Alaska" },
  { fips: "02110", name: "Juneau", state: "Alaska" },
  { fips: "02122", name: "Kenai Peninsula Borough", state: "Alaska" },
  { fips: "02130", name: "Ketchikan Gateway Borough", state: "Alaska" },
  { fips: "02150", name: "Kodiak Island Borough", state: "Alaska" },
  { fips: "02158", name: "Kusilvak Census Area", state: "Alaska" },
  { fips: "02164", name: "Lake and Peninsula Borough", state: "Alaska" },
  { fips: "02170", name: "Matanuska-Susitna Borough", state: "Alaska" },
  { fips: "02180", name: "Nome Census Area", state: "Alaska" },
  { fips: "02185", name: "North Slope Borough", state: "Alaska" },
  { fips: "02188", name: "Northwest Arctic Borough", state: "Alaska" },
  { fips: "02195", name: "Petersburg Borough", state: "Alaska" },
  {
    fips: "02198",
    name: "Prince of Wales – Hyder Census Area",
    state: "Alaska",
  },
  { fips: "02220", name: "Sitka", state: "Alaska" },
  { fips: "02230", name: "Skagway", state: "Alaska" },
  { fips: "02240", name: "Southeast Fairbanks Census Area", state: "Alaska" },
  { fips: "02275", name: "Wrangell", state: "Alaska" },
  { fips: "02282", name: "Yakutat", state: "Alaska" },
  { fips: "02290", name: "Yukon–Koyukuk Census Area", state: "Alaska" },
  { fips: "04001", name: "Apache County", state: "Arizona" },
  { fips: "04003", name: "Cochise County", state: "Arizona" },
  { fips: "04005", name: "Coconino County", state: "Arizona" },
  { fips: "04007", name: "Gila County", state: "Arizona" },
  { fips: "04009", name: "Graham County", state: "Arizona" },
  { fips: "04011", name: "Greenlee County", state: "Arizona" },
  { fips: "04012", name: "La Paz County", state: "Arizona" },
  { fips: "04013", name: "Maricopa County", state: "Arizona" },
  { fips: "04015", name: "Mohave County", state: "Arizona" },
  { fips: "04017", name: "Navajo County", state: "Arizona" },
  { fips: "04019", name: "Pima County", state: "Arizona" },
  { fips: "04021", name: "Pinal County", state: "Arizona" },
  { fips: "04023", name: "Santa Cruz County", state: "Arizona" },
  { fips: "04025", name: "Yavapai County", state: "Arizona" },
  { fips: "04027", name: "Yuma County", state: "Arizona" },
  { fips: "05001", name: "Arkansas County", state: "Arkansas" },
  { fips: "05003", name: "Ashley County", state: "Arkansas" },
  { fips: "05005", name: "Baxter County", state: "Arkansas" },
  { fips: "05007", name: "Benton County", state: "Arkansas" },
  { fips: "05009", name: "Boone County", state: "Arkansas" },
  { fips: "05011", name: "Bradley County", state: "Arkansas" },
  { fips: "05013", name: "Calhoun County", state: "Arkansas" },
  { fips: "05015", name: "Carroll County", state: "Arkansas" },
  { fips: "05017", name: "Chicot County", state: "Arkansas" },
  { fips: "05019", name: "Clark County", state: "Arkansas" },
  { fips: "05021", name: "Clay County", state: "Arkansas" },
  { fips: "05023", name: "Cleburne County", state: "Arkansas" },
  { fips: "05025", name: "Cleveland County", state: "Arkansas" },
  { fips: "05027", name: "Columbia County", state: "Arkansas" },
  { fips: "05029", name: "Conway County", state: "Arkansas" },
  { fips: "05031", name: "Craighead County", state: "Arkansas" },
  { fips: "05033", name: "Crawford County", state: "Arkansas" },
  { fips: "05035", name: "Crittenden County", state: "Arkansas" },
  { fips: "05037", name: "Cross County", state: "Arkansas" },
  { fips: "05039", name: "Dallas County", state: "Arkansas" },
  { fips: "05041", name: "Desha County", state: "Arkansas" },
  { fips: "05043", name: "Drew County", state: "Arkansas" },
  { fips: "05045", name: "Faulkner County", state: "Arkansas" },
  { fips: "05047", name: "Franklin County", state: "Arkansas" },
  { fips: "05049", name: "Fulton County", state: "Arkansas" },
  { fips: "05051", name: "Garland County", state: "Arkansas" },
  { fips: "05053", name: "Grant County", state: "Arkansas" },
  { fips: "05055", name: "Greene County", state: "Arkansas" },
  { fips: "05057", name: "Hempstead County", state: "Arkansas" },
  { fips: "05059", name: "Hot Spring County", state: "Arkansas" },
  { fips: "05061", name: "Howard County", state: "Arkansas" },
  { fips: "05063", name: "Independence County", state: "Arkansas" },
  { fips: "05065", name: "Izard County", state: "Arkansas" },
  { fips: "05067", name: "Jackson County", state: "Arkansas" },
  { fips: "05069", name: "Jefferson County", state: "Arkansas" },
  { fips: "05071", name: "Johnson County", state: "Arkansas" },
  { fips: "05073", name: "Lafayette County", state: "Arkansas" },
  { fips: "05075", name: "Lawrence County", state: "Arkansas" },
  { fips: "05077", name: "Lee County", state: "Arkansas" },
  { fips: "05079", name: "Lincoln County", state: "Arkansas" },
  { fips: "05081", name: "Little River County", state: "Arkansas" },
  { fips: "05083", name: "Logan County", state: "Arkansas" },
  { fips: "05085", name: "Lonoke County", state: "Arkansas" },
  { fips: "05087", name: "Madison County", state: "Arkansas" },
  { fips: "05089", name: "Marion County", state: "Arkansas" },
  { fips: "05091", name: "Miller County", state: "Arkansas" },
  { fips: "05093", name: "Mississippi County", state: "Arkansas" },
  { fips: "05095", name: "Monroe County", state: "Arkansas" },
  { fips: "05097", name: "Montgomery County", state: "Arkansas" },
  { fips: "05099", name: "Nevada County", state: "Arkansas" },
  { fips: "05101", name: "Newton County", state: "Arkansas" },
  { fips: "05103", name: "Ouachita County", state: "Arkansas" },
  { fips: "05105", name: "Perry County", state: "Arkansas" },
  { fips: "05107", name: "Phillips County", state: "Arkansas" },
  { fips: "05109", name: "Pike County", state: "Arkansas" },
  { fips: "05111", name: "Poinsett County", state: "Arkansas" },
  { fips: "05113", name: "Polk County", state: "Arkansas" },
  { fips: "05115", name: "Pope County", state: "Arkansas" },
  { fips: "05117", name: "Prairie County", state: "Arkansas" },
  { fips: "05119", name: "Pulaski County", state: "Arkansas" },
  { fips: "05121", name: "Randolph County", state: "Arkansas" },
  { fips: "05123", name: "St. Francis County", state: "Arkansas" },
  { fips: "05125", name: "Saline County", state: "Arkansas" },
  { fips: "05127", name: "Scott County", state: "Arkansas" },
  { fips: "05129", name: "Searcy County", state: "Arkansas" },
  { fips: "05131", name: "Sebastian County", state: "Arkansas" },
  { fips: "05133", name: "Sevier County", state: "Arkansas" },
  { fips: "05135", name: "Sharp County", state: "Arkansas" },
  { fips: "05137", name: "Stone County", state: "Arkansas" },
  { fips: "05139", name: "Union County", state: "Arkansas" },
  { fips: "05141", name: "Van Buren County", state: "Arkansas" },
  { fips: "05143", name: "Washington County", state: "Arkansas" },
  { fips: "05145", name: "White County", state: "Arkansas" },
  { fips: "05147", name: "Woodruff County", state: "Arkansas" },
  { fips: "05149", name: "Yell County", state: "Arkansas" },
  { fips: "06001", name: "Alameda County", state: "California" },
  { fips: "06003", name: "Alpine County", state: "California" },
  { fips: "06005", name: "Amador County", state: "California" },
  { fips: "06007", name: "Butte County", state: "California" },
  { fips: "06009", name: "Calaveras County", state: "California" },
  { fips: "06011", name: "Colusa County", state: "California" },
  { fips: "06013", name: "Contra Costa County", state: "California" },
  { fips: "06015", name: "Del Norte County", state: "California" },
  { fips: "06017", name: "El Dorado County", state: "California" },
  { fips: "06019", name: "Fresno County", state: "California" },
  { fips: "06021", name: "Glenn County", state: "California" },
  { fips: "06023", name: "Humboldt County", state: "California" },
  { fips: "06025", name: "Imperial County", state: "California" },
  { fips: "06027", name: "Inyo County", state: "California" },
  { fips: "06029", name: "Kern County", state: "California" },
  { fips: "06031", name: "Kings County", state: "California" },
  { fips: "06033", name: "Lake County", state: "California" },
  { fips: "06035", name: "Lassen County", state: "California" },
  { fips: "06037", name: "Los Angeles County", state: "California" },
  { fips: "06039", name: "Madera County", state: "California" },
  { fips: "06041", name: "Marin County", state: "California" },
  { fips: "06043", name: "Mariposa County", state: "California" },
  { fips: "06045", name: "Mendocino County", state: "California" },
  { fips: "06047", name: "Merced County", state: "California" },
  { fips: "06049", name: "Modoc County", state: "California" },
  { fips: "06051", name: "Mono County", state: "California" },
  { fips: "06053", name: "Monterey County", state: "California" },
  { fips: "06055", name: "Napa County", state: "California" },
  { fips: "06057", name: "Nevada County", state: "California" },
  { fips: "06059", name: "Orange County", state: "California" },
  { fips: "06061", name: "Placer County", state: "California" },
  { fips: "06063", name: "Plumas County", state: "California" },
  { fips: "06065", name: "Riverside County", state: "California" },
  { fips: "06067", name: "Sacramento County", state: "California" },
  { fips: "06069", name: "San Benito County", state: "California" },
  { fips: "06071", name: "San Bernardino County", state: "California" },
  { fips: "06073", name: "San Diego County", state: "California" },
  { fips: "06075", name: "San Francisco", state: "California" },
  { fips: "06077", name: "San Joaquin County", state: "California" },
  { fips: "06079", name: "San Luis Obispo County", state: "California" },
  { fips: "06081", name: "San Mateo County", state: "California" },
  { fips: "06083", name: "Santa Barbara County", state: "California" },
  { fips: "06085", name: "Santa Clara County", state: "California" },
  { fips: "06087", name: "Santa Cruz County", state: "California" },
  { fips: "06089", name: "Shasta County", state: "California" },
  { fips: "06091", name: "Sierra County", state: "California" },
  { fips: "06093", name: "Siskiyou County", state: "California" },
  { fips: "06095", name: "Solano County", state: "California" },
  { fips: "06097", name: "Sonoma County", state: "California" },
  { fips: "06099", name: "Stanislaus County", state: "California" },
  { fips: "06101", name: "Sutter County", state: "California" },
  { fips: "06103", name: "Tehama County", state: "California" },
  { fips: "06105", name: "Trinity County", state: "California" },
  { fips: "06107", name: "Tulare County", state: "California" },
  { fips: "06109", name: "Tuolumne County", state: "California" },
  { fips: "06111", name: "Ventura County", state: "California" },
  { fips: "06113", name: "Yolo County", state: "California" },
  { fips: "06115", name: "Yuba County", state: "California" },
  { fips: "08001", name: "Adams County", state: "Colorado" },
  { fips: "08003", name: "Alamosa County", state: "Colorado" },
  { fips: "08005", name: "Arapahoe County", state: "Colorado" },
  { fips: "08007", name: "Archuleta County", state: "Colorado" },
  { fips: "08009", name: "Baca County", state: "Colorado" },
  { fips: "08011", name: "Bent County", state: "Colorado" },
  { fips: "08013", name: "Boulder County", state: "Colorado" },
  { fips: "08014", name: "Broomfield", state: "Colorado" },
  { fips: "08015", name: "Chaffee County", state: "Colorado" },
  { fips: "08017", name: "Cheyenne County", state: "Colorado" },
  { fips: "08019", name: "Clear Creek County", state: "Colorado" },
  { fips: "08021", name: "Conejos County", state: "Colorado" },
  { fips: "08023", name: "Costilla County", state: "Colorado" },
  { fips: "08025", name: "Crowley County", state: "Colorado" },
  { fips: "08027", name: "Custer County", state: "Colorado" },
  { fips: "08029", name: "Delta County", state: "Colorado" },
  { fips: "08031", name: "Denver", state: "Colorado" },
  { fips: "08033", name: "Dolores County", state: "Colorado" },
  { fips: "08035", name: "Douglas County", state: "Colorado" },
  { fips: "08037", name: "Eagle County", state: "Colorado" },
  { fips: "08039", name: "Elbert County", state: "Colorado" },
  { fips: "08041", name: "El Paso County", state: "Colorado" },
  { fips: "08043", name: "Fremont County", state: "Colorado" },
  { fips: "08045", name: "Garfield County", state: "Colorado" },
  { fips: "08047", name: "Gilpin County", state: "Colorado" },
  { fips: "08049", name: "Grand County", state: "Colorado" },
  { fips: "08051", name: "Gunnison County", state: "Colorado" },
  { fips: "08053", name: "Hinsdale County", state: "Colorado" },
  { fips: "08055", name: "Huerfano County", state: "Colorado" },
  { fips: "08057", name: "Jackson County", state: "Colorado" },
  { fips: "08059", name: "Jefferson County", state: "Colorado" },
  { fips: "08061", name: "Kiowa County", state: "Colorado" },
  { fips: "08063", name: "Kit Carson County", state: "Colorado" },
  { fips: "08065", name: "Lake County", state: "Colorado" },
  { fips: "08067", name: "La Plata County", state: "Colorado" },
  { fips: "08069", name: "Larimer County", state: "Colorado" },
  { fips: "08071", name: "Las Animas County", state: "Colorado" },
  { fips: "08073", name: "Lincoln County", state: "Colorado" },
  { fips: "08075", name: "Logan County", state: "Colorado" },
  { fips: "08077", name: "Mesa County", state: "Colorado" },
  { fips: "08079", name: "Mineral County", state: "Colorado" },
  { fips: "08081", name: "Moffat County", state: "Colorado" },
  { fips: "08083", name: "Montezuma County", state: "Colorado" },
  { fips: "08085", name: "Montrose County", state: "Colorado" },
  { fips: "08087", name: "Morgan County", state: "Colorado" },
  { fips: "08089", name: "Otero County", state: "Colorado" },
  { fips: "08091", name: "Ouray County", state: "Colorado" },
  { fips: "08093", name: "Park County", state: "Colorado" },
  { fips: "08095", name: "Phillips County", state: "Colorado" },
  { fips: "08097", name: "Pitkin County", state: "Colorado" },
  { fips: "08099", name: "Prowers County", state: "Colorado" },
  { fips: "08101", name: "Pueblo County", state: "Colorado" },
  { fips: "08103", name: "Rio Blanco County", state: "Colorado" },
  { fips: "08105", name: "Rio Grande County", state: "Colorado" },
  { fips: "08107", name: "Routt County", state: "Colorado" },
  { fips: "08109", name: "Saguache County", state: "Colorado" },
  { fips: "08111", name: "San Juan County", state: "Colorado" },
  { fips: "08113", name: "San Miguel County", state: "Colorado" },
  { fips: "08115", name: "Sedgwick County", state: "Colorado" },
  { fips: "08117", name: "Summit County", state: "Colorado" },
  { fips: "08119", name: "Teller County", state: "Colorado" },
  { fips: "08121", name: "Washington County", state: "Colorado" },
  { fips: "08123", name: "Weld County", state: "Colorado" },
  { fips: "08125", name: "Yuma County", state: "Colorado" },
  { fips: "09001", name: "Fairfield County", state: "Connecticut" },
  { fips: "09003", name: "Hartford County", state: "Connecticut" },
  { fips: "09005", name: "Litchfield County", state: "Connecticut" },
  { fips: "09007", name: "Middlesex County", state: "Connecticut" },
  { fips: "09009", name: "New Haven County", state: "Connecticut" },
  { fips: "09011", name: "New London County", state: "Connecticut" },
  { fips: "09013", name: "Tolland County", state: "Connecticut" },
  { fips: "09015", name: "Windham County", state: "Connecticut" },
  { fips: "10001", name: "Kent County", state: "Delaware" },
  { fips: "10003", name: "New Castle County", state: "Delaware" },
  { fips: "10005", name: "Sussex County", state: "Delaware" },
  { fips: "11001", name: "District of Columbia", state: "DC" },
  { fips: "12001", name: "Alachua County", state: "Florida" },
  { fips: "12003", name: "Baker County", state: "Florida" },
  { fips: "12005", name: "Bay County", state: "Florida" },
  { fips: "12007", name: "Bradford County", state: "Florida" },
  { fips: "12009", name: "Brevard County", state: "Florida" },
  { fips: "12011", name: "Broward County", state: "Florida" },
  { fips: "12013", name: "Calhoun County", state: "Florida" },
  { fips: "12015", name: "Charlotte County", state: "Florida" },
  { fips: "12017", name: "Citrus County", state: "Florida" },
  { fips: "12019", name: "Clay County", state: "Florida" },
  { fips: "12021", name: "Collier County", state: "Florida" },
  { fips: "12023", name: "Columbia County", state: "Florida" },
  { fips: "12027", name: "DeSoto County", state: "Florida" },
  { fips: "12029", name: "Dixie County", state: "Florida" },
  { fips: "12031", name: "Duval County", state: "Florida" },
  { fips: "12033", name: "Escambia County", state: "Florida" },
  { fips: "12035", name: "Flagler County", state: "Florida" },
  { fips: "12037", name: "Franklin County", state: "Florida" },
  { fips: "12039", name: "Gadsden County", state: "Florida" },
  { fips: "12041", name: "Gilchrist County", state: "Florida" },
  { fips: "12043", name: "Glades County", state: "Florida" },
  { fips: "12045", name: "Gulf County", state: "Florida" },
  { fips: "12047", name: "Hamilton County", state: "Florida" },
  { fips: "12049", name: "Hardee County", state: "Florida" },
  { fips: "12051", name: "Hendry County", state: "Florida" },
  { fips: "12053", name: "Hernando County", state: "Florida" },
  { fips: "12055", name: "Highlands County", state: "Florida" },
  { fips: "12057", name: "Hillsborough County", state: "Florida" },
  { fips: "12059", name: "Holmes County", state: "Florida" },
  { fips: "12061", name: "Indian River County", state: "Florida" },
  { fips: "12063", name: "Jackson County", state: "Florida" },
  { fips: "12065", name: "Jefferson County", state: "Florida" },
  { fips: "12067", name: "Lafayette County", state: "Florida" },
  { fips: "12069", name: "Lake County", state: "Florida" },
  { fips: "12071", name: "Lee County", state: "Florida" },
  { fips: "12073", name: "Leon County", state: "Florida" },
  { fips: "12075", name: "Levy County", state: "Florida" },
  { fips: "12077", name: "Liberty County", state: "Florida" },
  { fips: "12079", name: "Madison County", state: "Florida" },
  { fips: "12081", name: "Manatee County", state: "Florida" },
  { fips: "12083", name: "Marion County", state: "Florida" },
  { fips: "12085", name: "Martin County", state: "Florida" },
  { fips: "12086", name: "Miami-Dade County", state: "Florida" },
  { fips: "12087", name: "Monroe County", state: "Florida" },
  { fips: "12089", name: "Nassau County", state: "Florida" },
  { fips: "12091", name: "Okaloosa County", state: "Florida" },
  { fips: "12093", name: "Okeechobee County", state: "Florida" },
  { fips: "12095", name: "Orange County", state: "Florida" },
  { fips: "12097", name: "Osceola County", state: "Florida" },
  { fips: "12099", name: "Palm Beach County", state: "Florida" },
  { fips: "12101", name: "Pasco County", state: "Florida" },
  { fips: "12103", name: "Pinellas County", state: "Florida" },
  { fips: "12105", name: "Polk County", state: "Florida" },
  { fips: "12107", name: "Putnam County", state: "Florida" },
  { fips: "12109", name: "St. Johns County", state: "Florida" },
  { fips: "12111", name: "St. Lucie County", state: "Florida" },
  { fips: "12113", name: "Santa Rosa County", state: "Florida" },
  { fips: "12115", name: "Sarasota County", state: "Florida" },
  { fips: "12117", name: "Seminole County", state: "Florida" },
  { fips: "12119", name: "Sumter County", state: "Florida" },
  { fips: "12121", name: "Suwannee County", state: "Florida" },
  { fips: "12123", name: "Taylor County", state: "Florida" },
  { fips: "12125", name: "Union County", state: "Florida" },
  { fips: "12127", name: "Volusia County", state: "Florida" },
  { fips: "12129", name: "Wakulla County", state: "Florida" },
  { fips: "12131", name: "Walton County", state: "Florida" },
  { fips: "12133", name: "Washington County", state: "Florida" },
  { fips: "13001", name: "Appling County", state: "Georgia" },
  { fips: "13003", name: "Atkinson County", state: "Georgia" },
  { fips: "13005", name: "Bacon County", state: "Georgia" },
  { fips: "13007", name: "Baker County", state: "Georgia" },
  { fips: "13009", name: "Baldwin County", state: "Georgia" },
  { fips: "13011", name: "Banks County", state: "Georgia" },
  { fips: "13013", name: "Barrow County", state: "Georgia" },
  { fips: "13015", name: "Bartow County", state: "Georgia" },
  { fips: "13017", name: "Ben Hill County", state: "Georgia" },
  { fips: "13019", name: "Berrien County", state: "Georgia" },
  { fips: "13021", name: "Bibb County", state: "Georgia" },
  { fips: "13023", name: "Bleckley County", state: "Georgia" },
  { fips: "13025", name: "Brantley County", state: "Georgia" },
  { fips: "13027", name: "Brooks County", state: "Georgia" },
  { fips: "13029", name: "Bryan County", state: "Georgia" },
  { fips: "13031", name: "Bulloch County", state: "Georgia" },
  { fips: "13033", name: "Burke County", state: "Georgia" },
  { fips: "13035", name: "Butts County", state: "Georgia" },
  { fips: "13037", name: "Calhoun County", state: "Georgia" },
  { fips: "13039", name: "Camden County", state: "Georgia" },
  { fips: "13043", name: "Candler County", state: "Georgia" },
  { fips: "13045", name: "Carroll County", state: "Georgia" },
  { fips: "13047", name: "Catoosa County", state: "Georgia" },
  { fips: "13049", name: "Charlton County", state: "Georgia" },
  { fips: "13051", name: "Chatham County", state: "Georgia" },
  { fips: "13053", name: "Chattahoochee County", state: "Georgia" },
  { fips: "13055", name: "Chattooga County", state: "Georgia" },
  { fips: "13057", name: "Cherokee County", state: "Georgia" },
  { fips: "13059", name: "Clarke County", state: "Georgia" },
  { fips: "13061", name: "Clay County", state: "Georgia" },
  { fips: "13063", name: "Clayton County", state: "Georgia" },
  { fips: "13065", name: "Clinch County", state: "Georgia" },
  { fips: "13067", name: "Cobb County", state: "Georgia" },
  { fips: "13069", name: "Coffee County", state: "Georgia" },
  { fips: "13071", name: "Colquitt County", state: "Georgia" },
  { fips: "13073", name: "Columbia County", state: "Georgia" },
  { fips: "13075", name: "Cook County", state: "Georgia" },
  { fips: "13077", name: "Coweta County", state: "Georgia" },
  { fips: "13079", name: "Crawford County", state: "Georgia" },
  { fips: "13081", name: "Crisp County", state: "Georgia" },
  { fips: "13083", name: "Dade County", state: "Georgia" },
  { fips: "13085", name: "Dawson County", state: "Georgia" },
  { fips: "13087", name: "Decatur County", state: "Georgia" },
  { fips: "13089", name: "DeKalb County", state: "Georgia" },
  { fips: "13091", name: "Dodge County", state: "Georgia" },
  { fips: "13093", name: "Dooly County", state: "Georgia" },
  { fips: "13095", name: "Dougherty County", state: "Georgia" },
  { fips: "13097", name: "Douglas County", state: "Georgia" },
  { fips: "13099", name: "Early County", state: "Georgia" },
  { fips: "13101", name: "Echols County", state: "Georgia" },
  { fips: "13103", name: "Effingham County", state: "Georgia" },
  { fips: "13105", name: "Elbert County", state: "Georgia" },
  { fips: "13107", name: "Emanuel County", state: "Georgia" },
  { fips: "13109", name: "Evans County", state: "Georgia" },
  { fips: "13111", name: "Fannin County", state: "Georgia" },
  { fips: "13113", name: "Fayette County", state: "Georgia" },
  { fips: "13115", name: "Floyd County", state: "Georgia" },
  { fips: "13117", name: "Forsyth County", state: "Georgia" },
  { fips: "13119", name: "Franklin County", state: "Georgia" },
  { fips: "13121", name: "Fulton County", state: "Georgia" },
  { fips: "13123", name: "Gilmer County", state: "Georgia" },
  { fips: "13125", name: "Glascock County", state: "Georgia" },
  { fips: "13127", name: "Glynn County", state: "Georgia" },
  { fips: "13129", name: "Gordon County", state: "Georgia" },
  { fips: "13131", name: "Grady County", state: "Georgia" },
  { fips: "13133", name: "Greene County", state: "Georgia" },
  { fips: "13135", name: "Gwinnett County", state: "Georgia" },
  { fips: "13137", name: "Habersham County", state: "Georgia" },
  { fips: "13139", name: "Hall County", state: "Georgia" },
  { fips: "13141", name: "Hancock County", state: "Georgia" },
  { fips: "13143", name: "Haralson County", state: "Georgia" },
  { fips: "13145", name: "Harris County", state: "Georgia" },
  { fips: "13147", name: "Hart County", state: "Georgia" },
  { fips: "13149", name: "Heard County", state: "Georgia" },
  { fips: "13151", name: "Henry County", state: "Georgia" },
  { fips: "13153", name: "Houston County", state: "Georgia" },
  { fips: "13155", name: "Irwin County", state: "Georgia" },
  { fips: "13157", name: "Jackson County", state: "Georgia" },
  { fips: "13159", name: "Jasper County", state: "Georgia" },
  { fips: "13161", name: "Jeff Davis County", state: "Georgia" },
  { fips: "13163", name: "Jefferson County", state: "Georgia" },
  { fips: "13165", name: "Jenkins County", state: "Georgia" },
  { fips: "13167", name: "Johnson County", state: "Georgia" },
  { fips: "13169", name: "Jones County", state: "Georgia" },
  { fips: "13171", name: "Lamar County", state: "Georgia" },
  { fips: "13173", name: "Lanier County", state: "Georgia" },
  { fips: "13175", name: "Laurens County", state: "Georgia" },
  { fips: "13177", name: "Lee County", state: "Georgia" },
  { fips: "13179", name: "Liberty County", state: "Georgia" },
  { fips: "13181", name: "Lincoln County", state: "Georgia" },
  { fips: "13183", name: "Long County", state: "Georgia" },
  { fips: "13185", name: "Lowndes County", state: "Georgia" },
  { fips: "13187", name: "Lumpkin County", state: "Georgia" },
  { fips: "13189", name: "McDuffie County", state: "Georgia" },
  { fips: "13191", name: "McIntosh County", state: "Georgia" },
  { fips: "13193", name: "Macon County", state: "Georgia" },
  { fips: "13195", name: "Madison County", state: "Georgia" },
  { fips: "13197", name: "Marion County", state: "Georgia" },
  { fips: "13199", name: "Meriwether County", state: "Georgia" },
  { fips: "13201", name: "Miller County", state: "Georgia" },
  { fips: "13205", name: "Mitchell County", state: "Georgia" },
  { fips: "13207", name: "Monroe County", state: "Georgia" },
  { fips: "13209", name: "Montgomery County", state: "Georgia" },
  { fips: "13211", name: "Morgan County", state: "Georgia" },
  { fips: "13213", name: "Murray County", state: "Georgia" },
  { fips: "13215", name: "Muscogee County", state: "Georgia" },
  { fips: "13217", name: "Newton County", state: "Georgia" },
  { fips: "13219", name: "Oconee County", state: "Georgia" },
  { fips: "13221", name: "Oglethorpe County", state: "Georgia" },
  { fips: "13223", name: "Paulding County", state: "Georgia" },
  { fips: "13225", name: "Peach County", state: "Georgia" },
  { fips: "13227", name: "Pickens County", state: "Georgia" },
  { fips: "13229", name: "Pierce County", state: "Georgia" },
  { fips: "13231", name: "Pike County", state: "Georgia" },
  { fips: "13233", name: "Polk County", state: "Georgia" },
  { fips: "13235", name: "Pulaski County", state: "Georgia" },
  { fips: "13237", name: "Putnam County", state: "Georgia" },
  { fips: "13239", name: "Quitman County", state: "Georgia" },
  { fips: "13241", name: "Rabun County", state: "Georgia" },
  { fips: "13243", name: "Randolph County", state: "Georgia" },
  { fips: "13245", name: "Richmond County", state: "Georgia" },
  { fips: "13247", name: "Rockdale County", state: "Georgia" },
  { fips: "13249", name: "Schley County", state: "Georgia" },
  { fips: "13251", name: "Screven County", state: "Georgia" },
  { fips: "13253", name: "Seminole County", state: "Georgia" },
  { fips: "13255", name: "Spalding County", state: "Georgia" },
  { fips: "13257", name: "Stephens County", state: "Georgia" },
  { fips: "13259", name: "Stewart County", state: "Georgia" },
  { fips: "13261", name: "Sumter County", state: "Georgia" },
  { fips: "13263", name: "Talbot County", state: "Georgia" },
  { fips: "13265", name: "Taliaferro County", state: "Georgia" },
  { fips: "13267", name: "Tattnall County", state: "Georgia" },
  { fips: "13269", name: "Taylor County", state: "Georgia" },
  { fips: "13271", name: "Telfair County", state: "Georgia" },
  { fips: "13273", name: "Terrell County", state: "Georgia" },
  { fips: "13275", name: "Thomas County", state: "Georgia" },
  { fips: "13277", name: "Tift County", state: "Georgia" },
  { fips: "13279", name: "Toombs County", state: "Georgia" },
  { fips: "13281", name: "Towns County", state: "Georgia" },
  { fips: "13283", name: "Treutlen County", state: "Georgia" },
  { fips: "13285", name: "Troup County", state: "Georgia" },
  { fips: "13287", name: "Turner County", state: "Georgia" },
  { fips: "13289", name: "Twiggs County", state: "Georgia" },
  { fips: "13291", name: "Union County", state: "Georgia" },
  { fips: "13293", name: "Upson County", state: "Georgia" },
  { fips: "13295", name: "Walker County", state: "Georgia" },
  { fips: "13297", name: "Walton County", state: "Georgia" },
  { fips: "13299", name: "Ware County", state: "Georgia" },
  { fips: "13301", name: "Warren County", state: "Georgia" },
  { fips: "13303", name: "Washington County", state: "Georgia" },
  { fips: "13305", name: "Wayne County", state: "Georgia" },
  { fips: "13307", name: "Webster County", state: "Georgia" },
  { fips: "13309", name: "Wheeler County", state: "Georgia" },
  { fips: "13311", name: "White County", state: "Georgia" },
  { fips: "13313", name: "Whitfield County", state: "Georgia" },
  { fips: "13315", name: "Wilcox County", state: "Georgia" },
  { fips: "13317", name: "Wilkes County", state: "Georgia" },
  { fips: "13319", name: "Wilkinson County", state: "Georgia" },
  { fips: "13321", name: "Worth County", state: "Georgia" },
  { fips: "66010", name: "Guam", state: "Georgia" },
  { fips: "15001", name: "Hawaii County", state: "Hawaii" },
  { fips: "15003", name: "Honolulu County", state: "Hawaii" },
  { fips: "15005", name: "Kalawao County", state: "Hawaii" },
  { fips: "15007", name: "Kauai County", state: "Hawaii" },
  { fips: "15009", name: "Maui County", state: "Hawaii" },
  { fips: "16001", name: "Ada County", state: "Idaho" },
  { fips: "16003", name: "Adams County", state: "Idaho" },
  { fips: "16005", name: "Bannock County", state: "Idaho" },
  { fips: "16007", name: "Bear Lake County", state: "Idaho" },
  { fips: "16009", name: "Benewah County", state: "Idaho" },
  { fips: "16011", name: "Bingham County", state: "Idaho" },
  { fips: "16013", name: "Blaine County", state: "Idaho" },
  { fips: "16015", name: "Boise County", state: "Idaho" },
  { fips: "16017", name: "Bonner County", state: "Idaho" },
  { fips: "16019", name: "Bonneville County", state: "Idaho" },
  { fips: "16021", name: "Boundary County", state: "Idaho" },
  { fips: "16023", name: "Butte County", state: "Idaho" },
  { fips: "16025", name: "Camas County", state: "Idaho" },
  { fips: "16027", name: "Canyon County", state: "Idaho" },
  { fips: "16029", name: "Caribou County", state: "Idaho" },
  { fips: "16031", name: "Cassia County", state: "Idaho" },
  { fips: "16033", name: "Clark County", state: "Idaho" },
  { fips: "16035", name: "Clearwater County", state: "Idaho" },
  { fips: "16037", name: "Custer County", state: "Idaho" },
  { fips: "16039", name: "Elmore County", state: "Idaho" },
  { fips: "16041", name: "Franklin County", state: "Idaho" },
  { fips: "16043", name: "Fremont County", state: "Idaho" },
  { fips: "16045", name: "Gem County", state: "Idaho" },
  { fips: "16047", name: "Gooding County", state: "Idaho" },
  { fips: "16049", name: "Idaho County", state: "Idaho" },
  { fips: "16051", name: "Jefferson County", state: "Idaho" },
  { fips: "16053", name: "Jerome County", state: "Idaho" },
  { fips: "16055", name: "Kootenai County", state: "Idaho" },
  { fips: "16057", name: "Latah County", state: "Idaho" },
  { fips: "16059", name: "Lemhi County", state: "Idaho" },
  { fips: "16061", name: "Lewis County", state: "Idaho" },
  { fips: "16063", name: "Lincoln County", state: "Idaho" },
  { fips: "16065", name: "Madison County", state: "Idaho" },
  { fips: "16067", name: "Minidoka County", state: "Idaho" },
  { fips: "16069", name: "Nez Perce County", state: "Idaho" },
  { fips: "16071", name: "Oneida County", state: "Idaho" },
  { fips: "16073", name: "Owyhee County", state: "Idaho" },
  { fips: "16075", name: "Payette County", state: "Idaho" },
  { fips: "16077", name: "Power County", state: "Idaho" },
  { fips: "16079", name: "Shoshone County", state: "Idaho" },
  { fips: "16081", name: "Teton County", state: "Idaho" },
  { fips: "16083", name: "Twin Falls County", state: "Idaho" },
  { fips: "16085", name: "Valley County", state: "Idaho" },
  { fips: "16087", name: "Washington County", state: "Idaho" },
  { fips: "17001", name: "Adams County", state: "Illinois" },
  { fips: "17003", name: "Alexander County", state: "Illinois" },
  { fips: "17005", name: "Bond County", state: "Illinois" },
  { fips: "17007", name: "Boone County", state: "Illinois" },
  { fips: "17009", name: "Brown County", state: "Illinois" },
  { fips: "17011", name: "Bureau County", state: "Illinois" },
  { fips: "17013", name: "Calhoun County", state: "Illinois" },
  { fips: "17015", name: "Carroll County", state: "Illinois" },
  { fips: "17017", name: "Cass County", state: "Illinois" },
  { fips: "17019", name: "Champaign County", state: "Illinois" },
  { fips: "17021", name: "Christian County", state: "Illinois" },
  { fips: "17023", name: "Clark County", state: "Illinois" },
  { fips: "17025", name: "Clay County", state: "Illinois" },
  { fips: "17027", name: "Clinton County", state: "Illinois" },
  { fips: "17029", name: "Coles County", state: "Illinois" },
  { fips: "17031", name: "Cook County", state: "Illinois" },
  { fips: "17033", name: "Crawford County", state: "Illinois" },
  { fips: "17035", name: "Cumberland County", state: "Illinois" },
  { fips: "17037", name: "DeKalb County", state: "Illinois" },
  { fips: "17039", name: "De Witt County", state: "Illinois" },
  { fips: "17041", name: "Douglas County", state: "Illinois" },
  { fips: "17043", name: "DuPage County", state: "Illinois" },
  { fips: "17045", name: "Edgar County", state: "Illinois" },
  { fips: "17047", name: "Edwards County", state: "Illinois" },
  { fips: "17049", name: "Effingham County", state: "Illinois" },
  { fips: "17051", name: "Fayette County", state: "Illinois" },
  { fips: "17053", name: "Ford County", state: "Illinois" },
  { fips: "17055", name: "Franklin County", state: "Illinois" },
  { fips: "17057", name: "Fulton County", state: "Illinois" },
  { fips: "17059", name: "Gallatin County", state: "Illinois" },
  { fips: "17061", name: "Greene County", state: "Illinois" },
  { fips: "17063", name: "Grundy County", state: "Illinois" },
  { fips: "17065", name: "Hamilton County", state: "Illinois" },
  { fips: "17067", name: "Hancock County", state: "Illinois" },
  { fips: "17069", name: "Hardin County", state: "Illinois" },
  { fips: "17071", name: "Henderson County", state: "Illinois" },
  { fips: "17073", name: "Henry County", state: "Illinois" },
  { fips: "17075", name: "Iroquois County", state: "Illinois" },
  { fips: "17077", name: "Jackson County", state: "Illinois" },
  { fips: "17079", name: "Jasper County", state: "Illinois" },
  { fips: "17081", name: "Jefferson County", state: "Illinois" },
  { fips: "17083", name: "Jersey County", state: "Illinois" },
  { fips: "17085", name: "Jo Daviess County", state: "Illinois" },
  { fips: "17087", name: "Johnson County", state: "Illinois" },
  { fips: "17089", name: "Kane County", state: "Illinois" },
  { fips: "17091", name: "Kankakee County", state: "Illinois" },
  { fips: "17093", name: "Kendall County", state: "Illinois" },
  { fips: "17095", name: "Knox County", state: "Illinois" },
  { fips: "17097", name: "Lake County", state: "Illinois" },
  { fips: "17099", name: "LaSalle County", state: "Illinois" },
  { fips: "17101", name: "Lawrence County", state: "Illinois" },
  { fips: "17103", name: "Lee County", state: "Illinois" },
  { fips: "17105", name: "Livingston County", state: "Illinois" },
  { fips: "17107", name: "Logan County", state: "Illinois" },
  { fips: "17109", name: "McDonough County", state: "Illinois" },
  { fips: "17111", name: "McHenry County", state: "Illinois" },
  { fips: "17113", name: "McLean County", state: "Illinois" },
  { fips: "17115", name: "Macon County", state: "Illinois" },
  { fips: "17117", name: "Macoupin County", state: "Illinois" },
  { fips: "17119", name: "Madison County", state: "Illinois" },
  { fips: "17121", name: "Marion County", state: "Illinois" },
  { fips: "17123", name: "Marshall County", state: "Illinois" },
  { fips: "17125", name: "Mason County", state: "Illinois" },
  { fips: "17127", name: "Massac County", state: "Illinois" },
  { fips: "17129", name: "Menard County", state: "Illinois" },
  { fips: "17131", name: "Mercer County", state: "Illinois" },
  { fips: "17133", name: "Monroe County", state: "Illinois" },
  { fips: "17135", name: "Montgomery County", state: "Illinois" },
  { fips: "17137", name: "Morgan County", state: "Illinois" },
  { fips: "17139", name: "Moultrie County", state: "Illinois" },
  { fips: "17141", name: "Ogle County", state: "Illinois" },
  { fips: "17143", name: "Peoria County", state: "Illinois" },
  { fips: "17145", name: "Perry County", state: "Illinois" },
  { fips: "17147", name: "Piatt County", state: "Illinois" },
  { fips: "17149", name: "Pike County", state: "Illinois" },
  { fips: "17151", name: "Pope County", state: "Illinois" },
  { fips: "17153", name: "Pulaski County", state: "Illinois" },
  { fips: "17155", name: "Putnam County", state: "Illinois" },
  { fips: "17157", name: "Randolph County", state: "Illinois" },
  { fips: "17159", name: "Richland County", state: "Illinois" },
  { fips: "17161", name: "Rock Island County", state: "Illinois" },
  { fips: "17163", name: "St. Clair County", state: "Illinois" },
  { fips: "17165", name: "Saline County", state: "Illinois" },
  { fips: "17167", name: "Sangamon County", state: "Illinois" },
  { fips: "17169", name: "Schuyler County", state: "Illinois" },
  { fips: "17171", name: "Scott County", state: "Illinois" },
  { fips: "17173", name: "Shelby County", state: "Illinois" },
  { fips: "17175", name: "Stark County", state: "Illinois" },
  { fips: "17177", name: "Stephenson County", state: "Illinois" },
  { fips: "17179", name: "Tazewell County", state: "Illinois" },
  { fips: "17181", name: "Union County", state: "Illinois" },
  { fips: "17183", name: "Vermilion County", state: "Illinois" },
  { fips: "17185", name: "Wabash County", state: "Illinois" },
  { fips: "17187", name: "Warren County", state: "Illinois" },
  { fips: "17189", name: "Washington County", state: "Illinois" },
  { fips: "17191", name: "Wayne County", state: "Illinois" },
  { fips: "17193", name: "White County", state: "Illinois" },
  { fips: "17195", name: "Whiteside County", state: "Illinois" },
  { fips: "17197", name: "Will County", state: "Illinois" },
  { fips: "17199", name: "Williamson County", state: "Illinois" },
  { fips: "17201", name: "Winnebago County", state: "Illinois" },
  { fips: "17203", name: "Woodford County", state: "Illinois" },
  { fips: "18001", name: "Adams County", state: "Indiana" },
  { fips: "18003", name: "Allen County", state: "Indiana" },
  { fips: "18005", name: "Bartholomew County", state: "Indiana" },
  { fips: "18007", name: "Benton County", state: "Indiana" },
  { fips: "18009", name: "Blackford County", state: "Indiana" },
  { fips: "18011", name: "Boone County", state: "Indiana" },
  { fips: "18013", name: "Brown County", state: "Indiana" },
  { fips: "18015", name: "Carroll County", state: "Indiana" },
  { fips: "18017", name: "Cass County", state: "Indiana" },
  { fips: "18019", name: "Clark County", state: "Indiana" },
  { fips: "18021", name: "Clay County", state: "Indiana" },
  { fips: "18023", name: "Clinton County", state: "Indiana" },
  { fips: "18025", name: "Crawford County", state: "Indiana" },
  { fips: "18027", name: "Daviess County", state: "Indiana" },
  { fips: "18029", name: "Dearborn County", state: "Indiana" },
  { fips: "18031", name: "Decatur County", state: "Indiana" },
  { fips: "18033", name: "DeKalb County", state: "Indiana" },
  { fips: "18035", name: "Delaware County", state: "Indiana" },
  { fips: "18037", name: "Dubois County", state: "Indiana" },
  { fips: "18039", name: "Elkhart County", state: "Indiana" },
  { fips: "18041", name: "Fayette County", state: "Indiana" },
  { fips: "18043", name: "Floyd County", state: "Indiana" },
  { fips: "18045", name: "Fountain County", state: "Indiana" },
  { fips: "18047", name: "Franklin County", state: "Indiana" },
  { fips: "18049", name: "Fulton County", state: "Indiana" },
  { fips: "18051", name: "Gibson County", state: "Indiana" },
  { fips: "18053", name: "Grant County", state: "Indiana" },
  { fips: "18055", name: "Greene County", state: "Indiana" },
  { fips: "18057", name: "Hamilton County", state: "Indiana" },
  { fips: "18059", name: "Hancock County", state: "Indiana" },
  { fips: "18061", name: "Harrison County", state: "Indiana" },
  { fips: "18063", name: "Hendricks County", state: "Indiana" },
  { fips: "18065", name: "Henry County", state: "Indiana" },
  { fips: "18067", name: "Howard County", state: "Indiana" },
  { fips: "18069", name: "Huntington County", state: "Indiana" },
  { fips: "18071", name: "Jackson County", state: "Indiana" },
  { fips: "18073", name: "Jasper County", state: "Indiana" },
  { fips: "18075", name: "Jay County", state: "Indiana" },
  { fips: "18077", name: "Jefferson County", state: "Indiana" },
  { fips: "18079", name: "Jennings County", state: "Indiana" },
  { fips: "18081", name: "Johnson County", state: "Indiana" },
  { fips: "18083", name: "Knox County", state: "Indiana" },
  { fips: "18085", name: "Kosciusko County", state: "Indiana" },
  { fips: "18087", name: "LaGrange County", state: "Indiana" },
  { fips: "18089", name: "Lake County", state: "Indiana" },
  { fips: "18091", name: "LaPorte County", state: "Indiana" },
  { fips: "18093", name: "Lawrence County", state: "Indiana" },
  { fips: "18095", name: "Madison County", state: "Indiana" },
  { fips: "18097", name: "Marion County", state: "Indiana" },
  { fips: "18099", name: "Marshall County", state: "Indiana" },
  { fips: "18101", name: "Martin County", state: "Indiana" },
  { fips: "18103", name: "Miami County", state: "Indiana" },
  { fips: "18105", name: "Monroe County", state: "Indiana" },
  { fips: "18107", name: "Montgomery County", state: "Indiana" },
  { fips: "18109", name: "Morgan County", state: "Indiana" },
  { fips: "18111", name: "Newton County", state: "Indiana" },
  { fips: "18113", name: "Noble County", state: "Indiana" },
  { fips: "18115", name: "Ohio County", state: "Indiana" },
  { fips: "18117", name: "Orange County", state: "Indiana" },
  { fips: "18119", name: "Owen County", state: "Indiana" },
  { fips: "18121", name: "Parke County", state: "Indiana" },
  { fips: "18123", name: "Perry County", state: "Indiana" },
  { fips: "18125", name: "Pike County", state: "Indiana" },
  { fips: "18127", name: "Porter County", state: "Indiana" },
  { fips: "18129", name: "Posey County", state: "Indiana" },
  { fips: "18131", name: "Pulaski County", state: "Indiana" },
  { fips: "18133", name: "Putnam County", state: "Indiana" },
  { fips: "18135", name: "Randolph County", state: "Indiana" },
  { fips: "18137", name: "Ripley County", state: "Indiana" },
  { fips: "18139", name: "Rush County", state: "Indiana" },
  { fips: "18141", name: "St. Joseph County", state: "Indiana" },
  { fips: "18143", name: "Scott County", state: "Indiana" },
  { fips: "18145", name: "Shelby County", state: "Indiana" },
  { fips: "18147", name: "Spencer County", state: "Indiana" },
  { fips: "18149", name: "Starke County", state: "Indiana" },
  { fips: "18151", name: "Steuben County", state: "Indiana" },
  { fips: "18153", name: "Sullivan County", state: "Indiana" },
  { fips: "18155", name: "Switzerland County", state: "Indiana" },
  { fips: "18157", name: "Tippecanoe County", state: "Indiana" },
  { fips: "18159", name: "Tipton County", state: "Indiana" },
  { fips: "18161", name: "Union County", state: "Indiana" },
  { fips: "18163", name: "Vanderburgh County", state: "Indiana" },
  { fips: "18165", name: "Vermillion County", state: "Indiana" },
  { fips: "18167", name: "Vigo County", state: "Indiana" },
  { fips: "18169", name: "Wabash County", state: "Indiana" },
  { fips: "18171", name: "Warren County", state: "Indiana" },
  { fips: "18173", name: "Warrick County", state: "Indiana" },
  { fips: "18175", name: "Washington County", state: "Indiana" },
  { fips: "18177", name: "Wayne County", state: "Indiana" },
  { fips: "18179", name: "Wells County", state: "Indiana" },
  { fips: "18181", name: "White County", state: "Indiana" },
  { fips: "18183", name: "Whitley County", state: "Indiana" },
  { fips: "19001", name: "Adair County", state: "Iowa" },
  { fips: "19003", name: "Adams County", state: "Iowa" },
  { fips: "19005", name: "Allamakee County", state: "Iowa" },
  { fips: "19007", name: "Appanoose County", state: "Iowa" },
  { fips: "19009", name: "Audubon County", state: "Iowa" },
  { fips: "19011", name: "Benton County", state: "Iowa" },
  { fips: "19013", name: "Black Hawk County", state: "Iowa" },
  { fips: "19015", name: "Boone County", state: "Iowa" },
  { fips: "19017", name: "Bremer County", state: "Iowa" },
  { fips: "19019", name: "Buchanan County", state: "Iowa" },
  { fips: "19021", name: "Buena Vista County", state: "Iowa" },
  { fips: "19023", name: "Butler County", state: "Iowa" },
  { fips: "19025", name: "Calhoun County", state: "Iowa" },
  { fips: "19027", name: "Carroll County", state: "Iowa" },
  { fips: "19029", name: "Cass County", state: "Iowa" },
  { fips: "19031", name: "Cedar County", state: "Iowa" },
  { fips: "19033", name: "Cerro Gordo County", state: "Iowa" },
  { fips: "19035", name: "Cherokee County", state: "Iowa" },
  { fips: "19037", name: "Chickasaw County", state: "Iowa" },
  { fips: "19039", name: "Clarke County", state: "Iowa" },
  { fips: "19041", name: "Clay County", state: "Iowa" },
  { fips: "19043", name: "Clayton County", state: "Iowa" },
  { fips: "19045", name: "Clinton County", state: "Iowa" },
  { fips: "19047", name: "Crawford County", state: "Iowa" },
  { fips: "19049", name: "Dallas County", state: "Iowa" },
  { fips: "19051", name: "Davis County", state: "Iowa" },
  { fips: "19053", name: "Decatur County", state: "Iowa" },
  { fips: "19055", name: "Delaware County", state: "Iowa" },
  { fips: "19057", name: "Des Moines County", state: "Iowa" },
  { fips: "19059", name: "Dickinson County", state: "Iowa" },
  { fips: "19061", name: "Dubuque County", state: "Iowa" },
  { fips: "19063", name: "Emmet County", state: "Iowa" },
  { fips: "19065", name: "Fayette County", state: "Iowa" },
  { fips: "19067", name: "Floyd County", state: "Iowa" },
  { fips: "19069", name: "Franklin County", state: "Iowa" },
  { fips: "19071", name: "Fremont County", state: "Iowa" },
  { fips: "19073", name: "Greene County", state: "Iowa" },
  { fips: "19075", name: "Grundy County", state: "Iowa" },
  { fips: "19077", name: "Guthrie County", state: "Iowa" },
  { fips: "19079", name: "Hamilton County", state: "Iowa" },
  { fips: "19081", name: "Hancock County", state: "Iowa" },
  { fips: "19083", name: "Hardin County", state: "Iowa" },
  { fips: "19085", name: "Harrison County", state: "Iowa" },
  { fips: "19087", name: "Henry County", state: "Iowa" },
  { fips: "19089", name: "Howard County", state: "Iowa" },
  { fips: "19091", name: "Humboldt County", state: "Iowa" },
  { fips: "19093", name: "Ida County", state: "Iowa" },
  { fips: "19095", name: "Iowa County", state: "Iowa" },
  { fips: "19097", name: "Jackson County", state: "Iowa" },
  { fips: "19099", name: "Jasper County", state: "Iowa" },
  { fips: "19101", name: "Jefferson County", state: "Iowa" },
  { fips: "19103", name: "Johnson County", state: "Iowa" },
  { fips: "19105", name: "Jones County", state: "Iowa" },
  { fips: "19107", name: "Keokuk County", state: "Iowa" },
  { fips: "19109", name: "Kossuth County", state: "Iowa" },
  { fips: "19111", name: "Lee County", state: "Iowa" },
  { fips: "19113", name: "Linn County", state: "Iowa" },
  { fips: "19115", name: "Louisa County", state: "Iowa" },
  { fips: "19117", name: "Lucas County", state: "Iowa" },
  { fips: "19119", name: "Lyon County", state: "Iowa" },
  { fips: "19121", name: "Madison County", state: "Iowa" },
  { fips: "19123", name: "Mahaska County", state: "Iowa" },
  { fips: "19125", name: "Marion County", state: "Iowa" },
  { fips: "19127", name: "Marshall County", state: "Iowa" },
  { fips: "19129", name: "Mills County", state: "Iowa" },
  { fips: "19131", name: "Mitchell County", state: "Iowa" },
  { fips: "19133", name: "Monona County", state: "Iowa" },
  { fips: "19135", name: "Monroe County", state: "Iowa" },
  { fips: "19137", name: "Montgomery County", state: "Iowa" },
  { fips: "19139", name: "Muscatine County", state: "Iowa" },
  { fips: "19141", name: "O'Brien County", state: "Iowa" },
  { fips: "19143", name: "Osceola County", state: "Iowa" },
  { fips: "19145", name: "Page County", state: "Iowa" },
  { fips: "19147", name: "Palo Alto County", state: "Iowa" },
  { fips: "19149", name: "Plymouth County", state: "Iowa" },
  { fips: "19151", name: "Pocahontas County", state: "Iowa" },
  { fips: "19153", name: "Polk County", state: "Iowa" },
  { fips: "19155", name: "Pottawattamie County", state: "Iowa" },
  { fips: "19157", name: "Poweshiek County", state: "Iowa" },
  { fips: "19159", name: "Ringgold County", state: "Iowa" },
  { fips: "19161", name: "Sac County", state: "Iowa" },
  { fips: "19163", name: "Scott County", state: "Iowa" },
  { fips: "19165", name: "Shelby County", state: "Iowa" },
  { fips: "19167", name: "Sioux County", state: "Iowa" },
  { fips: "19169", name: "Story County", state: "Iowa" },
  { fips: "19171", name: "Tama County", state: "Iowa" },
  { fips: "19173", name: "Taylor County", state: "Iowa" },
  { fips: "19175", name: "Union County", state: "Iowa" },
  { fips: "19177", name: "Van Buren County", state: "Iowa" },
  { fips: "19179", name: "Wapello County", state: "Iowa" },
  { fips: "19181", name: "Warren County", state: "Iowa" },
  { fips: "19183", name: "Washington County", state: "Iowa" },
  { fips: "19185", name: "Wayne County", state: "Iowa" },
  { fips: "19187", name: "Webster County", state: "Iowa" },
  { fips: "19189", name: "Winnebago County", state: "Iowa" },
  { fips: "19191", name: "Winneshiek County", state: "Iowa" },
  { fips: "19193", name: "Woodbury County", state: "Iowa" },
  { fips: "19195", name: "Worth County", state: "Iowa" },
  { fips: "19197", name: "Wright County", state: "Iowa" },
  { fips: "20001", name: "Allen County", state: "Kansas" },
  { fips: "20003", name: "Anderson County", state: "Kansas" },
  { fips: "20005", name: "Atchison County", state: "Kansas" },
  { fips: "20007", name: "Barber County", state: "Kansas" },
  { fips: "20009", name: "Barton County", state: "Kansas" },
  { fips: "20011", name: "Bourbon County", state: "Kansas" },
  { fips: "20013", name: "Brown County", state: "Kansas" },
  { fips: "20015", name: "Butler County", state: "Kansas" },
  { fips: "20017", name: "Chase County", state: "Kansas" },
  { fips: "20019", name: "Chautauqua County", state: "Kansas" },
  { fips: "20021", name: "Cherokee County", state: "Kansas" },
  { fips: "20023", name: "Cheyenne County", state: "Kansas" },
  { fips: "20025", name: "Clark County", state: "Kansas" },
  { fips: "20027", name: "Clay County", state: "Kansas" },
  { fips: "20029", name: "Cloud County", state: "Kansas" },
  { fips: "20031", name: "Coffey County", state: "Kansas" },
  { fips: "20033", name: "Comanche County", state: "Kansas" },
  { fips: "20035", name: "Cowley County", state: "Kansas" },
  { fips: "20037", name: "Crawford County", state: "Kansas" },
  { fips: "20039", name: "Decatur County", state: "Kansas" },
  { fips: "20041", name: "Dickinson County", state: "Kansas" },
  { fips: "20043", name: "Doniphan County", state: "Kansas" },
  { fips: "20045", name: "Douglas County", state: "Kansas" },
  { fips: "20047", name: "Edwards County", state: "Kansas" },
  { fips: "20049", name: "Elk County", state: "Kansas" },
  { fips: "20051", name: "Ellis County", state: "Kansas" },
  { fips: "20053", name: "Ellsworth County", state: "Kansas" },
  { fips: "20055", name: "Finney County", state: "Kansas" },
  { fips: "20057", name: "Ford County", state: "Kansas" },
  { fips: "20059", name: "Franklin County", state: "Kansas" },
  { fips: "20061", name: "Geary County", state: "Kansas" },
  { fips: "20063", name: "Gove County", state: "Kansas" },
  { fips: "20065", name: "Graham County", state: "Kansas" },
  { fips: "20067", name: "Grant County", state: "Kansas" },
  { fips: "20069", name: "Gray County", state: "Kansas" },
  { fips: "20071", name: "Greeley County", state: "Kansas" },
  { fips: "20073", name: "Greenwood County", state: "Kansas" },
  { fips: "20075", name: "Hamilton County", state: "Kansas" },
  { fips: "20077", name: "Harper County", state: "Kansas" },
  { fips: "20079", name: "Harvey County", state: "Kansas" },
  { fips: "20081", name: "Haskell County", state: "Kansas" },
  { fips: "20083", name: "Hodgeman County", state: "Kansas" },
  { fips: "20085", name: "Jackson County", state: "Kansas" },
  { fips: "20087", name: "Jefferson County", state: "Kansas" },
  { fips: "20089", name: "Jewell County", state: "Kansas" },
  { fips: "20091", name: "Johnson County", state: "Kansas" },
  { fips: "20093", name: "Kearny County", state: "Kansas" },
  { fips: "20095", name: "Kingman County", state: "Kansas" },
  { fips: "20097", name: "Kiowa County", state: "Kansas" },
  { fips: "20099", name: "Labette County", state: "Kansas" },
  { fips: "20101", name: "Lane County", state: "Kansas" },
  { fips: "20103", name: "Leavenworth County", state: "Kansas" },
  { fips: "20105", name: "Lincoln County", state: "Kansas" },
  { fips: "20107", name: "Linn County", state: "Kansas" },
  { fips: "20109", name: "Logan County", state: "Kansas" },
  { fips: "20111", name: "Lyon County", state: "Kansas" },
  { fips: "20113", name: "McPherson County", state: "Kansas" },
  { fips: "20115", name: "Marion County", state: "Kansas" },
  { fips: "20117", name: "Marshall County", state: "Kansas" },
  { fips: "20119", name: "Meade County", state: "Kansas" },
  { fips: "20121", name: "Miami County", state: "Kansas" },
  { fips: "20123", name: "Mitchell County", state: "Kansas" },
  { fips: "20125", name: "Montgomery County", state: "Kansas" },
  { fips: "20127", name: "Morris County", state: "Kansas" },
  { fips: "20129", name: "Morton County", state: "Kansas" },
  { fips: "20131", name: "Nemaha County", state: "Kansas" },
  { fips: "20133", name: "Neosho County", state: "Kansas" },
  { fips: "20135", name: "Ness County", state: "Kansas" },
  { fips: "20137", name: "Norton County", state: "Kansas" },
  { fips: "20139", name: "Osage County", state: "Kansas" },
  { fips: "20141", name: "Osborne County", state: "Kansas" },
  { fips: "20143", name: "Ottawa County", state: "Kansas" },
  { fips: "20145", name: "Pawnee County", state: "Kansas" },
  { fips: "20147", name: "Phillips County", state: "Kansas" },
  { fips: "20149", name: "Pottawatomie County", state: "Kansas" },
  { fips: "20151", name: "Pratt County", state: "Kansas" },
  { fips: "20153", name: "Rawlins County", state: "Kansas" },
  { fips: "20155", name: "Reno County", state: "Kansas" },
  { fips: "20157", name: "Republic County", state: "Kansas" },
  { fips: "20159", name: "Rice County", state: "Kansas" },
  { fips: "20161", name: "Riley County", state: "Kansas" },
  { fips: "20163", name: "Rooks County", state: "Kansas" },
  { fips: "20165", name: "Rush County", state: "Kansas" },
  { fips: "20167", name: "Russell County", state: "Kansas" },
  { fips: "20169", name: "Saline County", state: "Kansas" },
  { fips: "20171", name: "Scott County", state: "Kansas" },
  { fips: "20173", name: "Sedgwick County", state: "Kansas" },
  { fips: "20175", name: "Seward County", state: "Kansas" },
  { fips: "20177", name: "Shawnee County", state: "Kansas" },
  { fips: "20179", name: "Sheridan County", state: "Kansas" },
  { fips: "20181", name: "Sherman County", state: "Kansas" },
  { fips: "20183", name: "Smith County", state: "Kansas" },
  { fips: "20185", name: "Stafford County", state: "Kansas" },
  { fips: "20187", name: "Stanton County", state: "Kansas" },
  { fips: "20189", name: "Stevens County", state: "Kansas" },
  { fips: "20191", name: "Sumner County", state: "Kansas" },
  { fips: "20193", name: "Thomas County", state: "Kansas" },
  { fips: "20195", name: "Trego County", state: "Kansas" },
  { fips: "20197", name: "Wabaunsee County", state: "Kansas" },
  { fips: "20199", name: "Wallace County", state: "Kansas" },
  { fips: "20201", name: "Washington County", state: "Kansas" },
  { fips: "20203", name: "Wichita County", state: "Kansas" },
  { fips: "20205", name: "Wilson County", state: "Kansas" },
  { fips: "20207", name: "Woodson County", state: "Kansas" },
  { fips: "20209", name: "Wyandotte County", state: "Kansas" },
  { fips: "21001", name: "Adair County", state: "Kentucky" },
  { fips: "21003", name: "Allen County", state: "Kentucky" },
  { fips: "21005", name: "Anderson County", state: "Kentucky" },
  { fips: "21007", name: "Ballard County", state: "Kentucky" },
  { fips: "21009", name: "Barren County", state: "Kentucky" },
  { fips: "21011", name: "Bath County", state: "Kentucky" },
  { fips: "21013", name: "Bell County", state: "Kentucky" },
  { fips: "21015", name: "Boone County", state: "Kentucky" },
  { fips: "21017", name: "Bourbon County", state: "Kentucky" },
  { fips: "21019", name: "Boyd County", state: "Kentucky" },
  { fips: "21021", name: "Boyle County", state: "Kentucky" },
  { fips: "21023", name: "Bracken County", state: "Kentucky" },
  { fips: "21025", name: "Breathitt County", state: "Kentucky" },
  { fips: "21027", name: "Breckinridge County", state: "Kentucky" },
  { fips: "21029", name: "Bullitt County", state: "Kentucky" },
  { fips: "21031", name: "Butler County", state: "Kentucky" },
  { fips: "21033", name: "Caldwell County", state: "Kentucky" },
  { fips: "21035", name: "Calloway County", state: "Kentucky" },
  { fips: "21037", name: "Campbell County", state: "Kentucky" },
  { fips: "21039", name: "Carlisle County", state: "Kentucky" },
  { fips: "21041", name: "Carroll County", state: "Kentucky" },
  { fips: "21043", name: "Carter County", state: "Kentucky" },
  { fips: "21045", name: "Casey County", state: "Kentucky" },
  { fips: "21047", name: "Christian County", state: "Kentucky" },
  { fips: "21049", name: "Clark County", state: "Kentucky" },
  { fips: "21051", name: "Clay County", state: "Kentucky" },
  { fips: "21053", name: "Clinton County", state: "Kentucky" },
  { fips: "21055", name: "Crittenden County", state: "Kentucky" },
  { fips: "21057", name: "Cumberland County", state: "Kentucky" },
  { fips: "21059", name: "Daviess County", state: "Kentucky" },
  { fips: "21061", name: "Edmonson County", state: "Kentucky" },
  { fips: "21063", name: "Elliott County", state: "Kentucky" },
  { fips: "21065", name: "Estill County", state: "Kentucky" },
  { fips: "21067", name: "Fayette County", state: "Kentucky" },
  { fips: "21069", name: "Fleming County", state: "Kentucky" },
  { fips: "21071", name: "Floyd County", state: "Kentucky" },
  { fips: "21073", name: "Franklin County", state: "Kentucky" },
  { fips: "21075", name: "Fulton County", state: "Kentucky" },
  { fips: "21077", name: "Gallatin County", state: "Kentucky" },
  { fips: "21079", name: "Garrard County", state: "Kentucky" },
  { fips: "21081", name: "Grant County", state: "Kentucky" },
  { fips: "21083", name: "Graves County", state: "Kentucky" },
  { fips: "21085", name: "Grayson County", state: "Kentucky" },
  { fips: "21087", name: "Green County", state: "Kentucky" },
  { fips: "21089", name: "Greenup County", state: "Kentucky" },
  { fips: "21091", name: "Hancock County", state: "Kentucky" },
  { fips: "21093", name: "Hardin County", state: "Kentucky" },
  { fips: "21095", name: "Harlan County", state: "Kentucky" },
  { fips: "21097", name: "Harrison County", state: "Kentucky" },
  { fips: "21099", name: "Hart County", state: "Kentucky" },
  { fips: "21101", name: "Henderson County", state: "Kentucky" },
  { fips: "21103", name: "Henry County", state: "Kentucky" },
  { fips: "21105", name: "Hickman County", state: "Kentucky" },
  { fips: "21107", name: "Hopkins County", state: "Kentucky" },
  { fips: "21109", name: "Jackson County", state: "Kentucky" },
  { fips: "21111", name: "Jefferson County", state: "Kentucky" },
  { fips: "21113", name: "Jessamine County", state: "Kentucky" },
  { fips: "21115", name: "Johnson County", state: "Kentucky" },
  { fips: "21117", name: "Kenton County", state: "Kentucky" },
  { fips: "21119", name: "Knott County", state: "Kentucky" },
  { fips: "21121", name: "Knox County", state: "Kentucky" },
  { fips: "21123", name: "LaRue County", state: "Kentucky" },
  { fips: "21125", name: "Laurel County", state: "Kentucky" },
  { fips: "21127", name: "Lawrence County", state: "Kentucky" },
  { fips: "21129", name: "Lee County", state: "Kentucky" },
  { fips: "21131", name: "Leslie County", state: "Kentucky" },
  { fips: "21133", name: "Letcher County", state: "Kentucky" },
  { fips: "21135", name: "Lewis County", state: "Kentucky" },
  { fips: "21137", name: "Lincoln County", state: "Kentucky" },
  { fips: "21139", name: "Livingston County", state: "Kentucky" },
  { fips: "21141", name: "Logan County", state: "Kentucky" },
  { fips: "21143", name: "Lyon County", state: "Kentucky" },
  { fips: "21145", name: "McCracken County", state: "Kentucky" },
  { fips: "21147", name: "McCreary County", state: "Kentucky" },
  { fips: "21149", name: "McLean County", state: "Kentucky" },
  { fips: "21151", name: "Madison County", state: "Kentucky" },
  { fips: "21153", name: "Magoffin County", state: "Kentucky" },
  { fips: "21155", name: "Marion County", state: "Kentucky" },
  { fips: "21157", name: "Marshall County", state: "Kentucky" },
  { fips: "21159", name: "Martin County", state: "Kentucky" },
  { fips: "21161", name: "Mason County", state: "Kentucky" },
  { fips: "21163", name: "Meade County", state: "Kentucky" },
  { fips: "21165", name: "Menifee County", state: "Kentucky" },
  { fips: "21167", name: "Mercer County", state: "Kentucky" },
  { fips: "21169", name: "Metcalfe County", state: "Kentucky" },
  { fips: "21171", name: "Monroe County", state: "Kentucky" },
  { fips: "21173", name: "Montgomery County", state: "Kentucky" },
  { fips: "21175", name: "Morgan County", state: "Kentucky" },
  { fips: "21177", name: "Muhlenberg County", state: "Kentucky" },
  { fips: "21179", name: "Nelson County", state: "Kentucky" },
  { fips: "21181", name: "Nicholas County", state: "Kentucky" },
  { fips: "21183", name: "Ohio County", state: "Kentucky" },
  { fips: "21185", name: "Oldham County", state: "Kentucky" },
  { fips: "21187", name: "Owen County", state: "Kentucky" },
  { fips: "21189", name: "Owsley County", state: "Kentucky" },
  { fips: "21191", name: "Pendleton County", state: "Kentucky" },
  { fips: "21193", name: "Perry County", state: "Kentucky" },
  { fips: "21195", name: "Pike County", state: "Kentucky" },
  { fips: "21197", name: "Powell County", state: "Kentucky" },
  { fips: "21199", name: "Pulaski County", state: "Kentucky" },
  { fips: "21201", name: "Robertson County", state: "Kentucky" },
  { fips: "21203", name: "Rockcastle County", state: "Kentucky" },
  { fips: "21205", name: "Rowan County", state: "Kentucky" },
  { fips: "21207", name: "Russell County", state: "Kentucky" },
  { fips: "21209", name: "Scott County", state: "Kentucky" },
  { fips: "21211", name: "Shelby County", state: "Kentucky" },
  { fips: "21213", name: "Simpson County", state: "Kentucky" },
  { fips: "21215", name: "Spencer County", state: "Kentucky" },
  { fips: "21217", name: "Taylor County", state: "Kentucky" },
  { fips: "21219", name: "Todd County", state: "Kentucky" },
  { fips: "21221", name: "Trigg County", state: "Kentucky" },
  { fips: "21223", name: "Trimble County", state: "Kentucky" },
  { fips: "21225", name: "Union County", state: "Kentucky" },
  { fips: "21227", name: "Warren County", state: "Kentucky" },
  { fips: "21229", name: "Washington County", state: "Kentucky" },
  { fips: "21231", name: "Wayne County", state: "Kentucky" },
  { fips: "21233", name: "Webster County", state: "Kentucky" },
  { fips: "21235", name: "Whitley County", state: "Kentucky" },
  { fips: "21237", name: "Wolfe County", state: "Kentucky" },
  { fips: "21239", name: "Woodford County", state: "Kentucky" },
  { fips: "22001", name: "Acadia Parish", state: "Louisiana" },
  { fips: "22003", name: "Allen Parish", state: "Louisiana" },
  { fips: "22005", name: "Ascension Parish", state: "Louisiana" },
  { fips: "22007", name: "Assumption Parish", state: "Louisiana" },
  { fips: "22009", name: "Avoyelles Parish", state: "Louisiana" },
  { fips: "22011", name: "Beauregard Parish", state: "Louisiana" },
  { fips: "22013", name: "Bienville Parish", state: "Louisiana" },
  { fips: "22015", name: "Bossier Parish", state: "Louisiana" },
  { fips: "22017", name: "Caddo Parish", state: "Louisiana" },
  { fips: "22019", name: "Calcasieu Parish", state: "Louisiana" },
  { fips: "22021", name: "Caldwell Parish", state: "Louisiana" },
  { fips: "22023", name: "Cameron Parish", state: "Louisiana" },
  { fips: "22025", name: "Catahoula Parish", state: "Louisiana" },
  { fips: "22027", name: "Claiborne Parish", state: "Louisiana" },
  { fips: "22029", name: "Concordia Parish", state: "Louisiana" },
  { fips: "22031", name: "De Soto Parish", state: "Louisiana" },
  { fips: "22033", name: "East Baton Rouge Parish", state: "Louisiana" },
  { fips: "22035", name: "East Carroll Parish", state: "Louisiana" },
  { fips: "22037", name: "East Feliciana Parish", state: "Louisiana" },
  { fips: "22039", name: "Evangeline Parish", state: "Louisiana" },
  { fips: "22041", name: "Franklin Parish", state: "Louisiana" },
  { fips: "22043", name: "Grant Parish", state: "Louisiana" },
  { fips: "22045", name: "Iberia Parish", state: "Louisiana" },
  { fips: "22047", name: "Iberville Parish", state: "Louisiana" },
  { fips: "22049", name: "Jackson Parish", state: "Louisiana" },
  { fips: "22051", name: "Jefferson Parish", state: "Louisiana" },
  { fips: "22053", name: "Jefferson Davis Parish", state: "Louisiana" },
  { fips: "22055", name: "Lafayette Parish", state: "Louisiana" },
  { fips: "22057", name: "Lafourche Parish", state: "Louisiana" },
  { fips: "22059", name: "LaSalle Parish", state: "Louisiana" },
  { fips: "22061", name: "Lincoln Parish", state: "Louisiana" },
  { fips: "22063", name: "Livingston Parish", state: "Louisiana" },
  { fips: "22065", name: "Madison Parish", state: "Louisiana" },
  { fips: "22067", name: "Morehouse Parish", state: "Louisiana" },
  { fips: "22069", name: "Natchitoches Parish", state: "Louisiana" },
  { fips: "22071", name: "New Orleans", state: "Louisiana" },
  { fips: "22073", name: "Ouachita Parish", state: "Louisiana" },
  { fips: "22075", name: "Plaquemines Parish", state: "Louisiana" },
  { fips: "22077", name: "Pointe Coupee Parish", state: "Louisiana" },
  { fips: "22079", name: "Rapides Parish", state: "Louisiana" },
  { fips: "22081", name: "Red River Parish", state: "Louisiana" },
  { fips: "22083", name: "Richland Parish", state: "Louisiana" },
  { fips: "22085", name: "Sabine Parish", state: "Louisiana" },
  { fips: "22087", name: "St. Bernard Parish", state: "Louisiana" },
  { fips: "22089", name: "St. Charles Parish", state: "Louisiana" },
  { fips: "22091", name: "St. Helena Parish", state: "Louisiana" },
  { fips: "22093", name: "St. James Parish", state: "Louisiana" },
  { fips: "22095", name: "St. John the Baptist Parish", state: "Louisiana" },
  { fips: "22097", name: "St. Landry Parish", state: "Louisiana" },
  { fips: "22099", name: "St. Martin Parish", state: "Louisiana" },
  { fips: "22101", name: "St. Mary Parish", state: "Louisiana" },
  { fips: "22103", name: "St. Tammany Parish", state: "Louisiana" },
  { fips: "22105", name: "Tangipahoa Parish", state: "Louisiana" },
  { fips: "22107", name: "Tensas Parish", state: "Louisiana" },
  { fips: "22109", name: "Terrebonne Parish", state: "Louisiana" },
  { fips: "22111", name: "Union Parish", state: "Louisiana" },
  { fips: "22113", name: "Vermilion Parish", state: "Louisiana" },
  { fips: "22115", name: "Vernon Parish", state: "Louisiana" },
  { fips: "22117", name: "Washington Parish", state: "Louisiana" },
  { fips: "22119", name: "Webster Parish", state: "Louisiana" },
  { fips: "22121", name: "West Baton Rouge Parish", state: "Louisiana" },
  { fips: "22123", name: "West Carroll Parish", state: "Louisiana" },
  { fips: "22125", name: "West Feliciana Parish", state: "Louisiana" },
  { fips: "22127", name: "Winn Parish", state: "Louisiana" },
  { fips: "23001", name: "Androscoggin County", state: "Maine" },
  { fips: "23003", name: "Aroostook County", state: "Maine" },
  { fips: "23005", name: "Cumberland County", state: "Maine" },
  { fips: "23007", name: "Franklin County", state: "Maine" },
  { fips: "23009", name: "Hancock County", state: "Maine" },
  { fips: "23011", name: "Kennebec County", state: "Maine" },
  { fips: "23013", name: "Knox County", state: "Maine" },
  { fips: "23015", name: "Lincoln County", state: "Maine" },
  { fips: "23017", name: "Oxford County", state: "Maine" },
  { fips: "23019", name: "Penobscot County", state: "Maine" },
  { fips: "23021", name: "Piscataquis County", state: "Maine" },
  { fips: "23023", name: "Sagadahoc County", state: "Maine" },
  { fips: "23025", name: "Somerset County", state: "Maine" },
  { fips: "23027", name: "Waldo County", state: "Maine" },
  { fips: "23029", name: "Washington County", state: "Maine" },
  { fips: "23031", name: "York County", state: "Maine" },
  { fips: "24001", name: "Allegany County", state: "Maryland" },
  { fips: "24003", name: "Anne Arundel County", state: "Maryland" },
  { fips: "24005", name: "Baltimore County", state: "Maryland" },
  { fips: "24009", name: "Calvert County", state: "Maryland" },
  { fips: "24011", name: "Caroline County", state: "Maryland" },
  { fips: "24013", name: "Carroll County", state: "Maryland" },
  { fips: "24015", name: "Cecil County", state: "Maryland" },
  { fips: "24017", name: "Charles County", state: "Maryland" },
  { fips: "24019", name: "Dorchester County", state: "Maryland" },
  { fips: "24021", name: "Frederick County", state: "Maryland" },
  { fips: "24023", name: "Garrett County", state: "Maryland" },
  { fips: "24025", name: "Harford County", state: "Maryland" },
  { fips: "24027", name: "Howard County", state: "Maryland" },
  { fips: "24029", name: "Kent County", state: "Maryland" },
  { fips: "24031", name: "Montgomery County", state: "Maryland" },
  { fips: "24033", name: "Prince George's County", state: "Maryland" },
  { fips: "24035", name: "Queen Anne's County", state: "Maryland" },
  { fips: "24037", name: "St. Mary's County", state: "Maryland" },
  { fips: "24039", name: "Somerset County", state: "Maryland" },
  { fips: "24041", name: "Talbot County", state: "Maryland" },
  { fips: "24043", name: "Washington County", state: "Maryland" },
  { fips: "24045", name: "Wicomico County", state: "Maryland" },
  { fips: "24047", name: "Worcester County", state: "Maryland" },
  { fips: "24510", name: "Baltimore", state: "Maryland" },
  { fips: "25001", name: "Barnstable County", state: "Massachusetts" },
  { fips: "25003", name: "Berkshire County", state: "Massachusetts" },
  { fips: "25005", name: "Bristol County", state: "Massachusetts" },
  { fips: "25007", name: "Dukes County", state: "Massachusetts" },
  { fips: "25009", name: "Essex County", state: "Massachusetts" },
  { fips: "25011", name: "Franklin County", state: "Massachusetts" },
  { fips: "25013", name: "Hampden County", state: "Massachusetts" },
  { fips: "25015", name: "Hampshire County", state: "Massachusetts" },
  { fips: "25017", name: "Middlesex County", state: "Massachusetts" },
  { fips: "25019", name: "Nantucket", state: "Massachusetts" },
  { fips: "25021", name: "Norfolk County", state: "Massachusetts" },
  { fips: "25023", name: "Plymouth County", state: "Massachusetts" },
  { fips: "25025", name: "Suffolk County", state: "Massachusetts" },
  { fips: "25027", name: "Worcester County", state: "Massachusetts" },
  { fips: "26001", name: "Alcona County", state: "Michigan" },
  { fips: "26003", name: "Alger County", state: "Michigan" },
  { fips: "26005", name: "Allegan County", state: "Michigan" },
  { fips: "26007", name: "Alpena County", state: "Michigan" },
  { fips: "26009", name: "Antrim County", state: "Michigan" },
  { fips: "26011", name: "Arenac County", state: "Michigan" },
  { fips: "26013", name: "Baraga County", state: "Michigan" },
  { fips: "26015", name: "Barry County", state: "Michigan" },
  { fips: "26017", name: "Bay County", state: "Michigan" },
  { fips: "26019", name: "Benzie County", state: "Michigan" },
  { fips: "26021", name: "Berrien County", state: "Michigan" },
  { fips: "26023", name: "Branch County", state: "Michigan" },
  { fips: "26025", name: "Calhoun County", state: "Michigan" },
  { fips: "26027", name: "Cass County", state: "Michigan" },
  { fips: "26029", name: "Charlevoix County", state: "Michigan" },
  { fips: "26031", name: "Cheboygan County", state: "Michigan" },
  { fips: "26033", name: "Chippewa County", state: "Michigan" },
  { fips: "26035", name: "Clare County", state: "Michigan" },
  { fips: "26037", name: "Clinton County", state: "Michigan" },
  { fips: "26039", name: "Crawford County", state: "Michigan" },
  { fips: "26041", name: "Delta County", state: "Michigan" },
  { fips: "26043", name: "Dickinson County", state: "Michigan" },
  { fips: "26045", name: "Eaton County", state: "Michigan" },
  { fips: "26047", name: "Emmet County", state: "Michigan" },
  { fips: "26049", name: "Genesee County", state: "Michigan" },
  { fips: "26051", name: "Gladwin County", state: "Michigan" },
  { fips: "26053", name: "Gogebic County", state: "Michigan" },
  { fips: "26055", name: "Grand Traverse County", state: "Michigan" },
  { fips: "26057", name: "Gratiot County", state: "Michigan" },
  { fips: "26059", name: "Hillsdale County", state: "Michigan" },
  { fips: "26061", name: "Houghton County", state: "Michigan" },
  { fips: "26063", name: "Huron County", state: "Michigan" },
  { fips: "26065", name: "Ingham County", state: "Michigan" },
  { fips: "26067", name: "Ionia County", state: "Michigan" },
  { fips: "26069", name: "Iosco County", state: "Michigan" },
  { fips: "26071", name: "Iron County", state: "Michigan" },
  { fips: "26073", name: "Isabella County", state: "Michigan" },
  { fips: "26075", name: "Jackson County", state: "Michigan" },
  { fips: "26077", name: "Kalamazoo County", state: "Michigan" },
  { fips: "26079", name: "Kalkaska County", state: "Michigan" },
  { fips: "26081", name: "Kent County", state: "Michigan" },
  { fips: "26083", name: "Keweenaw County", state: "Michigan" },
  { fips: "26085", name: "Lake County", state: "Michigan" },
  { fips: "26087", name: "Lapeer County", state: "Michigan" },
  { fips: "26089", name: "Leelanau County", state: "Michigan" },
  { fips: "26091", name: "Lenawee County", state: "Michigan" },
  { fips: "26093", name: "Livingston County", state: "Michigan" },
  { fips: "26095", name: "Luce County", state: "Michigan" },
  { fips: "26097", name: "Mackinac County", state: "Michigan" },
  { fips: "26099", name: "Macomb County", state: "Michigan" },
  { fips: "26101", name: "Manistee County", state: "Michigan" },
  { fips: "26103", name: "Marquette County", state: "Michigan" },
  { fips: "26105", name: "Mason County", state: "Michigan" },
  { fips: "26107", name: "Mecosta County", state: "Michigan" },
  { fips: "26109", name: "Menominee County", state: "Michigan" },
  { fips: "26111", name: "Midland County", state: "Michigan" },
  { fips: "26113", name: "Missaukee County", state: "Michigan" },
  { fips: "26115", name: "Monroe County", state: "Michigan" },
  { fips: "26117", name: "Montcalm County", state: "Michigan" },
  { fips: "26119", name: "Montmorency County", state: "Michigan" },
  { fips: "26121", name: "Muskegon County", state: "Michigan" },
  { fips: "26123", name: "Newaygo County", state: "Michigan" },
  { fips: "26125", name: "Oakland County", state: "Michigan" },
  { fips: "26127", name: "Oceana County", state: "Michigan" },
  { fips: "26129", name: "Ogemaw County", state: "Michigan" },
  { fips: "26131", name: "Ontonagon County", state: "Michigan" },
  { fips: "26133", name: "Osceola County", state: "Michigan" },
  { fips: "26135", name: "Oscoda County", state: "Michigan" },
  { fips: "26137", name: "Otsego County", state: "Michigan" },
  { fips: "26139", name: "Ottawa County", state: "Michigan" },
  { fips: "26141", name: "Presque Isle County", state: "Michigan" },
  { fips: "26143", name: "Roscommon County", state: "Michigan" },
  { fips: "26145", name: "Saginaw County", state: "Michigan" },
  { fips: "26147", name: "St. Clair County", state: "Michigan" },
  { fips: "26149", name: "St. Joseph County", state: "Michigan" },
  { fips: "26151", name: "Sanilac County", state: "Michigan" },
  { fips: "26153", name: "Schoolcraft County", state: "Michigan" },
  { fips: "26155", name: "Shiawassee County", state: "Michigan" },
  { fips: "26157", name: "Tuscola County", state: "Michigan" },
  { fips: "26159", name: "Van Buren County", state: "Michigan" },
  { fips: "26161", name: "Washtenaw County", state: "Michigan" },
  { fips: "26163", name: "Wayne County", state: "Michigan" },
  { fips: "26165", name: "Wexford County", state: "Michigan" },
  { fips: "27001", name: "Aitkin County", state: "Minnesota" },
  { fips: "27003", name: "Anoka County", state: "Minnesota" },
  { fips: "27005", name: "Becker County", state: "Minnesota" },
  { fips: "27007", name: "Beltrami County", state: "Minnesota" },
  { fips: "27009", name: "Benton County", state: "Minnesota" },
  { fips: "27011", name: "Big Stone County", state: "Minnesota" },
  { fips: "27013", name: "Blue Earth County", state: "Minnesota" },
  { fips: "27015", name: "Brown County", state: "Minnesota" },
  { fips: "27017", name: "Carlton County", state: "Minnesota" },
  { fips: "27019", name: "Carver County", state: "Minnesota" },
  { fips: "27021", name: "Cass County", state: "Minnesota" },
  { fips: "27023", name: "Chippewa County", state: "Minnesota" },
  { fips: "27025", name: "Chisago County", state: "Minnesota" },
  { fips: "27027", name: "Clay County", state: "Minnesota" },
  { fips: "27029", name: "Clearwater County", state: "Minnesota" },
  { fips: "27031", name: "Cook County", state: "Minnesota" },
  { fips: "27033", name: "Cottonwood County", state: "Minnesota" },
  { fips: "27035", name: "Crow Wing County", state: "Minnesota" },
  { fips: "27037", name: "Dakota County", state: "Minnesota" },
  { fips: "27039", name: "Dodge County", state: "Minnesota" },
  { fips: "27041", name: "Douglas County", state: "Minnesota" },
  { fips: "27043", name: "Faribault County", state: "Minnesota" },
  { fips: "27045", name: "Fillmore County", state: "Minnesota" },
  { fips: "27047", name: "Freeborn County", state: "Minnesota" },
  { fips: "27049", name: "Goodhue County", state: "Minnesota" },
  { fips: "27051", name: "Grant County", state: "Minnesota" },
  { fips: "27053", name: "Hennepin County", state: "Minnesota" },
  { fips: "27055", name: "Houston County", state: "Minnesota" },
  { fips: "27057", name: "Hubbard County", state: "Minnesota" },
  { fips: "27059", name: "Isanti County", state: "Minnesota" },
  { fips: "27061", name: "Itasca County", state: "Minnesota" },
  { fips: "27063", name: "Jackson County", state: "Minnesota" },
  { fips: "27065", name: "Kanabec County", state: "Minnesota" },
  { fips: "27067", name: "Kandiyohi County", state: "Minnesota" },
  { fips: "27069", name: "Kittson County", state: "Minnesota" },
  { fips: "27071", name: "Koochiching County", state: "Minnesota" },
  { fips: "27073", name: "Lac qui Parle County", state: "Minnesota" },
  { fips: "27075", name: "Lake County", state: "Minnesota" },
  { fips: "27077", name: "Lake of the Woods County", state: "Minnesota" },
  { fips: "27079", name: "Le Sueur County", state: "Minnesota" },
  { fips: "27081", name: "Lincoln County", state: "Minnesota" },
  { fips: "27083", name: "Lyon County", state: "Minnesota" },
  { fips: "27085", name: "McLeod County", state: "Minnesota" },
  { fips: "27087", name: "Mahnomen County", state: "Minnesota" },
  { fips: "27089", name: "Marshall County", state: "Minnesota" },
  { fips: "27091", name: "Martin County", state: "Minnesota" },
  { fips: "27093", name: "Meeker County", state: "Minnesota" },
  { fips: "27095", name: "Mille Lacs County", state: "Minnesota" },
  { fips: "27097", name: "Morrison County", state: "Minnesota" },
  { fips: "27099", name: "Mower County", state: "Minnesota" },
  { fips: "27101", name: "Murray County", state: "Minnesota" },
  { fips: "27103", name: "Nicollet County", state: "Minnesota" },
  { fips: "27105", name: "Nobles County", state: "Minnesota" },
  { fips: "27107", name: "Norman County", state: "Minnesota" },
  { fips: "27109", name: "Olmsted County", state: "Minnesota" },
  { fips: "27111", name: "Otter Tail County", state: "Minnesota" },
  { fips: "27113", name: "Pennington County", state: "Minnesota" },
  { fips: "27115", name: "Pine County", state: "Minnesota" },
  { fips: "27117", name: "Pipestone County", state: "Minnesota" },
  { fips: "27119", name: "Polk County", state: "Minnesota" },
  { fips: "27121", name: "Pope County", state: "Minnesota" },
  { fips: "27123", name: "Ramsey County", state: "Minnesota" },
  { fips: "27125", name: "Red Lake County", state: "Minnesota" },
  { fips: "27127", name: "Redwood County", state: "Minnesota" },
  { fips: "27129", name: "Renville County", state: "Minnesota" },
  { fips: "27131", name: "Rice County", state: "Minnesota" },
  { fips: "27133", name: "Rock County", state: "Minnesota" },
  { fips: "27135", name: "Roseau County", state: "Minnesota" },
  { fips: "27137", name: "St. Louis County", state: "Minnesota" },
  { fips: "27139", name: "Scott County", state: "Minnesota" },
  { fips: "27141", name: "Sherburne County", state: "Minnesota" },
  { fips: "27143", name: "Sibley County", state: "Minnesota" },
  { fips: "27145", name: "Stearns County", state: "Minnesota" },
  { fips: "27147", name: "Steele County", state: "Minnesota" },
  { fips: "27149", name: "Stevens County", state: "Minnesota" },
  { fips: "27151", name: "Swift County", state: "Minnesota" },
  { fips: "27153", name: "Todd County", state: "Minnesota" },
  { fips: "27155", name: "Traverse County", state: "Minnesota" },
  { fips: "27157", name: "Wabasha County", state: "Minnesota" },
  { fips: "27159", name: "Wadena County", state: "Minnesota" },
  { fips: "27161", name: "Waseca County", state: "Minnesota" },
  { fips: "27163", name: "Washington County", state: "Minnesota" },
  { fips: "27165", name: "Watonwan County", state: "Minnesota" },
  { fips: "27167", name: "Wilkin County", state: "Minnesota" },
  { fips: "27169", name: "Winona County", state: "Minnesota" },
  { fips: "27171", name: "Wright County", state: "Minnesota" },
  { fips: "27173", name: "Yellow Medicine County", state: "Minnesota" },
  { fips: "28001", name: "Adams County", state: "Mississippi" },
  { fips: "28003", name: "Alcorn County", state: "Mississippi" },
  { fips: "28005", name: "Amite County", state: "Mississippi" },
  { fips: "28007", name: "Attala County", state: "Mississippi" },
  { fips: "28009", name: "Benton County", state: "Mississippi" },
  { fips: "28011", name: "Bolivar County", state: "Mississippi" },
  { fips: "28013", name: "Calhoun County", state: "Mississippi" },
  { fips: "28015", name: "Carroll County", state: "Mississippi" },
  { fips: "28017", name: "Chickasaw County", state: "Mississippi" },
  { fips: "28019", name: "Choctaw County", state: "Mississippi" },
  { fips: "28021", name: "Claiborne County", state: "Mississippi" },
  { fips: "28023", name: "Clarke County", state: "Mississippi" },
  { fips: "28025", name: "Clay County", state: "Mississippi" },
  { fips: "28027", name: "Coahoma County", state: "Mississippi" },
  { fips: "28029", name: "Copiah County", state: "Mississippi" },
  { fips: "28031", name: "Covington County", state: "Mississippi" },
  { fips: "28033", name: "DeSoto County", state: "Mississippi" },
  { fips: "28035", name: "Forrest County", state: "Mississippi" },
  { fips: "28037", name: "Franklin County", state: "Mississippi" },
  { fips: "28039", name: "George County", state: "Mississippi" },
  { fips: "28041", name: "Greene County", state: "Mississippi" },
  { fips: "28043", name: "Grenada County", state: "Mississippi" },
  { fips: "28045", name: "Hancock County", state: "Mississippi" },
  { fips: "28047", name: "Harrison County", state: "Mississippi" },
  { fips: "28049", name: "Hinds County", state: "Mississippi" },
  { fips: "28051", name: "Holmes County", state: "Mississippi" },
  { fips: "28053", name: "Humphreys County", state: "Mississippi" },
  { fips: "28055", name: "Issaquena County", state: "Mississippi" },
  { fips: "28057", name: "Itawamba County", state: "Mississippi" },
  { fips: "28059", name: "Jackson County", state: "Mississippi" },
  { fips: "28061", name: "Jasper County", state: "Mississippi" },
  { fips: "28063", name: "Jefferson County", state: "Mississippi" },
  { fips: "28065", name: "Jefferson Davis County", state: "Mississippi" },
  { fips: "28067", name: "Jones County", state: "Mississippi" },
  { fips: "28069", name: "Kemper County", state: "Mississippi" },
  { fips: "28071", name: "Lafayette County", state: "Mississippi" },
  { fips: "28073", name: "Lamar County", state: "Mississippi" },
  { fips: "28075", name: "Lauderdale County", state: "Mississippi" },
  { fips: "28077", name: "Lawrence County", state: "Mississippi" },
  { fips: "28079", name: "Leake County", state: "Mississippi" },
  { fips: "28081", name: "Lee County", state: "Mississippi" },
  { fips: "28083", name: "Leflore County", state: "Mississippi" },
  { fips: "28085", name: "Lincoln County", state: "Mississippi" },
  { fips: "28087", name: "Lowndes County", state: "Mississippi" },
  { fips: "28089", name: "Madison County", state: "Mississippi" },
  { fips: "28091", name: "Marion County", state: "Mississippi" },
  { fips: "28093", name: "Marshall County", state: "Mississippi" },
  { fips: "28095", name: "Monroe County", state: "Mississippi" },
  { fips: "28097", name: "Montgomery County", state: "Mississippi" },
  { fips: "28099", name: "Neshoba County", state: "Mississippi" },
  { fips: "28101", name: "Newton County", state: "Mississippi" },
  { fips: "28103", name: "Noxubee County", state: "Mississippi" },
  { fips: "28105", name: "Oktibbeha County", state: "Mississippi" },
  { fips: "28107", name: "Panola County", state: "Mississippi" },
  { fips: "28109", name: "Pearl River County", state: "Mississippi" },
  { fips: "28111", name: "Perry County", state: "Mississippi" },
  { fips: "28113", name: "Pike County", state: "Mississippi" },
  { fips: "28115", name: "Pontotoc County", state: "Mississippi" },
  { fips: "28117", name: "Prentiss County", state: "Mississippi" },
  { fips: "28119", name: "Quitman County", state: "Mississippi" },
  { fips: "28121", name: "Rankin County", state: "Mississippi" },
  { fips: "28123", name: "Scott County", state: "Mississippi" },
  { fips: "28125", name: "Sharkey County", state: "Mississippi" },
  { fips: "28127", name: "Simpson County", state: "Mississippi" },
  { fips: "28129", name: "Smith County", state: "Mississippi" },
  { fips: "28131", name: "Stone County", state: "Mississippi" },
  { fips: "28133", name: "Sunflower County", state: "Mississippi" },
  { fips: "28135", name: "Tallahatchie County", state: "Mississippi" },
  { fips: "28137", name: "Tate County", state: "Mississippi" },
  { fips: "28139", name: "Tippah County", state: "Mississippi" },
  { fips: "28141", name: "Tishomingo County", state: "Mississippi" },
  { fips: "28143", name: "Tunica County", state: "Mississippi" },
  { fips: "28145", name: "Union County", state: "Mississippi" },
  { fips: "28147", name: "Walthall County", state: "Mississippi" },
  { fips: "28149", name: "Warren County", state: "Mississippi" },
  { fips: "28151", name: "Washington County", state: "Mississippi" },
  { fips: "28153", name: "Wayne County", state: "Mississippi" },
  { fips: "28155", name: "Webster County", state: "Mississippi" },
  { fips: "28157", name: "Wilkinson County", state: "Mississippi" },
  { fips: "28159", name: "Winston County", state: "Mississippi" },
  { fips: "28161", name: "Yalobusha County", state: "Mississippi" },
  { fips: "28163", name: "Yazoo County", state: "Mississippi" },
  { fips: "29001", name: "Adair County", state: "Missouri" },
  { fips: "29003", name: "Andrew County", state: "Missouri" },
  { fips: "29005", name: "Atchison County", state: "Missouri" },
  { fips: "29007", name: "Audrain County", state: "Missouri" },
  { fips: "29009", name: "Barry County", state: "Missouri" },
  { fips: "29011", name: "Barton County", state: "Missouri" },
  { fips: "29013", name: "Bates County", state: "Missouri" },
  { fips: "29015", name: "Benton County", state: "Missouri" },
  { fips: "29017", name: "Bollinger County", state: "Missouri" },
  { fips: "29019", name: "Boone County", state: "Missouri" },
  { fips: "29021", name: "Buchanan County", state: "Missouri" },
  { fips: "29023", name: "Butler County", state: "Missouri" },
  { fips: "29025", name: "Caldwell County", state: "Missouri" },
  { fips: "29027", name: "Callaway County", state: "Missouri" },
  { fips: "29029", name: "Camden County", state: "Missouri" },
  { fips: "29031", name: "Cape Girardeau County", state: "Missouri" },
  { fips: "29033", name: "Carroll County", state: "Missouri" },
  { fips: "29035", name: "Carter County", state: "Missouri" },
  { fips: "29037", name: "Cass County", state: "Missouri" },
  { fips: "29039", name: "Cedar County", state: "Missouri" },
  { fips: "29041", name: "Chariton County", state: "Missouri" },
  { fips: "29043", name: "Christian County", state: "Missouri" },
  { fips: "29045", name: "Clark County", state: "Missouri" },
  { fips: "29047", name: "Clay County", state: "Missouri" },
  { fips: "29049", name: "Clinton County", state: "Missouri" },
  { fips: "29051", name: "Cole County", state: "Missouri" },
  { fips: "29053", name: "Cooper County", state: "Missouri" },
  { fips: "29055", name: "Crawford County", state: "Missouri" },
  { fips: "29057", name: "Dade County", state: "Missouri" },
  { fips: "29059", name: "Dallas County", state: "Missouri" },
  { fips: "29061", name: "Daviess County", state: "Missouri" },
  { fips: "29063", name: "DeKalb County", state: "Missouri" },
  { fips: "29065", name: "Dent County", state: "Missouri" },
  { fips: "29067", name: "Douglas County", state: "Missouri" },
  { fips: "29069", name: "Dunklin County", state: "Missouri" },
  { fips: "29071", name: "Franklin County", state: "Missouri" },
  { fips: "29073", name: "Gasconade County", state: "Missouri" },
  { fips: "29075", name: "Gentry County", state: "Missouri" },
  { fips: "29077", name: "Greene County", state: "Missouri" },
  { fips: "29079", name: "Grundy County", state: "Missouri" },
  { fips: "29081", name: "Harrison County", state: "Missouri" },
  { fips: "29083", name: "Henry County", state: "Missouri" },
  { fips: "29085", name: "Hickory County", state: "Missouri" },
  { fips: "29087", name: "Holt County", state: "Missouri" },
  { fips: "29089", name: "Howard County", state: "Missouri" },
  { fips: "29091", name: "Howell County", state: "Missouri" },
  { fips: "29093", name: "Iron County", state: "Missouri" },
  { fips: "29095", name: "Jackson County", state: "Missouri" },
  { fips: "29097", name: "Jasper County", state: "Missouri" },
  { fips: "29099", name: "Jefferson County", state: "Missouri" },
  { fips: "29101", name: "Johnson County", state: "Missouri" },
  { fips: "29103", name: "Knox County", state: "Missouri" },
  { fips: "29105", name: "Laclede County", state: "Missouri" },
  { fips: "29107", name: "Lafayette County", state: "Missouri" },
  { fips: "29109", name: "Lawrence County", state: "Missouri" },
  { fips: "29111", name: "Lewis County", state: "Missouri" },
  { fips: "29113", name: "Lincoln County", state: "Missouri" },
  { fips: "29115", name: "Linn County", state: "Missouri" },
  { fips: "29117", name: "Livingston County", state: "Missouri" },
  { fips: "29119", name: "McDonald County", state: "Missouri" },
  { fips: "29121", name: "Macon County", state: "Missouri" },
  { fips: "29123", name: "Madison County", state: "Missouri" },
  { fips: "29125", name: "Maries County", state: "Missouri" },
  { fips: "29127", name: "Marion County", state: "Missouri" },
  { fips: "29129", name: "Mercer County", state: "Missouri" },
  { fips: "29131", name: "Miller County", state: "Missouri" },
  { fips: "29133", name: "Mississippi County", state: "Missouri" },
  { fips: "29135", name: "Moniteau County", state: "Missouri" },
  { fips: "29137", name: "Monroe County", state: "Missouri" },
  { fips: "29139", name: "Montgomery County", state: "Missouri" },
  { fips: "29141", name: "Morgan County", state: "Missouri" },
  { fips: "29143", name: "New Madrid County", state: "Missouri" },
  { fips: "29145", name: "Newton County", state: "Missouri" },
  { fips: "29147", name: "Nodaway County", state: "Missouri" },
  { fips: "29149", name: "Oregon County", state: "Missouri" },
  { fips: "29151", name: "Osage County", state: "Missouri" },
  { fips: "29153", name: "Ozark County", state: "Missouri" },
  { fips: "29155", name: "Pemiscot County", state: "Missouri" },
  { fips: "29157", name: "Perry County", state: "Missouri" },
  { fips: "29159", name: "Pettis County", state: "Missouri" },
  { fips: "29161", name: "Phelps County", state: "Missouri" },
  { fips: "29163", name: "Pike County", state: "Missouri" },
  { fips: "29165", name: "Platte County", state: "Missouri" },
  { fips: "29167", name: "Polk County", state: "Missouri" },
  { fips: "29169", name: "Pulaski County", state: "Missouri" },
  { fips: "29171", name: "Putnam County", state: "Missouri" },
  { fips: "29173", name: "Ralls County", state: "Missouri" },
  { fips: "29175", name: "Randolph County", state: "Missouri" },
  { fips: "29177", name: "Ray County", state: "Missouri" },
  { fips: "29179", name: "Reynolds County", state: "Missouri" },
  { fips: "29181", name: "Ripley County", state: "Missouri" },
  { fips: "29183", name: "St. Charles County", state: "Missouri" },
  { fips: "29185", name: "St. Clair County", state: "Missouri" },
  { fips: "29186", name: "Ste. Genevieve County", state: "Missouri" },
  { fips: "29187", name: "St. Francois County", state: "Missouri" },
  { fips: "29189", name: "St. Louis County", state: "Missouri" },
  { fips: "29195", name: "Saline County", state: "Missouri" },
  { fips: "29197", name: "Schuyler County", state: "Missouri" },
  { fips: "29199", name: "Scotland County", state: "Missouri" },
  { fips: "29201", name: "Scott County", state: "Missouri" },
  { fips: "29203", name: "Shannon County", state: "Missouri" },
  { fips: "29205", name: "Shelby County", state: "Missouri" },
  { fips: "29207", name: "Stoddard County", state: "Missouri" },
  { fips: "29209", name: "Stone County", state: "Missouri" },
  { fips: "29211", name: "Sullivan County", state: "Missouri" },
  { fips: "29213", name: "Taney County", state: "Missouri" },
  { fips: "29215", name: "Texas County", state: "Missouri" },
  { fips: "29217", name: "Vernon County", state: "Missouri" },
  { fips: "29219", name: "Warren County", state: "Missouri" },
  { fips: "29221", name: "Washington County", state: "Missouri" },
  { fips: "29223", name: "Wayne County", state: "Missouri" },
  { fips: "29225", name: "Webster County", state: "Missouri" },
  { fips: "29227", name: "Worth County", state: "Missouri" },
  { fips: "29229", name: "Wright County", state: "Missouri" },
  { fips: "29510", name: "St. Louis", state: "Missouri" },
  { fips: "30001", name: "Beaverhead County", state: "Montana" },
  { fips: "30003", name: "Big Horn County", state: "Montana" },
  { fips: "30005", name: "Blaine County", state: "Montana" },
  { fips: "30007", name: "Broadwater County", state: "Montana" },
  { fips: "30009", name: "Carbon County", state: "Montana" },
  { fips: "30011", name: "Carter County", state: "Montana" },
  { fips: "30013", name: "Cascade County", state: "Montana" },
  { fips: "30015", name: "Chouteau County", state: "Montana" },
  { fips: "30017", name: "Custer County", state: "Montana" },
  { fips: "30019", name: "Daniels County", state: "Montana" },
  { fips: "30021", name: "Dawson County", state: "Montana" },
  { fips: "30023", name: "Deer Lodge County", state: "Montana" },
  { fips: "30025", name: "Fallon County", state: "Montana" },
  { fips: "30027", name: "Fergus County", state: "Montana" },
  { fips: "30029", name: "Flathead County", state: "Montana" },
  { fips: "30031", name: "Gallatin County", state: "Montana" },
  { fips: "30033", name: "Garfield County", state: "Montana" },
  { fips: "30035", name: "Glacier County", state: "Montana" },
  { fips: "30037", name: "Golden Valley County", state: "Montana" },
  { fips: "30039", name: "Granite County", state: "Montana" },
  { fips: "30041", name: "Hill County", state: "Montana" },
  { fips: "30043", name: "Jefferson County", state: "Montana" },
  { fips: "30045", name: "Judith Basin County", state: "Montana" },
  { fips: "30047", name: "Lake County", state: "Montana" },
  { fips: "30049", name: "Lewis and Clark County", state: "Montana" },
  { fips: "30051", name: "Liberty County", state: "Montana" },
  { fips: "30053", name: "Lincoln County", state: "Montana" },
  { fips: "30055", name: "McCone County", state: "Montana" },
  { fips: "30057", name: "Madison County", state: "Montana" },
  { fips: "30059", name: "Meagher County", state: "Montana" },
  { fips: "30061", name: "Mineral County", state: "Montana" },
  { fips: "30063", name: "Missoula County", state: "Montana" },
  { fips: "30065", name: "Musselshell County", state: "Montana" },
  { fips: "30067", name: "Park County", state: "Montana" },
  { fips: "30069", name: "Petroleum County", state: "Montana" },
  { fips: "30071", name: "Phillips County", state: "Montana" },
  { fips: "30073", name: "Pondera County", state: "Montana" },
  { fips: "30075", name: "Powder River County", state: "Montana" },
  { fips: "30077", name: "Powell County", state: "Montana" },
  { fips: "30079", name: "Prairie County", state: "Montana" },
  { fips: "30081", name: "Ravalli County", state: "Montana" },
  { fips: "30083", name: "Richland County", state: "Montana" },
  { fips: "30085", name: "Roosevelt County", state: "Montana" },
  { fips: "30087", name: "Rosebud County", state: "Montana" },
  { fips: "30089", name: "Sanders County", state: "Montana" },
  { fips: "30091", name: "Sheridan County", state: "Montana" },
  { fips: "30093", name: "Silver Bow County", state: "Montana" },
  { fips: "30095", name: "Stillwater County", state: "Montana" },
  { fips: "30097", name: "Sweet Grass County", state: "Montana" },
  { fips: "30099", name: "Teton County", state: "Montana" },
  { fips: "30101", name: "Toole County", state: "Montana" },
  { fips: "30103", name: "Treasure County", state: "Montana" },
  { fips: "30105", name: "Valley County", state: "Montana" },
  { fips: "30107", name: "Wheatland County", state: "Montana" },
  { fips: "30109", name: "Wibaux County", state: "Montana" },
  { fips: "30111", name: "Yellowstone County", state: "Montana" },
  { fips: "31001", name: "Adams County", state: "Nebraska" },
  { fips: "31003", name: "Antelope County", state: "Nebraska" },
  { fips: "31005", name: "Arthur County", state: "Nebraska" },
  { fips: "31007", name: "Banner County", state: "Nebraska" },
  { fips: "31009", name: "Blaine County", state: "Nebraska" },
  { fips: "31011", name: "Boone County", state: "Nebraska" },
  { fips: "31013", name: "Box Butte County", state: "Nebraska" },
  { fips: "31015", name: "Boyd County", state: "Nebraska" },
  { fips: "31017", name: "Brown County", state: "Nebraska" },
  { fips: "31019", name: "Buffalo County", state: "Nebraska" },
  { fips: "31021", name: "Burt County", state: "Nebraska" },
  { fips: "31023", name: "Butler County", state: "Nebraska" },
  { fips: "31025", name: "Cass County", state: "Nebraska" },
  { fips: "31027", name: "Cedar County", state: "Nebraska" },
  { fips: "31029", name: "Chase County", state: "Nebraska" },
  { fips: "31031", name: "Cherry County", state: "Nebraska" },
  { fips: "31033", name: "Cheyenne County", state: "Nebraska" },
  { fips: "31035", name: "Clay County", state: "Nebraska" },
  { fips: "31037", name: "Colfax County", state: "Nebraska" },
  { fips: "31039", name: "Cuming County", state: "Nebraska" },
  { fips: "31041", name: "Custer County", state: "Nebraska" },
  { fips: "31043", name: "Dakota County", state: "Nebraska" },
  { fips: "31045", name: "Dawes County", state: "Nebraska" },
  { fips: "31047", name: "Dawson County", state: "Nebraska" },
  { fips: "31049", name: "Deuel County", state: "Nebraska" },
  { fips: "31051", name: "Dixon County", state: "Nebraska" },
  { fips: "31053", name: "Dodge County", state: "Nebraska" },
  { fips: "31055", name: "Douglas County", state: "Nebraska" },
  { fips: "31057", name: "Dundy County", state: "Nebraska" },
  { fips: "31059", name: "Fillmore County", state: "Nebraska" },
  { fips: "31061", name: "Franklin County", state: "Nebraska" },
  { fips: "31063", name: "Frontier County", state: "Nebraska" },
  { fips: "31065", name: "Furnas County", state: "Nebraska" },
  { fips: "31067", name: "Gage County", state: "Nebraska" },
  { fips: "31069", name: "Garden County", state: "Nebraska" },
  { fips: "31071", name: "Garfield County", state: "Nebraska" },
  { fips: "31073", name: "Gosper County", state: "Nebraska" },
  { fips: "31075", name: "Grant County", state: "Nebraska" },
  { fips: "31077", name: "Greeley County", state: "Nebraska" },
  { fips: "31079", name: "Hall County", state: "Nebraska" },
  { fips: "31081", name: "Hamilton County", state: "Nebraska" },
  { fips: "31083", name: "Harlan County", state: "Nebraska" },
  { fips: "31085", name: "Hayes County", state: "Nebraska" },
  { fips: "31087", name: "Hitchcock County", state: "Nebraska" },
  { fips: "31089", name: "Holt County", state: "Nebraska" },
  { fips: "31091", name: "Hooker County", state: "Nebraska" },
  { fips: "31093", name: "Howard County", state: "Nebraska" },
  { fips: "31095", name: "Jefferson County", state: "Nebraska" },
  { fips: "31097", name: "Johnson County", state: "Nebraska" },
  { fips: "31099", name: "Kearney County", state: "Nebraska" },
  { fips: "31101", name: "Keith County", state: "Nebraska" },
  { fips: "31103", name: "Keya Paha County", state: "Nebraska" },
  { fips: "31105", name: "Kimball County", state: "Nebraska" },
  { fips: "31107", name: "Knox County", state: "Nebraska" },
  { fips: "31109", name: "Lancaster County", state: "Nebraska" },
  { fips: "31111", name: "Lincoln County", state: "Nebraska" },
  { fips: "31113", name: "Logan County", state: "Nebraska" },
  { fips: "31115", name: "Loup County", state: "Nebraska" },
  { fips: "31117", name: "McPherson County", state: "Nebraska" },
  { fips: "31119", name: "Madison County", state: "Nebraska" },
  { fips: "31121", name: "Merrick County", state: "Nebraska" },
  { fips: "31123", name: "Morrill County", state: "Nebraska" },
  { fips: "31125", name: "Nance County", state: "Nebraska" },
  { fips: "31127", name: "Nemaha County", state: "Nebraska" },
  { fips: "31129", name: "Nuckolls County", state: "Nebraska" },
  { fips: "31131", name: "Otoe County", state: "Nebraska" },
  { fips: "31133", name: "Pawnee County", state: "Nebraska" },
  { fips: "31135", name: "Perkins County", state: "Nebraska" },
  { fips: "31137", name: "Phelps County", state: "Nebraska" },
  { fips: "31139", name: "Pierce County", state: "Nebraska" },
  { fips: "31141", name: "Platte County", state: "Nebraska" },
  { fips: "31143", name: "Polk County", state: "Nebraska" },
  { fips: "31145", name: "Red Willow County", state: "Nebraska" },
  { fips: "31147", name: "Richardson County", state: "Nebraska" },
  { fips: "31149", name: "Rock County", state: "Nebraska" },
  { fips: "31151", name: "Saline County", state: "Nebraska" },
  { fips: "31153", name: "Sarpy County", state: "Nebraska" },
  { fips: "31155", name: "Saunders County", state: "Nebraska" },
  { fips: "31157", name: "Scotts Bluff County", state: "Nebraska" },
  { fips: "31159", name: "Seward County", state: "Nebraska" },
  { fips: "31161", name: "Sheridan County", state: "Nebraska" },
  { fips: "31163", name: "Sherman County", state: "Nebraska" },
  { fips: "31165", name: "Sioux County", state: "Nebraska" },
  { fips: "31167", name: "Stanton County", state: "Nebraska" },
  { fips: "31169", name: "Thayer County", state: "Nebraska" },
  { fips: "31171", name: "Thomas County", state: "Nebraska" },
  { fips: "31173", name: "Thurston County", state: "Nebraska" },
  { fips: "31175", name: "Valley County", state: "Nebraska" },
  { fips: "31177", name: "Washington County", state: "Nebraska" },
  { fips: "31179", name: "Wayne County", state: "Nebraska" },
  { fips: "31181", name: "Webster County", state: "Nebraska" },
  { fips: "31183", name: "Wheeler County", state: "Nebraska" },
  { fips: "31185", name: "York County", state: "Nebraska" },
  { fips: "32001", name: "Churchill County", state: "Nevada" },
  { fips: "32003", name: "Clark County", state: "Nevada" },
  { fips: "32005", name: "Douglas County", state: "Nevada" },
  { fips: "32007", name: "Elko County", state: "Nevada" },
  { fips: "32009", name: "Esmeralda County", state: "Nevada" },
  { fips: "32011", name: "Eureka County", state: "Nevada" },
  { fips: "32013", name: "Humboldt County", state: "Nevada" },
  { fips: "32015", name: "Lander County", state: "Nevada" },
  { fips: "32017", name: "Lincoln County", state: "Nevada" },
  { fips: "32019", name: "Lyon County", state: "Nevada" },
  { fips: "32021", name: "Mineral County", state: "Nevada" },
  { fips: "32023", name: "Nye County", state: "Nevada" },
  { fips: "32027", name: "Pershing County", state: "Nevada" },
  { fips: "32029", name: "Storey County", state: "Nevada" },
  { fips: "32031", name: "Washoe County", state: "Nevada" },
  { fips: "32033", name: "White Pine County", state: "Nevada" },
  { fips: "32510", name: "Carson City", state: "Nevada" },
  { fips: "33001", name: "Belknap County", state: "New Hampshire" },
  { fips: "33003", name: "Carroll County", state: "New Hampshire" },
  { fips: "33005", name: "Cheshire County", state: "New Hampshire" },
  { fips: "33007", name: "Coos County", state: "New Hampshire" },
  { fips: "33009", name: "Grafton County", state: "New Hampshire" },
  { fips: "33011", name: "Hillsborough County", state: "New Hampshire" },
  { fips: "33013", name: "Merrimack County", state: "New Hampshire" },
  { fips: "33015", name: "Rockingham County", state: "New Hampshire" },
  { fips: "33017", name: "Strafford County", state: "New Hampshire" },
  { fips: "33019", name: "Sullivan County", state: "New Hampshire" },
  { fips: "34001", name: "Atlantic County", state: "New Jersey" },
  { fips: "34003", name: "Bergen County", state: "New Jersey" },
  { fips: "34005", name: "Burlington County", state: "New Jersey" },
  { fips: "34007", name: "Camden County", state: "New Jersey" },
  { fips: "34009", name: "Cape May County", state: "New Jersey" },
  { fips: "34011", name: "Cumberland County", state: "New Jersey" },
  { fips: "34013", name: "Essex County", state: "New Jersey" },
  { fips: "34015", name: "Gloucester County", state: "New Jersey" },
  { fips: "34017", name: "Hudson County", state: "New Jersey" },
  { fips: "34019", name: "Hunterdon County", state: "New Jersey" },
  { fips: "34021", name: "Mercer County", state: "New Jersey" },
  { fips: "34023", name: "Middlesex County", state: "New Jersey" },
  { fips: "34025", name: "Monmouth County", state: "New Jersey" },
  { fips: "34027", name: "Morris County", state: "New Jersey" },
  { fips: "34029", name: "Ocean County", state: "New Jersey" },
  { fips: "34031", name: "Passaic County", state: "New Jersey" },
  { fips: "34033", name: "Salem County", state: "New Jersey" },
  { fips: "34035", name: "Somerset County", state: "New Jersey" },
  { fips: "34037", name: "Sussex County", state: "New Jersey" },
  { fips: "34039", name: "Union County", state: "New Jersey" },
  { fips: "34041", name: "Warren County", state: "New Jersey" },
  { fips: "35001", name: "Bernalillo County", state: "New Mexico" },
  { fips: "35003", name: "Catron County", state: "New Mexico" },
  { fips: "35005", name: "Chaves County", state: "New Mexico" },
  { fips: "35006", name: "Cibola County", state: "New Mexico" },
  { fips: "35007", name: "Colfax County", state: "New Mexico" },
  { fips: "35009", name: "Curry County", state: "New Mexico" },
  { fips: "35011", name: "De Baca County", state: "New Mexico" },
  { fips: "35013", name: "Doña Ana County", state: "New Mexico" },
  { fips: "35015", name: "Eddy County", state: "New Mexico" },
  { fips: "35017", name: "Grant County", state: "New Mexico" },
  { fips: "35019", name: "Guadalupe County", state: "New Mexico" },
  { fips: "35021", name: "Harding County", state: "New Mexico" },
  { fips: "35023", name: "Hidalgo County", state: "New Mexico" },
  { fips: "35025", name: "Lea County", state: "New Mexico" },
  { fips: "35027", name: "Lincoln County", state: "New Mexico" },
  { fips: "35028", name: "Los Alamos County", state: "New Mexico" },
  { fips: "35029", name: "Luna County", state: "New Mexico" },
  { fips: "35031", name: "McKinley County", state: "New Mexico" },
  { fips: "35033", name: "Mora County", state: "New Mexico" },
  { fips: "35035", name: "Otero County", state: "New Mexico" },
  { fips: "35037", name: "Quay County", state: "New Mexico" },
  { fips: "35039", name: "Rio Arriba County", state: "New Mexico" },
  { fips: "35041", name: "Roosevelt County", state: "New Mexico" },
  { fips: "35043", name: "Sandoval County", state: "New Mexico" },
  { fips: "35045", name: "San Juan County", state: "New Mexico" },
  { fips: "35047", name: "San Miguel County", state: "New Mexico" },
  { fips: "35049", name: "Santa Fe County", state: "New Mexico" },
  { fips: "35051", name: "Sierra County", state: "New Mexico" },
  { fips: "35053", name: "Socorro County", state: "New Mexico" },
  { fips: "35055", name: "Taos County", state: "New Mexico" },
  { fips: "35057", name: "Torrance County", state: "New Mexico" },
  { fips: "35059", name: "Union County", state: "New Mexico" },
  { fips: "35061", name: "Valencia County", state: "New Mexico" },
  { fips: "36001", name: "Albany County", state: "New York" },
  { fips: "36003", name: "Allegany County", state: "New York" },
  { fips: "36005", name: "Bronx County", state: "New York" },
  { fips: "36007", name: "Broome County", state: "New York" },
  { fips: "36009", name: "Cattaraugus County", state: "New York" },
  { fips: "36011", name: "Cayuga County", state: "New York" },
  { fips: "36013", name: "Chautauqua County", state: "New York" },
  { fips: "36015", name: "Chemung County", state: "New York" },
  { fips: "36017", name: "Chenango County", state: "New York" },
  { fips: "36019", name: "Clinton County", state: "New York" },
  { fips: "36021", name: "Columbia County", state: "New York" },
  { fips: "36023", name: "Cortland County", state: "New York" },
  { fips: "36025", name: "Delaware County", state: "New York" },
  { fips: "36027", name: "Dutchess County", state: "New York" },
  { fips: "36029", name: "Erie County", state: "New York" },
  { fips: "36031", name: "Essex County", state: "New York" },
  { fips: "36033", name: "Franklin County", state: "New York" },
  { fips: "36035", name: "Fulton County", state: "New York" },
  { fips: "36037", name: "Genesee County", state: "New York" },
  { fips: "36039", name: "Greene County", state: "New York" },
  { fips: "36041", name: "Hamilton County", state: "New York" },
  { fips: "36043", name: "Herkimer County", state: "New York" },
  { fips: "36045", name: "Jefferson County", state: "New York" },
  { fips: "36047", name: "Brooklyn", state: "New York" },
  { fips: "36049", name: "Lewis County", state: "New York" },
  { fips: "36051", name: "Livingston County", state: "New York" },
  { fips: "36053", name: "Madison County", state: "New York" },
  { fips: "36055", name: "Monroe County", state: "New York" },
  { fips: "36057", name: "Montgomery County", state: "New York" },
  { fips: "36059", name: "Nassau County", state: "New York" },
  { fips: "36061", name: "New York County", state: "New York" },
  { fips: "36063", name: "Niagara County", state: "New York" },
  { fips: "36065", name: "Oneida County", state: "New York" },
  { fips: "36067", name: "Onondaga County", state: "New York" },
  { fips: "36069", name: "Ontario County", state: "New York" },
  { fips: "36071", name: "Orange County", state: "New York" },
  { fips: "36073", name: "Orleans County", state: "New York" },
  { fips: "36075", name: "Oswego County", state: "New York" },
  { fips: "36077", name: "Otsego County", state: "New York" },
  { fips: "36079", name: "Putnam County", state: "New York" },
  { fips: "36081", name: "Queens", state: "New York" },
  { fips: "36083", name: "Rensselaer County", state: "New York" },
  { fips: "36085", name: "Richmond County", state: "New York" },
  { fips: "36087", name: "Rockland County", state: "New York" },
  { fips: "36089", name: "St. Lawrence County", state: "New York" },
  { fips: "36091", name: "Saratoga County", state: "New York" },
  { fips: "36093", name: "Schenectady County", state: "New York" },
  { fips: "36095", name: "Schoharie County", state: "New York" },
  { fips: "36097", name: "Schuyler County", state: "New York" },
  { fips: "36099", name: "Seneca County", state: "New York" },
  { fips: "36101", name: "Steuben County", state: "New York" },
  { fips: "36103", name: "Suffolk County", state: "New York" },
  { fips: "36105", name: "Sullivan County", state: "New York" },
  { fips: "36107", name: "Tioga County", state: "New York" },
  { fips: "36109", name: "Tompkins County", state: "New York" },
  { fips: "36111", name: "Ulster County", state: "New York" },
  { fips: "36113", name: "Warren County", state: "New York" },
  { fips: "36115", name: "Washington County", state: "New York" },
  { fips: "36117", name: "Wayne County", state: "New York" },
  { fips: "36119", name: "Westchester County", state: "New York" },
  { fips: "36121", name: "Wyoming County", state: "New York" },
  { fips: "36123", name: "Yates County", state: "New York" },
  { fips: "37001", name: "Alamance County", state: "North Carolina" },
  { fips: "37003", name: "Alexander County", state: "North Carolina" },
  { fips: "37005", name: "Alleghany County", state: "North Carolina" },
  { fips: "37007", name: "Anson County", state: "North Carolina" },
  { fips: "37009", name: "Ashe County", state: "North Carolina" },
  { fips: "37011", name: "Avery County", state: "North Carolina" },
  { fips: "37013", name: "Beaufort County", state: "North Carolina" },
  { fips: "37015", name: "Bertie County", state: "North Carolina" },
  { fips: "37017", name: "Bladen County", state: "North Carolina" },
  { fips: "37019", name: "Brunswick County", state: "North Carolina" },
  { fips: "37021", name: "Buncombe County", state: "North Carolina" },
  { fips: "37023", name: "Burke County", state: "North Carolina" },
  { fips: "37025", name: "Cabarrus County", state: "North Carolina" },
  { fips: "37027", name: "Caldwell County", state: "North Carolina" },
  { fips: "37029", name: "Camden County", state: "North Carolina" },
  { fips: "37031", name: "Carteret County", state: "North Carolina" },
  { fips: "37033", name: "Caswell County", state: "North Carolina" },
  { fips: "37035", name: "Catawba County", state: "North Carolina" },
  { fips: "37037", name: "Chatham County", state: "North Carolina" },
  { fips: "37039", name: "Cherokee County", state: "North Carolina" },
  { fips: "37041", name: "Chowan County", state: "North Carolina" },
  { fips: "37043", name: "Clay County", state: "North Carolina" },
  { fips: "37045", name: "Cleveland County", state: "North Carolina" },
  { fips: "37047", name: "Columbus County", state: "North Carolina" },
  { fips: "37049", name: "Craven County", state: "North Carolina" },
  { fips: "37051", name: "Cumberland County", state: "North Carolina" },
  { fips: "37053", name: "Currituck County", state: "North Carolina" },
  { fips: "37055", name: "Dare County", state: "North Carolina" },
  { fips: "37057", name: "Davidson County", state: "North Carolina" },
  { fips: "37059", name: "Davie County", state: "North Carolina" },
  { fips: "37061", name: "Duplin County", state: "North Carolina" },
  { fips: "37063", name: "Durham County", state: "North Carolina" },
  { fips: "37065", name: "Edgecombe County", state: "North Carolina" },
  { fips: "37067", name: "Forsyth County", state: "North Carolina" },
  { fips: "37069", name: "Franklin County", state: "North Carolina" },
  { fips: "37071", name: "Gaston County", state: "North Carolina" },
  { fips: "37073", name: "Gates County", state: "North Carolina" },
  { fips: "37075", name: "Graham County", state: "North Carolina" },
  { fips: "37077", name: "Granville County", state: "North Carolina" },
  { fips: "37079", name: "Greene County", state: "North Carolina" },
  { fips: "37081", name: "Guilford County", state: "North Carolina" },
  { fips: "37083", name: "Halifax County", state: "North Carolina" },
  { fips: "37085", name: "Harnett County", state: "North Carolina" },
  { fips: "37087", name: "Haywood County", state: "North Carolina" },
  { fips: "37089", name: "Henderson County", state: "North Carolina" },
  { fips: "37091", name: "Hertford County", state: "North Carolina" },
  { fips: "37093", name: "Hoke County", state: "North Carolina" },
  { fips: "37095", name: "Hyde County", state: "North Carolina" },
  { fips: "37097", name: "Iredell County", state: "North Carolina" },
  { fips: "37099", name: "Jackson County", state: "North Carolina" },
  { fips: "37101", name: "Johnston County", state: "North Carolina" },
  { fips: "37103", name: "Jones County", state: "North Carolina" },
  { fips: "37105", name: "Lee County", state: "North Carolina" },
  { fips: "37107", name: "Lenoir County", state: "North Carolina" },
  { fips: "37109", name: "Lincoln County", state: "North Carolina" },
  { fips: "37111", name: "McDowell County", state: "North Carolina" },
  { fips: "37113", name: "Macon County", state: "North Carolina" },
  { fips: "37115", name: "Madison County", state: "North Carolina" },
  { fips: "37117", name: "Martin County", state: "North Carolina" },
  { fips: "37119", name: "Mecklenburg County", state: "North Carolina" },
  { fips: "37121", name: "Mitchell County", state: "North Carolina" },
  { fips: "37123", name: "Montgomery County", state: "North Carolina" },
  { fips: "37125", name: "Moore County", state: "North Carolina" },
  { fips: "37127", name: "Nash County", state: "North Carolina" },
  { fips: "37129", name: "New Hanover County", state: "North Carolina" },
  { fips: "37131", name: "Northampton County", state: "North Carolina" },
  { fips: "37133", name: "Onslow County", state: "North Carolina" },
  { fips: "37135", name: "Orange County", state: "North Carolina" },
  { fips: "37137", name: "Pamlico County", state: "North Carolina" },
  { fips: "37139", name: "Pasquotank County", state: "North Carolina" },
  { fips: "37141", name: "Pender County", state: "North Carolina" },
  { fips: "37143", name: "Perquimans County", state: "North Carolina" },
  { fips: "37145", name: "Person County", state: "North Carolina" },
  { fips: "37147", name: "Pitt County", state: "North Carolina" },
  { fips: "37149", name: "Polk County", state: "North Carolina" },
  { fips: "37151", name: "Randolph County", state: "North Carolina" },
  { fips: "37153", name: "Richmond County", state: "North Carolina" },
  { fips: "37155", name: "Robeson County", state: "North Carolina" },
  { fips: "37157", name: "Rockingham County", state: "North Carolina" },
  { fips: "37159", name: "Rowan County", state: "North Carolina" },
  { fips: "37161", name: "Rutherford County", state: "North Carolina" },
  { fips: "37163", name: "Sampson County", state: "North Carolina" },
  { fips: "37165", name: "Scotland County", state: "North Carolina" },
  { fips: "37167", name: "Stanly County", state: "North Carolina" },
  { fips: "37169", name: "Stokes County", state: "North Carolina" },
  { fips: "37171", name: "Surry County", state: "North Carolina" },
  { fips: "37173", name: "Swain County", state: "North Carolina" },
  { fips: "37175", name: "Transylvania County", state: "North Carolina" },
  { fips: "37177", name: "Tyrrell County", state: "North Carolina" },
  { fips: "37179", name: "Union County", state: "North Carolina" },
  { fips: "37181", name: "Vance County", state: "North Carolina" },
  { fips: "37183", name: "Wake County", state: "North Carolina" },
  { fips: "37185", name: "Warren County", state: "North Carolina" },
  { fips: "37187", name: "Washington County", state: "North Carolina" },
  { fips: "37189", name: "Watauga County", state: "North Carolina" },
  { fips: "37191", name: "Wayne County", state: "North Carolina" },
  { fips: "37193", name: "Wilkes County", state: "North Carolina" },
  { fips: "37195", name: "Wilson County", state: "North Carolina" },
  { fips: "37197", name: "Yadkin County", state: "North Carolina" },
  { fips: "37199", name: "Yancey County", state: "North Carolina" },
  { fips: "38001", name: "Adams County", state: "North Dakota" },
  { fips: "38003", name: "Barnes County", state: "North Dakota" },
  { fips: "38005", name: "Benson County", state: "North Dakota" },
  { fips: "38007", name: "Billings County", state: "North Dakota" },
  { fips: "38009", name: "Bottineau County", state: "North Dakota" },
  { fips: "38011", name: "Bowman County", state: "North Dakota" },
  { fips: "38013", name: "Burke County", state: "North Dakota" },
  { fips: "38015", name: "Burleigh County", state: "North Dakota" },
  { fips: "38017", name: "Cass County", state: "North Dakota" },
  { fips: "38019", name: "Cavalier County", state: "North Dakota" },
  { fips: "38021", name: "Dickey County", state: "North Dakota" },
  { fips: "38023", name: "Divide County", state: "North Dakota" },
  { fips: "38025", name: "Dunn County", state: "North Dakota" },
  { fips: "38027", name: "Eddy County", state: "North Dakota" },
  { fips: "38029", name: "Emmons County", state: "North Dakota" },
  { fips: "38031", name: "Foster County", state: "North Dakota" },
  { fips: "38033", name: "Golden Valley County", state: "North Dakota" },
  { fips: "38035", name: "Grand Forks County", state: "North Dakota" },
  { fips: "38037", name: "Grant County", state: "North Dakota" },
  { fips: "38039", name: "Griggs County", state: "North Dakota" },
  { fips: "38041", name: "Hettinger County", state: "North Dakota" },
  { fips: "38043", name: "Kidder County", state: "North Dakota" },
  { fips: "38045", name: "LaMoure County", state: "North Dakota" },
  { fips: "38047", name: "Logan County", state: "North Dakota" },
  { fips: "38049", name: "McHenry County", state: "North Dakota" },
  { fips: "38051", name: "McIntosh County", state: "North Dakota" },
  { fips: "38053", name: "McKenzie County", state: "North Dakota" },
  { fips: "38055", name: "McLean County", state: "North Dakota" },
  { fips: "38057", name: "Mercer County", state: "North Dakota" },
  { fips: "38059", name: "Morton County", state: "North Dakota" },
  { fips: "38061", name: "Mountrail County", state: "North Dakota" },
  { fips: "38063", name: "Nelson County", state: "North Dakota" },
  { fips: "38065", name: "Oliver County", state: "North Dakota" },
  { fips: "38067", name: "Pembina County", state: "North Dakota" },
  { fips: "38069", name: "Pierce County", state: "North Dakota" },
  { fips: "38071", name: "Ramsey County", state: "North Dakota" },
  { fips: "38073", name: "Ransom County", state: "North Dakota" },
  { fips: "38075", name: "Renville County", state: "North Dakota" },
  { fips: "38077", name: "Richland County", state: "North Dakota" },
  { fips: "38079", name: "Rolette County", state: "North Dakota" },
  { fips: "38081", name: "Sargent County", state: "North Dakota" },
  { fips: "38083", name: "Sheridan County", state: "North Dakota" },
  { fips: "38085", name: "Sioux County", state: "North Dakota" },
  { fips: "38087", name: "Slope County", state: "North Dakota" },
  { fips: "38089", name: "Stark County", state: "North Dakota" },
  { fips: "38091", name: "Steele County", state: "North Dakota" },
  { fips: "38093", name: "Stutsman County", state: "North Dakota" },
  { fips: "38095", name: "Towner County", state: "North Dakota" },
  { fips: "38097", name: "Traill County", state: "North Dakota" },
  { fips: "38099", name: "Walsh County", state: "North Dakota" },
  { fips: "38101", name: "Ward County", state: "North Dakota" },
  { fips: "38103", name: "Wells County", state: "North Dakota" },
  { fips: "38105", name: "Williams County", state: "North Dakota" },
  {
    fips: "69085",
    name: "Northern Islands Municipality",
    state: "North Dakota",
  },
  { fips: "69100", name: "Rota Island", state: "North Dakota" },
  { fips: "69110", name: "Saipan", state: "North Dakota" },
  { fips: "69120", name: "Tinian", state: "North Dakota" },
  { fips: "39001", name: "Adams County", state: "Ohio" },
  { fips: "39003", name: "Allen County", state: "Ohio" },
  { fips: "39005", name: "Ashland County", state: "Ohio" },
  { fips: "39007", name: "Ashtabula County", state: "Ohio" },
  { fips: "39009", name: "Athens County", state: "Ohio" },
  { fips: "39011", name: "Auglaize County", state: "Ohio" },
  { fips: "39013", name: "Belmont County", state: "Ohio" },
  { fips: "39015", name: "Brown County", state: "Ohio" },
  { fips: "39017", name: "Butler County", state: "Ohio" },
  { fips: "39019", name: "Carroll County", state: "Ohio" },
  { fips: "39021", name: "Champaign County", state: "Ohio" },
  { fips: "39023", name: "Clark County", state: "Ohio" },
  { fips: "39025", name: "Clermont County", state: "Ohio" },
  { fips: "39027", name: "Clinton County", state: "Ohio" },
  { fips: "39029", name: "Columbiana County", state: "Ohio" },
  { fips: "39031", name: "Coshocton County", state: "Ohio" },
  { fips: "39033", name: "Crawford County", state: "Ohio" },
  { fips: "39035", name: "Cuyahoga County", state: "Ohio" },
  { fips: "39037", name: "Darke County", state: "Ohio" },
  { fips: "39039", name: "Defiance County", state: "Ohio" },
  { fips: "39041", name: "Delaware County", state: "Ohio" },
  { fips: "39043", name: "Erie County", state: "Ohio" },
  { fips: "39045", name: "Fairfield County", state: "Ohio" },
  { fips: "39047", name: "Fayette County", state: "Ohio" },
  { fips: "39049", name: "Franklin County", state: "Ohio" },
  { fips: "39051", name: "Fulton County", state: "Ohio" },
  { fips: "39053", name: "Gallia County", state: "Ohio" },
  { fips: "39055", name: "Geauga County", state: "Ohio" },
  { fips: "39057", name: "Greene County", state: "Ohio" },
  { fips: "39059", name: "Guernsey County", state: "Ohio" },
  { fips: "39061", name: "Hamilton County", state: "Ohio" },
  { fips: "39063", name: "Hancock County", state: "Ohio" },
  { fips: "39065", name: "Hardin County", state: "Ohio" },
  { fips: "39067", name: "Harrison County", state: "Ohio" },
  { fips: "39069", name: "Henry County", state: "Ohio" },
  { fips: "39071", name: "Highland County", state: "Ohio" },
  { fips: "39073", name: "Hocking County", state: "Ohio" },
  { fips: "39075", name: "Holmes County", state: "Ohio" },
  { fips: "39077", name: "Huron County", state: "Ohio" },
  { fips: "39079", name: "Jackson County", state: "Ohio" },
  { fips: "39081", name: "Jefferson County", state: "Ohio" },
  { fips: "39083", name: "Knox County", state: "Ohio" },
  { fips: "39085", name: "Lake County", state: "Ohio" },
  { fips: "39087", name: "Lawrence County", state: "Ohio" },
  { fips: "39089", name: "Licking County", state: "Ohio" },
  { fips: "39091", name: "Logan County", state: "Ohio" },
  { fips: "39093", name: "Lorain County", state: "Ohio" },
  { fips: "39095", name: "Lucas County", state: "Ohio" },
  { fips: "39097", name: "Madison County", state: "Ohio" },
  { fips: "39099", name: "Mahoning County", state: "Ohio" },
  { fips: "39101", name: "Marion County", state: "Ohio" },
  { fips: "39103", name: "Medina County", state: "Ohio" },
  { fips: "39105", name: "Meigs County", state: "Ohio" },
  { fips: "39107", name: "Mercer County", state: "Ohio" },
  { fips: "39109", name: "Miami County", state: "Ohio" },
  { fips: "39111", name: "Monroe County", state: "Ohio" },
  { fips: "39113", name: "Montgomery County", state: "Ohio" },
  { fips: "39115", name: "Morgan County", state: "Ohio" },
  { fips: "39117", name: "Morrow County", state: "Ohio" },
  { fips: "39119", name: "Muskingum County", state: "Ohio" },
  { fips: "39121", name: "Noble County", state: "Ohio" },
  { fips: "39123", name: "Ottawa County", state: "Ohio" },
  { fips: "39125", name: "Paulding County", state: "Ohio" },
  { fips: "39127", name: "Perry County", state: "Ohio" },
  { fips: "39129", name: "Pickaway County", state: "Ohio" },
  { fips: "39131", name: "Pike County", state: "Ohio" },
  { fips: "39133", name: "Portage County", state: "Ohio" },
  { fips: "39135", name: "Preble County", state: "Ohio" },
  { fips: "39137", name: "Putnam County", state: "Ohio" },
  { fips: "39139", name: "Richland County", state: "Ohio" },
  { fips: "39141", name: "Ross County", state: "Ohio" },
  { fips: "39143", name: "Sandusky County", state: "Ohio" },
  { fips: "39145", name: "Scioto County", state: "Ohio" },
  { fips: "39147", name: "Seneca County", state: "Ohio" },
  { fips: "39149", name: "Shelby County", state: "Ohio" },
  { fips: "39151", name: "Stark County", state: "Ohio" },
  { fips: "39153", name: "Summit County", state: "Ohio" },
  { fips: "39155", name: "Trumbull County", state: "Ohio" },
  { fips: "39157", name: "Tuscarawas County", state: "Ohio" },
  { fips: "39159", name: "Union County", state: "Ohio" },
  { fips: "39161", name: "Van Wert County", state: "Ohio" },
  { fips: "39163", name: "Vinton County", state: "Ohio" },
  { fips: "39165", name: "Warren County", state: "Ohio" },
  { fips: "39167", name: "Washington County", state: "Ohio" },
  { fips: "39169", name: "Wayne County", state: "Ohio" },
  { fips: "39171", name: "Williams County", state: "Ohio" },
  { fips: "39173", name: "Wood County", state: "Ohio" },
  { fips: "39175", name: "Wyandot County", state: "Ohio" },
  { fips: "40001", name: "Adair County", state: "Oklahoma" },
  { fips: "40003", name: "Alfalfa County", state: "Oklahoma" },
  { fips: "40005", name: "Atoka County", state: "Oklahoma" },
  { fips: "40007", name: "Beaver County", state: "Oklahoma" },
  { fips: "40009", name: "Beckham County", state: "Oklahoma" },
  { fips: "40011", name: "Blaine County", state: "Oklahoma" },
  { fips: "40013", name: "Bryan County", state: "Oklahoma" },
  { fips: "40015", name: "Caddo County", state: "Oklahoma" },
  { fips: "40017", name: "Canadian County", state: "Oklahoma" },
  { fips: "40019", name: "Carter County", state: "Oklahoma" },
  { fips: "40021", name: "Cherokee County", state: "Oklahoma" },
  { fips: "40023", name: "Choctaw County", state: "Oklahoma" },
  { fips: "40025", name: "Cimarron County", state: "Oklahoma" },
  { fips: "40027", name: "Cleveland County", state: "Oklahoma" },
  { fips: "40029", name: "Coal County", state: "Oklahoma" },
  { fips: "40031", name: "Comanche County", state: "Oklahoma" },
  { fips: "40033", name: "Cotton County", state: "Oklahoma" },
  { fips: "40035", name: "Craig County", state: "Oklahoma" },
  { fips: "40037", name: "Creek County", state: "Oklahoma" },
  { fips: "40039", name: "Custer County", state: "Oklahoma" },
  { fips: "40041", name: "Delaware County", state: "Oklahoma" },
  { fips: "40043", name: "Dewey County", state: "Oklahoma" },
  { fips: "40045", name: "Ellis County", state: "Oklahoma" },
  { fips: "40047", name: "Garfield County", state: "Oklahoma" },
  { fips: "40049", name: "Garvin County", state: "Oklahoma" },
  { fips: "40051", name: "Grady County", state: "Oklahoma" },
  { fips: "40053", name: "Grant County", state: "Oklahoma" },
  { fips: "40055", name: "Greer County", state: "Oklahoma" },
  { fips: "40057", name: "Harmon County", state: "Oklahoma" },
  { fips: "40059", name: "Harper County", state: "Oklahoma" },
  { fips: "40061", name: "Haskell County", state: "Oklahoma" },
  { fips: "40063", name: "Hughes County", state: "Oklahoma" },
  { fips: "40065", name: "Jackson County", state: "Oklahoma" },
  { fips: "40067", name: "Jefferson County", state: "Oklahoma" },
  { fips: "40069", name: "Johnston County", state: "Oklahoma" },
  { fips: "40071", name: "Kay County", state: "Oklahoma" },
  { fips: "40073", name: "Kingfisher County", state: "Oklahoma" },
  { fips: "40075", name: "Kiowa County", state: "Oklahoma" },
  { fips: "40077", name: "Latimer County", state: "Oklahoma" },
  { fips: "40079", name: "Le Flore County", state: "Oklahoma" },
  { fips: "40081", name: "Lincoln County", state: "Oklahoma" },
  { fips: "40083", name: "Logan County", state: "Oklahoma" },
  { fips: "40085", name: "Love County", state: "Oklahoma" },
  { fips: "40087", name: "McClain County", state: "Oklahoma" },
  { fips: "40089", name: "McCurtain County", state: "Oklahoma" },
  { fips: "40091", name: "McIntosh County", state: "Oklahoma" },
  { fips: "40093", name: "Major County", state: "Oklahoma" },
  { fips: "40095", name: "Marshall County", state: "Oklahoma" },
  { fips: "40097", name: "Mayes County", state: "Oklahoma" },
  { fips: "40099", name: "Murray County", state: "Oklahoma" },
  { fips: "40101", name: "Muskogee County", state: "Oklahoma" },
  { fips: "40103", name: "Noble County", state: "Oklahoma" },
  { fips: "40105", name: "Nowata County", state: "Oklahoma" },
  { fips: "40107", name: "Okfuskee County", state: "Oklahoma" },
  { fips: "40109", name: "Oklahoma County", state: "Oklahoma" },
  { fips: "40111", name: "Okmulgee County", state: "Oklahoma" },
  { fips: "40113", name: "Osage County", state: "Oklahoma" },
  { fips: "40115", name: "Ottawa County", state: "Oklahoma" },
  { fips: "40117", name: "Pawnee County", state: "Oklahoma" },
  { fips: "40119", name: "Payne County", state: "Oklahoma" },
  { fips: "40121", name: "Pittsburg County", state: "Oklahoma" },
  { fips: "40123", name: "Pontotoc County", state: "Oklahoma" },
  { fips: "40125", name: "Pottawatomie County", state: "Oklahoma" },
  { fips: "40127", name: "Pushmataha County", state: "Oklahoma" },
  { fips: "40129", name: "Roger Mills County", state: "Oklahoma" },
  { fips: "40131", name: "Rogers County", state: "Oklahoma" },
  { fips: "40133", name: "Seminole County", state: "Oklahoma" },
  { fips: "40135", name: "Sequoyah County", state: "Oklahoma" },
  { fips: "40137", name: "Stephens County", state: "Oklahoma" },
  { fips: "40139", name: "Texas County", state: "Oklahoma" },
  { fips: "40141", name: "Tillman County", state: "Oklahoma" },
  { fips: "40143", name: "Tulsa County", state: "Oklahoma" },
  { fips: "40145", name: "Wagoner County", state: "Oklahoma" },
  { fips: "40147", name: "Washington County", state: "Oklahoma" },
  { fips: "40149", name: "Washita County", state: "Oklahoma" },
  { fips: "40151", name: "Woods County", state: "Oklahoma" },
  { fips: "40153", name: "Woodward County", state: "Oklahoma" },
  { fips: "41001", name: "Baker County", state: "Oregon" },
  { fips: "41003", name: "Benton County", state: "Oregon" },
  { fips: "41005", name: "Clackamas County", state: "Oregon" },
  { fips: "41007", name: "Clatsop County", state: "Oregon" },
  { fips: "41009", name: "Columbia County", state: "Oregon" },
  { fips: "41011", name: "Coos County", state: "Oregon" },
  { fips: "41013", name: "Crook County", state: "Oregon" },
  { fips: "41015", name: "Curry County", state: "Oregon" },
  { fips: "41017", name: "Deschutes County", state: "Oregon" },
  { fips: "41019", name: "Douglas County", state: "Oregon" },
  { fips: "41021", name: "Gilliam County", state: "Oregon" },
  { fips: "41023", name: "Grant County", state: "Oregon" },
  { fips: "41025", name: "Harney County", state: "Oregon" },
  { fips: "41027", name: "Hood River County", state: "Oregon" },
  { fips: "41029", name: "Jackson County", state: "Oregon" },
  { fips: "41031", name: "Jefferson County", state: "Oregon" },
  { fips: "41033", name: "Josephine County", state: "Oregon" },
  { fips: "41035", name: "Klamath County", state: "Oregon" },
  { fips: "41037", name: "Lake County", state: "Oregon" },
  { fips: "41039", name: "Lane County", state: "Oregon" },
  { fips: "41041", name: "Lincoln County", state: "Oregon" },
  { fips: "41043", name: "Linn County", state: "Oregon" },
  { fips: "41045", name: "Malheur County", state: "Oregon" },
  { fips: "41047", name: "Marion County", state: "Oregon" },
  { fips: "41049", name: "Morrow County", state: "Oregon" },
  { fips: "41051", name: "Multnomah County", state: "Oregon" },
  { fips: "41053", name: "Polk County", state: "Oregon" },
  { fips: "41055", name: "Sherman County", state: "Oregon" },
  { fips: "41057", name: "Tillamook County", state: "Oregon" },
  { fips: "41059", name: "Umatilla County", state: "Oregon" },
  { fips: "41061", name: "Union County", state: "Oregon" },
  { fips: "41063", name: "Wallowa County", state: "Oregon" },
  { fips: "41065", name: "Wasco County", state: "Oregon" },
  { fips: "41067", name: "Washington County", state: "Oregon" },
  { fips: "41069", name: "Wheeler County", state: "Oregon" },
  { fips: "41071", name: "Yamhill County", state: "Oregon" },
  { fips: "42001", name: "Adams County", state: "Pennsylvania" },
  { fips: "42003", name: "Allegheny County", state: "Pennsylvania" },
  { fips: "42005", name: "Armstrong County", state: "Pennsylvania" },
  { fips: "42007", name: "Beaver County", state: "Pennsylvania" },
  { fips: "42009", name: "Bedford County", state: "Pennsylvania" },
  { fips: "42011", name: "Berks County", state: "Pennsylvania" },
  { fips: "42013", name: "Blair County", state: "Pennsylvania" },
  { fips: "42015", name: "Bradford County", state: "Pennsylvania" },
  { fips: "42017", name: "Bucks County", state: "Pennsylvania" },
  { fips: "42019", name: "Butler County", state: "Pennsylvania" },
  { fips: "42021", name: "Cambria County", state: "Pennsylvania" },
  { fips: "42023", name: "Cameron County", state: "Pennsylvania" },
  { fips: "42025", name: "Carbon County", state: "Pennsylvania" },
  { fips: "42027", name: "Centre County", state: "Pennsylvania" },
  { fips: "42029", name: "Chester County", state: "Pennsylvania" },
  { fips: "42031", name: "Clarion County", state: "Pennsylvania" },
  { fips: "42033", name: "Clearfield County", state: "Pennsylvania" },
  { fips: "42035", name: "Clinton County", state: "Pennsylvania" },
  { fips: "42037", name: "Columbia County", state: "Pennsylvania" },
  { fips: "42039", name: "Crawford County", state: "Pennsylvania" },
  { fips: "42041", name: "Cumberland County", state: "Pennsylvania" },
  { fips: "42043", name: "Dauphin County", state: "Pennsylvania" },
  { fips: "42045", name: "Delaware County", state: "Pennsylvania" },
  { fips: "42047", name: "Elk County", state: "Pennsylvania" },
  { fips: "42049", name: "Erie County", state: "Pennsylvania" },
  { fips: "42051", name: "Fayette County", state: "Pennsylvania" },
  { fips: "42053", name: "Forest County", state: "Pennsylvania" },
  { fips: "42055", name: "Franklin County", state: "Pennsylvania" },
  { fips: "42057", name: "Fulton County", state: "Pennsylvania" },
  { fips: "42059", name: "Greene County", state: "Pennsylvania" },
  { fips: "42061", name: "Huntingdon County", state: "Pennsylvania" },
  { fips: "42063", name: "Indiana County", state: "Pennsylvania" },
  { fips: "42065", name: "Jefferson County", state: "Pennsylvania" },
  { fips: "42067", name: "Juniata County", state: "Pennsylvania" },
  { fips: "42069", name: "Lackawanna County", state: "Pennsylvania" },
  { fips: "42071", name: "Lancaster County", state: "Pennsylvania" },
  { fips: "42073", name: "Lawrence County", state: "Pennsylvania" },
  { fips: "42075", name: "Lebanon County", state: "Pennsylvania" },
  { fips: "42077", name: "Lehigh County", state: "Pennsylvania" },
  { fips: "42079", name: "Luzerne County", state: "Pennsylvania" },
  { fips: "42081", name: "Lycoming County", state: "Pennsylvania" },
  { fips: "42083", name: "McKean County", state: "Pennsylvania" },
  { fips: "42085", name: "Mercer County", state: "Pennsylvania" },
  { fips: "42087", name: "Mifflin County", state: "Pennsylvania" },
  { fips: "42089", name: "Monroe County", state: "Pennsylvania" },
  { fips: "42091", name: "Montgomery County", state: "Pennsylvania" },
  { fips: "42093", name: "Montour County", state: "Pennsylvania" },
  { fips: "42095", name: "Northampton County", state: "Pennsylvania" },
  { fips: "42097", name: "Northumberland County", state: "Pennsylvania" },
  { fips: "42099", name: "Perry County", state: "Pennsylvania" },
  { fips: "42101", name: "Philadelphia County", state: "Pennsylvania" },
  { fips: "42103", name: "Pike County", state: "Pennsylvania" },
  { fips: "42105", name: "Potter County", state: "Pennsylvania" },
  { fips: "42107", name: "Schuylkill County", state: "Pennsylvania" },
  { fips: "42109", name: "Snyder County", state: "Pennsylvania" },
  { fips: "42111", name: "Somerset County", state: "Pennsylvania" },
  { fips: "42113", name: "Sullivan County", state: "Pennsylvania" },
  { fips: "42115", name: "Susquehanna County", state: "Pennsylvania" },
  { fips: "42117", name: "Tioga County", state: "Pennsylvania" },
  { fips: "42119", name: "Union County", state: "Pennsylvania" },
  { fips: "42121", name: "Venango County", state: "Pennsylvania" },
  { fips: "42123", name: "Warren County", state: "Pennsylvania" },
  { fips: "42125", name: "Washington County", state: "Pennsylvania" },
  { fips: "42127", name: "Wayne County", state: "Pennsylvania" },
  { fips: "42129", name: "Westmoreland County", state: "Pennsylvania" },
  { fips: "42131", name: "Wyoming County", state: "Pennsylvania" },
  { fips: "42133", name: "York County", state: "Pennsylvania" },
  { fips: "44001", name: "Bristol County", state: "Rhode Island" },
  { fips: "44003", name: "Kent County", state: "Rhode Island" },
  { fips: "44005", name: "Newport County", state: "Rhode Island" },
  { fips: "44007", name: "Providence County", state: "Rhode Island" },
  { fips: "44009", name: "Washington County", state: "Rhode Island" },
  { fips: "45001", name: "Abbeville County", state: "South Carolina" },
  { fips: "45003", name: "Aiken County", state: "South Carolina" },
  { fips: "45005", name: "Allendale County", state: "South Carolina" },
  { fips: "45007", name: "Anderson County", state: "South Carolina" },
  { fips: "45009", name: "Bamberg County", state: "South Carolina" },
  { fips: "45011", name: "Barnwell County", state: "South Carolina" },
  { fips: "45013", name: "Beaufort County", state: "South Carolina" },
  { fips: "45015", name: "Berkeley County", state: "South Carolina" },
  { fips: "45017", name: "Calhoun County", state: "South Carolina" },
  { fips: "45019", name: "Charleston County", state: "South Carolina" },
  { fips: "45021", name: "Cherokee County", state: "South Carolina" },
  { fips: "45023", name: "Chester County", state: "South Carolina" },
  { fips: "45025", name: "Chesterfield County", state: "South Carolina" },
  { fips: "45027", name: "Clarendon County", state: "South Carolina" },
  { fips: "45029", name: "Colleton County", state: "South Carolina" },
  { fips: "45031", name: "Darlington County", state: "South Carolina" },
  { fips: "45033", name: "Dillon County", state: "South Carolina" },
  { fips: "45035", name: "Dorchester County", state: "South Carolina" },
  { fips: "45037", name: "Edgefield County", state: "South Carolina" },
  { fips: "45039", name: "Fairfield County", state: "South Carolina" },
  { fips: "45041", name: "Florence County", state: "South Carolina" },
  { fips: "45043", name: "Georgetown County", state: "South Carolina" },
  { fips: "45045", name: "Greenville County", state: "South Carolina" },
  { fips: "45047", name: "Greenwood County", state: "South Carolina" },
  { fips: "45049", name: "Hampton County", state: "South Carolina" },
  { fips: "45051", name: "Horry County", state: "South Carolina" },
  { fips: "45053", name: "Jasper County", state: "South Carolina" },
  { fips: "45055", name: "Kershaw County", state: "South Carolina" },
  { fips: "45057", name: "Lancaster County", state: "South Carolina" },
  { fips: "45059", name: "Laurens County", state: "South Carolina" },
  { fips: "45061", name: "Lee County", state: "South Carolina" },
  { fips: "45063", name: "Lexington County", state: "South Carolina" },
  { fips: "45065", name: "McCormick County", state: "South Carolina" },
  { fips: "45067", name: "Marion County", state: "South Carolina" },
  { fips: "45069", name: "Marlboro County", state: "South Carolina" },
  { fips: "45071", name: "Newberry County", state: "South Carolina" },
  { fips: "45073", name: "Oconee County", state: "South Carolina" },
  { fips: "45075", name: "Orangeburg County", state: "South Carolina" },
  { fips: "45077", name: "Pickens County", state: "South Carolina" },
  { fips: "45079", name: "Richland County", state: "South Carolina" },
  { fips: "45081", name: "Saluda County", state: "South Carolina" },
  { fips: "45083", name: "Spartanburg County", state: "South Carolina" },
  { fips: "45085", name: "Sumter County", state: "South Carolina" },
  { fips: "45087", name: "Union County", state: "South Carolina" },
  { fips: "45089", name: "Williamsburg County", state: "South Carolina" },
  { fips: "45091", name: "York County", state: "South Carolina" },
  { fips: "46003", name: "Aurora County", state: "South Dakota" },
  { fips: "46005", name: "Beadle County", state: "South Dakota" },
  { fips: "46007", name: "Bennett County", state: "South Dakota" },
  { fips: "46009", name: "Bon Homme County", state: "South Dakota" },
  { fips: "46011", name: "Brookings County", state: "South Dakota" },
  { fips: "46013", name: "Brown County", state: "South Dakota" },
  { fips: "46015", name: "Brule County", state: "South Dakota" },
  { fips: "46017", name: "Buffalo County", state: "South Dakota" },
  { fips: "46019", name: "Butte County", state: "South Dakota" },
  { fips: "46021", name: "Campbell County", state: "South Dakota" },
  { fips: "46023", name: "Charles Mix County", state: "South Dakota" },
  { fips: "46025", name: "Clark County", state: "South Dakota" },
  { fips: "46027", name: "Clay County", state: "South Dakota" },
  { fips: "46029", name: "Codington County", state: "South Dakota" },
  { fips: "46031", name: "Corson County", state: "South Dakota" },
  { fips: "46033", name: "Custer County", state: "South Dakota" },
  { fips: "46035", name: "Davison County", state: "South Dakota" },
  { fips: "46037", name: "Day County", state: "South Dakota" },
  { fips: "46039", name: "Deuel County", state: "South Dakota" },
  { fips: "46041", name: "Dewey County", state: "South Dakota" },
  { fips: "46043", name: "Douglas County", state: "South Dakota" },
  { fips: "46045", name: "Edmunds County", state: "South Dakota" },
  { fips: "46047", name: "Fall River County", state: "South Dakota" },
  { fips: "46049", name: "Faulk County", state: "South Dakota" },
  { fips: "46051", name: "Grant County", state: "South Dakota" },
  { fips: "46053", name: "Gregory County", state: "South Dakota" },
  { fips: "46055", name: "Haakon County", state: "South Dakota" },
  { fips: "46057", name: "Hamlin County", state: "South Dakota" },
  { fips: "46059", name: "Hand County", state: "South Dakota" },
  { fips: "46061", name: "Hanson County", state: "South Dakota" },
  { fips: "46063", name: "Harding County", state: "South Dakota" },
  { fips: "46065", name: "Hughes County", state: "South Dakota" },
  { fips: "46067", name: "Hutchinson County", state: "South Dakota" },
  { fips: "46069", name: "Hyde County", state: "South Dakota" },
  { fips: "46071", name: "Jackson County", state: "South Dakota" },
  { fips: "46073", name: "Jerauld County", state: "South Dakota" },
  { fips: "46075", name: "Jones County", state: "South Dakota" },
  { fips: "46077", name: "Kingsbury County", state: "South Dakota" },
  { fips: "46079", name: "Lake County", state: "South Dakota" },
  { fips: "46081", name: "Lawrence County", state: "South Dakota" },
  { fips: "46083", name: "Lincoln County", state: "South Dakota" },
  { fips: "46085", name: "Lyman County", state: "South Dakota" },
  { fips: "46087", name: "McCook County", state: "South Dakota" },
  { fips: "46089", name: "McPherson County", state: "South Dakota" },
  { fips: "46091", name: "Marshall County", state: "South Dakota" },
  { fips: "46093", name: "Meade County", state: "South Dakota" },
  { fips: "46095", name: "Mellette County", state: "South Dakota" },
  { fips: "46097", name: "Miner County", state: "South Dakota" },
  { fips: "46099", name: "Minnehaha County", state: "South Dakota" },
  { fips: "46101", name: "Moody County", state: "South Dakota" },
  { fips: "46102", name: "Oglala Lakota County", state: "South Dakota" },
  { fips: "46103", name: "Pennington County", state: "South Dakota" },
  { fips: "46105", name: "Perkins County", state: "South Dakota" },
  { fips: "46107", name: "Potter County", state: "South Dakota" },
  { fips: "46109", name: "Roberts County", state: "South Dakota" },
  { fips: "46111", name: "Sanborn County", state: "South Dakota" },
  { fips: "46115", name: "Spink County", state: "South Dakota" },
  { fips: "46117", name: "Stanley County", state: "South Dakota" },
  { fips: "46119", name: "Sully County", state: "South Dakota" },
  { fips: "46121", name: "Todd County", state: "South Dakota" },
  { fips: "46123", name: "Tripp County", state: "South Dakota" },
  { fips: "46125", name: "Turner County", state: "South Dakota" },
  { fips: "46127", name: "Union County", state: "South Dakota" },
  { fips: "46129", name: "Walworth County", state: "South Dakota" },
  { fips: "46135", name: "Yankton County", state: "South Dakota" },
  { fips: "46137", name: "Ziebach County", state: "South Dakota" },
  { fips: "47001", name: "Anderson County", state: "Tennessee" },
  { fips: "47003", name: "Bedford County", state: "Tennessee" },
  { fips: "47005", name: "Benton County", state: "Tennessee" },
  { fips: "47007", name: "Bledsoe County", state: "Tennessee" },
  { fips: "47009", name: "Blount County", state: "Tennessee" },
  { fips: "47011", name: "Bradley County", state: "Tennessee" },
  { fips: "47013", name: "Campbell County", state: "Tennessee" },
  { fips: "47015", name: "Cannon County", state: "Tennessee" },
  { fips: "47017", name: "Carroll County", state: "Tennessee" },
  { fips: "47019", name: "Carter County", state: "Tennessee" },
  { fips: "47021", name: "Cheatham County", state: "Tennessee" },
  { fips: "47023", name: "Chester County", state: "Tennessee" },
  { fips: "47025", name: "Claiborne County", state: "Tennessee" },
  { fips: "47027", name: "Clay County", state: "Tennessee" },
  { fips: "47029", name: "Cocke County", state: "Tennessee" },
  { fips: "47031", name: "Coffee County", state: "Tennessee" },
  { fips: "47033", name: "Crockett County", state: "Tennessee" },
  { fips: "47035", name: "Cumberland County", state: "Tennessee" },
  { fips: "47037", name: "Davidson County", state: "Tennessee" },
  { fips: "47039", name: "Decatur County", state: "Tennessee" },
  { fips: "47041", name: "DeKalb County", state: "Tennessee" },
  { fips: "47043", name: "Dickson County", state: "Tennessee" },
  { fips: "47045", name: "Dyer County", state: "Tennessee" },
  { fips: "47047", name: "Fayette County", state: "Tennessee" },
  { fips: "47049", name: "Fentress County", state: "Tennessee" },
  { fips: "47051", name: "Franklin County", state: "Tennessee" },
  { fips: "47053", name: "Gibson County", state: "Tennessee" },
  { fips: "47055", name: "Giles County", state: "Tennessee" },
  { fips: "47057", name: "Grainger County", state: "Tennessee" },
  { fips: "47059", name: "Greene County", state: "Tennessee" },
  { fips: "47061", name: "Grundy County", state: "Tennessee" },
  { fips: "47063", name: "Hamblen County", state: "Tennessee" },
  { fips: "47065", name: "Hamilton County", state: "Tennessee" },
  { fips: "47067", name: "Hancock County", state: "Tennessee" },
  { fips: "47069", name: "Hardeman County", state: "Tennessee" },
  { fips: "47071", name: "Hardin County", state: "Tennessee" },
  { fips: "47073", name: "Hawkins County", state: "Tennessee" },
  { fips: "47075", name: "Haywood County", state: "Tennessee" },
  { fips: "47077", name: "Henderson County", state: "Tennessee" },
  { fips: "47079", name: "Henry County", state: "Tennessee" },
  { fips: "47081", name: "Hickman County", state: "Tennessee" },
  { fips: "47083", name: "Houston County", state: "Tennessee" },
  { fips: "47085", name: "Humphreys County", state: "Tennessee" },
  { fips: "47087", name: "Jackson County", state: "Tennessee" },
  { fips: "47089", name: "Jefferson County", state: "Tennessee" },
  { fips: "47091", name: "Johnson County", state: "Tennessee" },
  { fips: "47093", name: "Knox County", state: "Tennessee" },
  { fips: "47095", name: "Lake County", state: "Tennessee" },
  { fips: "47097", name: "Lauderdale County", state: "Tennessee" },
  { fips: "47099", name: "Lawrence County", state: "Tennessee" },
  { fips: "47101", name: "Lewis County", state: "Tennessee" },
  { fips: "47103", name: "Lincoln County", state: "Tennessee" },
  { fips: "47105", name: "Loudon County", state: "Tennessee" },
  { fips: "47107", name: "McMinn County", state: "Tennessee" },
  { fips: "47109", name: "McNairy County", state: "Tennessee" },
  { fips: "47111", name: "Macon County", state: "Tennessee" },
  { fips: "47113", name: "Madison County", state: "Tennessee" },
  { fips: "47115", name: "Marion County", state: "Tennessee" },
  { fips: "47117", name: "Marshall County", state: "Tennessee" },
  { fips: "47119", name: "Maury County", state: "Tennessee" },
  { fips: "47121", name: "Meigs County", state: "Tennessee" },
  { fips: "47123", name: "Monroe County", state: "Tennessee" },
  { fips: "47125", name: "Montgomery County", state: "Tennessee" },
  { fips: "47127", name: "Moore County", state: "Tennessee" },
  { fips: "47129", name: "Morgan County", state: "Tennessee" },
  { fips: "47131", name: "Obion County", state: "Tennessee" },
  { fips: "47133", name: "Overton County", state: "Tennessee" },
  { fips: "47135", name: "Perry County", state: "Tennessee" },
  { fips: "47137", name: "Pickett County", state: "Tennessee" },
  { fips: "47139", name: "Polk County", state: "Tennessee" },
  { fips: "47141", name: "Putnam County", state: "Tennessee" },
  { fips: "47143", name: "Rhea County", state: "Tennessee" },
  { fips: "47145", name: "Roane County", state: "Tennessee" },
  { fips: "47147", name: "Robertson County", state: "Tennessee" },
  { fips: "47149", name: "Rutherford County", state: "Tennessee" },
  { fips: "47151", name: "Scott County", state: "Tennessee" },
  { fips: "47153", name: "Sequatchie County", state: "Tennessee" },
  { fips: "47155", name: "Sevier County", state: "Tennessee" },
  { fips: "47157", name: "Shelby County", state: "Tennessee" },
  { fips: "47159", name: "Smith County", state: "Tennessee" },
  { fips: "47161", name: "Stewart County", state: "Tennessee" },
  { fips: "47163", name: "Sullivan County", state: "Tennessee" },
  { fips: "47165", name: "Sumner County", state: "Tennessee" },
  { fips: "47167", name: "Tipton County", state: "Tennessee" },
  { fips: "47169", name: "Trousdale County", state: "Tennessee" },
  { fips: "47171", name: "Unicoi County", state: "Tennessee" },
  { fips: "47173", name: "Union County", state: "Tennessee" },
  { fips: "47175", name: "Van Buren County", state: "Tennessee" },
  { fips: "47177", name: "Warren County", state: "Tennessee" },
  { fips: "47179", name: "Washington County", state: "Tennessee" },
  { fips: "47181", name: "Wayne County", state: "Tennessee" },
  { fips: "47183", name: "Weakley County", state: "Tennessee" },
  { fips: "47185", name: "White County", state: "Tennessee" },
  { fips: "47187", name: "Williamson County", state: "Tennessee" },
  { fips: "47189", name: "Wilson County", state: "Tennessee" },
  { fips: "48001", name: "Anderson County", state: "Texas" },
  { fips: "48003", name: "Andrews County", state: "Texas" },
  { fips: "48005", name: "Angelina County", state: "Texas" },
  { fips: "48007", name: "Aransas County", state: "Texas" },
  { fips: "48009", name: "Archer County", state: "Texas" },
  { fips: "48011", name: "Armstrong County", state: "Texas" },
  { fips: "48013", name: "Atascosa County", state: "Texas" },
  { fips: "48015", name: "Austin County", state: "Texas" },
  { fips: "48017", name: "Bailey County", state: "Texas" },
  { fips: "48019", name: "Bandera County", state: "Texas" },
  { fips: "48021", name: "Bastrop County", state: "Texas" },
  { fips: "48023", name: "Baylor County", state: "Texas" },
  { fips: "48025", name: "Bee County", state: "Texas" },
  { fips: "48027", name: "Bell County", state: "Texas" },
  { fips: "48029", name: "Bexar County", state: "Texas" },
  { fips: "48031", name: "Blanco County", state: "Texas" },
  { fips: "48033", name: "Borden County", state: "Texas" },
  { fips: "48035", name: "Bosque County", state: "Texas" },
  { fips: "48037", name: "Bowie County", state: "Texas" },
  { fips: "48039", name: "Brazoria County", state: "Texas" },
  { fips: "48041", name: "Brazos County", state: "Texas" },
  { fips: "48043", name: "Brewster County", state: "Texas" },
  { fips: "48045", name: "Briscoe County", state: "Texas" },
  { fips: "48047", name: "Brooks County", state: "Texas" },
  { fips: "48049", name: "Brown County", state: "Texas" },
  { fips: "48051", name: "Burleson County", state: "Texas" },
  { fips: "48053", name: "Burnet County", state: "Texas" },
  { fips: "48055", name: "Caldwell County", state: "Texas" },
  { fips: "48057", name: "Calhoun County", state: "Texas" },
  { fips: "48059", name: "Callahan County", state: "Texas" },
  { fips: "48061", name: "Cameron County", state: "Texas" },
  { fips: "48063", name: "Camp County", state: "Texas" },
  { fips: "48065", name: "Carson County", state: "Texas" },
  { fips: "48067", name: "Cass County", state: "Texas" },
  { fips: "48069", name: "Castro County", state: "Texas" },
  { fips: "48071", name: "Chambers County", state: "Texas" },
  { fips: "48073", name: "Cherokee County", state: "Texas" },
  { fips: "48075", name: "Childress County", state: "Texas" },
  { fips: "48077", name: "Clay County", state: "Texas" },
  { fips: "48079", name: "Cochran County", state: "Texas" },
  { fips: "48081", name: "Coke County", state: "Texas" },
  { fips: "48083", name: "Coleman County", state: "Texas" },
  { fips: "48085", name: "Collin County", state: "Texas" },
  { fips: "48087", name: "Collingsworth County", state: "Texas" },
  { fips: "48089", name: "Colorado County", state: "Texas" },
  { fips: "48091", name: "Comal County", state: "Texas" },
  { fips: "48093", name: "Comanche County", state: "Texas" },
  { fips: "48095", name: "Concho County", state: "Texas" },
  { fips: "48097", name: "Cooke County", state: "Texas" },
  { fips: "48099", name: "Coryell County", state: "Texas" },
  { fips: "48101", name: "Cottle County", state: "Texas" },
  { fips: "48103", name: "Crane County", state: "Texas" },
  { fips: "48105", name: "Crockett County", state: "Texas" },
  { fips: "48107", name: "Crosby County", state: "Texas" },
  { fips: "48109", name: "Culberson County", state: "Texas" },
  { fips: "48111", name: "Dallam County", state: "Texas" },
  { fips: "48113", name: "Dallas County", state: "Texas" },
  { fips: "48115", name: "Dawson County", state: "Texas" },
  { fips: "48117", name: "Deaf Smith County", state: "Texas" },
  { fips: "48119", name: "Delta County", state: "Texas" },
  { fips: "48121", name: "Denton County", state: "Texas" },
  { fips: "48123", name: "DeWitt County", state: "Texas" },
  { fips: "48125", name: "Dickens County", state: "Texas" },
  { fips: "48127", name: "Dimmit County", state: "Texas" },
  { fips: "48129", name: "Donley County", state: "Texas" },
  { fips: "48131", name: "Duval County", state: "Texas" },
  { fips: "48133", name: "Eastland County", state: "Texas" },
  { fips: "48135", name: "Ector County", state: "Texas" },
  { fips: "48137", name: "Edwards County", state: "Texas" },
  { fips: "48139", name: "Ellis County", state: "Texas" },
  { fips: "48141", name: "El Paso County", state: "Texas" },
  { fips: "48143", name: "Erath County", state: "Texas" },
  { fips: "48145", name: "Falls County", state: "Texas" },
  { fips: "48147", name: "Fannin County", state: "Texas" },
  { fips: "48149", name: "Fayette County", state: "Texas" },
  { fips: "48151", name: "Fisher County", state: "Texas" },
  { fips: "48153", name: "Floyd County", state: "Texas" },
  { fips: "48155", name: "Foard County", state: "Texas" },
  { fips: "48157", name: "Fort Bend County", state: "Texas" },
  { fips: "48159", name: "Franklin County", state: "Texas" },
  { fips: "48161", name: "Freestone County", state: "Texas" },
  { fips: "48163", name: "Frio County", state: "Texas" },
  { fips: "48165", name: "Gaines County", state: "Texas" },
  { fips: "48167", name: "Galveston County", state: "Texas" },
  { fips: "48169", name: "Garza County", state: "Texas" },
  { fips: "48171", name: "Gillespie County", state: "Texas" },
  { fips: "48173", name: "Glasscock County", state: "Texas" },
  { fips: "48175", name: "Goliad County", state: "Texas" },
  { fips: "48177", name: "Gonzales County", state: "Texas" },
  { fips: "48179", name: "Gray County", state: "Texas" },
  { fips: "48181", name: "Grayson County", state: "Texas" },
  { fips: "48183", name: "Gregg County", state: "Texas" },
  { fips: "48185", name: "Grimes County", state: "Texas" },
  { fips: "48187", name: "Guadalupe County", state: "Texas" },
  { fips: "48189", name: "Hale County", state: "Texas" },
  { fips: "48191", name: "Hall County", state: "Texas" },
  { fips: "48193", name: "Hamilton County", state: "Texas" },
  { fips: "48195", name: "Hansford County", state: "Texas" },
  { fips: "48197", name: "Hardeman County", state: "Texas" },
  { fips: "48199", name: "Hardin County", state: "Texas" },
  { fips: "48201", name: "Harris County", state: "Texas" },
  { fips: "48203", name: "Harrison County", state: "Texas" },
  { fips: "48205", name: "Hartley County", state: "Texas" },
  { fips: "48207", name: "Haskell County", state: "Texas" },
  { fips: "48209", name: "Hays County", state: "Texas" },
  { fips: "48211", name: "Hemphill County", state: "Texas" },
  { fips: "48213", name: "Henderson County", state: "Texas" },
  { fips: "48215", name: "Hidalgo County", state: "Texas" },
  { fips: "48217", name: "Hill County", state: "Texas" },
  { fips: "48219", name: "Hockley County", state: "Texas" },
  { fips: "48221", name: "Hood County", state: "Texas" },
  { fips: "48223", name: "Hopkins County", state: "Texas" },
  { fips: "48225", name: "Houston County", state: "Texas" },
  { fips: "48227", name: "Howard County", state: "Texas" },
  { fips: "48229", name: "Hudspeth County", state: "Texas" },
  { fips: "48231", name: "Hunt County", state: "Texas" },
  { fips: "48233", name: "Hutchinson County", state: "Texas" },
  { fips: "48235", name: "Irion County", state: "Texas" },
  { fips: "48237", name: "Jack County", state: "Texas" },
  { fips: "48239", name: "Jackson County", state: "Texas" },
  { fips: "48241", name: "Jasper County", state: "Texas" },
  { fips: "48243", name: "Jeff Davis County", state: "Texas" },
  { fips: "48245", name: "Jefferson County", state: "Texas" },
  { fips: "48247", name: "Jim Hogg County", state: "Texas" },
  { fips: "48249", name: "Jim Wells County", state: "Texas" },
  { fips: "48251", name: "Johnson County", state: "Texas" },
  { fips: "48253", name: "Jones County", state: "Texas" },
  { fips: "48255", name: "Karnes County", state: "Texas" },
  { fips: "48257", name: "Kaufman County", state: "Texas" },
  { fips: "48259", name: "Kendall County", state: "Texas" },
  { fips: "48261", name: "Kenedy County", state: "Texas" },
  { fips: "48263", name: "Kent County", state: "Texas" },
  { fips: "48265", name: "Kerr County", state: "Texas" },
  { fips: "48267", name: "Kimble County", state: "Texas" },
  { fips: "48269", name: "King County", state: "Texas" },
  { fips: "48271", name: "Kinney County", state: "Texas" },
  { fips: "48273", name: "Kleberg County", state: "Texas" },
  { fips: "48275", name: "Knox County", state: "Texas" },
  { fips: "48277", name: "Lamar County", state: "Texas" },
  { fips: "48279", name: "Lamb County", state: "Texas" },
  { fips: "48281", name: "Lampasas County", state: "Texas" },
  { fips: "48283", name: "La Salle County", state: "Texas" },
  { fips: "48285", name: "Lavaca County", state: "Texas" },
  { fips: "48287", name: "Lee County", state: "Texas" },
  { fips: "48289", name: "Leon County", state: "Texas" },
  { fips: "48291", name: "Liberty County", state: "Texas" },
  { fips: "48293", name: "Limestone County", state: "Texas" },
  { fips: "48295", name: "Lipscomb County", state: "Texas" },
  { fips: "48297", name: "Live Oak County", state: "Texas" },
  { fips: "48299", name: "Llano County", state: "Texas" },
  { fips: "48301", name: "Loving County", state: "Texas" },
  { fips: "48303", name: "Lubbock County", state: "Texas" },
  { fips: "48305", name: "Lynn County", state: "Texas" },
  { fips: "48307", name: "McCulloch County", state: "Texas" },
  { fips: "48309", name: "McLennan County", state: "Texas" },
  { fips: "48311", name: "McMullen County", state: "Texas" },
  { fips: "48313", name: "Madison County", state: "Texas" },
  { fips: "48315", name: "Marion County", state: "Texas" },
  { fips: "48317", name: "Martin County", state: "Texas" },
  { fips: "48319", name: "Mason County", state: "Texas" },
  { fips: "48321", name: "Matagorda County", state: "Texas" },
  { fips: "48323", name: "Maverick County", state: "Texas" },
  { fips: "48325", name: "Medina County", state: "Texas" },
  { fips: "48327", name: "Menard County", state: "Texas" },
  { fips: "48329", name: "Midland County", state: "Texas" },
  { fips: "48331", name: "Milam County", state: "Texas" },
  { fips: "48333", name: "Mills County", state: "Texas" },
  { fips: "48335", name: "Mitchell County", state: "Texas" },
  { fips: "48337", name: "Montague County", state: "Texas" },
  { fips: "48339", name: "Montgomery County", state: "Texas" },
  { fips: "48341", name: "Moore County", state: "Texas" },
  { fips: "48343", name: "Morris County", state: "Texas" },
  { fips: "48345", name: "Motley County", state: "Texas" },
  { fips: "48347", name: "Nacogdoches County", state: "Texas" },
  { fips: "48349", name: "Navarro County", state: "Texas" },
  { fips: "48351", name: "Newton County", state: "Texas" },
  { fips: "48353", name: "Nolan County", state: "Texas" },
  { fips: "48355", name: "Nueces County", state: "Texas" },
  { fips: "48357", name: "Ochiltree County", state: "Texas" },
  { fips: "48359", name: "Oldham County", state: "Texas" },
  { fips: "48361", name: "Orange County", state: "Texas" },
  { fips: "48363", name: "Palo Pinto County", state: "Texas" },
  { fips: "48365", name: "Panola County", state: "Texas" },
  { fips: "48367", name: "Parker County", state: "Texas" },
  { fips: "48369", name: "Parmer County", state: "Texas" },
  { fips: "48371", name: "Pecos County", state: "Texas" },
  { fips: "48373", name: "Polk County", state: "Texas" },
  { fips: "48375", name: "Potter County", state: "Texas" },
  { fips: "48377", name: "Presidio County", state: "Texas" },
  { fips: "48379", name: "Rains County", state: "Texas" },
  { fips: "48381", name: "Randall County", state: "Texas" },
  { fips: "48383", name: "Reagan County", state: "Texas" },
  { fips: "48385", name: "Real County", state: "Texas" },
  { fips: "48387", name: "Red River County", state: "Texas" },
  { fips: "48389", name: "Reeves County", state: "Texas" },
  { fips: "48391", name: "Refugio County", state: "Texas" },
  { fips: "48393", name: "Roberts County", state: "Texas" },
  { fips: "48395", name: "Robertson County", state: "Texas" },
  { fips: "48397", name: "Rockwall County", state: "Texas" },
  { fips: "48399", name: "Runnels County", state: "Texas" },
  { fips: "48401", name: "Rusk County", state: "Texas" },
  { fips: "48403", name: "Sabine County", state: "Texas" },
  { fips: "48405", name: "San Augustine County", state: "Texas" },
  { fips: "48407", name: "San Jacinto County", state: "Texas" },
  { fips: "48409", name: "San Patricio County", state: "Texas" },
  { fips: "48411", name: "San Saba County", state: "Texas" },
  { fips: "48413", name: "Schleicher County", state: "Texas" },
  { fips: "48415", name: "Scurry County", state: "Texas" },
  { fips: "48417", name: "Shackelford County", state: "Texas" },
  { fips: "48419", name: "Shelby County", state: "Texas" },
  { fips: "48421", name: "Sherman County", state: "Texas" },
  { fips: "48423", name: "Smith County", state: "Texas" },
  { fips: "48425", name: "Somervell County", state: "Texas" },
  { fips: "48427", name: "Starr County", state: "Texas" },
  { fips: "48429", name: "Stephens County", state: "Texas" },
  { fips: "48431", name: "Sterling County", state: "Texas" },
  { fips: "48433", name: "Stonewall County", state: "Texas" },
  { fips: "48435", name: "Sutton County", state: "Texas" },
  { fips: "48437", name: "Swisher County", state: "Texas" },
  { fips: "48439", name: "Tarrant County", state: "Texas" },
  { fips: "48441", name: "Taylor County", state: "Texas" },
  { fips: "48443", name: "Terrell County", state: "Texas" },
  { fips: "48445", name: "Terry County", state: "Texas" },
  { fips: "48447", name: "Throckmorton County", state: "Texas" },
  { fips: "48449", name: "Titus County", state: "Texas" },
  { fips: "48451", name: "Tom Green County", state: "Texas" },
  { fips: "48453", name: "Travis County", state: "Texas" },
  { fips: "48455", name: "Trinity County", state: "Texas" },
  { fips: "48457", name: "Tyler County", state: "Texas" },
  { fips: "48459", name: "Upshur County", state: "Texas" },
  { fips: "48461", name: "Upton County", state: "Texas" },
  { fips: "48463", name: "Uvalde County", state: "Texas" },
  { fips: "48465", name: "Val Verde County", state: "Texas" },
  { fips: "48467", name: "Van Zandt County", state: "Texas" },
  { fips: "48469", name: "Victoria County", state: "Texas" },
  { fips: "48471", name: "Walker County", state: "Texas" },
  { fips: "48473", name: "Waller County", state: "Texas" },
  { fips: "48475", name: "Ward County", state: "Texas" },
  { fips: "48477", name: "Washington County", state: "Texas" },
  { fips: "48479", name: "Webb County", state: "Texas" },
  { fips: "48481", name: "Wharton County", state: "Texas" },
  { fips: "48483", name: "Wheeler County", state: "Texas" },
  { fips: "48485", name: "Wichita County", state: "Texas" },
  { fips: "48487", name: "Wilbarger County", state: "Texas" },
  { fips: "48489", name: "Willacy County", state: "Texas" },
  { fips: "48491", name: "Williamson County", state: "Texas" },
  { fips: "48493", name: "Wilson County", state: "Texas" },
  { fips: "48495", name: "Winkler County", state: "Texas" },
  { fips: "48497", name: "Wise County", state: "Texas" },
  { fips: "48499", name: "Wood County", state: "Texas" },
  { fips: "48501", name: "Yoakum County", state: "Texas" },
  { fips: "48503", name: "Young County", state: "Texas" },
  { fips: "48505", name: "Zapata County", state: "Texas" },
  { fips: "48507", name: "Zavala County", state: "Texas" },
  { fips: "74050", name: "Baker Island", state: "Texas" },
  { fips: "74100", name: "Howland Island", state: "Texas" },
  { fips: "74150", name: "Jarvis Island", state: "Texas" },
  { fips: "74200", name: "Johnston Atoll", state: "Texas" },
  { fips: "74250", name: "Kingman Reef", state: "Texas" },
  { fips: "74300", name: "Midway Atoll", state: "Texas" },
  { fips: "74350", name: "Navassa Island", state: "Texas" },
  { fips: "74400", name: "Palmyra Atoll", state: "Texas" },
  { fips: "74450", name: "Wake Island", state: "Texas" },
  { fips: "49001", name: "Beaver County", state: "Utah" },
  { fips: "49003", name: "Box Elder County", state: "Utah" },
  { fips: "49005", name: "Cache County", state: "Utah" },
  { fips: "49007", name: "Carbon County", state: "Utah" },
  { fips: "49009", name: "Daggett County", state: "Utah" },
  { fips: "49011", name: "Davis County", state: "Utah" },
  { fips: "49013", name: "Duchesne County", state: "Utah" },
  { fips: "49015", name: "Emery County", state: "Utah" },
  { fips: "49017", name: "Garfield County", state: "Utah" },
  { fips: "49019", name: "Grand County", state: "Utah" },
  { fips: "49021", name: "Iron County", state: "Utah" },
  { fips: "49023", name: "Juab County", state: "Utah" },
  { fips: "49025", name: "Kane County", state: "Utah" },
  { fips: "49027", name: "Millard County", state: "Utah" },
  { fips: "49029", name: "Morgan County", state: "Utah" },
  { fips: "49031", name: "Piute County", state: "Utah" },
  { fips: "49033", name: "Rich County", state: "Utah" },
  { fips: "49035", name: "Salt Lake County", state: "Utah" },
  { fips: "49037", name: "San Juan County", state: "Utah" },
  { fips: "49039", name: "Sanpete County", state: "Utah" },
  { fips: "49041", name: "Sevier County", state: "Utah" },
  { fips: "49043", name: "Summit County", state: "Utah" },
  { fips: "49045", name: "Tooele County", state: "Utah" },
  { fips: "49047", name: "Uintah County", state: "Utah" },
  { fips: "49049", name: "Utah County", state: "Utah" },
  { fips: "49051", name: "Wasatch County", state: "Utah" },
  { fips: "49053", name: "Washington County", state: "Utah" },
  { fips: "49055", name: "Wayne County", state: "Utah" },
  { fips: "49057", name: "Weber County", state: "Utah" },
  { fips: "50001", name: "Addison County", state: "Vermont" },
  { fips: "50003", name: "Bennington County", state: "Vermont" },
  { fips: "50005", name: "Caledonia County", state: "Vermont" },
  { fips: "50007", name: "Chittenden County", state: "Vermont" },
  { fips: "50009", name: "Essex County", state: "Vermont" },
  { fips: "50011", name: "Franklin County", state: "Vermont" },
  { fips: "50013", name: "Grand Isle County", state: "Vermont" },
  { fips: "50015", name: "Lamoille County", state: "Vermont" },
  { fips: "50017", name: "Orange County", state: "Vermont" },
  { fips: "50019", name: "Orleans County", state: "Vermont" },
  { fips: "50021", name: "Rutland County", state: "Vermont" },
  { fips: "50023", name: "Washington County", state: "Vermont" },
  { fips: "50025", name: "Windham County", state: "Vermont" },
  { fips: "50027", name: "Windsor County", state: "Vermont" },
  { fips: "51001", name: "Accomack County", state: "Virginia" },
  { fips: "51003", name: "Albemarle County", state: "Virginia" },
  { fips: "51005", name: "Alleghany County", state: "Virginia" },
  { fips: "51007", name: "Amelia County", state: "Virginia" },
  { fips: "51009", name: "Amherst County", state: "Virginia" },
  { fips: "51011", name: "Appomattox County", state: "Virginia" },
  { fips: "51013", name: "Arlington County", state: "Virginia" },
  { fips: "51015", name: "Augusta County", state: "Virginia" },
  { fips: "51017", name: "Bath County", state: "Virginia" },
  { fips: "51019", name: "Bedford County", state: "Virginia" },
  { fips: "51021", name: "Bland County", state: "Virginia" },
  { fips: "51023", name: "Botetourt County", state: "Virginia" },
  { fips: "51025", name: "Brunswick County", state: "Virginia" },
  { fips: "51027", name: "Buchanan County", state: "Virginia" },
  { fips: "51029", name: "Buckingham County", state: "Virginia" },
  { fips: "51031", name: "Campbell County", state: "Virginia" },
  { fips: "51033", name: "Caroline County", state: "Virginia" },
  { fips: "51035", name: "Carroll County", state: "Virginia" },
  { fips: "51036", name: "Charles City County", state: "Virginia" },
  { fips: "51037", name: "Charlotte County", state: "Virginia" },
  { fips: "51041", name: "Chesterfield County", state: "Virginia" },
  { fips: "51043", name: "Clarke County", state: "Virginia" },
  { fips: "51045", name: "Craig County", state: "Virginia" },
  { fips: "51047", name: "Culpeper County", state: "Virginia" },
  { fips: "51049", name: "Cumberland County", state: "Virginia" },
  { fips: "51051", name: "Dickenson County", state: "Virginia" },
  { fips: "51053", name: "Dinwiddie County", state: "Virginia" },
  { fips: "51057", name: "Essex County", state: "Virginia" },
  { fips: "51059", name: "Fairfax County", state: "Virginia" },
  { fips: "51061", name: "Fauquier County", state: "Virginia" },
  { fips: "51063", name: "Floyd County", state: "Virginia" },
  { fips: "51065", name: "Fluvanna County", state: "Virginia" },
  { fips: "51067", name: "Franklin County", state: "Virginia" },
  { fips: "51069", name: "Frederick County", state: "Virginia" },
  { fips: "51071", name: "Giles County", state: "Virginia" },
  { fips: "51073", name: "Gloucester County", state: "Virginia" },
  { fips: "51075", name: "Goochland County", state: "Virginia" },
  { fips: "51077", name: "Grayson County", state: "Virginia" },
  { fips: "51079", name: "Greene County", state: "Virginia" },
  { fips: "51081", name: "Greensville County", state: "Virginia" },
  { fips: "51083", name: "Halifax County", state: "Virginia" },
  { fips: "51085", name: "Hanover County", state: "Virginia" },
  { fips: "51087", name: "Henrico County", state: "Virginia" },
  { fips: "51089", name: "Henry County", state: "Virginia" },
  { fips: "51091", name: "Highland County", state: "Virginia" },
  { fips: "51093", name: "Isle of Wight County", state: "Virginia" },
  { fips: "51095", name: "James City County", state: "Virginia" },
  { fips: "51097", name: "King and Queen County", state: "Virginia" },
  { fips: "51099", name: "King George County", state: "Virginia" },
  { fips: "51101", name: "King William County", state: "Virginia" },
  { fips: "51103", name: "Lancaster County", state: "Virginia" },
  { fips: "51105", name: "Lee County", state: "Virginia" },
  { fips: "51107", name: "Loudoun County", state: "Virginia" },
  { fips: "51109", name: "Louisa County", state: "Virginia" },
  { fips: "51111", name: "Lunenburg County", state: "Virginia" },
  { fips: "51113", name: "Madison County", state: "Virginia" },
  { fips: "51115", name: "Mathews County", state: "Virginia" },
  { fips: "51117", name: "Mecklenburg County", state: "Virginia" },
  { fips: "51119", name: "Middlesex County", state: "Virginia" },
  { fips: "51121", name: "Montgomery County", state: "Virginia" },
  { fips: "51125", name: "Nelson County", state: "Virginia" },
  { fips: "51127", name: "New Kent County", state: "Virginia" },
  { fips: "51131", name: "Northampton County", state: "Virginia" },
  { fips: "51133", name: "Northumberland County", state: "Virginia" },
  { fips: "51135", name: "Nottoway County", state: "Virginia" },
  { fips: "51137", name: "Orange County", state: "Virginia" },
  { fips: "51139", name: "Page County", state: "Virginia" },
  { fips: "51141", name: "Patrick County", state: "Virginia" },
  { fips: "51143", name: "Pittsylvania County", state: "Virginia" },
  { fips: "51145", name: "Powhatan County", state: "Virginia" },
  { fips: "51147", name: "Prince Edward County", state: "Virginia" },
  { fips: "51149", name: "Prince George County", state: "Virginia" },
  { fips: "51153", name: "Prince William County", state: "Virginia" },
  { fips: "51155", name: "Pulaski County", state: "Virginia" },
  { fips: "51157", name: "Rappahannock County", state: "Virginia" },
  { fips: "51159", name: "Richmond County", state: "Virginia" },
  { fips: "51161", name: "Roanoke County", state: "Virginia" },
  { fips: "51163", name: "Rockbridge County", state: "Virginia" },
  { fips: "51165", name: "Rockingham County", state: "Virginia" },
  { fips: "51167", name: "Russell County", state: "Virginia" },
  { fips: "51169", name: "Scott County", state: "Virginia" },
  { fips: "51171", name: "Shenandoah County", state: "Virginia" },
  { fips: "51173", name: "Smyth County", state: "Virginia" },
  { fips: "51175", name: "Southampton County", state: "Virginia" },
  { fips: "51177", name: "Spotsylvania County", state: "Virginia" },
  { fips: "51179", name: "Stafford County", state: "Virginia" },
  { fips: "51181", name: "Surry County", state: "Virginia" },
  { fips: "51183", name: "Sussex County", state: "Virginia" },
  { fips: "51185", name: "Tazewell County", state: "Virginia" },
  { fips: "51187", name: "Warren County", state: "Virginia" },
  { fips: "51191", name: "Washington County", state: "Virginia" },
  { fips: "51193", name: "Westmoreland County", state: "Virginia" },
  { fips: "51195", name: "Wise County", state: "Virginia" },
  { fips: "51197", name: "Wythe County", state: "Virginia" },
  { fips: "51199", name: "York County", state: "Virginia" },
  { fips: "51510", name: "Alexandria", state: "Virginia" },
  { fips: "51520", name: "Bristol", state: "Virginia" },
  { fips: "51530", name: "Buena Vista", state: "Virginia" },
  { fips: "51540", name: "Charlottesville", state: "Virginia" },
  { fips: "51550", name: "Chesapeake", state: "Virginia" },
  { fips: "51570", name: "Colonial Heights", state: "Virginia" },
  { fips: "51580", name: "Covington", state: "Virginia" },
  { fips: "51590", name: "Danville", state: "Virginia" },
  { fips: "51595", name: "Emporia", state: "Virginia" },
  { fips: "51600", name: "Fairfax", state: "Virginia" },
  { fips: "51610", name: "Falls Church", state: "Virginia" },
  { fips: "51620", name: "Franklin", state: "Virginia" },
  { fips: "51630", name: "Fredericksburg", state: "Virginia" },
  { fips: "51640", name: "Galax", state: "Virginia" },
  { fips: "51650", name: "Hampton", state: "Virginia" },
  { fips: "51660", name: "Harrisonburg", state: "Virginia" },
  { fips: "51670", name: "Hopewell", state: "Virginia" },
  { fips: "51678", name: "Lexington", state: "Virginia" },
  { fips: "51680", name: "Lynchburg", state: "Virginia" },
  { fips: "51683", name: "Manassas", state: "Virginia" },
  { fips: "51685", name: "Manassas Park", state: "Virginia" },
  { fips: "51690", name: "Martinsville", state: "Virginia" },
  { fips: "51700", name: "Newport News", state: "Virginia" },
  { fips: "51710", name: "Norfolk", state: "Virginia" },
  { fips: "51720", name: "Norton", state: "Virginia" },
  { fips: "51730", name: "Petersburg", state: "Virginia" },
  { fips: "51735", name: "Poquoson", state: "Virginia" },
  { fips: "51740", name: "Portsmouth", state: "Virginia" },
  { fips: "51750", name: "Radford", state: "Virginia" },
  { fips: "51760", name: "Richmond", state: "Virginia" },
  { fips: "51770", name: "Roanoke", state: "Virginia" },
  { fips: "51775", name: "Salem", state: "Virginia" },
  { fips: "51790", name: "Staunton", state: "Virginia" },
  { fips: "51800", name: "Suffolk", state: "Virginia" },
  { fips: "51810", name: "Virginia Beach", state: "Virginia" },
  { fips: "51820", name: "Waynesboro", state: "Virginia" },
  { fips: "51830", name: "Williamsburg", state: "Virginia" },
  { fips: "51840", name: "Winchester", state: "Virginia" },
  { fips: "53001", name: "Adams County", state: "Washington" },
  { fips: "53003", name: "Asotin County", state: "Washington" },
  { fips: "53005", name: "Benton County", state: "Washington" },
  { fips: "53007", name: "Chelan County", state: "Washington" },
  { fips: "53009", name: "Clallam County", state: "Washington" },
  { fips: "53011", name: "Clark County", state: "Washington" },
  { fips: "53013", name: "Columbia County", state: "Washington" },
  { fips: "53015", name: "Cowlitz County", state: "Washington" },
  { fips: "53017", name: "Douglas County", state: "Washington" },
  { fips: "53019", name: "Ferry County", state: "Washington" },
  { fips: "53021", name: "Franklin County", state: "Washington" },
  { fips: "53023", name: "Garfield County", state: "Washington" },
  { fips: "53025", name: "Grant County", state: "Washington" },
  { fips: "53027", name: "Grays Harbor County", state: "Washington" },
  { fips: "53029", name: "Island County", state: "Washington" },
  { fips: "53031", name: "Jefferson County", state: "Washington" },
  { fips: "53033", name: "King County", state: "Washington" },
  { fips: "53035", name: "Kitsap County", state: "Washington" },
  { fips: "53037", name: "Kittitas County", state: "Washington" },
  { fips: "53039", name: "Klickitat County", state: "Washington" },
  { fips: "53041", name: "Lewis County", state: "Washington" },
  { fips: "53043", name: "Lincoln County", state: "Washington" },
  { fips: "53045", name: "Mason County", state: "Washington" },
  { fips: "53047", name: "Okanogan County", state: "Washington" },
  { fips: "53049", name: "Pacific County", state: "Washington" },
  { fips: "53051", name: "Pend Oreille County", state: "Washington" },
  { fips: "53053", name: "Pierce County", state: "Washington" },
  { fips: "53055", name: "San Juan County", state: "Washington" },
  { fips: "53057", name: "Skagit County", state: "Washington" },
  { fips: "53059", name: "Skamania County", state: "Washington" },
  { fips: "53061", name: "Snohomish County", state: "Washington" },
  { fips: "53063", name: "Spokane County", state: "Washington" },
  { fips: "53065", name: "Stevens County", state: "Washington" },
  { fips: "53067", name: "Thurston County", state: "Washington" },
  { fips: "53069", name: "Wahkiakum County", state: "Washington" },
  { fips: "53071", name: "Walla Walla County", state: "Washington" },
  { fips: "53073", name: "Whatcom County", state: "Washington" },
  { fips: "53075", name: "Whitman County", state: "Washington" },
  { fips: "53077", name: "Yakima County", state: "Washington" },
  { fips: "54001", name: "Barbour County", state: "West Virginia" },
  { fips: "54003", name: "Berkeley County", state: "West Virginia" },
  { fips: "54005", name: "Boone County", state: "West Virginia" },
  { fips: "54007", name: "Braxton County", state: "West Virginia" },
  { fips: "54009", name: "Brooke County", state: "West Virginia" },
  { fips: "54011", name: "Cabell County", state: "West Virginia" },
  { fips: "54013", name: "Calhoun County", state: "West Virginia" },
  { fips: "54015", name: "Clay County", state: "West Virginia" },
  { fips: "54017", name: "Doddridge County", state: "West Virginia" },
  { fips: "54019", name: "Fayette County", state: "West Virginia" },
  { fips: "54021", name: "Gilmer County", state: "West Virginia" },
  { fips: "54023", name: "Grant County", state: "West Virginia" },
  { fips: "54025", name: "Greenbrier County", state: "West Virginia" },
  { fips: "54027", name: "Hampshire County", state: "West Virginia" },
  { fips: "54029", name: "Hancock County", state: "West Virginia" },
  { fips: "54031", name: "Hardy County", state: "West Virginia" },
  { fips: "54033", name: "Harrison County", state: "West Virginia" },
  { fips: "54035", name: "Jackson County", state: "West Virginia" },
  { fips: "54037", name: "Jefferson County", state: "West Virginia" },
  { fips: "54039", name: "Kanawha County", state: "West Virginia" },
  { fips: "54041", name: "Lewis County", state: "West Virginia" },
  { fips: "54043", name: "Lincoln County", state: "West Virginia" },
  { fips: "54045", name: "Logan County", state: "West Virginia" },
  { fips: "54047", name: "McDowell County", state: "West Virginia" },
  { fips: "54049", name: "Marion County", state: "West Virginia" },
  { fips: "54051", name: "Marshall County", state: "West Virginia" },
  { fips: "54053", name: "Mason County", state: "West Virginia" },
  { fips: "54055", name: "Mercer County", state: "West Virginia" },
  { fips: "54057", name: "Mineral County", state: "West Virginia" },
  { fips: "54059", name: "Mingo County", state: "West Virginia" },
  { fips: "54061", name: "Monongalia County", state: "West Virginia" },
  { fips: "54063", name: "Monroe County", state: "West Virginia" },
  { fips: "54065", name: "Morgan County", state: "West Virginia" },
  { fips: "54067", name: "Nicholas County", state: "West Virginia" },
  { fips: "54069", name: "Ohio County", state: "West Virginia" },
  { fips: "54071", name: "Pendleton County", state: "West Virginia" },
  { fips: "54073", name: "Pleasants County", state: "West Virginia" },
  { fips: "54075", name: "Pocahontas County", state: "West Virginia" },
  { fips: "54077", name: "Preston County", state: "West Virginia" },
  { fips: "54079", name: "Putnam County", state: "West Virginia" },
  { fips: "54081", name: "Raleigh County", state: "West Virginia" },
  { fips: "54083", name: "Randolph County", state: "West Virginia" },
  { fips: "54085", name: "Ritchie County", state: "West Virginia" },
  { fips: "54087", name: "Roane County", state: "West Virginia" },
  { fips: "54089", name: "Summers County", state: "West Virginia" },
  { fips: "54091", name: "Taylor County", state: "West Virginia" },
  { fips: "54093", name: "Tucker County", state: "West Virginia" },
  { fips: "54095", name: "Tyler County", state: "West Virginia" },
  { fips: "54097", name: "Upshur County", state: "West Virginia" },
  { fips: "54099", name: "Wayne County", state: "West Virginia" },
  { fips: "54101", name: "Webster County", state: "West Virginia" },
  { fips: "54103", name: "Wetzel County", state: "West Virginia" },
  { fips: "54105", name: "Wirt County", state: "West Virginia" },
  { fips: "54107", name: "Wood County", state: "West Virginia" },
  { fips: "54109", name: "Wyoming County", state: "West Virginia" },
  { fips: "55001", name: "Adams County", state: "Wisconsin" },
  { fips: "55003", name: "Ashland County", state: "Wisconsin" },
  { fips: "55005", name: "Barron County", state: "Wisconsin" },
  { fips: "55007", name: "Bayfield County", state: "Wisconsin" },
  { fips: "55009", name: "Brown County", state: "Wisconsin" },
  { fips: "55011", name: "Buffalo County", state: "Wisconsin" },
  { fips: "55013", name: "Burnett County", state: "Wisconsin" },
  { fips: "55015", name: "Calumet County", state: "Wisconsin" },
  { fips: "55017", name: "Chippewa County", state: "Wisconsin" },
  { fips: "55019", name: "Clark County", state: "Wisconsin" },
  { fips: "55021", name: "Columbia County", state: "Wisconsin" },
  { fips: "55023", name: "Crawford County", state: "Wisconsin" },
  { fips: "55025", name: "Dane County", state: "Wisconsin" },
  { fips: "55027", name: "Dodge County", state: "Wisconsin" },
  { fips: "55029", name: "Door County", state: "Wisconsin" },
  { fips: "55031", name: "Douglas County", state: "Wisconsin" },
  { fips: "55033", name: "Dunn County", state: "Wisconsin" },
  { fips: "55035", name: "Eau Claire County", state: "Wisconsin" },
  { fips: "55037", name: "Florence County", state: "Wisconsin" },
  { fips: "55039", name: "Fond du Lac County", state: "Wisconsin" },
  { fips: "55041", name: "Forest County", state: "Wisconsin" },
  { fips: "55043", name: "Grant County", state: "Wisconsin" },
  { fips: "55045", name: "Green County", state: "Wisconsin" },
  { fips: "55047", name: "Green Lake County", state: "Wisconsin" },
  { fips: "55049", name: "Iowa County", state: "Wisconsin" },
  { fips: "55051", name: "Iron County", state: "Wisconsin" },
  { fips: "55053", name: "Jackson County", state: "Wisconsin" },
  { fips: "55055", name: "Jefferson County", state: "Wisconsin" },
  { fips: "55057", name: "Juneau County", state: "Wisconsin" },
  { fips: "55059", name: "Kenosha County", state: "Wisconsin" },
  { fips: "55061", name: "Kewaunee County", state: "Wisconsin" },
  { fips: "55063", name: "La Crosse County", state: "Wisconsin" },
  { fips: "55065", name: "Lafayette County", state: "Wisconsin" },
  { fips: "55067", name: "Langlade County", state: "Wisconsin" },
  { fips: "55069", name: "Lincoln County", state: "Wisconsin" },
  { fips: "55071", name: "Manitowoc County", state: "Wisconsin" },
  { fips: "55073", name: "Marathon County", state: "Wisconsin" },
  { fips: "55075", name: "Marinette County", state: "Wisconsin" },
  { fips: "55077", name: "Marquette County", state: "Wisconsin" },
  { fips: "55078", name: "Menominee County", state: "Wisconsin" },
  { fips: "55079", name: "Milwaukee County", state: "Wisconsin" },
  { fips: "55081", name: "Monroe County", state: "Wisconsin" },
  { fips: "55083", name: "Oconto County", state: "Wisconsin" },
  { fips: "55085", name: "Oneida County", state: "Wisconsin" },
  { fips: "55087", name: "Outagamie County", state: "Wisconsin" },
  { fips: "55089", name: "Ozaukee County", state: "Wisconsin" },
  { fips: "55091", name: "Pepin County", state: "Wisconsin" },
  { fips: "55093", name: "Pierce County", state: "Wisconsin" },
  { fips: "55095", name: "Polk County", state: "Wisconsin" },
  { fips: "55097", name: "Portage County", state: "Wisconsin" },
  { fips: "55099", name: "Price County", state: "Wisconsin" },
  { fips: "55101", name: "Racine County", state: "Wisconsin" },
  { fips: "55103", name: "Richland County", state: "Wisconsin" },
  { fips: "55105", name: "Rock County", state: "Wisconsin" },
  { fips: "55107", name: "Rusk County", state: "Wisconsin" },
  { fips: "55109", name: "St. Croix County", state: "Wisconsin" },
  { fips: "55111", name: "Sauk County", state: "Wisconsin" },
  { fips: "55113", name: "Sawyer County", state: "Wisconsin" },
  { fips: "55115", name: "Shawano County", state: "Wisconsin" },
  { fips: "55117", name: "Sheboygan County", state: "Wisconsin" },
  { fips: "55119", name: "Taylor County", state: "Wisconsin" },
  { fips: "55121", name: "Trempealeau County", state: "Wisconsin" },
  { fips: "55123", name: "Vernon County", state: "Wisconsin" },
  { fips: "55125", name: "Vilas County", state: "Wisconsin" },
  { fips: "55127", name: "Walworth County", state: "Wisconsin" },
  { fips: "55129", name: "Washburn County", state: "Wisconsin" },
  { fips: "55131", name: "Washington County", state: "Wisconsin" },
  { fips: "55133", name: "Waukesha County", state: "Wisconsin" },
  { fips: "55135", name: "Waupaca County", state: "Wisconsin" },
  { fips: "55137", name: "Waushara County", state: "Wisconsin" },
  { fips: "55139", name: "Winnebago County", state: "Wisconsin" },
  { fips: "55141", name: "Wood County", state: "Wisconsin" },
  { fips: "56001", name: "Albany County", state: "Wyoming" },
  { fips: "56003", name: "Big Horn County", state: "Wyoming" },
  { fips: "56005", name: "Campbell County", state: "Wyoming" },
  { fips: "56007", name: "Carbon County", state: "Wyoming" },
  { fips: "56009", name: "Converse County", state: "Wyoming" },
  { fips: "56011", name: "Crook County", state: "Wyoming" },
  { fips: "56013", name: "Fremont County", state: "Wyoming" },
  { fips: "56015", name: "Goshen County", state: "Wyoming" },
  { fips: "56017", name: "Hot Springs County", state: "Wyoming" },
  { fips: "56019", name: "Johnson County", state: "Wyoming" },
  { fips: "56021", name: "Laramie County", state: "Wyoming" },
  { fips: "56023", name: "Lincoln County", state: "Wyoming" },
  { fips: "56025", name: "Natrona County", state: "Wyoming" },
  { fips: "56027", name: "Niobrara County", state: "Wyoming" },
  { fips: "56029", name: "Park County", state: "Wyoming" },
  { fips: "56031", name: "Platte County", state: "Wyoming" },
  { fips: "56033", name: "Sheridan County", state: "Wyoming" },
  { fips: "56035", name: "Sublette County", state: "Wyoming" },
  { fips: "56037", name: "Sweetwater County", state: "Wyoming" },
  { fips: "56039", name: "Teton County", state: "Wyoming" },
  { fips: "56041", name: "Uinta County", state: "Wyoming" },
  { fips: "56043", name: "Washakie County", state: "Wyoming" },
  { fips: "56045", name: "Weston County", state: "Wyoming" },
];


export const fipsMap = new Map<string, Fips>();

fips.forEach(fips => {
  fipsMap.set(fips.fips, fips);
});
