import React from 'react';
import useModal from 'src/hooks/useModal';
import { App } from 'src/interfaces/app';
import AppInfoModal from '../modals/AppInfoModal';

interface AppButtonProps {
  app: App
}

const AppButton = ({app}: AppButtonProps) => {

  const { openModalWith } = useModal();

  const handleClick = () => {
    openModalWith({
      title: "App Info",
      body: <AppInfoModal app={app} />,
      hideButtons: true,
    })
  }
  return (
    <>
        <button
        className=" flex h-8 cursor-pointer select-none items-center justify-center space-x-1 rounded-lg border border-zinc-500 bg-card-light px-2 py-0.5 dark:bg-card-dark"
        onClick={handleClick}
      >
        
        <span className="select-none text-sm font-semibold text-text-dark dark:text-text-light">
          {app.name}
        </span>
      </button>
    </>
  )
};

export default AppButton;