import React from "react";
import LoadingSpinner from "./LoadingSpinner";

interface LoadingTableProps {
  label: string;
}

const LoadingTable = ({ label }: LoadingTableProps) => {
  return (
    <>
      <div className="flex items-center justify-center gap-2 rounded-lg bg-card-light px-2 py-2 dark:bg-card-dark">
        <LoadingSpinner className={"text-secondary dark:text-theme1"} />
        <span className="animate-pulse font-bold text-secondary">
          Loading {label}
        </span>
      </div>
    </>
  );
};

export default LoadingTable;
