import { faCrow, faCrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { User } from "src/interfaces/user";
import useModal from "src/hooks/useModal";
import useTheme from "src/hooks/useTheme";
import UserModal from "../modals/UserModal";

interface UserAvatarProps {
  user: User;
}

const UserAvatar = ({ user }: UserAvatarProps) => {
  const { tailwindTheme } = useTheme();

  const { id, firstName, fullName, isLeader, isBirddog } = user;

  const { openModalWith } = useModal();

  const userInitial = firstName[0];

  const colors = [
    tailwindTheme.theme1,
    tailwindTheme.theme2,
    tailwindTheme.theme3,
    tailwindTheme.theme4,
    tailwindTheme.theme5,
  ];

  const assignColor = (userID: number) => {
    return colors[userID % colors.length];
  };

  const trimLongerNames = (name: string) => {
    if (!name) return "";
    if (name.length > 18) {
      return firstName;
    } else {
      return fullName;
    }
  };

  const handleClick = () => {
    openModalWith({
      title: "User",
      body: <UserModal user={user} />,
      hideButtons: true,
    });
  };

  return (
    <>
      <div
        style={{
          textTransform: "none",
        }}
        className={
          "btn-ghost btn-xs btn flex w-fit cursor-pointer select-none items-center justify-center gap-1 sm:btn-sm"
        }
        onClick={handleClick}
      >
        <div
          style={{
            backgroundColor: assignColor(id),
          }}
          className="flex h-[18px] w-[18px] items-center justify-center rounded-full md:h-[26px] md:w-[26px]"
        >
          <p className="font-extrabold text-text-light dark:text-back-dark">
            {userInitial}
          </p>
        </div>
        <div>
          <p className="font-semibold text-text-dark dark:text-text-light">
            {trimLongerNames(fullName)}
          </p>
        </div>
        {isLeader && (
          <div className="">
            <FontAwesomeIcon icon={faCrown} className={"text-yellow-500"} />
          </div>
        )}
        {isBirddog && (
          <div>
            <FontAwesomeIcon icon={faCrow} className={"text-blue-400"} />
          </div>
        )}
        <div className="flex items-center justify-center">
          <span
            className={
              "mb-1 flex items-end text-xs font-semibold text-zinc-400"
            }
          >
            #{id}
          </span>
        </div>
      </div>
    </>
  );
};

export default UserAvatar;
