import { PropsWithChildren, useState, createContext, useCallback } from "react";
import { Outlet } from "react-router-dom";
import { Admin } from "src/interfaces/admin";

type TokenData = Admin & { token: string };

interface ContextState {
  email: string;
  purpose: string;
  tokenData?: TokenData;
  setState: (email: string, purpose: string, data: TokenData) => void;
}

const defaultValue = {
  email: "",
  purpose: "",
  setState: () => {},
};

export const TokenContext = createContext<ContextState>(defaultValue);

const TokenGuardContext = ({ children }: PropsWithChildren<{}>) => {
  const [tokenData, setTokenData] = useState<TokenData>();
  const [email, setEmail] = useState<string>("");
  const [purpose, setPurpose] = useState<string>("");

  const setState = useCallback(
    (email: string, purpose: string, data: TokenData) => {
      setTokenData(data);
      setEmail(email);
      setPurpose(purpose);
    },
    []
  );

  return (
    <TokenContext.Provider value={{ tokenData, email, purpose, setState }}>
      {children || <Outlet />}
    </TokenContext.Provider>
  );
};

export default TokenGuardContext;
