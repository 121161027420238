import { useEffect, useState } from "react";
import { Configs } from "src/interfaces/configs";
import { configApi } from "src/api";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import Toggle from "src/components/buttons/ToggleX";

interface ConfigsProps {
  props?: any;
}

const ConfigsCard = ({ props }: ConfigsProps) => {
  const [configs, setConfigs] = useState<Configs[]>([]);

  const { handleError } = useErrorHandler();

  const fetchConfigs = async () => {
    const response = await configApi.getConfigs();
    setConfigs(response);
  };

  useEffect(() => {
    fetchConfigs();
  }, []);

  const handleChange = async (id: number) => {
    try {
      await configApi.toggleConfig(id);
    } catch (e) {
      handleError(e);
    } finally {
      return fetchConfigs();
    }
  };

  return (
    <>
      {configs.length ? (
        <div className="w-full">
          <div className="card flex w-full flex-row items-center justify-between bg-card-light px-6 py-4 dark:bg-card-dark">
            <div className="font-semibold text-text-dark dark:text-text-light">
              Show Data Provider Down Banner
            </div>
            <div className="flex items-center">
              <Toggle
                title={"BannerAlert"}
                name={"BannerAlert"}
                checked={configs[0].value === "true" ? true : false}
                onChange={() => handleChange(1)}
                className="toggle"
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ConfigsCard;
