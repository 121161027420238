import { useState, useEffect } from "react";
import { themes } from "src/global/themes";

const useThemeSwitcher = () => {
  const [theme, setTheme] = useState<any>(() => {
    return localStorage.getItem("theme");
  });

  const findTheme = (name: string) => {
    return themes.find((theme) => theme.name === name);
  };

  useEffect(() => {
    if (theme) {
      document.body.dataset.theme = theme;
      const themeObject = findTheme(theme);
      if (themeObject) {
        const { tailwind }:any  = themeObject;
        Object.keys(tailwind).forEach((key) => {
          document.documentElement.style.setProperty(`--${key}`, tailwind[key]);
        });
      }
      localStorage.setItem("theme", theme);
    } else {
      document.body.dataset.theme = "legacy";
      localStorage.setItem("theme", "legacy");
    }
  }, [theme]);

  return [theme, setTheme];
};

export default useThemeSwitcher;
