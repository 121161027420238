import React, { useState } from "react";
import { stripeApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import { TeamPageData } from "src/interfaces/TeamPageData";
import FloatingInput from "../fields/FloatingInputText";
import ButtonBlock from "./ButtonBlock";

interface EditStripeIDModalProps {
  team: TeamPageData;
  fetchTeam: () => void;
  fetchStripeData: () => void;
}

const EditStripeIDModal = ({
  team,
  fetchTeam,
  fetchStripeData,
}: EditStripeIDModalProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  interface FormState {
    stripeID: string;
  }

  const initialFormState = {
    stripeID: team.stripeAccount.stripeSubscriptionID || "",
  };

  const initialFormErrors: {
    stripeID: string;
  } = {
    stripeID: "",
  };

  interface FormErrors {
    stripeID: string | null;
  }

  const [formData, setFormData] = useState<FormState>(initialFormState);
  const [onSubmitting, setSubmitting] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    setFormData({ ...formData, [name]: checked || value });
  };

  const validate: any = (data: any) => {
    const errors: {
      keapID?: string;
    } = {};
    if (!data.keapID) {
      errors.keapID = "Stripe Subscription ID is required";
    }
    return errors;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let errors = validate(formData);
    if (errors.stripeID) {
      return setFormErrors(errors);
    } else {
      setSubmitting(true);
      try {
        const { stripeID } = formData;
        await stripeApi.updateStripeSubID({
          teamID: team.team.id,
          subscriptionID: stripeID,
        });
        setShowModal(false);
        setAlert({
          display: true,
          message: `Stripe subscriptionID has been updated to ${stripeID}`,
          type: "success",
        });
      } catch (err: any) {
        setAlert({
          display: true,
          message: err?.message || "Unable to update Stripe subscriptionID",
          type: "error",
        });
      } finally {
        fetchTeam();
        fetchStripeData();
        setShowModal(false);
      }
      setSubmitting(false);
    }
  };

  const handleCancel = async () => {
    setShowModal(false);
  };
  return (
    <>
      <div>
        <div className="flex w-full items-center justify-center">
          <div className="w-full sm:w-2/3">
            <div className="my-3">
              <FloatingInput
                name={"stripeID"}
                type={"text"}
                value={formData.stripeID.toString()}
                onChange={handleChange}
                error={formErrors.stripeID}
                label={"StripeID"}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <ButtonBlock
            submitting={onSubmitting}
            handleSubmit={handleSubmit}
            onCancel={handleCancel}
            submitLabel={"Save"}
            disabled={onSubmitting}
          />
        </div>
      </div>
    </>
  );
};

export default EditStripeIDModal;
