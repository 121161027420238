import { Navigate } from "react-router-dom";
import CacheCard from "src/components/settings/CacheCard";
import ConfigsCard from "src/components/settings/ConfigsCard";
import useAdminRedirect from "src/hooks/private/useAdminRedirect";

const Settings = () => {

  const redirectPath = useAdminRedirect();

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }
  
  return (
    <>
      <div className="mb-1">
        <ConfigsCard />
      </div>
      <div className="mb-1">
        <CacheCard />
      </div>
    </>
  );
};

export default Settings;
