export interface SitePlan {
  id: number;
  description: string;
  hasDash: boolean;
}

const dealioSitePlans: SitePlan[] = [
  {
    id: 582,
    description: "Dealio Pro + LPP + 3 AI Zips",
    hasDash: true,
  },
  {
    id: 626,
    description: "Dealio Pro + LPP + 10 AI Zips",
    hasDash: true,
  },
  {
    id: 635,
    description: "Dealio Pro Basic RF Pro",
    hasDash: false,
  },
  {
    id: 699,
    description: "Dealio Pro + LPP + 2 AI Zips",
    hasDash: true,
  },
  {
    id: 700,
    description: "Dealio Pro + LPP + 3 AI Zips",
    hasDash: true,
  },
  {
    id: 737,
    description: "Dealio Pro - Pro (Annual) | LPP",
    hasDash: false,
  },
  {
    id: 772,
    description: "Dealio Pro + LPP + 1 AI Zip",
    hasDash: true,
  },
  {
    id: 871,
    description: "Dealio Pro - LF (Monthly) | Basic Leads | 5 AI Zips",
    hasDash: true,
  },
  {
    id: 872,
    description: "Dealio Pro - LF (Annual) | Basic Leads | 5 AI Zips",
    hasDash: true,
  },
  {
    id: 896,
    description: "Dealio Pro API Access Only",
    hasDash: false,
  },
];

export default dealioSitePlans;
