import React, { useState } from "react";
import { adminApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import { TeamPageData } from "src/interfaces/TeamPageData";
import FloatingInput from "../fields/FloatingInputText";
import ButtonBlock from "./ButtonBlock";

interface EditHubspotIDModalProps {
  team: TeamPageData;
  fetchTeam: () => void;
}

const EditHubspotIDModal = ({ team, fetchTeam }: EditHubspotIDModalProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  interface FormState {
    hubspotID: number;
  }

  const initialFormState = {
    hubspotID: team.team.hubspotID || 0,
  };

  const initialFormErrors: {
    hubspotID: string;
  } = {
    hubspotID: "",
  };

  interface FormErrors {
    hubspotID: string | null;
  }

  const [formData, setFormData] = useState<FormState>(initialFormState);
  const [onSubmitting, setSubmitting] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    setFormData({ ...formData, [name]: checked || value });
  };

  const validate: any = (data: any) => {
    const errors: {
      hubspotID?: string;
    } = {};
    if (!data.hubspotID) {
      errors.hubspotID = "Hubspot ID is required";
    }
    return errors;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let errors = validate(formData);
    if (errors.hubspotID) {
      return setFormErrors(errors);
    } else {
      setSubmitting(true);
      try {
        const { hubspotID } = formData;
        await adminApi.updateTeam({
          ...team.team,
          hubspotID: hubspotID,
        });
        setShowModal(false);
        setAlert({
          display: true,
          message: `HubspotID has been updated to ${hubspotID}`,
          type: "success",
        });
      } catch (err: any) {
        setAlert({
          display: true,
          message: err?.message || "Unable to update KeapID",
          type: "error",
        });
      } finally {
        fetchTeam();
        setShowModal(false);
      }
      setSubmitting(false);
    }
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  return (
    <div>
      <div className="flex w-full items-center justify-center">
        <div className="w-full sm:w-2/3">
          <div className="my-3">
            <FloatingInput
              name={"hubspotID"}
              type={"text"}
              value={formData.hubspotID.toString()}
              onChange={handleChange}
              error={formErrors.hubspotID}
              label={"HubspotID"}
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <ButtonBlock
          submitting={onSubmitting}
          handleSubmit={handleSubmit}
          onCancel={handleCancel}
          submitLabel={"Save"}
          disabled={onSubmitting}
        />
      </div>
    </div>
  );
};

export default EditHubspotIDModal;
