interface PublicLayoutProps {
  children: JSX.Element | JSX.Element[];
}

const PublicLayout = ( { children } : PublicLayoutProps ) => {

  return (
    <>
      <div className='min-w-full min-h-full'>
        {children}
      </div>
      
    </>
  )

}

export default PublicLayout;
