import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import useTheme from "src/hooks/useTheme";
import useViewport from "src/hooks/useViewport";
import { statisticsApi } from "src/api";
import classNames from "classnames";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface PeriodInterval {
  period: string;
  interval: number;
}

const ActionStatistics = () => {
  const { mode, tailwindTheme } = useTheme();

  const { width } = useViewport();

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          color: mode === "dark" ? "#FFFFFF" : "#000000",
          font: {
            size: width < 640 ? 10 : width < 1024 ? 12 : 14,
            family: "sans-serif",
          },
          boxWidth: width < 640 ? 12 : 16,
          boxHeight: width < 640 ? 12 : 16,
        },
      },
      title: {
        display: true,
        text: "Action Statistics",
        color: mode === "light" ? "#7E7D85" : "#ABA9B4",
        padding: 0,
        font: {
          size: width < 640 ? 13 : 17,
          family: "sans-serif",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: width < 640 ? 8 : 12,
          },
          color: mode === "dark" ? "#FFFFFF" : "#000000",
        },
      },
      y: {
        ticks: {
          font: {
            size: width < 640 ? 8 : 12,
          },
          color: mode === "dark" ? "#FFFFFF" : "#000000",
        },
      },
    },
  };

  const initialDataSetValue = [
    {
      label: "Researches",
      data: [],
      backgroundColor: tailwindTheme?.theme1,
      order: 1,
    },
    {
      label: "Calls/Emails",
      data: [],
      backgroundColor: tailwindTheme?.theme4,
      order: 2,
    },
    {
      label: "Debt Stacks",
      data: [],
      backgroundColor: tailwindTheme?.theme5,
      order: 3,
    },
    {
      label: "Sequences",
      data: [],
      backgroundColor: tailwindTheme?.theme2,
      order: 4,
    },
    {
      label: "Offers",
      data: [],
      backgroundColor: tailwindTheme?.theme3,
      order: 5,
    },
  ];

  const [labels, setLabels] = useState<ChartData<"bar">["labels"]>([]);
  const [dataSets, setDataSets] =
    useState<ChartData<"bar">["datasets"]>(initialDataSetValue);

  const chartData: ChartData<"bar"> = {
    labels,
    datasets: dataSets,
  };

  const [periodInterval, setPeriodInterval] = useState<PeriodInterval>({
    period: "weeks",
    interval: 12,
  });

  const handleTimeChange = (period: string, interval: number) => {
    setPeriodInterval({ period, interval });
  };

  const { period, interval } = periodInterval;

  const fetchStats = useCallback(async () => {
    try {
      const { labels, datasets } = await statisticsApi.getActionStats(
        period,
        interval
      );

      setLabels(labels);
      setDataSets((dataSets) =>
        dataSets.map((dataset1, index) => ({
          ...dataset1,
          data: datasets[index].data,
        }))
      );
    } catch (err: any) {
      console.warn(err?.message);
    }
  }, [period, interval]);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  const Buttons = useMemo(
    () =>
      [
        { period: "months", interval: 12, label: "12 Months" },
        { period: "months", interval: 6, label: "6 Months" },
        { period: "weeks", interval: 12, label: "12 Weeks" },
        { period: "weeks", interval: 6, label: "6 Weeks" },
        { period: "days", interval: 7, label: "1 Week" },
      ].map((btn) => {
        const active = btn.period === period && btn.interval === interval;
        return (
          <button
            key={`${btn.interval}-${btn.period}`}
            onClick={() => handleTimeChange(btn.period, btn.interval)}
            className={classNames("btn btn-xs text-xxs sm:btn-sm", {
              "btn-secondary text-text-light hover:bg-secondary dark:text-text-dark":
                active,
              "btn-ghost text-secondary hover:bg-blue-100 hover:dark:bg-back-dark":
                !active,
            })}
          >
            {btn.label}
          </button>
        );
      }),
    [period, interval]
  );

  return (
    <>
      <div className="card h-full w-full bg-card-light px-1 pb-1 pt-2 shadow-lg dark:bg-card-dark">
        <div className="mb-1 lg:px-2">
          <Bar options={chartOptions} data={chartData} />
        </div>
        <div className="mb-0.5 flex flex-row items-center justify-between xs:justify-center xs:gap-3 xs:py-2">
          {Buttons}
        </div>
      </div>
    </>
  );
};

export default ActionStatistics;
