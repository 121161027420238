import TeamEvent from "src/interfaces/teamEvent";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";

class TeamEventsApi extends ApiTokenSupport {
  async getByTeamID(teamID: number): Promise<TeamEvent[]> {
    const resp = await axios.get<TeamEvent[]>(
      `/api/teamEvent/teams/${teamID}`,
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new TeamEventsApi();
