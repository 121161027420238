import React from 'react';
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faBell, faGaugeHigh, faUsers, faGear } from '@fortawesome/free-solid-svg-icons'


interface MobileFooterNavProps {
  props?: any;
}

const MobileFooterNav = ({ props }: MobileFooterNavProps) => {
  const navItems = [{
    route: "/properties",
    icon: faHouse,
    label: "Properties"
  },
  {
    route: "/team",
    icon: faUsers,
    label: "Team"
  },
  {
    route: "/dashboard",
    icon: faGaugeHigh,
    label: "Dashboard"
  },
  {
    route: "/notifications",
    icon: faBell,
    label: "Notifications"
  },
  {
    route: "/settings",
    icon: faGear,
    label: "Settings"
  },
  ]

  return (
    <footer className="bg-white dark:bg-card-dark text-center fixed inset-x-0 bottom-0 p-4 border-t-2 border-periwinkle z-50">
      <nav className="flex justify-around">
        {
          navItems.map((navItem, index) => (
            <NavLink key={index} to={navItem.route} className={(navData) => navData.isActive ? "text-periwinkle" : "text-icon-light dark:text-icon-dark"}>
              <div className="justify-content-center align-items-center">
                <FontAwesomeIcon size="lg" icon={navItem.icon} />
                <div className="pt-1 text-xxs sm:text-xs">{navItem.label}</div>
              </div>
            </NavLink>
          ))
        }
      </nav>
    </footer>
  );
}

export default MobileFooterNav;