import { ReactNode } from "react";
/***** Navigation ******/
import HeaderNav from "../components/navigation/desktop/HeaderNav";
import SideNav from "../components/navigation/desktop/SideNav";
import HeaderNavMobile from "../components/navigation/mobile/HeaderNavMobile";
import FooterNavMobile from "../components/navigation/mobile/FooterNavMobile";
import SearchBarMobile from "../components/navigation/mobile/SearchBarMobile";
import Alert from "src/components/alert/Alert";

/***** Hooks *****/
import useViewport from "../hooks/useViewport";
import useAlert from "src/hooks/useAlert";

interface PrivateLayoutProps {
  children: ReactNode;
}

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  let { width } = useViewport();
  let isMobile = width <= 1024;

  const { alert, clearAlert } = useAlert();

  return (
    <>
      {isMobile ? <HeaderNavMobile /> : <SideNav />}
      {!isMobile && <HeaderNav />}
      {isMobile ? (
        <div className=" bg-back-light dark:bg-back-dark min-w-full min-h-screen overflow-x-hidden">
          <div className="mt-4 mx-3 mb-2 md:mx-4">
            <SearchBarMobile />
          </div>
          <div className="relative top-40 mx-3">
            <Alert alert={alert} onClose={clearAlert} />
          </div>
          <div className="mt-44 mx-3 overflow-auto z-10">{children}</div>
        </div>
      ) : (
        <>
          <div className="fixed left-72 right-0 top-16 bottom-0 bg-back-light dark:bg-back-dark overflow-auto overscroll-none overflow-x-hidden px-6 py-0.5">
            {children}
            
          </div>
        </>
      )}
      {isMobile && <FooterNavMobile />}
    </>
  );
};

export default PrivateLayout;
