import useViewport from "src/hooks/useViewport";

interface SwiperInvisibleBlockProps {
  num: number;
}

const SwiperInvisibleBlock = ({ num }: SwiperInvisibleBlockProps) => {
  const { height } = useViewport();
  return (
    <>
      {num < 10 && (
        <div
          style={{
            height: `${num === 0 ? height - 360 : height - 360 - num * 110}px`,
          }}
          className="bg-back-light dark:bg-back-dark w-full overscroll-none"
        ></div>
      )}
    </>
  );
};

export default SwiperInvisibleBlock;
