import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "src/assets/images/SendFuse_Logo_Light.png"


import ThemeSwitch from "../../theme/ThemeSwitch";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faGaugeHigh,
  faUserGroup,
  faGear,
  faArrowTrendUp,
  faEnvelope,
  faEnvelopeOpenText,
  faArrowRightFromBracket,
  faHouse,
  faHeadset,
  faComment,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";


interface HeaderBarNavProps {
  props?: any;
}

const HeaderBarNav = ({ props }: HeaderBarNavProps) => {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <header className="fixed flex min-w-full items-space-between justify-between flex-no-wrap bg-white dark:bg-card-dark p-6 z-30">
      {navOpen ? (
        <>
          <div onClick={() => setNavOpen(!navOpen)} className="absolute bg-title-text-light opacity-25 top-0 bottom-0 left-0 w-1/3 md:w-7/12 min-h-screen z-40"></div>
          <div id='drawer-nav' className="absolute bg-white dark:bg-card-dark top-0 bottom-0 right-0 w-2/3 md:w-5/12 min-h-screen z-40">
            <div className="flex items-left min-w-full">

            <ul className="flex flex-col list-none min-w-full">
            {/***** Dashboard Link *****/}
            <li className="items-center mt-2">
              <Link to="/dashboard">
                <div
                  onClick={() => setNavOpen(!navOpen)}
                  className={
                    window.location.href.indexOf("/dashboard") !== -1
                      ? "bg-gradient-to-b from-gradient1 to-gradient2 shadow-lg shadow-icon-light dark:shadow-back-dark px-10 py-3 mb-3"
                      : "hover:bg-card-blend-light dark:hover:bg-back-dark rounded-lg px-10 py-3 mb-3"
                  }
                >
                  <FontAwesomeIcon
                    icon={faGaugeHigh}
                    className={
                      window.location.href.indexOf("/dashboard") !== -1
                        ? "text-white dark:text-black text-xl md:text-2xl  mr-1.5"
                        : "text-icon-light dark:text-icon-dark text-xl md:text-2xl mr-1.5"
                    }
                  />
                  <span
                    className={
                      window.location.href.indexOf("/dashboard") !== -1
                        ? "text-white dark:text-black text-xl md:text-2xl  font-bold"
                        : "text-xl text-icon-light dark:text-icon-dark md:text-2xl font-bold "
                    }
                  >
                    {" "}
                    Dashboard
                  </span>
                </div>
              </Link>
            </li>

            {/***** Properties Link *****/}
            <li className="items-center">
              <Link to="/properties">
                <div
                onClick={() => setNavOpen(!navOpen)}
                  className={
                    window.location.href.indexOf("/properties") !== -1
                      ? "bg-gradient-to-b from-gradient1 to-gradient2 shadow-lg shadow-icon-light dark:shadow-back-dark px-10 py-3 mb-3"
                      : "hover:bg-card-blend-light dark:hover:bg-back-dark rounded-lg px-10 py-3 mb-3"
                  }
                >
                  <FontAwesomeIcon
                    icon={faHouse}
                    className={
                      window.location.href.indexOf("/properties") !== -1
                        ? "text-white dark:text-black text-xl md:text-2xl  mr-1.5"
                        : "text-icon-light dark:text-icon-dark text-xl md:text-2xl  mr-1.5"
                    }
                  />
                  <span
                    className={
                      window.location.href.indexOf("/properties") !== -1
                        ? "text-white dark:text-black text-xl md:text-2xl  font-bold"
                        : "text-xl text-icon-light dark:text-icon-dark md:text-2xl  font-bold "
                    }
                  >
                    {" "}
                    Properties
                  </span>
                </div>
              </Link>
            </li>

            {/***** Sequencess Link *****/}
            <li className="items-center">
              <Link to="/sequences">
                <div
                onClick={() => setNavOpen(!navOpen)}
                  className={
                    window.location.href.indexOf("/sequences") !== -1
                      ? "bg-gradient-to-b from-gradient1 to-gradient2 shadow-lg shadow-icon-light dark:shadow-back-dark px-10 py-3 mb-3"
                      : "hover:bg-card-blend-light dark:hover:bg-back-dark rounded-lg px-10 py-3 mb-3"
                  }
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={
                      window.location.href.indexOf("/sequences") !== -1
                        ? "text-white dark:text-black text-xl md:text-2xl  mr-1.5"
                        : "text-icon-light dark:text-icon-dark text-xl md:text-2xl  mr-1.5"
                    }
                  />
                  <span
                    className={
                      window.location.href.indexOf("/sequences") !== -1
                        ? "text-white dark:text-black text-xl md:text-2xl  font-bold"
                        : "text-xl text-icon-light dark:text-icon-dark md:text-2xl  font-bold "
                    }
                  >
                    {" "}
                    Sequences
                  </span>
                </div>
              </Link>
            </li>

            {/***** Templates Link *****/}
            <li className="items-center">
              <Link to="/templates">
                <div
                onClick={() => setNavOpen(!navOpen)}
                  className={
                    window.location.href.indexOf("/templates") !== -1
                      ? "bg-gradient-to-b from-gradient1 to-gradient2 shadow-lg shadow-icon-light dark:shadow-back-dark px-10 py-3 mb-3"
                      : "hover:bg-card-blend-light dark:hover:bg-back-dark rounded-lg px-10 py-3 mb-3"
                  }
                >
                  <FontAwesomeIcon
                    icon={faEnvelopeOpenText}
                    className={
                      window.location.href.indexOf("/templates") !== -1
                        ? "text-white dark:text-black text-xl md:text-2xl mr-1.5"
                        : "text-icon-light dark:text-icon-dark text-xl md:text-2xl mr-1.5"
                    }
                  />
                  <span
                    className={
                      window.location.href.indexOf("/templates") !== -1
                        ? "text-white dark:text-black text-xl md:text-2xl font-bold"
                        : "text-xl text-icon-light dark:text-icon-dark md:text-2xl font-bold "
                    }
                  >
                    {" "}
                    Templates
                  </span>
                </div>
              </Link>
            </li>

           

            {/***** Team Link *****/}
            <li className="items-center">
              <Link to="/team">
                <div
                onClick={() => setNavOpen(!navOpen)}
                  className={
                    window.location.href.indexOf("/team") !== -1
                      ? "bg-gradient-to-b from-gradient1 to-gradient2shadow-lg shadow-icon-light dark:shadow-back-dark px-10 py-3 mb-3"
                      : "hover:bg-card-blend-light dark:hover:bg-back-dark rounded-lg px-10 py-3 mb-3"
                  }
                >
                  <FontAwesomeIcon
                    icon={faUserGroup}
                    className={
                      window.location.href.indexOf("/team") !== -1
                        ? "text-white dark:text-black text-xl md:text-2xl mr-1.5"
                        : "text-icon-light dark:text-icon-dark text-xl md:text-2xl mr-1.5"
                    }
                  />
                  <span
                    className={
                      window.location.href.indexOf("/team") !== -1
                        ? "text-white dark:text-black text-xl md:text-2xl font-bold"
                        : "text-xl text-icon-light dark:text-icon-dark md:text-2xl font-bold "
                    }
                  >
                    {" "}
                    Team
                  </span>
                </div>
              </Link>
            </li>

            {/***** Features Link *****/}
            <li className="items-center">
              <Link to="/features">
                <div
                onClick={() => setNavOpen(!navOpen)}
                  className={
                    window.location.href.indexOf("/features") !== -1
                      ? "bg-gradient-to-b from-gradient1 to-gradient2 shadow-lg shadow-icon-light dark:shadow-back-dark px-10 py-3 mb-3"
                      : "hover:bg-card-blend-light dark:hover:bg-back-dark rounded-lg px-10 py-3 mb-3"
                  }
                >
                  <FontAwesomeIcon
                    icon={faArrowTrendUp}
                    className={
                      window.location.href.indexOf("/features") !== -1
                        ? "text-white dark:text-black text-xl md:text-2xl mr-1.5"
                        : "text-icon-light dark:text-icon-dark text-xl md:text-2xl mr-1.5"
                    }
                  />
                  <span
                    className={
                      window.location.href.indexOf("/features") !== -1
                        ? "text-white dark:text-black text-xl md:text-2xl font-bold"
                        : "text-xl text-icon-light dark:text-icon-dark md:text-2xl font-bold "
                    }
                  >
                    {" "}
                    Features
                  </span>
                </div>
              </Link>
            </li>

            {/***** Settings Link *****/}
            <li className="items-center">
              <Link to="/settings">
                <div
                onClick={() => setNavOpen(!navOpen)}
                  className={
                    window.location.href.indexOf("/settings") !== -1
                      ? "bg-gradient-to-b from-gradient1 to-gradient2 shadow-lg shadow-icon-light dark:shadow-back-dark px-10 py-3 mb-3"
                      : "hover:bg-card-blend-light dark:hover:bg-back-dark rounded-lg px-10 py-3 mb-3"
                  }
                >
                  <FontAwesomeIcon
                    icon={faGear}
                    className={
                      window.location.href.indexOf("/settings") !== -1
                        ? "text-white dark:text-black text-xl md:text-2xl mr-1.5"
                        : "text-icon-light dark:text-icon-dark text-xl md:text-2xl mr-1.5"
                    }
                  />
                  <span
                    className={
                      window.location.href.indexOf("/settings") !== -1
                        ? "text-white dark:text-black text-xl md:text-2xl font-bold"
                        : "text-xl text-icon-light dark:text-icon-dark md:text-2xl font-bold "
                    }
                  >
                    {" "}
                    Settings
                  </span>
                </div>
              </Link>
            </li>

            <li>
              <hr className="my-4 min-w-full border-icon-light dark:border-icon-dark" />
            </li>
            {/***** Logout Link *****/}
            <li className="items-center mb-2">
              <Link
                to="/"
                className="px-10 py-3 mb-4"
              >
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket}
                  
                  className="text-md md:text-lg text-icon-light dark:text-icon-dark mr-1.5"
                />
                <span
                  className="text-md md:text-lg text-icon-light dark:text-icon-dark font-bold"
                  
                >
                  {" "}
                  Logout
                </span>
              </Link>
            </li>

            {/***** Support Link *****/}
            <li className="items-center mb-2">
              <Link
                to="/support"
                className="px-10 py-3 mb-4"
                onClick={() => setNavOpen(!navOpen)}
              >
                <FontAwesomeIcon
                  icon={faHeadset}
                
                  className="text-md md:text-lg text-icon-light dark:text-icon-dark mr-1.5"
                />
                <span
                  className="text-md md:text-lg text-icon-light dark:text-icon-dark font-bold"
                
                >
                  {" "}
                  Support
                </span>
              </Link>
            </li>

            {/***** Feedback Link *****/}
            <li className="items-center mb-2 ">
              <Link
                to="/feedback"
                className="px-10 py-3 mb-4"
                onClick={() => setNavOpen(!navOpen)}
              >
                <FontAwesomeIcon
                  icon={faComment}
                  className="text-md md:text-lg text-icon-light dark:text-icon-dark mr-1.5"
                />
                <span className="text-md md:text-lg text-icon-light dark:text-icon-dark font-bold">
                  {" "}
                  Feedback
                </span>
              </Link>
            </li>

            {/***** Version Link *****/}
            <li className="items-center mb-2 ">
              <Link
                to="/roadmap"
                className="px-10 py-3 mb-4"
                onClick={() => setNavOpen(!navOpen)}
              >
                <FontAwesomeIcon
                  icon={faRocket}
                  className="text-md md:text-lg text-icon-light dark:text-icon-dark mr-1.5"
                />
                <span className="text-md md:text-lg text-icon-light dark:text-icon-dark font-bold h ">
                  {" "}
                  Version 0.1.0
                </span>
              </Link>
            </li>
            <li>
              <ThemeSwitch />
            </li>
          </ul>
            </div>
          </div>
          
        </>
      ) : null}

      <div className="flex items-center justify-around h-8 w-full">
        <div className=""> 
        </div>
       

        <div className="mr-14">
          <img src={logo} width="200" alt="SendFuse Logo" />
        </div>
        <div className="flex">
          <button>
            <FontAwesomeIcon
              icon={faBars}
              className="text-periwinkle h-6 md:h-8"
              onClick={() => setNavOpen(!navOpen)}
            />
          </button>
        </div>
      </div>
    </header>
  );
};

export default HeaderBarNav;
