import React from "react";
import useViewport from "src/hooks/useViewport";
import SwiperTable from "../swiper/SwiperTable";
import SupportTicketRow from "./SupportTicketRow";

interface SupportTicketTableProps {
  supportTickets: any[];
  fetchSupportTickets: () => {};
}

const SupportTicketTable = ({
  supportTickets,
  fetchSupportTickets,
}: SupportTicketTableProps) => {
  const { isMobile } = useViewport();

  const openTickets = supportTickets
    ?.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
    .filter((ticket: any) => ticket.status === "open");

  const closedTickets = supportTickets
    ?.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
    .filter((ticket: any) => ticket.status === "closed");

  return (
    <>
      <SwiperTable
        slides={[
          {
            title: "Open",
            content: (
              <table
                style={{
                  tableLayout: "fixed",
                }}
                className={
                  isMobile
                    ? "table-compact block table w-full shadow-xl"
                    : "block table w-full shadow-xl"
                }
              >
                <thead>
                  <tr className="sticky top-0 border-separate border-b-2 border-secondary text-secondary">
                    <th className="sticky bg-card-light text-left text-base dark:bg-card-dark rounded-tl-lg">
                      Ticket ID
                    </th>
                    <th className="sticky bg-card-light text-left text-base dark:bg-card-dark">
                      Status
                    </th>
                    <th className="sticky bg-card-light text-left text-base dark:bg-card-dark">
                      Created
                    </th>
                    <th className="sticky bg-card-light text-left text-base dark:bg-card-dark">
                      Team
                    </th>
                    <th className="sticky bg-card-light text-left text-base dark:bg-card-dark">
                      User
                    </th>
                    <th className="sticky bg-card-light text-left text-base dark:bg-card-dark rounded-tr-lg">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {openTickets.length ? (
                    openTickets.map((ticket: any, index: number) => (
                      <SupportTicketRow
                        key={index}
                        supportTicket={ticket}
                        fetchSupportTickets={fetchSupportTickets}
                      />
                    ))
                  ) : (
                    <tr>
                      <td
                        className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                        colSpan={6}
                      >
                        No Open Support Tickets
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ),
          },
          {
            title: "Closed",
            content: (
              <table
                className={
                  isMobile
                    ? "table-compact block table w-full shadow-xl"
                    : "block table w-full shadow-xl"
                }
              >
                <thead>
                  <tr className="sticky top-0 border-separate border-b-2 border-secondary text-secondary">
                    <th className="sticky w-12 bg-card-light text-left text-base dark:bg-card-dark">
                      Ticket ID
                    </th>
                    <th className="sticky bg-card-light text-left text-base dark:bg-card-dark">
                      Status
                    </th>
                    <th className="sticky bg-card-light text-left text-base dark:bg-card-dark">
                      Created
                    </th>
                    <th className="sticky bg-card-light text-left text-base dark:bg-card-dark">
                      Team
                    </th>
                    <th className="sticky bg-card-light text-left text-base dark:bg-card-dark">
                      User
                    </th>
                    <th className="sticky bg-card-light text-left text-base dark:bg-card-dark">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {closedTickets.length ? (
                    closedTickets.map((ticket: any, index: number) => (
                      <SupportTicketRow
                        key={index}
                        supportTicket={ticket}
                        fetchSupportTickets={fetchSupportTickets}
                      />
                    ))
                  ) : (
                    <tr>
                      <td
                        className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                        colSpan={8}
                      >
                        No Closed Support Tickets
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ),
          },
        ]}
      />
    </>
  );
};

export default SupportTicketTable;
