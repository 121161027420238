import FloatingInput from "../fields/FloatingInputText";



interface TicketDrawerProps {
  subject: string;
  message: string;
}

const TicketDrawer = ({ subject, message }: TicketDrawerProps) => {
  return (
    <>
      <div className="container w-full px-2 py-2 bg-card-light dark:bg-card-dark rounded-lg text-text-dark dark:text-text-light ">
        <p className="font-bold px-5 mb-1">{subject}</p>
        <FloatingInput
          name="message"
          type="textarea"
          inputProps={{
            className: "text-sm border-none p-2"
          }}
          rows={7}
          disabled
          value={message}
        />
      </div>
    </>
  );
};

export default TicketDrawer