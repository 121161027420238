import React from "react";
import { DashTeam } from "src/pages/private/Dash";
import { useNavigate } from "react-router-dom";

interface DashTeamRowProps {
  dashTeam: DashTeam;
}

const DashTeamRow = ({ dashTeam }: DashTeamRowProps) => {
  const navigate = useNavigate();

  return (
    <>
      <tr className="my-0.5 cursor-pointer bg-card-light text-base  font-medium text-text-dark dark:bg-card-dark dark:text-text-light">
        <td className={"bg-card-light dark:bg-card-dark"}>
          <button
            style={{ textTransform: "none", transition: "color 0s" }}
            className="btn-ghost btn-sm btn"
            onClick={() => navigate(`/teams/${dashTeam.id}`)}
          >
            {dashTeam.id}
          </button>
        </td>
        <td className={"bg-card-light dark:bg-card-dark"}>
          <button
            style={{ textTransform: "none", transition: "color 0s" }}
            className="btn-ghost btn-sm btn"
            onClick={() => navigate(`/teams/${dashTeam.id}`)}
          >
            {dashTeam.companyName}
          </button>
        </td>
        <td className={"bg-card-light dark:bg-card-dark"}>
          <span>{dashTeam.drivesCount}</span>
        </td>
        <td className={"bg-card-light dark:bg-card-dark"}>
          <span>{dashTeam.propertiesCount}</span>
        </td>
        <td className={"bg-card-light dark:bg-card-dark"}>
          <span>{dashTeam.propertiesAddedCount}</span>
        </td>
      </tr>
    </>
  );
};

export default DashTeamRow;
