const getRoute = (purpose: string) => {
	switch (purpose) {
		case "demo-mode":
			return "demo";
		case "failed-payment":
			return "update-card";
		case "invite":
			return "register";
		case "invite-to-v2":
			return "join/v2";
		case "invite-admin":
			return "setup-password";
		case "recovery":
			return "reset-password";
		case "migrate":
			return "migrate";
    case "initial-invite":
      return "reset-password";
	}
};

export { getRoute };
