import React from "react";
import { useNavigate } from "react-router-dom";
import { adminApi } from "src/api";
import { Team } from "src/interfaces/team";

interface DashInterestedRowProps {
  team: Team;
  fetchDashInterestedTeams: () => void;
}

const DashInterestedRow = ({
  team,
  fetchDashInterestedTeams,
}: DashInterestedRowProps) => {
  const navigate = useNavigate();

  const handleInterestedChange = async () => {
    try {
      await adminApi.updateTeam({
        id: team.id,
        isInterestedDash: !team.isInterestedDash,
      });
      fetchDashInterestedTeams();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <tr className="my-0.5 cursor-pointer bg-card-light text-base  font-medium text-text-dark dark:bg-card-dark dark:text-text-light">
        <td className={"bg-card-light dark:bg-card-dark"}>
          <button
            style={{ textTransform: "none", transition: "color 0s" }}
            className="btn-ghost btn-sm btn"
            onClick={() => navigate(`/teams/${team.id}`)}
          >
            {team.id}
          </button>
        </td>
        <td className={"bg-card-light dark:bg-card-dark"}>
          <button
            style={{ textTransform: "none", transition: "color 0s" }}
            className="btn-ghost btn-sm btn"
            onClick={() => navigate(`/teams/${team.id}`)}
          >
            {team.companyName}
          </button>
        </td>
        <td className={"bg-card-light dark:bg-card-dark"}>
          <label
            htmlFor={`interested-${team.id}`}
            className="flex cursor-pointer flex-row items-center justify-between gap-2 rounded-lg bg-back-light px-2 py-1 dark:bg-back-dark w-32"
            onChange={handleInterestedChange}
          >
            <input
              id={`interested-${team.id}`}
              type="checkbox"
              checked={team.isInterestedDash}
              onChange={handleInterestedChange}
              className="checkbox-secondary checkbox checkbox-sm"
            />
            <div className="font-semibold text-zinc-500 dark:text-zinc-400">
              Interested
            </div>
          </label>
        </td>
      </tr>
    </>
  );
};

export default DashInterestedRow;
