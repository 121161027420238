import useTokenGuard from "src/hooks/useTokenGuard";
import SetPassword from "src/pages/public/SetPassword";

const AdminInvitationGuard = () => {
    const { onChecking } = useTokenGuard("invite-admin");

    if (onChecking) {
        return <div className='w-full justify-center flex'><span className='loading'>...</span></div>;
    }

    return (
    <SetPassword />)
}

export default AdminInvitationGuard;