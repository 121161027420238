import { useEffect, useState } from "react";
import SupportTicketTable from "src/components/support/SupportTicketTable";
import AdminApi from "src/api/admin";
import useAdminRedirect from "src/hooks/private/useAdminRedirect";
import { Navigate } from "react-router-dom";

const Support = () => {
  const [supportTickets, setSupportTickets] = useState<any[]>([]);

  const fetchSupportTickets = async () => {
    const response = await AdminApi.getAllSupportTickets();
    setSupportTickets(response);
  };

  useEffect(() => {
    fetchSupportTickets();
  }, []);

  const redirectPath = useAdminRedirect();

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  return (
    <>
      <div>
        <SupportTicketTable
          supportTickets={supportTickets}
          fetchSupportTickets={fetchSupportTickets}
        />
      </div>
    </>
  );
};

export default Support;
