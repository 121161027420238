import { PropsWithChildren, useState } from "react";
import { ModalOptionsProps } from "src/contexts/ModalContext";
import useModal from "src/hooks/useModal";
import DeleteButton from "../buttons/DeleteButton";
import ButtonBlock from "./ButtonBlock";

export const ModalLayout = ({
  children,
  disableOutsideClick,
}: PropsWithChildren<{ disableOutsideClick?: boolean }>) => {

  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.69)",
      }}
      className={
        "fixed bottom-0 left-0 right-0 top-0 z-40 flex items-center justify-center bg-title-text-light"
      }
    >
      <div className="flex w-full items-center justify-center">
        <div className="w-11/12 rounded-lg bg-back-light px-2 pb-6 pt-2 shadow-lg drop-shadow-2xl dark:bg-base-100 sm:w-8/12 md:w-7/12 lg:w-7/12 xl:w-6/12 2xl:w-5/12">
          <div className="flex columns-1 flex-col justify-center">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalCard = ({
  title,
  body,
  onSubmit,
  submitLabel,
  cancelLabel,
  onCancel,
  hideButtons,
}: ModalOptionsProps) => {
  const { setShowModal, showModal } = useModal();

  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const handleSubmitWithLoader = async (data: any) => {
    setOnSubmitting(true);
    if (typeof onSubmit === "function") await onSubmit(data);
    setOnSubmitting(false);
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  const onClickCancel = async () => {
    setShowModal(false);
    if (typeof onCancel === "function") {
      onCancel();
    }
  };

  return (
    <>
      {showModal && (
        <ModalLayout>
          <div className="flex items-center justify-between">
            <div className="mb-6 w-5"></div>
            <h1 className="text-dark mt-4 select-none text-center text-xxl font-semibold text-secondary md:text-xxxl">
              {title}
            </h1>
            <div className="mb-6">
              <DeleteButton onClick={handleCancel} />
            </div>
          </div>

          <div className="my-4 w-full px-2 text-center text-text-dark dark:text-text-light">
            {body}
          </div>
          {!hideButtons && (
            <ButtonBlock
              submitting={onSubmitting}
              handleSubmit={handleSubmitWithLoader}
              onCancel={onClickCancel}
              submitLabel={submitLabel || "Submit"}
              disabled={onSubmitting}
              cancelLabel={cancelLabel || "Cancel"}
            />
          )}
        </ModalLayout>
      )}
    </>
  );
};

export default ModalCard;
