import ApiTokenSupport from "./token.support";
import axios from "src/utils/axios";

class PropertyApi extends ApiTokenSupport {
  async refreshProperty(propertyID: number): Promise<boolean> {
    const resp = await axios.post<boolean>(
      "/api/admin/property/refresh",
      { propertyID },
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new PropertyApi();
