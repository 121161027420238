import Property from "src/interfaces/property";
import PropertyUpdateTable from "./PropertyUpdateTable";
import Progress from "src/components/property/Progress";
import { useState } from "react";

interface propertyDrawerProps {
  property: Property;
}

const PropertyDrawer = ({ property }: propertyDrawerProps) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <>
      <div className=" w-full">
        <div>
          <div
            className="mb-1 rounded-xl bg-card-light px-2 py-2 shadow-xl dark:bg-card-dark sm:mb-2"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <Progress
              property={property}
              showLegend={false}
              isHovering={isHovering}
              textSize={"text-xs sm:text-sm md:text-base lg:text-lg"}
            />
          </div>
        </div>
        <div className="w-full overflow-y-auto overscroll-y-none">
          <PropertyUpdateTable property={property} />
        </div>
      </div>
    </>
  );
};

export default PropertyDrawer;
