import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import {
  RealeflowAccountDetails,
  RealeflowResponse,
} from "src/interfaces/realeflow";

export interface UpdateSitePlanResponse {
  FormerPlanId: number;
  NewPlanId: number;
  Notes: string;
  Success: boolean;
  AccountId: number;
  Error: null;
}

class RealeflowApi extends ApiTokenSupport {
  async fetchRealeflowData(
    teamID: number,
    lookupID: "email" | "external"
  ): Promise<RealeflowAccountDetails | null> {
    const resp = await axios.post<RealeflowAccountDetails | null>(
      `api/admin/test-realeflow-id`,
      { teamID },
      this.withAuthorization()
    );
    return resp.data;
  }

  async updateRealeflowData(data: {
    realeflowID: number;
    externalAccountID: number;
  }): Promise<RealeflowResponse | null> {
    const resp = await axios.post<RealeflowResponse | null>(
      `api/admin/update-realeflow-external-id`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async updateSitePlan(data: {
    realeflowID: number;
    sitePlanID: number;
  }): Promise<UpdateSitePlanResponse> {
    const resp = await axios.put<UpdateSitePlanResponse>(
      "api/admin/update-site-plan-id",
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async addOn(data: {
    teamID: number;
    AddOnName: string;
    BillingFrequency: string;
    AIZips: number;
    AIStates: number;
    AICounties: number;
  }): Promise<any> {
    const resp = await axios.post<any>(
      "api/admin/addOnRealeflow",
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async updateAccountStatus(realeflowID: number): Promise<RealeflowResponse> {
    const resp = await axios.post<RealeflowResponse>(
      "api/admin/update-realeflow-account-status",
      { realeflowID },
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new RealeflowApi();
