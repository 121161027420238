import React from "react";
import { TeamPageData } from "src/interfaces/TeamPageData";
import useModal from "src/hooks/useModal";
import FuseCoin from "../icons/FuseCoin";
import AdjustBalance from "../modals/AdjustBalance";

interface CoinBalanceProps {
  team: TeamPageData;
  fetchTeam: () => void;
  fetchNotes: () => void;
}

const CoinBalance = ({ team, fetchTeam, fetchNotes }: CoinBalanceProps) => {
  const { openModalWith } = useModal();

  const { coinsSubscription, coinsPurchased } = team.team;

  const coinBalance = coinsSubscription + coinsPurchased;

  const handleClick = () => {
    openModalWith({
      title: "Adjust Balance",
      body: <AdjustBalance team={team} fetchTeam={fetchTeam} fetchNotes={fetchNotes} />,
      hideButtons: true,
    });
  };

  return (
    <>
      <button
        className=" flex h-8 cursor-pointer select-none items-center justify-center space-x-1 rounded-lg border border-zinc-500 bg-card-light px-1 shadow-lg drop-shadow-lg dark:bg-card-dark"
        onClick={handleClick}
      >
        <FuseCoin />
        <span className="select-none text-sm font-extrabold text-text-dark dark:text-text-light">
          {coinBalance}
        </span>
      </button>
    </>
  );
};

export default CoinBalance;
