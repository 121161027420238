import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";

class HubspotApi extends ApiTokenSupport {
  async getContact(hubspotID: number): Promise<any> {
    const resp = await axios.post<any>(
      `/api/hubspot/contacts`,
      { hubspotID },
      this.withAuthorization()
    );
    return resp.data;
  }
}
export default new HubspotApi();
