import { ToolbarItem } from "../toolbar/ToolbarItem";

interface TeamToolbarProps {
  isActiveLinks: any;
  handleActive: (str: string) => void;
}

const TeamToolbar = ({ isActiveLinks, handleActive }: TeamToolbarProps) => {
  return (
    <>
      <div className="tabs tabs-boxed border-[.5px] border-zinc-300 bg-white dark:border-back-dark dark:bg-card-dark">
        <ToolbarItem
          title={"Account"}
          isActive={isActiveLinks["Account"]}
          onClick={() => handleActive("Account")}
        />
        <ToolbarItem
          title={"Team"}
          isActive={isActiveLinks["Team"]}
          onClick={() => handleActive("Team")}
        />
        <ToolbarItem
          title={"Properties"}
          isActive={isActiveLinks["Properties"]}
          onClick={() => handleActive("Properties")}
        />
        <ToolbarItem
          title={"Usage"}
          isActive={isActiveLinks["Usage"]}
          onClick={() => handleActive("Usage")}
        />
        <ToolbarItem
          title={"Tickets"}
          isActive={isActiveLinks["Tickets"]}
          onClick={() => handleActive("Tickets")}
        />
        <ToolbarItem
          title={"Transactions"}
          isActive={isActiveLinks["Transactions"]}
          onClick={() => handleActive("Transactions")}
        />
        <ToolbarItem
          title={"Logs"}
          isActive={isActiveLinks["Logs"]}
          onClick={() => handleActive("Logs")}
        />
      </div>
    </>
  );
};

export default TeamToolbar;
