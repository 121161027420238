import React, { useEffect, useState } from "react";
import { partnerApi } from "src/api";
import useViewport from "src/hooks/useViewport";
import Partner from "src/interfaces/partner";

interface PartnersProps {
  props?: any;
}

const Partners = ({ props }: PartnersProps) => {
  const { isMobile } = useViewport();

  const [partners, setPartners] = useState<Partner[]>([]);

  const fetchPartners = async () => {
    const resp = await partnerApi.getPartners();
    setPartners(resp);
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  return (
    <>
      <table
        className={
          isMobile
            ? "table-compact block table w-full shadow-lg"
            : "block table w-full shadow-lg"
        }
      >
        <thead className="">
          <tr className="sticky top-0 border-b-2 border-secondary text-secondary">
            <th className="sticky top-0 bg-card-light text-xs dark:bg-card-dark sm:text-sm">
              App Name
            </th>
            <th className="sticky top-0 bg-card-light text-xs dark:bg-card-dark sm:text-sm">
              URL
            </th>
            <th className="sticky top-0 bg-card-light text-xs dark:bg-card-dark sm:text-sm">
              Teams
            </th>
            <th className="sticky top-0 rounded-tr-lg bg-card-light text-left text-xs dark:bg-card-dark sm:text-sm">
              MRR
            </th>
          </tr>
        </thead>
        <tbody>
          {partners &&
            partners.map((partner: Partner, index: number) => (
              <tr key={index}>
                <td className="bg-card-light text-left text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">{partner.app.name}</td>
                <td className="bg-card-light text-left text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">{partner.app.url}</td>
                <td className="bg-card-light text-left text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">
                  {partner.stats.numTeams}
                </td>
                <td className="bg-card-light text-left text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">
                  $ {partner.stats.MRR}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default Partners;
