import React, { useState } from "react";
import { TeamPageData } from "src/interfaces/TeamPageData";
import Button from "../buttons/Button";
import useModal from "src/hooks/useModal";
import { adminApi, keapApi } from "src/api";

interface DashSubscriptionModalProps {
  team: TeamPageData;
  fetchTeam: () => void;
  isDashTagInKeap: boolean;
}

const DashSubscriptionModal = ({
  team,
  fetchTeam,
  isDashTagInKeap,
}: DashSubscriptionModalProps) => {
  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);
  const linkToOrderForm =
    "https://thesolution.infusionsoft.com/app/manageCart/addProduct?productId=3546";

  const { setShowModal } = useModal();

  const handleCancel = () => setShowModal(false);

  const handleSubmit = async () => {
    setOnSubmitting(true);
    try {
      await adminApi.updateTeam({
        id: team.team.id,
        subscriptionID: 6,
      });
      await keapApi.updateKeapData({
        keapID: team.team.keapID,
        sendFuseSubscriptionID: 6,
      });
      // await keapApi.addCoins({
      //   teamID: team.team.id,
      //   coins: 1000,
      //   message:
      //     "A credit of 1000 FuseCoins was added to your account for purchasing SendFuse Dash",
      // });
      fetchTeam();
      setOnSubmitting(false);
      setShowModal(false);
    } catch (err) {
      console.error(err);
      setOnSubmitting(false);
    }
  };

  return (
    <>
      <div>
        {team.team.subscriptionID === 3 ? (
          <div>
            {isDashTagInKeap ? (
              <p>
                The SendFuse_Dash tag is present on this teams Keap Account.
                Team is ready to be converted to the new Platinum Subscription
              </p>
            ) : (
              <p>
                Team is Platinum but does not have Keap Tag that shows they have
                paid for the Dash Upgrade. Please have team pay{" "}
                <a
                  href={linkToOrderForm}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-secondary btn-xs btn font-bold "
                >
                  here
                </a>
                . If you believe the team has paid and want to convert them to
                the new Platinum subscription click Convert below, otherwise
                click Cancel.
              </p>
            )}
            <div className="mt-4 flex items-center justify-center">
              <Button
                className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
                onClick={handleCancel}
                disabled={onSubmitting}
              >
                Cancel
              </Button>
              <Button
                className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
                onClick={handleSubmit}
                disabled={onSubmitting}
                loading={onSubmitting}
              >
                Convert
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <p>Team needs to be upgraded to Platinum Subscription </p>
          </div>
        )}
      </div>
    </>
  );
};

export default DashSubscriptionModal;
