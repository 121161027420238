import { faCircleCheck, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface DisplayEditTextProps {
  label: string;
  name: string;
  value: string | number | null;
  disabled: boolean;
  error?: boolean;
  success?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DisplayEditText = ({
  label,
  name,
  value,
  disabled,
  error,
  success,
  onChange
}: DisplayEditTextProps) => {
  return (
    <>
      <label className="form-control w-full max-w-xs">
        <div className="">
          <span className="label-text text-xs text-zinc-500">{label}</span>
        </div>
        <div className="relative">
          <input
            type="text"
            name={name}
            placeholder={disabled ? "" : "Type here"}
            value={value || ""}
            className="placeholder:dark:text-zinc-500 placeholder:text-zinc-400  border-zinc-5 input-bordered input input-sm w-full max-w-xs bg-card-light text-text-dark disabled:bg-card-light dark:border-zinc-500 dark:bg-card-dark  dark:text-text-light"
            onChange={onChange}
            disabled={disabled}
          />
          {error && (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="absolute right-[8px] top-[8px] text-error"
            />
          )}
          {success && (
            <FontAwesomeIcon
              icon={faCircleCheck} 
              className="absolute right-[8px] top-[8px] text-success"
            />
          )}
        </div>
      </label>
    </>
  );
};

export default DisplayEditText;
