import React, { Dispatch, SetStateAction } from 'react';

interface ContinuityTabProps {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const ContinuityTab = ({isLoading, setIsLoading}: ContinuityTabProps) => {

  return (
    <>
      
    </>
  )
};

export default ContinuityTab;