import { faSwatchbook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme, themes } from "src/global/themes";
import { capitalize } from "src/helpers/parseStrings";
import useTheme from "src/hooks/useTheme";

interface ThemeOptionProps {
  theme: Theme;
}

const ThemeOptions = ({ theme }: ThemeOptionProps) => {
  const { setTheme } = useTheme();

  const handleThemeChange = (name: string) => {
    setTheme(name);
  };
  return (
    <>
      <li className="mb-1 w-full">
        <button
          onClick={() => handleThemeChange(theme.name)}
          className="btn btn-ghost hover:bg-hover-card-light dark:hover:bg-hover-card-dark font-bold w-full"
        >
          <div className="flex items-center justify-between w-full">
            <div
              style={{
                color: theme.daisyUI[0],
              }}
            >
              {capitalize(theme.name)}
            </div>
            <div className="flex items-center justify-end gap-1">
              {theme.daisyUI.map((color: any, index: number) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: color,
                  }}
                  className="h-5 w-2 gap-2 rounded-t-full rounded-b-full"
                ></div>
              ))}
            </div>
          </div>
        </button>
      </li>
    </>
  );
};

const ThemeChooser = () => {
  return (
    <>
      <div className="dropdown dropdown-hover dropdown-end">
        <label
          tabIndex={0}
          className="btn btn-ghost hover:bg-hover-back-light dark:hover:bg-hover-back-dark"
        >
          <FontAwesomeIcon
            icon={faSwatchbook}
            className="text-xl text-primary cursor-pointer"
          />
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu p-2 drop-shadow-xl bg-card-light dark:bg-card-dark md:dark:bg-card-dark rounded-box w-52 text-primary border-2 border-primary mt-0.5"
        >
          {themes.map((theme: Theme, index: number) => (
            <ThemeOptions key={index} theme={theme} />
          ))}
        </ul>
      </div>
    </>
  );
};

export default ThemeChooser;
