import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import Partner from "src/interfaces/partner";

class PartnersApi extends ApiTokenSupport {
  async getPartners(): Promise<Partner[]> {
    const resp = await axios.get<Partner[]>(
      `/api/admin/partners`,
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new PartnersApi();
