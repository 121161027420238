import { Navigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";

import PublicLayout from "../layouts/PublicLayout";

interface PublicRouteProps {
  children: JSX.Element | JSX.Element[];
}

const PublicRoute = ({ children }: PublicRouteProps) => {
  const { currentAdmin, redirectTo } = useAuth();

  if (currentAdmin) {
    return <Navigate to={redirectTo || '/dashboard'} />
  }

  return (
    <>
      <PublicLayout>{children}</PublicLayout>
    </>
  );
};

export default PublicRoute;
