import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonTD = () => {
  return (
    <tr>
      <td>
        <Skeleton count={1} height={30} width={300} />
      </td>
    </tr>
  );
};

interface TopOfferTeamsProps {
  teams: any;
  subTable: string;
  sanitizeFunction?: any;
  isLoading: boolean;
}

const TopOfferTeams = ({
  teams,
  subTable,
  sanitizeFunction,
  isLoading,
}: TopOfferTeamsProps) => {
  return (
    <div className="card bg-card-light px-4 shadow-lg">
      <div className="flex flex-row items-center gap-20 text-zinc-500">
        <div className="text-[40px] font-bold">Top Teams</div>
        <span
          className={
            subTable === "Volume"
              ? "text-center text-sm font-black text-success"
              : "text-center text-sm font-black text-accent"
          }
        >
          {subTable}
        </span>
      </div>
      <table className="table-compact table">
        <tbody>
          {isLoading
            ? Array.from({ length: 10 }).map((_, index) => (
                <SkeletonTD key={index} />
              ))
            : teams.map((team: any, index: number) => (
                <tr key={index}>
                  <td className="font semibold text-lg text-black">
                    {team.companyName}
                  </td>
                  <td className="text-xxxl font-bold text-secondary">
                    {sanitizeFunction
                      ? sanitizeFunction(team.amount)
                      : team.amount}
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopOfferTeams;
