import { useState } from "react";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import FloatingInput from "../fields/FloatingInputText";
import Toggle from "../buttons/ToggleX";
import { TeamPageData } from "src/interfaces/TeamPageData";
import AdminApi from "src/api/admin";
import ButtonBlock from "./ButtonBlock";

interface NewTeamMemberModalProps {
  team: TeamPageData;
  fetchTeam: () => void;
}

const NewTeamMemberModal = ({ team, fetchTeam }: NewTeamMemberModalProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const { id } = team.team;

  const initialFormData: {
    firstName: string;
    lastName: string;
    email: string;
    canPurchase: boolean;
    canSkipTrace: boolean;
    canSequence: boolean;
    canTitle: boolean;
    canOffer: boolean;
    canInvite: boolean;
  } = {
    firstName: "",
    lastName: "",
    email: "",
    canPurchase: true,
    canSkipTrace: true,
    canSequence: true,
    canTitle: true,
    canOffer: true,
    canInvite: true,
  };

  const [formData, setFormData] = useState(initialFormData);

  const initialFormErrors = {
    firstName: "",
    lastName: "",
    email: "",
  };

  interface FormErrors {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
  }

  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormChangeBoolean = (e: any) => {
    const { name } = e.target;
    setFormData({ ...formData, [name]: !formData[name as keyof {}] });
  };

  const validate: any = (data: any) => {
    const errors: {
      firstName?: string;
      lastName?: string;
      email?: string;
    } = {};
    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!data.firstName) {
      errors.firstName = "First name is required";
    }
    if (!data.lastName) {
      errors.lastName = "Last name is required";
    }
    if (!data.email) {
      errors.email = "Email is required";
    } else if (!regexEmail.test(data.email)) {
      errors.email = "Email is not a valid format";
    }
    return errors;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setFormErrors(initialFormErrors);
    let errors = validate(formData);
    if (errors.firstName || errors.lastName || errors.email) {
      return setFormErrors(errors);
    } else {
      setFormErrors(initialFormErrors);
      setOnSubmitting(true);
      try {
        if (id) {
          const result = await AdminApi.addTeamMember(id, {
            ...formData,
            fullName: `${formData.firstName} ${formData.lastName}`,
            teamID: id,
          });
          if (result) {
            setFormData(initialFormData);
            setAlert({
              display: true,
              message: `${formData.firstName} ${formData.lastName} has been invited`,
              type: "success",
            });
          }
        }
      } catch (err: any) {
        console.error(err);
        setAlert({
          display: true,
          message: err?.message || "Unable to invite new member",
          type: "error",
        });
      } finally {
        fetchTeam();
        setOnSubmitting(false);
        setShowModal(false);
      }
    }
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  return (
    <div className="flex w-full items-center justify-center px-3">
      <form className="mb-1 w-10/12">
        <div className="mb-3 flex w-full items-center justify-center">
          <div className="w-full sm:w-2/3">
            <div className="my-3">
              <FloatingInput
                name={"firstName"}
                type={"text"}
                value={formData.firstName}
                onChange={handleFormChange}
                error={formErrors.firstName}
                label={"First Name"}
              />
            </div>
            <div className="my-3">
              <FloatingInput
                name={"lastName"}
                type={"text"}
                value={formData.lastName}
                onChange={handleFormChange}
                error={formErrors.lastName}
                label={"Last Name"}
              />
            </div>
            <div className="my-3">
              <FloatingInput
                name={"email"}
                type={"text"}
                value={formData.email}
                onChange={handleFormChange}
                error={formErrors.email}
                label={"Email"}
              />
            </div>
          </div>
        </div>
        <div className="mb-2 flex columns-3 items-center justify-around">
          <div className="flex basis-1/3 columns-1 flex-col items-center justify-center">
            <div>
              <label
                htmlFor="newcanPurchase"
                className="block text-text-dark dark:text-text-light"
              >
                Purchase
              </label>
            </div>
            <div>
              <Toggle
                title="canPurchase"
                name="canPurchase"
                className="toggle-secondary toggle toggle-md block"
                checked={formData.canPurchase}
                onChange={handleFormChangeBoolean}
              ></Toggle>
            </div>
          </div>
          <div className="flex basis-1/3 columns-1 flex-col items-center justify-center">
            <div>
              <label
                htmlFor="newCanSkipTrace"
                className="block text-text-dark dark:text-text-light"
              >
                Skip Trace
              </label>
            </div>
            <div>
              <Toggle
                title="canSkipTrace"
                name="canSkipTrace"
                className="toggle-secondary toggle toggle-md block"
                checked={formData.canSkipTrace}
                onChange={handleFormChangeBoolean}
              ></Toggle>
            </div>
          </div>
          <div className="flex basis-1/3 columns-1 flex-col items-center justify-center">
            <div>
              <label
                htmlFor="newCanTitle"
                className="block text-text-dark dark:text-text-light"
              >
                Title
              </label>
            </div>
            <div>
              <Toggle
                title="canTitle"
                name="canTitle"
                className="toggle-secondary toggle toggle-md block"
                checked={formData.canTitle}
                onChange={handleFormChangeBoolean}
              ></Toggle>
            </div>
          </div>
        </div>
        <div className="mb-2 flex columns-3 items-center justify-around">
          <div className="flex basis-1/3 columns-1 flex-col items-center justify-center">
            <div>
              <label
                htmlFor="newcanPurchase"
                className="block text-text-dark dark:text-text-light"
              >
                Sequence
              </label>
            </div>
            <div>
              <Toggle
                title="canSequence"
                name="canSequence"
                className="toggle-secondary toggle toggle-md block"
                checked={formData.canSequence}
                onChange={handleFormChangeBoolean}
              ></Toggle>
            </div>
          </div>
          <div className="flex basis-1/3 columns-1 flex-col items-center justify-center">
            <label
              htmlFor="newCanOffer"
              className="block text-text-dark dark:text-text-light"
            >
              Offer
            </label>
            <Toggle
              title="canOffer"
              name="canOffer"
              className="toggle-secondary toggle toggle-md block"
              checked={formData.canOffer}
              onChange={handleFormChangeBoolean}
            ></Toggle>
          </div>
          <div className="flex basis-1/3 columns-1 flex-col items-center justify-center">
            <label
              htmlFor="newCanInvite"
              className="block text-text-dark dark:text-text-light"
            >
              Invite
            </label>
            <Toggle
              title="canInvite"
              name="canInvite"
              className="toggle-secondary toggle toggle-md block"
              checked={formData.canInvite}
              onChange={handleFormChangeBoolean}
            ></Toggle>
          </div>
        </div>
        <div className="mt-4">
          <ButtonBlock
            submitting={onSubmitting}
            handleSubmit={handleSubmit}
            onCancel={handleCancel}
            submitLabel={"Invite"}
            disabled={onSubmitting}
          />
        </div>
      </form>
    </div>
  );
};

export default NewTeamMemberModal;
