import React, { useState } from "react";

interface ToggleXProps {
  title: string;
  name: string;
  checked: boolean;
  onChange: any;
  className?: string;
  disabled?: boolean;
  id?: string;
}

const ToggleX = ({
  title,
  name,
  checked,
  onChange,
  className,
  disabled,
  id,
}: ToggleXProps) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = () => {
    setIsChecked(!isChecked);
    onChange();
  };

  return (
    <>
      <div className="flex items-center justify-center gap-2">
        <label className="flex cursor-pointer select-none items-center gap-1">
          <div className="relative">
            <input
              name={name}
              title={title}
              type="checkbox"
              checked={checked}
              onChange={handleChange}
              className="sr-only"
              disabled={disabled}
            />
            <div
              className={`box block h-[24px] w-[42px] rounded-full ${
                checked ? "bg-primary" : "bg-neutral-400 dark:bg-neutral-500"
              }`}
            ></div>
            <div
              className={`absolute left-[3px] top-[3px] flex h-[18px] w-[18px] items-center justify-center rounded-full bg-white transition ${
                checked ? "translate-x-full" : ""
              }`}
            ></div>
          </div>
        </label>
      </div>
    </>
  );
};

export default ToggleX;
