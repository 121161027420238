import React, { useEffect, useState } from "react";
import { adminApi } from "src/api";
import DashInterestedRow from "src/components/dash/DashInterestedRow";
import DashTeamRow from "src/components/dash/DashTeamRow";
import LoadingSpinner from "src/components/loading/LoadingSpinner";
import LoadingTable from "src/components/loading/LoadingTable";
import { ToolbarItem } from "src/components/toolbar/ToolbarItem";
import { Team } from "src/interfaces/team";

export interface DashTeam {
  id: number;
  companyName: string;
  drivesCount: number;
  propertiesCount: number;
  propertiesAddedCount: number;
}

interface Totals {
  totalDrives: number;
  totalProperties: number;
  totalPropertiesAdded: number;
}

const Dash = () => {
  const [dashTeams, setDashTeams] = useState<DashTeam[] | null>(null);
  const [dashInterestedTeams, setDashInterestedTeams] = useState<Team[] | null>(
    null
  );

  const [isActiveLinks, setIsActiveLinks] = useState({
    "Dash Teams": true,
    "Dash Interested": false,
  });

  const handleActive = (title: string) => {
    setIsActiveLinks({
      "Dash Teams": false,
      "Dash Interested": false,
      [title]: true,
    });
  };

  const [totals, setTotals] = useState<Totals>({
    totalDrives: 0,
    totalProperties: 0,
    totalPropertiesAdded: 0,
  });

  const fetchDashTeams = async () => {
    const resp = await adminApi.getDashTeams();
    setDashTeams(resp);
    calculateTotals(resp);
  };

  const fetchDashInterestedTeams = async () => {
    const resp = await adminApi.getDashInterestedTeams();
    setDashInterestedTeams(resp);
  };

  const calculateTotals = (teams: DashTeam[]) => {
    const totals = teams.reduce(
      (acc, team) => {
        acc.totalDrives += team.drivesCount;
        acc.totalProperties += team.propertiesCount;
        acc.totalPropertiesAdded += team.propertiesAddedCount;
        return acc;
      },
      { totalDrives: 0, totalProperties: 0, totalPropertiesAdded: 0 }
    );
    setTotals(totals);
  };

  useEffect(() => {
    fetchDashTeams();
    fetchDashInterestedTeams();
  }, []);

  const [currentDashVersion, setCurrentDashVersion] = useState<string>("");

  const [isFetchingCurrentVersion, setIsFetchingCurrentVersion] =
    useState<boolean>(true);

  const fetchCurrentVersion = async () => {
    try {
      setIsFetchingCurrentVersion(true);
      const res = await adminApi.getCurrentDashVersion();
      setCurrentDashVersion(res);
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsFetchingCurrentVersion(false);
    }
  };

  useEffect(() => {
    fetchCurrentVersion();
  }, []);

  const renderTableContent = () => {
    if (isActiveLinks["Dash Teams"]) {
      if (dashTeams === null) {
        return <LoadingTable label={"Teams"} />;
      } else if (dashTeams.length === 0) {
        return <div>No Dash Teams available.</div>;
      } else {
        return (
          <table className={"table w-full shadow-xl"}>
            <thead>
              <tr className="border-b-2 border-secondary text-secondary">
                <th className="select-none rounded-tl-lg bg-card-light dark:bg-card-dark">
                  ID
                </th>
                <th className="select-none bg-card-light  dark:bg-card-dark">
                  Company Name
                </th>
                <th className="select-none bg-card-light  dark:bg-card-dark">
                  Drives
                </th>
                <th className="select-none bg-card-light  dark:bg-card-dark">
                  Properties Found
                </th>
                <th className="select-none rounded-tr-lg bg-card-light dark:bg-card-dark">
                  Properties Added
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <td className="bg-white dark:bg-card-dark">
                  <div className="flex gap-1">
                    <span className="font-semibold text-zinc-500 dark:text-zinc-300">
                      {dashTeams.length}
                    </span>
                    <span className="font-light text-zinc-500 dark:text-zinc-300">
                      {dashTeams.length > 1 ? "Teams" : "Team"}
                    </span>
                  </div>
                </td>
                <td className="bg-white dark:bg-card-dark"></td>
                <td className="bg-white text-base font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                  {totals.totalDrives}
                </td>
                <td className="bg-white text-base font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                  {totals.totalProperties}
                </td>
                <td className="bg-white text-base font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                  {totals.totalPropertiesAdded}
                </td>
              </tr>
              {dashTeams.map((dashTeam, index) => (
                <DashTeamRow dashTeam={dashTeam} key={index} />
              ))}
            </tbody>
          </table>
        );
      }
    } else if (isActiveLinks["Dash Interested"]) {
      if (dashInterestedTeams === null) {
        return <LoadingTable label={"Interested Teams"} />;
      } else if (dashInterestedTeams.length === 0) {
        return (
          <div className="flex items-center justify-center">
            No Teams currently interested in Dash.
          </div>
        );
      } else {
        return (
          <table className={"table w-full shadow-xl"}>
            <thead>
              <tr className="border-b-2 border-secondary text-secondary">
                <th className="select-none rounded-tl-lg bg-card-light dark:bg-card-dark">
                  ID
                </th>
                <th className="select-none bg-card-light  dark:bg-card-dark">
                  Company Name
                </th>
                <th className="select-none rounded-tr-lg  bg-card-light dark:bg-card-dark">
                  Remove
                </th>
              </tr>
            </thead>
            <tbody>
              {dashInterestedTeams.map((team, index) => (
                <DashInterestedRow
                  team={team}
                  fetchDashInterestedTeams={fetchDashInterestedTeams}
                  key={index}
                />
              ))}
            </tbody>
          </table>
        );
      }
    }
  };

  return (
    <div className="">
      <div className="tabs tabs-boxed mb-1 border-[.5px] border-zinc-300 bg-white dark:border-back-dark dark:bg-card-dark">
        <ToolbarItem
          title={"Teams"}
          isActive={isActiveLinks["Dash Teams"]}
          onClick={() => handleActive("Dash Teams")}
        />
        <ToolbarItem
          title={"Interested"}
          isActive={isActiveLinks["Dash Interested"]}
          onClick={() => handleActive("Dash Interested")}
        />
        <div className="mb-1.5 ml-4 flex h-full flex-row items-center justify-start gap-2">
          <span className="text-zinc-500">Current Dash Version:</span>
          <div>
            {isFetchingCurrentVersion ? (
              <LoadingSpinner className={"text-secondary dark:text-theme1"} />
            ) : (
              <span className="font-bold text-zinc-500">
                {currentDashVersion}
              </span>
            )}
          </div>
        </div>
      </div>

      {renderTableContent()}
    </div>
  );
};

export default Dash;
