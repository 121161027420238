import React from 'react';

interface IdDisplayProps {
  ID: string | number;
  label: string;
  onClick: () => void;
  error?: boolean;
}

const IdDisplay = ({ID, label, onClick, error}: IdDisplayProps) => {

  return (
    <>
        <div className="">
            <label
              className={
                !ID || error
                  ? " relative flex cursor-pointer select-none items-center justify-center rounded-lg border border-error px-2 py-0.5 text-error dark:border-error dark:text-error font-bold"
                  : "relative flex  cursor-pointer select-none items-center justify-center  rounded-lg border border-zinc-500 px-2 py-0.5 text-text-dark dark:border-zinc-500 dark:text-text-light font-bold"
              }
              onClick={onClick}
            >
              <div
                className={
                  !ID || error
                    ? "absolute -top-[13px] rounded-md border border-error bg-card-light px-1 text-xxs dark:border-error dark:bg-card-dark"
                    : "absolute -top-[13px] rounded-md border border-zinc-500 bg-card-light px-1 text-xxs dark:border-zinc-500 dark:bg-card-dark"
                }
              >
                {label}
              </div>
              <h2 className={"mr-1 text-lg font-bold"}>{ID}</h2>
            </label>
          </div>
    </>
  )
};

export default IdDisplay;