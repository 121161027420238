import { ComponentType, Suspense, Component as ReactComponent } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

/***** Route Helpers *****/
import PublicLayout from "./layouts/PublicLayout";
import PrivateRoute from "./auth/PrivateRoute";

/***** Public Pages *****/
import Login from "./pages/public/Login";

import NotFound from "./pages/public/NotFound";

/***** Private Pages *****/
import Dashboard from "./pages/private/Dashboard";
import Teams from "./pages/private/Teams";
import Team from "./pages/private/Team";
import Reports from "./pages/private/Reports";
import Admins from "./pages/private/Admins";
import Support from "./pages/private/Support";
import Spinner from "./components/loading/Spinner";
import PublicRoute from "src/auth/PublicRoute";
import Demo from "./pages/private/Demo";
import AdminInvitationGuard from "./guards/AdminInvitationGuard";
import TokenGuard from "./contexts/TokenGuardContext";
import Settings from "./pages/private/Settings";
import Tokens from "./pages/private/Tokens";
import Migration from "./pages/private/Migration";
import OfferPortal from "./pages/public/OfferPortal";
import { OfferProvider } from "./contexts/OfferContext";
import Onboarding from "./pages/private/Onboarding";
import InvalidToken from "./pages/public/InvalidToken";
import Partners from "./pages/private/Partners";
import Dash from "./pages/private/Dash";
import Affiliates from "./pages/private/Affiliates";

export const Loader = <P extends object>(Component: ComponentType<P>) =>
  class WithLoader extends ReactComponent<P> {
    render() {
      const { ...props } = this.props;
      return (
        <Suspense fallback={<Spinner spin />}>
          <Component {...(props as P)} />
        </Suspense>
      );
    }
  };
const Router = () => {
  return (
    <>
      {/*** Public Routes ***/}
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />

        <Route
          path="/offers/key=bc0b5f1d-d70f-45da-bdc8-81ca6146990f/:currentIndex"
          element={
            <OfferProvider>
              <Helmet>
                <title>Offers | SendFuse Admin</title>
              </Helmet>
              <OfferPortal />
            </OfferProvider>
          }
        />

        <Route
          path="/login"
          element={
            <PublicRoute>
              <Helmet>
                <title>Login | SendFuse Admin</title>
              </Helmet>
              <Login />
            </PublicRoute>
          }
        />

        <Route
          path="/setup-password/:token"
          element={
            <TokenGuard>
              <Helmet>
                <title>Setup Password | SendFuse Admin</title>
              </Helmet>
              <AdminInvitationGuard />
            </TokenGuard>
          }
        />

        <Route
          path="/invalid-token"
          element={
            <PublicRoute>
              <Helmet>
                <title>Invalid Token | SendFuse Admin</title>
              </Helmet>
              <InvalidToken />
            </PublicRoute>
          }
        />

        {/*** Private Routes ***/}
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Dashboard | SendFuse Admin</title>
              </Helmet>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route
          path="/teams"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Teams | SendFuse Admin</title>
              </Helmet>
              <Teams />
            </PrivateRoute>
          }
        />

        <Route
          path="/teams/:teamID"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Team | SendFuse Admin</title>
              </Helmet>
              <Team />
            </PrivateRoute>
          }
        />

        <Route
          path="/reports"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Reports | SendFuse Admin</title>
              </Helmet>
              <Reports />
            </PrivateRoute>
          }
        />

        <Route
          path="/admins"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Admins | SendFuse Admin</title>
              </Helmet>
              <Admins />
            </PrivateRoute>
          }
        />

        <Route
          path="/partners"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Partners | SendFuse Admin</title>
              </Helmet>
              <Partners />
            </PrivateRoute>
          }
        />

        <Route
          path="/support"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Support | SendFuse Admin</title>
              </Helmet>
              <Support />
            </PrivateRoute>
          }
        />

        <Route
          path="/demo"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Demo Mode | SendFuse Admin</title>
              </Helmet>
              <Demo />
            </PrivateRoute>
          }
        />

        <Route
          path="/onboarding"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Onboarding | SendFuse Admin</title>
              </Helmet>
              <Onboarding />
            </PrivateRoute>
          }
        />

        <Route
          path="/tokens"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Tokens | SendFuse Admin</title>
              </Helmet>
              <Tokens />
            </PrivateRoute>
          }
        />

        <Route
          path="/migration"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Migration | SendFuse Admin</title>
              </Helmet>
              <Migration />
            </PrivateRoute>
          }
        />

        <Route
          path="/dash"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Dash | SendFuse Admin</title>
              </Helmet>
              <Dash />
            </PrivateRoute>
          }
        />

        <Route
          path="/affiliates"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Affiliates | SendFuse Admin</title>
              </Helmet>
              <Affiliates />
            </PrivateRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Settings | SendFuse Admin</title>
              </Helmet>
              <Settings />
            </PrivateRoute>
          }
        />

        <Route
          path="/404"
          element={
            <PublicLayout>
              <Helmet>
                <title>404 Not Found | SendFuse Admin</title>
              </Helmet>
              <NotFound />
            </PublicLayout>
          }
        />
      </Routes>
    </>
  );
};

export default Router;
