import React from "react";
import { Offer } from "src/interfaces/offer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface OffersSentProps {
  offers: Offer[];
  isLoading: boolean;
}

const OffersSent = ({ offers, isLoading }: OffersSentProps) => {
  const offersSent = offers.filter((offer: Offer) => offer.isSent);
  const percentSent =
    Math.round((offersSent.length / offers.length) * 100) || 100;

  return (
    <div className="card h-full bg-card-light px-4 shadow-lg">
      <div className="text-[40px] font-bold text-zinc-500">Mailed</div>
      <div className="mb-4 text-xxxl text-zinc-500">
        {offersSent.length} / {offers.length}
      </div>
      <div className="flex w-full items-center justify-center">
        {isLoading ? (
          <div className="flex w-full flex-col items-center justify-center">
            <Skeleton circle={true} height={200} width={200} />
          </div>
        ) : (
          <div
            className="radial-progress bg-zinc-100 text-[40px] font-bold text-primary"
            style={
              {
                "--value": percentSent,
                "--size": "12rem",
                "--thickness": "22px",
              } as React.CSSProperties
            }
          >
            {percentSent}%
          </div>
        )}
      </div>
    </div>
  );
};

export default OffersSent;
