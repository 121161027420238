import React from "react";
import FloatingInput from "./fields/FloatingInputText";
import { CreateFormErrors } from "./modals/EditRealeflowID";

interface CreateNewRealeflowIDProps {
  formData: {
    [key: string]: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: CreateFormErrors;
}

const CreateNewRealeflowID = ({
  formData,
  handleChange,
  errors,
}: CreateNewRealeflowIDProps) => {
  return (
    <div className="create-new-realeflow-id-form">
      {Object.entries(formData).map(([key, value]) => (
        <div className="my-2" key={key}>
          <FloatingInput
            name={key}
            type="text"
            value={value}
            onChange={handleChange}
            label={key}
            error={errors[key]}
          />
        </div>
      ))}
    </div>
  );
};

export default CreateNewRealeflowID;
