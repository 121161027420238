import React from "react";
import { SupportTicket } from "src/interfaces/supportTicket";
import { capitalize } from "src/helpers/parseStrings";
import AdminApi from "src/api/admin";
import useAlert from "src/hooks/useAlert";

interface TicketStatusProps {
  ticket: SupportTicket;
  fetchSupportTickets: () => void;
}

const TicketStatus = ({ ticket, fetchSupportTickets }: TicketStatusProps) => {
  const { id, status } = ticket;

  const { setAlert } = useAlert();

  const handleChange = async (e: any) => {
    const { value } = e.target;
    try {
      await AdminApi.updateSupportTicket(id, { status: value.toLowerCase() });
    } catch (err: any) {
      setAlert({
        display: true,
        message: err?.message || err || "Unable to update property status",
        type: "error",
      });
    } finally {
      return fetchSupportTickets();
    }
  };

  return (
    <>
      <div className="">
        <select
          style={{
            outline: "none",
          }}
          className={
            status === "open"
              ? "w-20 rounded-md bg-error font-bold text-text-dark"
              : "w-20 rounded-md bg-success font-bold text-text-dark"
          }
          title={"Update Ticket Status"}
          value={status}
          onChange={handleChange}
        >
          <option value={status} className="">
            {capitalize(status)}
          </option>
          {status === "open" ? (
            <option value="closed">Closed</option>
          ) : status === "closed" ? (
            <option value="open">Open</option>
          ) : null}
        </select>
      </div>
    </>
  );
};

export default TicketStatus;
