import { Dispatch, SetStateAction, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import useModal from "src/hooks/useModal";
import InviteNewTeamModal from "../modals/InviteNewTeamModal";

interface ToolbarItemProps {
  label: string;
  name: string;
  checked: boolean;
  setChecked: any;
}

const ToolbarItem = ({
  label,
  name,
  checked,
  setChecked,
}: ToolbarItemProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  return (
    <div className="flex items-center justify-center gap-2">
      <label className="flex cursor-pointer select-none items-center gap-1">
        <div className="text-text-dark dark:text-text-light">{label}</div>
        <div className="relative">
          <input
            name={name}
            type="checkbox"
            checked={checked}
            onChange={handleChange}
            className="sr-only"
          />
          <div
            className={`box block h-[24px] w-[42px] rounded-full ${
              checked ? "bg-primary" : "bg-back-light dark:bg-back-dark"
            }`}
          ></div>
          <div
            className={`absolute left-[3px] top-[3px] flex h-[18px] w-[18px] items-center justify-center rounded-full bg-white transition ${
              checked ? "translate-x-full" : ""
            }`}
          ></div>
        </div>
      </label>
    </div>
  );
};

interface TeamsToolbarProps {
  checkedItems: {
    active: boolean;
    demo: boolean;
    vacation: boolean;
    payments: boolean;
    staff: boolean;
    recent: boolean;
  };
  setCheckedItems: Dispatch<
    SetStateAction<{
      active: boolean;
      demo: boolean;
      vacation: boolean;
      payments: boolean;
      staff: boolean;
      recent: boolean;
    }>
  >;
  numTeams: number;
}

const TeamsToolbar = ({
  checkedItems,
  setCheckedItems,
  numTeams,
}: TeamsToolbarProps) => {
  const navigate = useNavigate();

  const { openModalWith } = useModal();

  const updateCheckbox = (name: string, value: boolean) => {
    const updatedCheckedItems = {
      ...checkedItems,
      [name]: value,
    };
  
    setCheckedItems(updatedCheckedItems);
  
    // Convert checkedItems to an object with string values
    const stringCheckedItems: Record<string, string> = {};
    for (const [key, boolValue] of Object.entries(updatedCheckedItems)) {
      stringCheckedItems[key] = boolValue.toString();
    }
  
    // Create a new query string based on the updated checkedItems
    const queryString = new URLSearchParams(stringCheckedItems).toString();
  
    // Update the URL
    navigate(`/teams?${queryString}`, { replace: true });
  };

  const handleNewTeam = () => {
    openModalWith({
      title: "Invite New Team",
      body: <InviteNewTeamModal />,
      hideButtons: true,
    })
  }
  
  
  

  return (
    <div className="tabs tabs-boxed mb-1 gap-12 border-[.5px] border-zinc-300 dark:border-back-dark bg-white px-6 py-2 dark:bg-card-dark">
      <ToolbarItem
        label="Active"
        name="active"
        checked={checkedItems.active}
        setChecked={(value: boolean) => updateCheckbox("active", value)}
      />
      <ToolbarItem
        label="Demo"
        name="demo"
        checked={checkedItems.demo}
        setChecked={(value: boolean) => {
          return updateCheckbox("demo", value);
        }}
      />
      <ToolbarItem
        label="Vacation"
        name="vacation"
        checked={checkedItems.vacation}
        setChecked={(value: boolean) => updateCheckbox("vacation", value)}
      />
      <ToolbarItem
        label="Payments"
        name="payments"
        checked={checkedItems.payments}
        setChecked={(value: boolean) => updateCheckbox("payments", value)}
      />
      <ToolbarItem
        label="Staff"
        name="staff"
        checked={checkedItems.staff}
        setChecked={(value: boolean) => updateCheckbox("staff", value)}
      />
      <ToolbarItem
        label="Recent"
        name="recent"
        checked={checkedItems.recent}
        setChecked={(value: boolean) => updateCheckbox("recent", value)}
      />
      <div className="flex gap-1">
        <span className="font-semibold text-zinc-500 dark:text-zinc-300">
          {numTeams}
        </span>
        <span className="font-light text-zinc-500 dark:text-zinc-300">
          {numTeams > 1 ? "Teams" : "Team"}
        </span>
      </div>
      <div>
        <button className="btn btn-secondary btn-xs text-white" onClick={handleNewTeam}>
          + New Team
        </button>
      </div>
    </div>
  );
};

export default TeamsToolbar;
