import React from "react";
import { Link } from "react-router-dom";

const InvalidToken = () => {
  return (
    <div className="mt-2 flex h-full w-full items-center justify-center text-text-light">
      <div className="flex columns-1 flex-col items-center justify-center">
        <h2 className="text-[36px]">Invalid token expired</h2>
        <div className="mb-2 text-center">
          This given link is invalid or expired.
        </div>
        <div>
          <Link to="/login">
            <button className="btn-sm btn">Login</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InvalidToken;
