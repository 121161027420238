import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface EditButtonProps {
  onClick?: (id?: number | undefined) => void;
}

const EditButton = ({ onClick }: EditButtonProps) => {
  return (
    <>
      <label
        className="btn btn-sm btn-circle cursor-pointer bg-card-dark text-text-light hover:bg-black dark:bg-back-dark  dark:text-gray-500 hover:dark:bg-black "
        onClick={() => onClick && onClick()}
      >
        <FontAwesomeIcon icon={faPenToSquare} />
      </label>
    </>
  );
};

export default EditButton;
