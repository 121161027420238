interface ToolbarItemProps {
  isActive: boolean;
  title: string;
  onClick: (e: any) => void;
}

export const ToolbarItem = ({ isActive, title, onClick }: ToolbarItemProps) => {
  return (
    <>
      <div
        className={
          isActive
            ? "tab rounded-md bg-secondary font-bold text-text-light ease-in-out"
            : "tab font-semibold text-icon-light ease-in-out hover:text-text-dark hover:dark:text-text-light"
        }
        onClick={onClick}
      >
        {title}
      </div>
    </>
  );
};
