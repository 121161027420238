import Property from "src/interfaces/property";

interface ValueButtonProps {
  property: Property;
}

const ValueButton = ({ property }: ValueButtonProps) => {
  const { appraisedValue } = property;

  return (
    <>
      <div
        style={{
          textTransform: "none",
        }}
        tabIndex={0}
        className="btn-ghost btn text-sm text-text-dark hover:bg-hover-card-light dark:text-text-light hover:dark:bg-hover-card-dark md:text-base"
      >
        <span>$</span>
        <span>
          {" "}
          {appraisedValue
            ? appraisedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : 0}
        </span>
      </div>
    </>
  );
};

export default ValueButton;
