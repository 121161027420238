import { PropsWithChildren } from "react";
import { ModalProvider } from "src/contexts/ModalContext";

/***** Context Provider *****/
import { AdminProvider } from "src/contexts/private/AdminContext";
import { AppProvider } from "src/contexts/private/AppContext";

interface PrivateProviderProps {}

const PrivateProvider = ({
  children,
}: PropsWithChildren<PrivateProviderProps>) => {
  return (
    <AdminProvider>
      <AppProvider>
        <ModalProvider>{children}</ModalProvider>
      </AppProvider>
    </AdminProvider>
  );
};

export default PrivateProvider;
