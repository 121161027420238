import React, { useState } from "react";
import { adminApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import { TeamPageData } from "src/interfaces/TeamPageData";
import CreateNewRealeflowID from "../CreateNewRealeflowID";
import FloatingInput from "../fields/FloatingInputText";
import JSONDataViewer from "../json/JSONDataViewer";
import { CreateFormErrors } from "./EditRealeflowID";
import ButtonBlock from "./ButtonBlock";

interface SearchRealeflowModalProps {
  team: TeamPageData;
  fetchTeam: () => void;
}

const SearchRealeflowModal = ({
  team,
  fetchTeam,
}: SearchRealeflowModalProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  interface FormState {
    realeflowID: number;
  }

  const initialFormState = {
    realeflowID: team.team.realeflowID || 0,
  };

  const initialFormErrors: {
    realeflowID: string;
  } = {
    realeflowID: "",
  };

  interface FormErrors {
    realeflowID: string | null;
  }

  const [formData, setFormData] = useState<FormState>(initialFormState);
  const [onSubmitting, setSubmitting] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const [isRealeflowIDActive, setIsRealeflowIDActive] = useState<boolean>(true);

  const [realeflowTestResponse, setRealeflowTestResponse] = useState<{}>();

  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    setFormData({ ...formData, [name]: checked || value });
  };

  const validate: any = (data: any) => {
    const errors: {
      realeflowID?: string;
    } = {};
    if (!data.realeflowID) {
      errors.realeflowID = "A Realeflow ID is required";
    }
    return errors;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let errors = validate(formData);
    if (errors.companyName || errors.teamLeaderEmail) {
      return setFormErrors(errors);
    } else {
      setSubmitting(true);
      try {
        const { realeflowID } = formData;
        await adminApi.updateTeam({
          ...team.team,
          realeflowID,
        });
        setShowModal(false);
        setAlert({
          display: true,
          message: `${team.team.companyName}'s RealeflowID has been updated to ${realeflowID}`,
          type: "success",
        });
      } catch (err: any) {
        setAlert({
          display: true,
          message: "Team has been updated",
          type: "success",
        });
      } finally {
        fetchTeam();
        setShowModal(false);
      }
      setSubmitting(false);
    }
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  const handleTestClick = async () => {
    try {
      const res: {} | null = await adminApi.testRealeflowID(team.team.id);
      if (res) {
        setRealeflowTestResponse(res);
      } else {
        const errors = {
          realeflowID: "RealeflowID is not connected to an active account",
        };
        setFormErrors(errors);
        setIsRealeflowIDActive(false);
      }
    } catch (e: any) {
      console.error(e);
    } finally {
    }
  };

  const [createFormData, setCreateFormData] = useState({
    FirstName: team.user.firstName || "",
    LastName: team.user.lastName || "",
    Email: team.user.email || "",
    Address: team.teamSettings.mailingAddressLine1 || "",
    City: team.teamSettings.mailingAddressCity || "",
    State: team.teamSettings.mailingAddressState || "",
    ZipCode: team.teamSettings.mailingAddressZip || "",
    Phone: team.user.phoneNumber ? team.user.phoneNumber.toString() : "",
  });

  const handleCreateFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreateFormData({ ...createFormData, [e.target.name]: e.target.value });
  };

  const initialCreateFormErrors: CreateFormErrors = {
    FirstName: "",
    LastName: "",
    Email: "",
    Address: "",
    City: "",
    State: "",
    ZipCode: "",
    Phone: "",
  };

  const [createFormErrors, setCreateFormErrors] = useState(
    initialCreateFormErrors
  );

  const validateCreateForm = (data: typeof createFormData) => {
    const errors: typeof initialCreateFormErrors = {
      ...initialCreateFormErrors,
    };

    if (!data.FirstName.trim()) errors.FirstName = "First name is required";
    if (!data.LastName.trim()) errors.LastName = "Last name is required";
    if (!data.Email.trim()) errors.Email = "Email is required";
    if (!data.Address.trim()) errors.Address = "Address is required";
    if (!data.City.trim()) errors.City = "City is required";
    if (!data.State.trim()) errors.State = "State is required";
    if (!data.ZipCode.trim()) errors.ZipCode = "Zipcode is required";
    if (data.ZipCode.trim().length !== 5)
      errors.ZipCode = "Zipcode must be 5 digits";
    if (!data.Phone.trim()) errors.Phone = "Phone number is required";
    if (data.Phone.trim().length !== 10)
      errors.Phone = "Phone number must be 10 digits";

    // Additional validations like email format, phone number format, etc., can be added here

    return errors;
  };

  const handleCreateNewAccount = async () => {
    const errors = validateCreateForm(createFormData);
    setCreateFormErrors(errors);

    if (Object.values(errors).some((error) => error !== "")) {
      return; // Stop the function if there are errors
    }

    try {
      setSubmitting(true);
      const res = await adminApi.createRealeflowID({
        ...createFormData,
        ExternalAccountId: team.team.keapID.toString(),
        PlanId: "896",
        teamID: team.team.id,
      });
      if (res.success) {
        fetchTeam();
        setShowModal(false);
        setSubmitting(false);
        setAlert({
          display: true,
          type: "success",
          message: "Team's RealeflowID was successfully created",
        });
      } else {
        const errors = {
          realeflowID: res.error,
        };
        setFormErrors(errors);
        setSubmitting(false);
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <>
      <div>
        <div className="mb-3 flex w-full items-center justify-center">
          <div className="w-full sm:w-2/3">
            <div className="relative my-3">
              <FloatingInput
                name={"realeflowID"}
                type={"text"}
                value={formData.realeflowID.toString()}
                onChange={handleChange}
                error={formErrors.realeflowID}
                label={"Realeflow ID"}
              />
              <div className="absolute right-[12px] top-[12px]">
                <button
                  className="btn-accent btn-sm btn"
                  onClick={handleTestClick}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="px-28">
          {!isRealeflowIDActive && (
            <CreateNewRealeflowID
              formData={createFormData}
              handleChange={handleCreateFormChange}
              errors={createFormErrors}
            />
          )}
        </div>
        <div className="my-2 px-24">
          {realeflowTestResponse && (
            <JSONDataViewer data={realeflowTestResponse} />
          )}
        </div>
        <div className="mt-4">
          <ButtonBlock
            submitting={onSubmitting}
            handleSubmit={
              isRealeflowIDActive ? handleSubmit : handleCreateNewAccount
            }
            onCancel={handleCancel}
            submitLabel={isRealeflowIDActive ? "Save" : "Create"}
            disabled={onSubmitting}
          />
        </div>
      </div>
    </>
  );
};

export default SearchRealeflowModal;
