import { ChangeEventHandler, ReactNode } from "react";
import classNames from "classnames";

export interface Option {
  label: string;
  value: string | number;
}

interface SelectInputProps {
  name: string;
  options: Option[];
  title?: string;
  label?: string | ReactNode;
  type?: string;
  placeholder?: string;
  value?: string;
  onChange?: ChangeEventHandler;
  error?: string | null;
  labelProps?: {
    className?: string;
    htmlFor?: string;
  };
  inputProps?: {
    className?: string;
  };
  errorProps?: {
    className?: string;
  };
}

const SelectInput = ({
  name,
  label,
  title,
  options,
  placeholder,
  value,
  error,
  labelProps,
  inputProps,
  errorProps,
  onChange,
}: SelectInputProps) => {
  const labelClassName = classNames(
    "block uppercase text-card-dark dark:text-text-light text-xs font-extrabold mb-1 text-left px-1",
    labelProps?.className
  );

  const inputClassName = classNames(
    {
      "border-2 border-error px-3 py-3 placeholder-gray-400 text-text-dark dark:text-text-light font-bold placeholder:font-normal bg-white dark:bg-back-dark rounded text-sm shadow focus:outline-none w-full h-[48px]":
        !!error,
      "border-0 px-3 py-3 placeholder-gray-400 text-text-dark dark:text-text-light font-bold placeholder:font-normal bg-white dark:bg-back-dark rounded text-xm shadow focus:outline-none focus:ring ring-secondary w-full h-[48px]":
        !error,
    },
    inputProps?.className
  );

  const errorClassName = classNames(
    "absolute font-semibold text-error text-xs md:text-sm italic ml-1",
    errorProps?.className
  );

  return (
    <>
      {label && (
        <label htmlFor={name} {...labelProps} className={labelClassName}>
          {label}
        </label>
      )}
      <select
        title={title}
        name={name}
        className={inputClassName}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      >
        {options.map((opt: Option) => (
          <option key={`op-${opt.value}`} value={opt.value}>
            {opt.label}
          </option>
        ))}
        ;
      </select>
      {error && (
        <p {...errorProps} className={errorClassName}>
          {error}
        </p>
      )}
    </>
  );
};

export default SelectInput;
