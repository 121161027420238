/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { TeamPageData } from "src/interfaces/TeamPageData";
import stripeImg from "../../assets/images/StripeLogoTransparent.png";
import ButtonLoader from "../loading/ButtonLoader";
import {
  faArrowsRotate,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import CircularActionButton from "../buttons/CircularActionButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IdDisplay from "./IdDisplay";
import { numTimestampToMonthDayYear } from "src/helpers/parseEpochs";
import useModal from "src/hooks/useModal";
import EditStripeIDModal from "../modals/EditStripeIDModal";
import ReactJson from "react-json-view";

interface StripeCardProps {
  team: TeamPageData;
  fetchTeam: () => void;
  stripeData: any;
  fetchStripeData: () => void;
  isLoadingStripeData: boolean;
  errors: any;
}

export interface StripeData {}

const StripeCard = ({
  team,
  fetchTeam,
  stripeData,
  fetchStripeData,
  isLoadingStripeData,
  errors,
}: StripeCardProps) => {
  const { openModalWith } = useModal();

  const handleClickSubID = () => {
    openModalWith({
      title: "Edit StripeID",
      body: (
        <EditStripeIDModal
          team={team}
          fetchTeam={fetchTeam}
          fetchStripeData={fetchStripeData}
        />
      ),
      hideButtons: true,
    });
  };

  // const handleEditStripe = () => {};

  useEffect(() => {
    fetchStripeData();
  }, []);

  const shortenSubID = (id: string) => {
    return id
      ? id.slice(0, 8).concat("....").concat(id.slice(-4))
      : "! No SubID";
  };

  return (
    <>
      <div className="card w-full rounded-lg bg-white px-6 py-4 shadow-lg dark:bg-card-dark">
        <div className="flex w-full items-center justify-between">
          <div className="flex w-24 items-center">
            <img src={stripeImg} alt={"Stripe Logo"} className="w-16" />
          </div>
          <IdDisplay
            ID={shortenSubID(team.stripeAccount.stripeSubscriptionID)}
            label="SubscriptionID"
            onClick={handleClickSubID}
            error={
              errors.missingSubscriptionID.isError ||
              errors.subscriptionMatch.isError
            }
          />
          <div className="flex w-24 items-center justify-end gap-2">
            <CircularActionButton
              icon={faArrowsRotate}
              onClick={fetchStripeData}
            />
            {/* <CircularActionButton
              icon={faPenToSquare}
              onClick={handleEditStripe}
            /> */}
          </div>
        </div>
        {isLoadingStripeData ? (
          <div className="flex w-full items-center justify-center">
            <ButtonLoader className="m-4 h-8 w-8" fill={"#665AFF"} />
          </div>
        ) : (
          <>
            <div className="mt-0.5 flex w-full items-center justify-center">
              <div className="mb-4 mt-2 flex w-full select-none items-center justify-center">
                <div className="flex w-full items-center justify-between gap-2">
                  <div
                    className={`badge h-8 rounded-lg border  border-sky-600 bg-sky-400  bg-opacity-20 
                        font-bold text-sky-600 dark:border-none dark:bg-opacity-30 dark:text-sky-400`}
                  >
                    Created {numTimestampToMonthDayYear(stripeData?.created)}
                  </div>
                  {stripeData?.cancel_at_period_end && (
                    <div
                      className={`badge h-8 rounded-lg border  border-yellow-600 bg-yellow-400  bg-opacity-20 
                        font-bold text-yellow-600 dark:border-none dark:bg-opacity-30 dark:text-yellow-400`}
                    >
                      Cancels{" "}
                      {numTimestampToMonthDayYear(
                        stripeData?.current_period_end
                      )}
                    </div>
                  )}
                  <div
                    className={`badge h-8 rounded-lg border bg-opacity-20  font-bold dark:border-none  dark:bg-opacity-30 ${
                      stripeData?.status === "active"
                        ? "border-green-600 bg-green-400 text-green-600 dark:text-green-400"
                        : stripeData?.status === "trialing"
                        ? "border-yellow-600 bg-yellow-400 text-yellow-600 dark:text-yellow-400"
                        : "border-red-600 bg-red-400 text-red-600 dark:text-red-400"
                    }`}
                  >
                    {stripeData?.status.toUpperCase()}
                  </div>
                </div>
              </div>
            </div>
            {(!stripeData?.cancel_at_period_end ||
              stripeData?.status !== "canceled") && (
              <div className="flex columns-1 flex-col gap-2">
                <div className="stats bg-back-light shadow dark:bg-back-dark">
                  <div className="stat">
                    <div className="stat-title text-base-100 dark:text-gray-400">
                      Next Payment
                    </div>
                    <div className="stat-value text-base-100 dark:text-gray-400">
                      ${stripeData?.plan?.amount / 100}
                    </div>
                    <div className="stat-desc text-base-100 dark:text-gray-400">
                      {numTimestampToMonthDayYear(
                        stripeData?.current_period_end
                      )}
                    </div>
                  </div>
                </div>
                <ReactJson
                  src={stripeData}
                  theme={"colors"}
                  collapsed={true}
                  collapseStringsAfterLength={120}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  name={false}
                  style={{
                    fontSize: "0.75rem",
                    padding: "1rem",
                    borderRadius: "0.5rem",
                  }}
                />
              </div>
            )}
            <div className="mt-4 flex columns-1 flex-col">
              {Object.values(errors).some((e: any) => e.isError) &&
                Object.values(errors)
                  .filter((e: any) => e.isError)
                  .map((error: any, index: number) => (
                    <div className="mb-1 flex items-center justify-center gap-2 text-error">
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        className="h-4 w-4"
                      />
                      <p key={index}>{error.errorMessage}</p>
                    </div>
                  ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default StripeCard;
