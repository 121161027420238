import { PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";

/***** Context Provider *****/
import { ThemeProvider } from "src/contexts/ThemeContext";
import { AuthProvider } from "src/contexts/AuthContext";
import { AlertProvider } from "src/contexts/AlertContext";
import { SpinnerProvider } from "src/contexts/SpinnerContext";
import { ModalProvider } from "src/contexts/ModalContext";


interface AppProviderProps {}

const AppProvider = ({ children }: PropsWithChildren<AppProviderProps>) => (
  <ThemeProvider>
    <SpinnerProvider>
      <ModalProvider>
        <BrowserRouter>
          <AlertProvider>
            <AuthProvider>{children}</AuthProvider>
          </AlertProvider>
        </BrowserRouter>
      </ModalProvider>
    </SpinnerProvider>
  </ThemeProvider>
);

export default AppProvider;
