function capitalize(str: string) {
  if(typeof str !== "string" || str === "" || str === null || str === undefined) return "";
  let arr = str.split("");
  arr[0] = arr[0].toUpperCase();
  return arr.join("");
}

function addCommas( num: number | string) {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export { capitalize, addCommas };
