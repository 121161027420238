import React from "react";
import { Link, useLocation } from "react-router-dom";
import logoLight from "src/assets/images/SendFuse_Logo_Light.png";
import logoDark from "src/assets/images/SendFuse_Logo_Dark.png";

import "./SideNav.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGaugeHigh,
  faArrowRightFromBracket,
  faHeadset,
  faUsers,
  faUserGear,
  faGamepad,
  faGear,
  faCoins,
  faDove,
  faAddressCard,
  faCar,
  faFile,
  faCircleNodes,
} from "@fortawesome/free-solid-svg-icons";
import useAuth from "src/hooks/useAuth";
import useTheme from "src/hooks/useTheme";
import useAdminContext from "src/hooks/private/useAdminContext";

interface SideBarNavProps {
  props?: any;
}

const SideBarNav = ({ props }: SideBarNavProps) => {
  const { logout } = useAuth();
  const { mode } = useTheme();
  const { admin } = useAdminContext();

  const location = useLocation();

  const isActiveRoute = (route: string) => {
    // Ensure both the route and pathname have a trailing slash for consistent comparison
    const formattedRoute = route.endsWith("/") ? route : `${route}/`;
    const formattedPathname = location.pathname.endsWith("/")
      ? location.pathname
      : `${location.pathname}/`;
    return formattedPathname === formattedRoute;
  };

  return (
    <>
      <nav className="fixed bottom-0 top-0 w-72 bg-card-light px-6 py-4 dark:bg-card-dark">
        <div>
          <Link to="/dashboard">
            <div className="mx-auto items-center">
              <img
                src={mode === "light" ? logoLight : logoDark}
                alt="SendFuse Logo"
                className="my-2"
              />
            </div>
          </Link>
          <hr className="my-4 min-w-full border-icon-light dark:border-icon-dark" />

          <ul className="flex list-none flex-col">
            {/***** Dashboard Link *****/}
            {(admin?.canDashboard || admin?.securityLevel === 1) && (
              <li className="items-center">
                <Link to="/dashboard">
                  <div
                    className={
                      isActiveRoute("/dashboard")
                        ? "mb-3 rounded-lg bg-gradient-to-b from-gradient1 to-gradient2 px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark"
                        : "hover:bg-card-blend-light mb-3 rounded-lg px-10 py-3 dark:hover:bg-back-dark"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faGaugeHigh}
                      className={
                        isActiveRoute("/dashboard")
                          ? "mr-3 text-xl text-white dark:text-black"
                          : "mr-3 text-xl text-icon-light dark:text-icon-dark"
                      }
                    />
                    <span
                      className={
                        isActiveRoute("/dashboard")
                          ? "text-xl font-bold text-white dark:text-black"
                          : "text-xl font-bold text-icon-light dark:text-icon-dark "
                      }
                    >
                      {" "}
                      Dashboard
                    </span>
                  </div>
                </Link>
              </li>
            )}

            {/***** Accounts Link *****/}
            {(admin?.canTeams || admin?.securityLevel === 1) && (
              <li className="items-center">
                <Link to="/teams">
                  <div
                    className={
                      isActiveRoute("/teams")
                        ? "mb-3 rounded-lg bg-gradient-to-b from-gradient1 to-gradient2 px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark"
                        : "hover:bg-card-blend-light mb-3 rounded-lg px-10 py-3 dark:hover:bg-back-dark"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faUsers}
                      className={
                        isActiveRoute("/teams")
                          ? "mr-1.5 text-xl text-white dark:text-black"
                          : "mr-1.5 text-xl text-icon-light dark:text-icon-dark"
                      }
                    />
                    <span
                      className={
                        isActiveRoute("/teams")
                          ? "text-xl font-bold text-white dark:text-black"
                          : "text-xl font-bold text-icon-light dark:text-icon-dark "
                      }
                    >
                      {" "}
                      Teams
                    </span>
                  </div>
                </Link>
              </li>
            )}

            {/***** Admins Link *****/}
            {(admin?.canAdmins || admin?.securityLevel === 1) && (
              <li className="items-center">
                <Link to="/admins">
                  <div
                    className={
                      isActiveRoute("/admins")
                        ? "mb-3 rounded-lg bg-gradient-to-b from-gradient1 to-gradient2 px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark"
                        : "hover:bg-card-blend-light mb-3 rounded-lg px-10 py-3 dark:hover:bg-back-dark"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faUserGear}
                      className={
                        isActiveRoute("/admins")
                          ? "mr-1.5 text-xl text-white dark:text-black"
                          : "mr-1.5 text-xl text-icon-light dark:text-icon-dark"
                      }
                    />
                    <span
                      className={
                        isActiveRoute("/admins")
                          ? "text-xl font-bold text-white dark:text-black"
                          : "text-xl font-bold text-icon-light dark:text-icon-dark "
                      }
                    >
                      {" "}
                      Admins
                    </span>
                  </div>
                </Link>
              </li>
            )}

            {/***** Partners Link *****/}
            {/* {(admin?.canPartners || admin?.securityLevel === 1) && (
              <li className="items-center">
                <Link to="/partners">
                  <div
                    className={
                      isActiveRoute("/partners")
                        ? "mb-3 rounded-lg bg-gradient-to-b from-gradient1 to-gradient2 px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark"
                        : "hover:bg-card-blend-light mb-3 rounded-lg px-10 py-3 dark:hover:bg-back-dark"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faHandshakeSimple}
                      className={
                        isActiveRoute("/partners")
                          ? "mr-1.5 text-xl text-white dark:text-black"
                          : "mr-1.5 text-xl text-icon-light dark:text-icon-dark"
                      }
                    />
                    <span
                      className={
                        isActiveRoute("/partners")
                          ? "text-xl font-bold text-white dark:text-black"
                          : "text-xl font-bold text-icon-light dark:text-icon-dark "
                      }
                    >
                      {" "}
                      Partners
                    </span>
                  </div>
                </Link>
              </li>
            )} */}

            {/***** Support Link *****/}
            {(admin?.canSupport || admin?.securityLevel === 1) && (
              <li className="items-center">
                <Link to="/support">
                  <div
                    className={
                      isActiveRoute("/support")
                        ? "mb-3 rounded-lg bg-gradient-to-b from-gradient1 to-gradient2 px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark"
                        : "hover:bg-card-blend-light mb-3 rounded-lg px-10 py-3 dark:hover:bg-back-dark"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faHeadset}
                      className={
                        isActiveRoute("/support")
                          ? "mr-3 text-xl text-white dark:text-black"
                          : "mr-3 text-xl text-icon-light dark:text-icon-dark"
                      }
                    />
                    <span
                      className={
                        isActiveRoute("/support")
                          ? "text-xl font-bold text-white dark:text-black"
                          : "text-xl font-bold text-icon-light dark:text-icon-dark "
                      }
                    >
                      {" "}
                      Support
                    </span>
                  </div>
                </Link>
              </li>
            )}

            {/***** Settings Link *****/}
            {(admin?.canSettings || admin?.securityLevel === 1) && (
              <li className="items-center">
                <Link to="/settings">
                  <div
                    className={
                      isActiveRoute("/settings")
                        ? "mb-3 rounded-lg bg-gradient-to-b from-gradient1 to-gradient2 px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark"
                        : "hover:bg-card-blend-light mb-3 rounded-lg px-10 py-3 dark:hover:bg-back-dark"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faGear}
                      className={
                        isActiveRoute("/settings")
                          ? "mr-3 text-xl text-white dark:text-black"
                          : "mr-3 text-xl text-icon-light dark:text-icon-dark"
                      }
                    />
                    <span
                      className={
                        isActiveRoute("/settings")
                          ? "text-xl font-bold text-white dark:text-black"
                          : "text-xl font-bold text-icon-light dark:text-icon-dark "
                      }
                    >
                      {" "}
                      Settings
                    </span>
                  </div>
                </Link>
              </li>
            )}

            {/***** Reports Link *****/}
            {(admin?.canReports || admin?.securityLevel === 1) && (
              <li className="items-center">
                <Link to="/reports">
                  <div
                    className={
                      window.location.href.indexOf("/reports") !== -1
                        ? "mb-3 ml-1 rounded-lg bg-gradient-to-b from-gradient1 to-gradient2 px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark"
                        : "hover:bg-card-blend-light mb-3 ml-1 rounded-lg px-10 py-3 dark:hover:bg-back-dark"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faFile}
                      className={
                        window.location.href.indexOf("/reports") !== -1
                          ? "mr-3 text-xl text-white dark:text-black"
                          : "mr-3 text-xl text-icon-light dark:text-icon-dark"
                      }
                    />
                    <span
                      className={
                        window.location.href.indexOf("/reports") !== -1
                          ? "text-xl font-bold text-white dark:text-black"
                          : "text-xl font-bold text-icon-light dark:text-icon-dark "
                      }
                    >
                      {" "}
                      Reports
                    </span>
                  </div>
                </Link>
              </li>
            )}

            {/***** Tokens Link *****/}
            {(admin?.canTokens || admin?.securityLevel === 1) && (
              <li className="items-center">
                <Link to="/tokens">
                  <div
                    className={
                      isActiveRoute("/tokens")
                        ? "mb-3 rounded-lg bg-gradient-to-b from-gradient1 to-gradient2 px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark"
                        : "hover:bg-card-blend-light mb-3 rounded-lg px-10 py-3 dark:hover:bg-back-dark"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCoins}
                      className={
                        isActiveRoute("/tokens")
                          ? "mr-3 text-xl text-white dark:text-black"
                          : "mr-3 text-xl text-icon-light dark:text-icon-dark"
                      }
                    />
                    <span
                      className={
                        isActiveRoute("/tokens")
                          ? "text-xl font-bold text-white dark:text-black"
                          : "text-xl font-bold text-icon-light dark:text-icon-dark "
                      }
                    >
                      {" "}
                      Tokens
                    </span>
                  </div>
                </Link>
              </li>
            )}

            {/***** Demo Link *****/}
            {(admin?.canDemo || admin?.securityLevel === 1) && (
              <li className="items-center">
                <Link to="/demo">
                  <div
                    className={
                      isActiveRoute("/demo")
                        ? "mb-3 rounded-lg bg-gradient-to-b from-gradient1 to-gradient2 px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark"
                        : "hover:bg-card-blend-light mb-3 rounded-lg px-10 py-3 dark:hover:bg-back-dark"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faGamepad}
                      className={
                        isActiveRoute("/demo")
                          ? "mr-3 text-xl text-white dark:text-black"
                          : "mr-3 text-xl text-icon-light dark:text-icon-dark"
                      }
                    />
                    <span
                      className={
                        isActiveRoute("/demo")
                          ? "text-xl font-bold text-white dark:text-black"
                          : "text-xl font-bold text-icon-light dark:text-icon-dark "
                      }
                    >
                      {" "}
                      Demo
                    </span>
                  </div>
                </Link>
              </li>
            )}

            {/***** Onboarding Link *****/}
            {(admin?.canOnboarding || admin?.securityLevel === 1) && (
              <li className="items-center">
                <Link to="/onboarding">
                  <div
                    className={
                      isActiveRoute("/onboarding")
                        ? "mb-3 rounded-lg bg-gradient-to-b from-gradient1 to-gradient2 px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark"
                        : "hover:bg-card-blend-light mb-3 rounded-lg px-10 py-3 dark:hover:bg-back-dark"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faAddressCard}
                      className={
                        isActiveRoute("/onboarding")
                          ? "mr-3 text-xl text-white dark:text-black"
                          : "mr-3 text-xl text-icon-light dark:text-icon-dark"
                      }
                    />
                    <span
                      className={
                        isActiveRoute("/onboarding")
                          ? "text-xl font-bold text-white dark:text-black"
                          : "text-xl font-bold text-icon-light dark:text-icon-dark "
                      }
                    >
                      {" "}
                      Onboarding
                    </span>
                  </div>
                </Link>
              </li>
            )}

            {/***** Migration Link *****/}
            {(admin?.canMigration || admin?.securityLevel === 1) && (
              <li className="items-center">
                <Link to="/migration">
                  <div
                    className={
                      isActiveRoute("/migration")
                        ? "mb-3 rounded-lg bg-gradient-to-b from-gradient1 to-gradient2 px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark"
                        : "hover:bg-card-blend-light mb-3 rounded-lg px-10 py-3 dark:hover:bg-back-dark"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faDove}
                      className={
                        isActiveRoute("/migration")
                          ? "mr-3 text-xl text-white dark:text-black"
                          : "mr-3 text-xl text-icon-light dark:text-icon-dark"
                      }
                    />
                    <span
                      className={
                        isActiveRoute("/migration")
                          ? "text-xl font-bold text-white dark:text-black"
                          : "text-xl font-bold text-icon-light dark:text-icon-dark "
                      }
                    >
                      {" "}
                      Migration
                    </span>
                  </div>
                </Link>
              </li>
            )}

            {/***** Dash Link *****/}
            {(admin?.canDash || admin?.securityLevel === 1) && (
              <li className="items-center">
                <Link to="/dash">
                  <div
                    className={
                      isActiveRoute("/dash")
                        ? "mb-3 rounded-lg bg-gradient-to-b from-gradient1 to-gradient2 px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark"
                        : "hover:bg-card-blend-light mb-3 rounded-lg px-10 py-3 dark:hover:bg-back-dark"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCar}
                      className={
                        isActiveRoute("/dash")
                          ? "mr-3 text-xl text-white dark:text-black"
                          : "mr-3 text-xl text-icon-light dark:text-icon-dark"
                      }
                    />
                    <span
                      className={
                        isActiveRoute("/dash")
                          ? "text-xl font-bold text-white dark:text-black"
                          : "text-xl font-bold text-icon-light dark:text-icon-dark "
                      }
                    >
                      {" "}
                      Dash
                    </span>
                  </div>
                </Link>
              </li>
            )}

             {/***** Affiliates Link *****/}
             {/* {(admin?.canMigration || admin?.securityLevel === 1) && ( */}
              <li className="items-center">
                <Link to="/affiliates">
                  <div
                    className={
                      isActiveRoute("/affiliates")
                        ? "mb-3 rounded-lg bg-gradient-to-b from-gradient1 to-gradient2 px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark"
                        : "hover:bg-card-blend-light mb-3 rounded-lg px-10 py-3 dark:hover:bg-back-dark"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCircleNodes}
                      className={
                        isActiveRoute("/affiliates")
                          ? "mr-3 text-xl text-white dark:text-black"
                          : "mr-3 text-xl text-icon-light dark:text-icon-dark"
                      }
                    />
                    <span
                      className={
                        isActiveRoute("/affiliates")
                          ? "text-xl font-bold text-white dark:text-black"
                          : "text-xl font-bold text-icon-light dark:text-icon-dark "
                      }
                    >
                      {" "}
                      Affiliates
                    </span>
                  </div>
                </Link>
              </li>
            {/* )} */}

            <li>
              <hr className="my-4 min-w-full border-icon-light dark:border-icon-dark" />
            </li>
          </ul>

          <ul className="">
            {/***** Logout Link *****/}
            <li className="mb-2 items-center">
              <Link to="/" onClick={logout} className="mb-4 px-10 py-3">
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket}
                  className="text-md mr-1.5 text-icon-light dark:text-icon-dark"
                />
                <span className="text-md font-bold text-icon-light dark:text-icon-dark">
                  {" "}
                  Logout
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default SideBarNav;
