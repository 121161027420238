import React, { useState } from "react";
import {
  faArrowsRotate,
  faCircleExclamation,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import CircularActionButton from "../buttons/CircularActionButton";
import IdDisplay from "./IdDisplay";
import { TeamPageData } from "src/interfaces/TeamPageData";
import hubspotImg from "../../assets/images/HubspotLogoTransparent.png";
import ButtonLoader from "../loading/ButtonLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DisplayEditText from "./DisplayEditText";
import { hubspotApi } from "src/api";
import LoadingSpinner from "../loading/LoadingSpinner";
import useModal from "src/hooks/useModal";
import EditHubspotIDModal from "../modals/EditHubspotIDModal";

interface HubspotCardProps {
  team: TeamPageData;
  fetchTeam: () => void;
}

const HubspotCard = ({ team, fetchTeam }: HubspotCardProps) => {
  const { hubspotID } = team.team;

  const { openModalWith } = useModal();

  const [hubspotData, setHubspotData] = useState<any>();

  const [hubspotError, setHubspotError] = useState<boolean>(false);

  const [isLoadingHubspotData, setIsLoadingHubspotData] =
    useState<boolean>(false);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [isSavingHubspotData, setIsSavingHubspotData] =
    useState<boolean>(false);

  const fetchHubspotData = async () => {
    setHubspotError(false);
    setIsLoadingHubspotData(true);
    if (hubspotID) {
      try {
        const data = await hubspotApi.getContact(hubspotID);
        setHubspotData(data);
      } catch (err: any) {
        console.error(err);
        setHubspotError(true);
      } finally {
        setIsLoadingHubspotData(false);
      }
    }
  };

  const handleEditHubspot = () => {
    setIsEditing(!isEditing);
  };

  const handleClickHubspotID = () => {
    openModalWith({
      title: "Edit KeapID",
      body: <EditHubspotIDModal team={team} fetchTeam={fetchTeam} />,
      hideButtons: true,
    });
  };

  const handleSaveHubspotData = async () => {};

  return (
    <>
      <div className="card w-full rounded-lg bg-white px-6 py-4 shadow-lg dark:bg-card-dark">
        <div className="flex w-full items-center justify-between">
          <div className="flex w-24 items-center">
            <img src={hubspotImg} alt={"Keap Logo"} className="w-16" />
          </div>
          <IdDisplay
            ID={hubspotID}
            label="HubspotID"
            onClick={handleClickHubspotID}
            error={hubspotError}
          />
          <div className="flex w-24 items-center justify-end gap-2">
            <CircularActionButton
              icon={faArrowsRotate}
              onClick={fetchHubspotData}
            />
            <CircularActionButton
              icon={faPenToSquare}
              onClick={handleEditHubspot}
            />
          </div>
        </div>
        <div className="mb-4 mt-0.5 flex w-full items-center justify-center">
          {isLoadingHubspotData ? (
            <LoadingSpinner className="mt-2 h-8 w-8 text-[#F2785B]" />
          ) : hubspotError ? (
            <div className="mt-4 flex items-center justify-center gap-2">
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className="h-4 w-4 text-error"
              />
              <p className="text-error">HubspotID not found, please update</p>
            </div>
          ) : (
            <div className="flex w-full  gap-2">
              <div className="w-1/2">
                {/* <DisplayEditText
                  label={"First Name"}
                  name={"firstName"}
                  value={keapData?.firstName || "null"}
                  disabled={true}
                />
                <DisplayEditText
                  label={"Email"}
                  name="email"
                  value={keapData?.email || "null"}
                  disabled={true}
                />
                <DisplayEditText
                  label={"SendFuseTeamID"}
                  name={"sendFuseTeamID"}
                  value={editedKeapData?.sendFuseTeamID || ""}
                  disabled={!isEditing}
                  error={keapData?.sendFuseTeamID !== team.team.id}
                  success={keapData?.sendFuseTeamID === team.team.id}
                  onChange={(e) =>
                    handleInputChange("sendFuseTeamID", e.target.value)
                  }
                />
                <DisplayEditText
                  label={"SendFuse SubscriptionID"}
                  name={"sendFuseSubscriptionID"}
                  value={editedKeapData?.sendFuseSubscriptionID || ""}
                  disabled={!isEditing}
                  error={
                    keapData?.sendFuseSubscriptionID !==
                    team.team.subscriptionID
                  }
                  success={
                    keapData?.sendFuseSubscriptionID ===
                    team.team.subscriptionID
                  }
                  onChange={(e) =>
                    handleInputChange("sendFuseSubscriptionID", e.target.value)
                  }
                /> */}
              </div>
              <div className="w-1/2">
                {/* <DisplayEditText
                  label={"Last Name"}
                  name="lastName"
                  value={keapData?.lastName || "null"}
                  disabled={true}
                />
                <DisplayEditText
                  label={"Phone Number"}
                  name="phoneNumber"
                  value={keapData?.phoneNumber || ""}
                  disabled={true}
                />
                <DisplayEditText
                  label={"Dealio AccountID"}
                  name="dealioAccountID"
                  value={editedKeapData?.dealioAccountID || ""}
                  disabled={!isEditing}
                  error={keapData?.dealioAccountID !== team.team.realeflowID}
                  success={keapData?.dealioAccountID === team.team.realeflowID}
                  onChange={(e) =>
                    handleInputChange("dealioAccountID", e.target.value)
                  }
                />
                <DisplayEditText
                  label={"Dealio Site Plan ID"}
                  name="dealioPlanID"
                  value={editedKeapData?.dealioPlanID || ""}
                  disabled={!isEditing}
                  onChange={(e) =>
                    handleInputChange("dealioPlanID", e.target.value)
                  }
                  success={keapData?.dealioPlanID === dealioSitePlanIDGlobal}
                  error={keapData?.dealioPlanID !== dealioSitePlanIDGlobal}
                /> */}
              </div>
            </div>
          )}
        </div>
        <div>
          {isEditing && (
            <button
              onClick={handleSaveHubspotData}
              className="btn-sm btn w-full bg-[#F2785B] text-white hover:bg-orange-700"
            >
              {isSavingHubspotData ? (
                <ButtonLoader className="h-4 w-4" fill={"#F2785B"} />
              ) : (
                "Save"
              )}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default HubspotCard;
