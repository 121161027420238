function abbreviateMonth(month: string) {
  if (month === "January") {
    return "Jan";
  } else if (month === "February") {
    return "Feb";
  } else if (month === "March") {
    return "Mar";
  } else if (month === "April") {
    return "Apr";
  } else if (month === "May") {
    return "May";
  } else if (month === "June") {
    return "Jun";
  } else if (month === "July") {
    return "Jul";
  } else if (month === "August") {
    return "Aug";
  } else if (month === "September") {
    return "Sep";
  } else if (month === "October") {
    return "Oct";
  } else if (month === "November") {
    return "Nov";
  } else if (month === "December") {
    return "Dec";
  }
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function findMondayEpoch() {
  const mSecondsAtMidnight = new Date().setHours(0, 0, 0, 0);
  const daysOfTheWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayOfTheWeek = daysOfTheWeek[new Date().getDay()];
  if (dayOfTheWeek === "Monday") {
    return mSecondsAtMidnight;
  } else if (dayOfTheWeek === "Tuesday") {
    return mSecondsAtMidnight - 86400000;
  } else if (dayOfTheWeek === "Wednesday") {
    return mSecondsAtMidnight - 172800000;
  } else if (dayOfTheWeek === "Thursday") {
    return mSecondsAtMidnight - 259200000;
  } else if (dayOfTheWeek === "Friday") {
    return mSecondsAtMidnight - 345600000;
  } else if (dayOfTheWeek === "Saturday") {
    return mSecondsAtMidnight - 432000000;
  } else if (dayOfTheWeek === "Sunday") {
    return mSecondsAtMidnight - 518400000;
  }
  return "Error";
}

function parseMonthDayYear(timestamp: number | string, epochToAdd = 0) {
  if (timestamp) {
    const epoch = new Date(timestamp).getTime() + epochToAdd;
    const date = new Date(epoch);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return `${abbreviateMonth(months[month])} ${day} ${year}`;
  } else {
    return null;
  }
}


function parseMonthDay(timestamp: number, epochToAdd = 0) {
  if (timestamp) {
    const epoch = new Date(timestamp).getTime() + epochToAdd;
    const date = new Date(epoch);
    const month = date.getMonth();
    const day = date.getDate();
    return `${abbreviateMonth(months[month])} ${day}`;
  } else {
    return null;
  }
}

// EXAMPLE PARAM:   2022-07-28T08:14:27.487Z

function timeAgo(timestamp: string | Date, abbreviate: boolean = false) {
  if (timestamp) {
    const epoch = new Date(timestamp).getTime();
    const now = new Date().getTime();
    const diff = now - epoch;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor(diff / (1000 * 60));
    const seconds = Math.floor(diff / 1000);
    if (days > 0 && days < 2) {
      return `${days} day`;
    } else if (days > 0) {
      return `${days} days`;
    } else if (hours > 0) {
      return `${hours} ${abbreviate ? "hrs" : "hours"}`;
    } else if (minutes > 0) {
      return `${minutes} ${abbreviate ? "min" : "minutes"}`;
    } else if (seconds > 0) {
      return `${seconds} ${abbreviate ? "sec" : "seconds"}`;
    } else {
      return "Just now";
    }
  }
}

function timeUntil(timestamp: string, abbreviate: boolean = false) {
  if (timestamp) {
    const now = new Date().getTime();
    const targetTime = new Date(timestamp).getTime();
    const diff = targetTime - now;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor(diff / (1000 * 60));
    const seconds = Math.floor(diff / 1000);
    if (days > 0 && days < 2) {
      return `${days} day`;
    } else if (days > 0) {
      return `${days} days`;
    } else if (hours > 0) {
      return `${hours} ${abbreviate ? "hrs" : "hours"}`;
    } else if (minutes > 0) {
      return `${minutes} ${abbreviate ? "min" : "minutes"}`;
    } else if (seconds > 0) {
      return `${seconds} ${abbreviate ? "sec" : "seconds"}`;
    } else {
      return "Expired";
    }
  }
}

const getYearFromEpoch = (epoch: string | number) => {
  const date = new Date(epoch);
  const year = date.getFullYear();
  const month = date.getMonth();
  return `${months[month]} ${year}`;
};

// EXAMPLE_PARAM = 1715099438
function numTimestampToMonthDayYear(timestamp: number): string {
  // Create a new Date object from the UNIX timestamp (multiplied by 1000 to convert seconds to milliseconds)
  const date = new Date(timestamp * 1000);

  // Extract the month, day, and year from the date
  const month = date.toLocaleString("default", { month: "short" }); // Gets the abbreviated month
  const day = date.getDate();                                      // Gets the day of the month
  const year = date.getFullYear();                                 // Gets the full year

  // Return the formatted date string
  return `${month} ${day}, ${year}`;
}

export {
  findMondayEpoch,
  parseMonthDayYear,
  parseMonthDay,
  timeAgo,
  timeUntil,
  getYearFromEpoch,
  numTimestampToMonthDayYear
};
