import Property from "src/interfaces/property";
import PropertyButton from "../buttons/PropertyButton";
import { useState } from "react";
import PropertyDrawer from "./PropertyDrawerHorizontle";
import TogglePropertyDetails from "../buttons/TogglePropertyDetails";
import { parseMonthDay } from "src/helpers/parseEpochs";
import ValueButton from "../buttons/ValueButton";
import StatusBadge from "../badges/StatusBadge";
import CircularActionButton from "../buttons/CircularActionButton";
import { propertyApi } from "src/api";
import { faRecycle } from "@fortawesome/free-solid-svg-icons";

interface PropertyRowProps {
  property: Property;
}

const PropertyRow = ({ property }: PropertyRowProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);

  const handleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const renderPropertyDrawer = () => {
    return <PropertyDrawer property={property} />;
  };

  const handleRefresh = async () => {
    setIsLoadingRefresh(true);
    try {
      const resp = await propertyApi.refreshProperty(property.id);
      if (resp) {
        console.log("Refreshed");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingRefresh(false);
    }
  };

  return (
    <>
      <tr className="scroll-m-36 bg-card-light dark:bg-card-dark">
        <td className="bg-card-light dark:bg-card-dark">
          <div className="flex items-center justify-center font-semibold text-text-dark dark:text-text-light">
            {parseMonthDay(property.createdAt)}
          </div>
        </td>
        <td className="bg-card-light text-center text-text-dark hover:text-secondary dark:bg-card-dark dark:text-text-light md:text-lg">
          <PropertyButton property={property} />
        </td>

        <td className="bg-card-light p-0 dark:bg-card-dark">
          <div className="flex items-center justify-center gap-1">
            <ValueButton property={property} />
          </div>
        </td>

        <td className="bg-card-light dark:bg-card-dark">
          <div className="flex items-center justify-center text-center">
            <StatusBadge status={property.status} />
          </div>
        </td>
        <td className="bg-card-light dark:bg-card-dark">
          <div className="flex items-center justify-center text-center">
            <CircularActionButton
              onClick={handleRefresh}
              icon={faRecycle}
              isLoading={isLoadingRefresh}
              tooltip="Refresh Property"
            />
          </div>
        </td>

        <td
          className="bg-card-light pl-3 text-center dark:bg-card-dark"
          onClick={handleDrawer}
        >
          <TogglePropertyDetails onClick={() => {}} isToggled={isDrawerOpen} />
        </td>
      </tr>
      <tr
        className={
          isDrawerOpen
            ? "relative bg-card-light transition-all duration-300 dark:bg-card-dark "
            : "absolute h-0 bg-card-light  duration-300 dark:bg-card-dark "
        }
      >
        {isDrawerOpen && (
          <td
            colSpan={6}
            className="border-x bg-back-light dark:border-x dark:border-card-dark dark:bg-back-dark"
          >
            {renderPropertyDrawer()}
          </td>
        )}
      </tr>
    </>
  );
};

export default PropertyRow;
