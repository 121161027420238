import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import { Offer } from "src/interfaces/offer";

class OffersApi extends ApiTokenSupport {
  async getOffers(): Promise<Offer[]> {
    const resp = await axios.get<Offer[]>(
      `/api/admin/offers`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getTopTeamsQuantity(timePeriod: string) {
    const response = await axios.get(
      `/api/admin/top-teams-quantity/${timePeriod}`
    );
    return response.data;
  }

  async getTopTeamsVolume(timePeriod: string) {
    const response = await axios.get(
      `/api/admin/top-teams-volume/${timePeriod}`
    );
    return response.data;
  }
}

export default new OffersApi();
