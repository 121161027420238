/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { statisticsApi } from "src/api";

interface ChurnTabProps {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const ChurnTab = ({ isLoading, setIsLoading }: ChurnTabProps) => {
  const startMonth = new Date();
  startMonth.setMonth(startMonth.getMonth() - 12);

  interface ChurnChartData {
    month: string;
    StartValue: number;
    Retained: number[];
  }

  const [chartData, setChartData] = useState<ChurnChartData[]>([]);

  const columns = [
    "Subscribed",
    "Start value",
    "Month 1",
    "Month 2",
    "Month 3",
    "Month 4",
    "Month 5",
    "Month 6",
    "Month 7",
    "Month 8",
    "Month 9",
    "Month 10",
    "Month 11",
  ];

  const fetchChurnData = async () => {
    try {
      const data = await statisticsApi.getChurnData();
      setChartData(data);
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChurnData();
  }, []);

  const getColorForPercentage = (percentage: number) => {
    const intensity = Math.pow(percentage / 100, 2) * 255;
    return `rgba(243, 120, 120, ${intensity / 255})`;
  };

  return (
    <>
      {!isLoading && (
        <div className="mt-1 rounded-lg bg-card-light p-3 dark:bg-card-dark">
          <table className="table-xs table">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => (
                  <td
                    key={`churn-header-${index}`}
                    className="text-zinc-500 dark:text-zinc-400"
                  >
                    {column}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {chartData.map((row: ChurnChartData, index: number) => (
                <tr className="" key={`churn-row-${index}`}>
                  <td className="text-zinc-500 dark:text-zinc-400">
                    {row.month}
                  </td>
                  <td className="text-zinc-500 dark:text-zinc-400">
                    {row.StartValue}
                  </td>
                  {row.Retained.map((value: number, index: number) => (
                    <td
                      key={`churn-retained-${index}`}
                      className="font-medium text-white"
                      style={{
                        backgroundColor: getColorForPercentage(
                          Math.min(
                            Math.round((value / row.StartValue || 1) * 100),
                            100
                          )
                        ),
                      }}
                    >
                      {Math.min(
                        Math.round((value / row.StartValue || 1) * 100),
                        100
                      )}
                      %
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ChurnTab;
