import { ChangeEventHandler, ReactNode, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface TextInputProps {
  name: string;
  label?: string | ReactNode;
  placeholder?: string;
  value?: string;
  onChange?: ChangeEventHandler;
  error?: string | null;
  labelProps?: {
    className?: string;
    htmlFor?: string;
  };
  inputProps?: {
    className?: string;
    disabled?: boolean;
  };
  errorProps?: {
    className?: string;
  };
  disabled?: boolean;
}

const PasswordInput = ({
  name,
  label,
  placeholder,
  value,
  error,
  labelProps,
  inputProps,
  errorProps,
  onChange,
  disabled = false,
}: TextInputProps) => {
  const labelClassName = classNames(
    "block uppercase text-card-dark text-xs font-extrabold mb-1",
    labelProps?.className
  );
  type InputType = "password" | "text";

  const [inputType, setInputType] = useState<InputType>("password");

  const handleInputTypeChange = () => {
    setInputType((prevState: InputType) => {
      if (prevState === "password") {
        return "text";
      } else {
        return "password";
      }
    });
  };

  const inputClassName = classNames(
    {
      "border-2 border-error px-3 py-3 placeholder-gray-400 text-secondary font-bold placeholder:font-normal bg-white rounded text-sm shadow focus:outline-none w-full":
        !!error,
      "border-0 px-3 py-3 placeholder-gray-400 text-secondary font-bold placeholder:font-normal bg-white rounded text-xm shadow focus:outline-none focus:ring ring-secondary w-full":
        !error,
    },
    inputProps?.className
  );

  const errorClassName = classNames(
    "text-error text-xs md:text-sm font-semibold italic ml-1",
    errorProps?.className
  );

  return (
    <>
      {label && (
        <label htmlFor={name} {...labelProps} className={labelClassName}>
          {label}
        </label>
      )}
      <div className="relative">
        <input
          {...inputProps}
          type={inputType}
          name={name}
          className={inputClassName}
          placeholder={placeholder}
          value={value || ""}
          onChange={onChange}
          disabled={disabled}
        />
        {value?.length ? (
          <div
            className="absolute top-3 right-3"
            onClick={handleInputTypeChange}
          >
            <FontAwesomeIcon
              icon={inputType === "password" ? faEye : faEyeSlash}
              className="text-lg text-secondary w-7"
            />
          </div>
        ) : null}
      </div>
      {error && (
        <p {...errorProps} className={errorClassName}>
          {error}
        </p>
      )}
    </>
  );
};

export default PasswordInput;
