import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import useTheme from "src/hooks/useTheme";
import useViewport from "src/hooks/useViewport";
import { statisticsApi } from "src/api";

const RevenueTypeDoughnutChart = () => {
  const { width } = useViewport();

  const { mode, tailwindTheme } = useTheme();

  const [chartDataSet, setChartDataSet] = useState<number[]>([0, 0, 0]);

  const fetchSubscriptionCount = async () => {
    const resp = await statisticsApi.getMRRByType();
    const {
      platinum_plan_count,
      pro_plan_count,
      legacy_pro_plan_count,
      vacation_plan_count,
    } = resp[0];
    setChartDataSet([
      vacation_plan_count * 10,
      (pro_plan_count * 50) + (legacy_pro_plan_count * 47),
      (platinum_plan_count * 75),
    ]);
  };

  useEffect(() => {
    fetchSubscriptionCount();
  }, []);

  let labels = ["Vacation", "Pro", "Platinum"];

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "MRR by Type",
        color: "#FFFFFF",
        data: chartDataSet,
        backgroundColor: [
          tailwindTheme.theme3,
          tailwindTheme.theme4,
          tailwindTheme.theme5,
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        labels: {
          color: mode === "dark" ? "#FFFFFF" : "#000000",
          boxWidth: width < 640 ? 12 : 16,
          boxHeight: width < 640 ? 12 : 16,
          font: {
            size: width < 640 ? 10 : width < 1024 ? 12 : 14,
            family: "sans-serif",
          },
        },
      },
      title: {
        display: true,
        text: "MRR by Type",
        color: mode === "light" ? "#7E7D85" : "#ABA9B4",
        padding: 0,
        font: {
          size: width < 640 ? 13 : 17,
          family: "sans-serif",
        },
      },
    },
  };

  return (
    <div className="flex w-full h-full">
      <div className="stats stats-vertical shadow rounded-tr-none rounded-br-none w-72">
        <div className="stat bg-card-light py-1 dark:bg-card-dark">
          <div className="stat-title text-zinc-600 dark:text-zinc-300">
            Total MRR
          </div>
          <div className="stat-value text-primary">
            ${chartDataSet.reduce((partialSum, a) => partialSum + a, 0)}
          </div>
        </div>

        <div className="stat bg-card-light py-1 dark:bg-card-dark">
          <div className="stat-title text-zinc-600 dark:text-zinc-300">
            Platinum
          </div>
          <div className="stat-value text-theme5">${chartDataSet[2]}</div>
        </div>

        <div className="stat bg-card-light py-1 dark:bg-card-dark">
          <div className="stat-title text-zinc-600 dark:text-zinc-300">Pro</div>
          <div className="stat-value text-theme4">${chartDataSet[1]}</div>
        </div>
        <div className="stat bg-card-light py-1 dark:bg-card-dark">
          <div className="stat-title text-zinc-600 dark:text-zinc-300">
            Vacation
          </div>
          <div className="stat-value text-theme3">${chartDataSet[0]}</div>
        </div>
      </div>
      <div className="card flex h-full w-full flex-row bg-card-light shadow-lg dark:bg-card-dark rounded-tl-none rounded-bl-none">
        <div className="flex flex-grow columns-1 flex-col items-center py-2 md:py-2 lg:py-3 ">
          <div className="mb-3">
            <Doughnut data={chartData} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueTypeDoughnutChart;
