import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface FailedPaymentModalProps {
  team: any;
}

const FailedPaymentModal = ({ team }: FailedPaymentModalProps) => {
  const { customerID } = team;

  return (
    <>
      <div className="flex columns-1 flex-col items-center justify-center">
        <div className="w-32 mb-2">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://dashboard.stripe.com/customers/${customerID}`}
            className="w-full"
          >
            <button className="btn btn-sm w-full border-none bg-theme5 text-text-light hover:bg-theme5 dark:text-black dark:text-text-dark">
              <FontAwesomeIcon
                icon={faCreditCard}
                className="text-md childButton mr-1"
              />
              Stripe
            </button>
          </a>
        </div>
        <div className="w-ful;">
          <p>Do you want to mark this payment issue as resolved?</p>
        </div>
      </div>
    </>
  );
};

export default FailedPaymentModal;
