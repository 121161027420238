import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import statisticsApi from "src/api/statistics";
import useAlert from "src/hooks/useAlert";

interface SyncButtonProps {
  props?: any;
}

const SyncButton = ({ props }: SyncButtonProps) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const { setAlert } = useAlert();

  const fetchNewStripeData = async () => {
    setIsFetching(true);
    try {
      const isFetched = await statisticsApi.syncStripe();
      if (isFetched) {
        setAlert({
          display: true,
          type: "info",
          message: "Stripe data was synced",
        });
      }
    } catch (e) {
      console.error(e);
      setAlert({
        display: true,
        type: "warning",
        message: "Three was an error syncing Stripe data",
      });
    }
    return setIsFetching(false);
  };
  return (
    <>
      <label
        className={
          isFetching
            ? "btn btn-ghost hover:bg-hover-back-light dark:hover:bg-hover-back-dark"
            : "btn btn-ghost  hover:bg-hover-back-light dark:hover:bg-hover-back-dark"
        }
        onClick={fetchNewStripeData}
      >
        <FontAwesomeIcon
          icon={faArrowsRotate}
          className={
            isFetching
              ? "swap swap-rotate animate-spin text-lg text-primary duration-1000 ease-in-out"
              : "swap swap-rotate text-lg text-primary"
          }
        />
      </label>
    </>
  );
};

export default SyncButton;
