import React from "react";
import { useNavigate } from "react-router-dom";

interface TeamButtonProps {
  companyName: string;
  teamID: number;
}

const TeamButton = ({ companyName, teamID }: TeamButtonProps) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="btn btn-sm btn-ghost hover:bg-hover-card-light dark:hover:bg-hover-card-dark"
        onClick={() => navigate(`/teams/${teamID}`)}
      >
        {companyName}
      </div>
    </>
  );
};

export default TeamButton;
