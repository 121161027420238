import { useState } from "react";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import SubscriptionChoice from "../stripe/SubscriptionChoice";
import { TeamPageData } from "src/interfaces/TeamPageData";
import { adminApi } from "src/api";
import ButtonBlock from "./ButtonBlock";

interface SubscriptionModalProps {
  team: TeamPageData;
  fetchTeam: () => void;
}

const UpgradeModal = ({ team, fetchTeam }: SubscriptionModalProps) => {
  const { setAlert } = useAlert();

  const { setShowModal } = useModal();

  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const [selectedSubscription, setSelectedSubscription] = useState<number>(
    team.subscription.id
  );

  const [isAnnual, setIsAnnual] = useState<boolean>(
    team.team.isAnnualSubscription
  );

  const [chargeImmediately, setChargeImmediately] = useState<boolean>(true);

  const handleChargeOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChargeImmediately(e.target.value === "true");
  };

  const handleSelectSub = (subId: number) => {
    setSelectedSubscription(subId);
  };

  const handleSelectPeriod = (annual: boolean) => {
    setIsAnnual(annual);
  };

  const handleSubmit = async () => {
    setOnSubmitting(true);
    try {
      await adminApi.changeTeamSubscription({
        teamID: team.team.id,
        subscriptionID: selectedSubscription,
        isAnnual,
        chargeImmediately: isAnnual ? chargeImmediately : false,
      });
      setAlert({
        type: "success",
        message: "Subscription updated successfully!",
        display: true,
      });
    } catch (error: any) {
      setAlert({
        type: "error",
        message: error?.message || "Error upgrading team subscription",
        display: true,
      });
    } finally {
      setOnSubmitting(false);
      setShowModal(false);
      fetchTeam();
    }
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  // Only show the buttons if there is a change to subscription or Annual/Monthly
  const shouldShowButtons =
    selectedSubscription !== team.subscription.id ||
    (selectedSubscription === team.subscription.id &&
      isAnnual !== team.team.isAnnualSubscription);

  return (
    <>
      <div>
        <div>
          <div className="mb-4">
            <SubscriptionChoice
              team={team}
              handleSelectSub={handleSelectSub}
              handleSelectPeriod={handleSelectPeriod}
              selectedSubscription={selectedSubscription}
              isAnnual={isAnnual}
            />
          </div>
        </div>
        {shouldShowButtons && (
          <div className="flex columns-1 flex-col">
            {isAnnual && (
              <div className="mb-4 flex columns-1 flex-col items-center justify-center gap-2">
                <div className="flex w-56 flex-row gap-2">
                  <input
                    type="radio"
                    id="chargeImmediately"
                    name="chargeOption"
                    value="true"
                    className="radio-secondary radio"
                    checked={chargeImmediately}
                    onChange={handleChargeOptionChange}
                  />
                  <label htmlFor="chargeImmediately">Charge Immediately</label>
                </div>
                <div className="flex w-56 flex-row gap-2">
                  <input
                    type="radio"
                    id="notChargeImmediately"
                    name="chargeOption"
                    value="false"
                    className="radio-secondary radio"
                    checked={!chargeImmediately}
                    onChange={handleChargeOptionChange}
                  />
                  <label htmlFor="notChargeImmediately">
                    Charge On Billing Cycle
                  </label>
                </div>
              </div>
            )}
            <div className="mt-4">
              <ButtonBlock
                submitting={onSubmitting}
                handleSubmit={handleSubmit}
                onCancel={handleCancel}
                submitLabel={"Update"}
                disabled={onSubmitting}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UpgradeModal;
