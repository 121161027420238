/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Property from "src/interfaces/property";
import AdminApi from "src/api/admin";
import useViewport from "src/hooks/useViewport";
import PropertyRow from "../property/PropertyRow";
import { TeamPageData } from "src/interfaces/TeamPageData";

interface PropertiesTabProps {
  team: TeamPageData;
}

const PropertiesTab = ({ team }: PropertiesTabProps) => {
  const { isMobile } = useViewport();

  const [properties, setProperties] = useState<Property[]>([]);

  const { id } = team.team;

  const fetchProperties = async () => {
    if (id) {
      const response = await AdminApi.getProperties(id);
      setProperties(response);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, [team]);

  return (
    <>
      <table
        className={
          isMobile
            ? "table-compact table w-full shadow-xl"
            : "table w-full shadow-xl"
        }
      >
        <thead className="">
          <tr className="border-b-2 border-secondary text-secondary">
            <th className="bg-card-light text-center dark:bg-card-dark">
              Added
            </th>
            <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
              Property
            </th>
            <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
              Value
            </th>
            <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
              Status
            </th>
            <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
              Refresh
            </th>
            <th className="bg-card-light dark:bg-card-dark "></th>
          </tr>
        </thead>
        <tbody className="">
          {properties.length ? (
            properties?.map((property: any, index: number) => (
              <PropertyRow property={property} key={index} />
            ))
          ) : (
            <tr>
              <td
                className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                colSpan={5}
              >
                No Properties Researched by this team yet
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default PropertiesTab;
