import { Tooltip as ReactTooltip } from 'react-tooltip'


interface TooltipProps {
  id: string;
  content: any;
}

const Tooltip = ({
  id,
  content,
}: TooltipProps) => {


  return (
    <>
      <ReactTooltip
        id={id}
        place="top"
        className="font-semibold opacity-5 bg-black dark:bg-white text-text-light dark:text-text-dark"
      >
        {content}
      </ReactTooltip>
    </>
  );
};

export default Tooltip;
