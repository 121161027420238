/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import AdminApi from "src/api/admin";
import { TeamPageData } from "src/interfaces/TeamPageData";
import { User } from "src/interfaces/user";
import useModal from "src/hooks/useModal";
import useViewport from "src/hooks/useViewport";
import Toggle from "../buttons/ToggleX";
import UserAvatar from "../buttons/UserAvatar";
import NewTeamMemberModal from "../modals/NewTeamMemberModal";

interface TeamControlPanelProps {
  team: TeamPageData;
}

const TeamControlPanel = ({ team }: TeamControlPanelProps) => {
  const { id, leaderID } = team.team;

  const { isMobile } = useViewport();

  const [users, setUsers] = useState<User[]>([]);

  const { openModalWith } = useModal();

  const fetchTeam = async () => {
    if (id) {
      const response = await AdminApi.getTeamUsers(id);
      setUsers(response);
    }
  };

  useEffect(() => {
    fetchTeam();
  }, [id]);

  const handleChange = async (userID: number, data: any) => {
    await AdminApi.updateTeamUsers(userID, data);
    fetchTeam();
  };

  const handleNewTeamMember = () => {
    openModalWith({
      title: "Invite New Team Member",
      body: <NewTeamMemberModal team={team} fetchTeam={fetchTeam} />,
      hideButtons: true,
    });
  };

  return (
    <>
      <div className="fixed left-0 right-0 w-full px-1.5  lg:static lg:px-0">
        <button
          className="btn-secondary btn-sm btn mb-2 w-full text-text-light"
          onClick={handleNewTeamMember}
        >
          + New Team Member
        </button>
      </div>
      <div className="relative top-[40px] lg:static">
        <table
          className={
            isMobile
              ? "table-compact block table w-full shadow-xl"
              : "block table w-full shadow-xl"
          }
        >
          <thead>
            <tr className="border-b-2 border-secondary text-secondary">
              <th className="sticky select-none bg-card-light  text-left text-xs text-secondary dark:bg-card-dark md:text-base">
                Team Member
              </th>
              <th className="select-none bg-card-light text-center  text-xs text-secondary dark:bg-card-dark md:text-base">
                Watchlist
              </th>
              <th className="select-none bg-card-light text-center  text-xs text-secondary dark:bg-card-dark md:text-base">
                Research
              </th>
              <th className="select-none bg-card-light text-center  text-xs text-secondary dark:bg-card-dark md:text-base">
                Status
              </th>
              <th className="select-none bg-card-light text-center  text-xs text-secondary dark:bg-card-dark md:text-base">
                Purchase
              </th>
              <th className="select-none bg-card-light text-center  text-xs text-secondary dark:bg-card-dark md:text-base">
                SkipTrace
              </th>
              <th className="select-none bg-card-light text-center  text-xs text-secondary dark:bg-card-dark md:text-base">
                DebtStack
              </th>
              <th className="select-none bg-card-light text-center  text-xs text-secondary dark:bg-card-dark md:text-base">
                Sequence
              </th>
              <th className="select-none bg-card-light text-center  text-xs text-secondary dark:bg-card-dark md:text-base">
                Offer
              </th>
              <th className="select-none bg-card-light text-center  text-xs text-secondary dark:bg-card-dark md:text-base">
                Invite
              </th>
              <th className="select-none bg-card-light text-center  text-xs text-secondary dark:bg-card-dark md:text-base">
                Active
              </th>
            </tr>
          </thead>
          <tbody>
            {users.length ? (
              users
                ?.sort(
                  (a: any, b: any) =>
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                )
                .map((teammate: any, index: number) => (
                  <tr key={index} className="">
                    <td className="sticky left-0 min-w-min bg-card-light text-left text-xxs dark:bg-card-dark md:text-base">
                      <div className="flex items-center justify-start px-1">
                        <UserAvatar user={teammate} />
                      </div>
                    </td>
                    <td className="bg-card-light text-center text-text-light dark:bg-card-dark dark:text-text-dark md:text-xs">
                      <label htmlFor="canWatchlist"></label>
                      <Toggle
                        title="canWatchlist"
                        name="canWatchlist"
                        className="toggle toggle-sm md:toggle-md"
                        disabled={teammate.id === leaderID}
                        checked={teammate.canWatchlist}
                        onChange={() =>
                          handleChange(teammate.id, {
                            toChange: "canWatchlist",
                            newValue: `${!teammate.canWatchlist}`,
                          })
                        }
                      ></Toggle>
                    </td>
                    <td className="bg-card-light text-center text-text-light dark:bg-card-dark dark:text-text-dark md:text-xs">
                      <label htmlFor="canResearch"></label>
                      <Toggle
                        title="canResearch"
                        name="canResearch"
                        className="toggle toggle-xs md:toggle-md"
                        disabled={teammate.id === leaderID}
                        checked={teammate.canResearch}
                        onChange={() =>
                          handleChange(teammate.id, {
                            toChange: "canResearch",
                            newValue: `${!teammate.canResearch}`,
                          })
                        }
                      ></Toggle>
                    </td>
                    <td className="bg-card-light text-center text-text-light dark:bg-card-dark dark:text-text-dark md:text-xs">
                      <label htmlFor="canUpdateStatus"></label>
                      <Toggle
                        title="canUpdateStatus"
                        name="canUpdateStatus"
                        className="toggle toggle-xs md:toggle-md"
                        disabled={teammate.id === leaderID}
                        checked={teammate.canUpdateStatus}
                        onChange={() =>
                          handleChange(teammate.id, {
                            toChange: "canUpdateStatus",
                            newValue: `${!teammate.canUpdateStatus}`,
                          })
                        }
                      ></Toggle>
                    </td>
                    <td className="bg-card-light text-center text-text-light dark:bg-card-dark dark:text-text-dark md:text-xs">
                      <label htmlFor="canPurchase"></label>
                      <Toggle
                        title="canPurchase"
                        name="canPurchase"
                        className="toggle toggle-xs md:toggle-md"
                        disabled={teammate.id === leaderID}
                        checked={teammate.canPurchase}
                        onChange={() =>
                          handleChange(teammate.id, {
                            toChange: "canPurchase",
                            newValue: `${!teammate.canPurchase}`,
                          })
                        }
                      />
                    </td>
                    <td className="bg-card-light text-center text-text-light dark:bg-card-dark dark:text-text-dark md:text-xs">
                      <label htmlFor="canSkipTrace"></label>
                      <Toggle
                        title="canSkipTrace"
                        name="canSkipTrace"
                        className="toggle toggle-xs md:toggle-md"
                        disabled={teammate.id === leaderID}
                        checked={teammate.canSkipTrace}
                        onChange={() =>
                          handleChange(teammate.id, {
                            toChange: "canSkipTrace",
                            newValue: `${!teammate.canSkipTrace}`,
                          })
                        }
                      ></Toggle>
                    </td>
                    <td className="bg-card-light text-center text-text-light dark:bg-card-dark dark:text-text-dark md:text-xs">
                      <label htmlFor="canTitle"></label>
                      <Toggle
                        title="canTitle"
                        name="canTitle"
                        className="toggle toggle-xs md:toggle-md"
                        disabled={teammate.id === leaderID}
                        checked={teammate.canTitle}
                        onChange={() =>
                          handleChange(teammate.id, {
                            toChange: "canTitle",
                            newValue: `${!teammate.canTitle}`,
                          })
                        }
                      ></Toggle>
                    </td>
                    <td className="bg-card-light text-center text-text-light dark:bg-card-dark dark:text-text-dark md:text-xs">
                      <label htmlFor="canSequence"></label>
                      <Toggle
                        title="canSequence"
                        name="canSequence"
                        className="toggle toggle-xs md:toggle-md"
                        disabled={teammate.id === leaderID}
                        checked={teammate.canSequence}
                        onChange={() =>
                          handleChange(teammate.id, {
                            toChange: "canSequence",
                            newValue: `${!teammate.canSequence}`,
                          })
                        }
                      ></Toggle>
                    </td>
                    <td className="bg-card-light text-center text-text-light dark:bg-card-dark dark:text-text-dark md:text-xs">
                      <label htmlFor="canOffer"></label>
                      <Toggle
                        title="canOffer"
                        name="canOffer"
                        className="toggle toggle-xs md:toggle-md"
                        disabled={teammate.id === leaderID}
                        checked={teammate.canOffer}
                        onChange={() =>
                          handleChange(teammate.id, {
                            toChange: "canOffer",
                            newValue: `${!teammate.canOffer}`,
                          })
                        }
                      ></Toggle>
                    </td>
                    <td className="bg-card-light text-center text-text-light dark:bg-card-dark dark:text-text-dark md:text-xs">
                      <label htmlFor="canInvite"></label>
                      <Toggle
                        title="canInvite"
                        name="canInvite"
                        className="toggle toggle-xs md:toggle-md"
                        disabled={teammate.id === leaderID}
                        checked={teammate.canInvite}
                        onChange={() =>
                          handleChange(teammate.id, {
                            toChange: "canInvite",
                            newValue: `${!teammate.canInvite}`,
                          })
                        }
                      ></Toggle>
                    </td>
                    <td className="bg-card-light text-center text-text-light dark:bg-card-dark dark:text-text-dark md:text-xs">
                      <label htmlFor="isActive"></label>
                      <Toggle
                        title="isActive"
                        name="isAcive"
                        className="toggle toggle-xs md:toggle-md"
                        // disabled={teammate.id === leaderID}
                        checked={teammate.isActive}
                        onChange={() =>
                          handleChange(teammate.id, {
                            toChange: "isActive",
                            newValue: `${!teammate.isActive}`,
                          })
                        }
                      ></Toggle>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td
                  className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                  colSpan={12}
                >
                  ! There was an error retrieving the team list.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TeamControlPanel;
