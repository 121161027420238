import React from "react";
import { Token } from "src/interfaces/token";
import useAlert from "src/hooks/useAlert";
import AdminApi from "src/api/admin";
import {
  faAddressCard,
  faCircleCheck,
  faCircleXmark,
  faDove,
  faLink,
  faPaperPlane,
  faTrash,
  faRefresh,
  faUnlockKeyhole,
  faUser,
  faUserGear,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularActionButton from "../buttons/CircularActionButton";
import useModal from "src/hooks/useModal";
import { timeUntil } from "src/helpers/parseEpochs";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { getRoute } from "src/helpers/emailTokenHelpter";
import InputNumber from "../input-number/InputNumber";

interface TokensRowProps {
  token: Token;
  fetchTokens: any;
}

const DEFAULT_DAYS_REFRESH = 6;

const TokensRow = ({ token, fetchTokens }: TokensRowProps) => {
  const { id, purpose, expireAt, email, consumed } = token;

  let refreshDays: number = DEFAULT_DAYS_REFRESH;

  const { setAlert } = useAlert();

  const { setShowModal, openModalWith } = useModal();

  const handleEmailToken = async () => {
    try {
      await AdminApi.sendTokenEmail(token);
      setAlert({
        display: true,
        message: `Email sent to ${email} with token information`,
        type: "success",
      });
    } catch (e) {
      setAlert({
        display: true,
        message: `There was an error sending an email to ${email}`,
        type: "error",
      });
    }
  };

  const copyTextToClipboard = (str: string) => {
    return navigator.clipboard.writeText(str);
  };

  const handleLinkClick = () => {
    const link: string = `${
      purpose === "invite-admin"
        ? process.env.REACT_APP_ADMIN_URL
        : process.env.REACT_APP_FRONTEND_URL
    }/${getRoute(purpose)}/${token.token}`;
    return copyTextToClipboard(link);
  };

  const displayPurpose = (purpose: string) => {
    switch (purpose) {
      case "invite":
        return (
          <div className="flex items-center gap-1">
            <FontAwesomeIcon icon={faUser} className="" />
            <p>User Invite</p>
          </div>
        );
      case "migrate":
        return (
          <div className="flex items-center gap-1">
            <FontAwesomeIcon icon={faDove} className="" />
            <p>Migrate</p>
          </div>
        );
      case "recovery":
        return (
          <div className="flex items-center gap-1">
            <FontAwesomeIcon icon={faUnlockKeyhole} className="" />
            <p>Password Recovery</p>
          </div>
        );
      case "invite-to-v2":
        return (
          <div className="flex items-center gap-1">
            <FontAwesomeIcon icon={faUsers} className="" />
            <p>Team Invite</p>
          </div>
        );
      case "failed-payment":
        return (
          <div className="flex items-center gap-1">
            <FontAwesomeIcon icon={faCreditCard} className="" />
            <p>Failed Payment</p>
          </div>
        );
      case "invite-admin":
        return (
          <div className="flex items-center gap-1">
            <FontAwesomeIcon icon={faUserGear} className="" />
            <p>Admin Invite</p>
          </div>
        );
      case "demo-mode":
        return (
          <div className="flex items-center gap-1">
            <FontAwesomeIcon icon={faUserGear} className="" />
            <p>Demo</p>
          </div>
        );
      case "initial-invite":
        return (
          <div className="flex items-center gap-1">
            <FontAwesomeIcon icon={faAddressCard} className="" />
            <p>Create Password</p>
          </div>
        );
      default:
        return "";
    }
  };

  const handleDeleteSubmit = async (id: number) => {
    try {
      setShowModal(false);
      const result = await AdminApi.deleteToken(id);
      if (result) {
        setAlert({
          display: true,
          message: "Token Deleted",
          type: "success",
        });
      }
    } catch (err: any) {
      setAlert({
        display: true,
        message: "There was an error deleting this token",
        type: "error",
      });
    } finally {
      return fetchTokens();
    }
  };

  const refreshToken = async (id: number) => {
    try {
      setShowModal(false);
      const result = await AdminApi.refreshToken(id, refreshDays);
      if (result) {
        setAlert({
          display: true,
          message: "Token refreshed",
          type: "success",
        });
      }
      return fetchTokens();
    } catch (error) {
      setAlert({
        display: true,
        message: "There was an error refreshing the token",
        type: "error",
      });
    }
  };

  const handleDelete = () => {
    openModalWith({
      title: "Delete Token",
      body: "Are you sure you want to delete this token? The user will no longer be able to utilize it",
      onSubmit: () => handleDeleteSubmit(id),
      submitLabel: "Delete",
    });
  };

  const handleRefresh = () => {
    openModalWith({
      title: "Refresh Token",
      body: (
        <InputNumber
          label="Days"
          description="Choose the new expiration days"
          handleChange={(result: number) => (refreshDays = result)}
          defaultValue={refreshDays}
        />
      ),
      onSubmit: () => refreshToken(id),
      submitLabel: "Refresh",
    });
  };

  const isExpired = (timestamp: string) => {
    const timestampNumber = new Date(timestamp).getTime();
    const currentTime = Date.now();
    return currentTime > timestampNumber;
  };

  return (
    <>
      <tr className="my-0.5 bg-card-light text-base  font-medium text-text-dark dark:bg-card-dark dark:text-text-light">
        <td className="bg-white dark:bg-card-dark">
          {displayPurpose(purpose)}
        </td>
        <td className="bg-white dark:bg-card-dark">
          {" "}
          <button
            className=" btn-ghost btn-sm btn cursor-pointer normal-case"
            onClick={() => copyTextToClipboard(email)}
          >
            {email}
          </button>
        </td>
        <td
          className={
            isExpired(expireAt)
              ? "bg-white text-error dark:bg-card-dark"
              : "bg-white dark:bg-card-dark"
          }
        >
          {timeUntil(expireAt)}
        </td>
        <td className="bg-card-light text-center dark:bg-card-dark">
          <FontAwesomeIcon
            icon={consumed ? faCircleCheck : faCircleXmark}
            className={
              consumed ? "text-xl text-success" : "text-xl text-warning"
            }
          />
        </td>
        <td className="bg-card-light text-center dark:bg-card-dark">
          {!isExpired(expireAt) && !consumed && (
            <button
              className="btn-secondary btn-sm btn gap-1 text-text-light dark:text-text-dark"
              onClick={handleLinkClick}
            >
              <FontAwesomeIcon icon={faLink} className="" />
              <div className="font-semibold">Link</div>
            </button>
          )}
        </td>
        <td className="bg-card-light text-center font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
          {!isExpired(expireAt) && !consumed && (
            <button
              className="btn-accent btn-sm btn gap-1 text-text-light dark:text-text-dark"
              onClick={handleEmailToken}
            >
              <FontAwesomeIcon icon={faPaperPlane} className="" />
              <div className="font-semibold">Email</div>
            </button>
          )}
        </td>
        <td className="bg-card-light text-center font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
          <div className="flex">
            {isExpired(expireAt) && (
              <div>
                <CircularActionButton
                  icon={faRefresh}
                  onClick={handleRefresh}
                />
              </div>
            )}
            {(isExpired(expireAt) || consumed) && (
              <div className="ml-2">
                <CircularActionButton icon={faTrash} onClick={handleDelete} />
              </div>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default TokensRow;
