import { Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import useAuth from "src/hooks/useAuth";
import PrivateProvider from "src/provider/private";
import PrivateLayout from "../layouts/PrivateLayout";

/* Privete Route that checks if the user is logged in then provides correct Layout depending on Subscription Level */

interface PrivateRouteProps {
  children: JSX.Element | JSX.Element[];
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { currentAdmin, setRedirection } = useAuth();
  const location = useLocation();

  useEffect(() => {
    setRedirection(location.pathname);
  }, [location.pathname, setRedirection]);

  if (!currentAdmin) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <PrivateProvider>
        <PrivateLayout>{children}</PrivateLayout>
      </PrivateProvider>
    </>
  );
};

export default PrivateRoute;
