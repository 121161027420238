import React from "react";

interface InputNumberProps {
  description: string;
  label: string;
  handleChange?: Function;
  defaultValue?: number;
}

const InputNumber = ({
  description,
  label,
  handleChange,
  defaultValue = 0,
}: InputNumberProps) => {
  return (
    <div>
      <div className="w-full px-2 text-center text-xl text-text-dark dark:text-text-light">
        {description}
      </div>
      <div>
        <div className="m-4 flex items-center justify-center">
          <input
            type="number"
            placeholder="days"
            className="input-bordered input min-w-0 min-w-min max-w-xs bg-card-light dark:bg-card-dark"
            onChange={(event) => {
              if (handleChange) {
                handleChange(event.target.value);
              }
            }}
            defaultValue={defaultValue}
          />
          <label className="ml-4 text-base">{label}</label>
        </div>
      </div>
    </div>
  );
};

export default InputNumber;
