import React from "react";

interface ToggleProps {
  checked: boolean;
  handleChange: () => void;
}

const Toggle = ({ checked, handleChange }: ToggleProps) => {
  return (
    <>
      <div className="relative cursor-pointer" onClick={handleChange}>
        <input
          name="isActive"
          type="checkbox"
          checked={checked}
          onChange={() => {}}
          className="sr-only"
        />
        <div
          className={`box block h-[24px] w-[42px] rounded-full ${
            checked ? "bg-primary" : "bg-back-light dark:bg-back-dark"
          }`}
        ></div>
        <div
          className={`absolute left-[3px] top-[3px] flex h-[18px] w-[18px] items-center justify-center rounded-full bg-card-light transition dark:bg-card-dark ${
            checked ? "translate-x-full" : ""
          }`}
        ></div>
      </div>
    </>
  );
};

export default Toggle;
