import { TeamPageData } from "src/interfaces/TeamPageData";
import ActionPallette from "../cards/ActionPallette";
import TeamInfo from "../cards/TeamInfo";
import TeamEventsTable from "./TeamEventsTable";
import { useState, useEffect } from "react";
import NotesTable from "../notes/NotesTable";
import AdminApi from "src/api/admin";
import { stripeApi } from "src/api";
import KeapCard from "../cards/KeapCard";
import RealeflowCard from "../cards/RealeflowCard";
import DashCard from "../cards/DashCard";
import StripeCard from "../cards/StripeCard";
import HubspotCard from "../cards/HubspotCard";

interface AccountTabProps {
  team: TeamPageData;
  fetchTeam: () => void;
  handleActive: (title: string) => void;
}

const AccountTab = ({ team, fetchTeam, handleActive }: AccountTabProps) => {
  const { teamID } = team.user;

  const [notes, setNotes] = useState([]);

  const [stripeData, setStripeData] = useState<any>();

  const [dealioSitePlanIDGlobal, setDealioSitePlanIDGlobal] = useState<
    number | null
  >(null);

  const [dashUpgradeTagInKeapGlobal, setDashUpgradeTagInKeapGlobal] =
    useState<boolean>(false);

  const fetchNotes = async () => {
    if (teamID) {
      const response = await AdminApi.getTeamNotes(teamID);
      setNotes(response);
    }
  };

  useEffect(() => {
    fetchNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamID]);

  const fetchStripeData = async () => {
    setIsLoadingStripeData(true);
    try {
      const data: any = await stripeApi.fetchSubscriptionData({
        teamID: team.team.id,
        appID: team.app.id,
      });
      setStripeData(data);
      handleStripeErrors(data);
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsLoadingStripeData(false);
    }
  };

  const [isLoadingStripeData, setIsLoadingStripeData] =
    useState<boolean>(false);

  interface ErrorObject {
    isError: boolean;
    errorMessage: string;
  }

  interface StripeErrors {
    subscriptionMatch: ErrorObject;
    missingSubscriptionID: ErrorObject;
    activeMismatch: ErrorObject;
  }

  const initialStripeErrors: StripeErrors = {
    subscriptionMatch: {
      isError: false,
      errorMessage: "",
    },
    missingSubscriptionID: {
      isError: !team.stripeAccount.stripeSubscriptionID,
      errorMessage: "No subscriptionID stored in database",
    },
    activeMismatch: {
      isError: false,
      errorMessage: "",
    },
  };

  const [errors, setErrors] = useState<StripeErrors>(initialStripeErrors);

  const handleStripeErrors = (data: any) => {
    const planID = team.team.isAnnualSubscription
      ? team.subscription.annualPlanID
      : team.subscription.monthlyPlanID;
    if (data.plan.id !== planID) {
      setErrors((prev) => ({
        ...prev,
        subscriptionMatch: {
          isError: true,
          errorMessage: "Stripe subscription does not match SendFuse",
        },
      }));
    }

    if (
      data.status === "active" &&
      !data?.cancel_at_period_end &&
      !team.team.isActive
    ) {
      setErrors((prev) => ({
        ...prev,
        activeMatch: {
          isError: true,
          errorMessage:
            "Stripe subscription is active but canceled in SendFuse",
        },
      }));
    } else if (
      (data.status === "canceled" || data.cancel_at_period_end) &&
      team.team.isActive
    ) {
      setErrors((prev) => ({
        ...prev,
        activeMatch: {
          isError: true,
          errorMessage:
            "Stripe subscription is canceled but active in SendFuse",
        },
      }));
    }
  };

  return (
    <>
      <div className="mb-2 flex w-full gap-1">
        <div className="w-1/2">
          <div className="mb-1">
            <TeamInfo
              team={team}
              fetchTeam={fetchTeam}
              handleActive={handleActive}
              fetchNotes={fetchNotes}
            />
          </div>
          <div className="mb-1">
            <DashCard
              team={team}
              fetchTeam={fetchTeam}
              dealioSitePlanIDGlobal={dealioSitePlanIDGlobal}
              dashUpgradeTagInKeapGlobal={dashUpgradeTagInKeapGlobal}
            />
          </div>
          <div className="mb-1">
            <NotesTable team={team} notes={notes} fetchNotes={fetchNotes} />
          </div>
          <TeamEventsTable team={team} />
        </div>
        <div className="w-1/2">
          <div className="mb-1">
            <ActionPallette
              team={team}
              fetchTeam={fetchTeam}
              fetchStripeData={fetchStripeData}
            />
          </div>
          <div className="mb-1">
            <StripeCard
              team={team}
              fetchTeam={fetchTeam}
              stripeData={stripeData}
              fetchStripeData={fetchStripeData}
              errors={errors}
              isLoadingStripeData={isLoadingStripeData}
            />
          </div>
          <div className="mb-1">
            <HubspotCard team={team} fetchTeam={fetchTeam} />
          </div>
          <div className="mb-1">
            <KeapCard
              team={team}
              fetchTeam={fetchTeam}
              dealioSitePlanIDGlobal={dealioSitePlanIDGlobal}
              setDashUpgradeTagInKeapGlobal={setDashUpgradeTagInKeapGlobal}
            />
          </div>
          <div className="mb-1">
            <RealeflowCard
              team={team}
              fetchTeam={fetchTeam}
              dealioSitePlanIDGlobal={dealioSitePlanIDGlobal}
              setDealioSitePlanIDGlobal={setDealioSitePlanIDGlobal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountTab;
