import React, { useState } from "react";
import { adminApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import SelectInput from "../fields/SelectInput";
import useAppContext from "src/hooks/private/useAppContext";
import { App } from "src/interfaces/app";
import TextInput from "../fields/TextInput";
import ButtonBlock from "./ButtonBlock";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import parsePhone from "src/helpers/parsePhone";

export interface AffiliateFormData {
  appID: number;
  name: string;
  email: string;
  phoneNumber: string;
  notes: string;
}

interface NewAffiliateModalProps {
  fetchAffiliates: () => void;
}

const NewAffiliateModal = ({ fetchAffiliates }: NewAffiliateModalProps) => {
  const { setAlert } = useAlert();

  const { setShowModal } = useModal();

  const { apps } = useAppContext();

  const initialFormData: AffiliateFormData = {
    appID: 1,
    name: "",
    email: "",
    phoneNumber: "",
    notes: "",
  };

  interface FormErrors {
    name: string | null;
    email: string | null;
    phoneNumber: string | null;
  }

  const initialFormErrors: FormErrors = {
    name: null,
    email: null,
    phoneNumber: null,
  };

  const [formData, setFormData] = useState<AffiliateFormData>(initialFormData);

  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validate: any = async (data: any) => {
    const errors: {
      name?: string;
      email?: string;
      phoneNumber?: string;
    } = {};
    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!data.email) {
      errors.email = "Email is required";
    } else if (!regexEmail.test(data.email)) {
      errors.email = "Email is not a valid format";
    }
    if (!data.name) {
      errors.name = "Affiliate name is required";
    }
    if (!data.phoneNumber) {
      errors.phoneNumber = "Phone Number is required";
    }
    if (data.phoneNumber.length !== 10) {
      errors.phoneNumber = "Phone Number must be 10 digits";
    }

    return errors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    if (
      name === "phoneNumber" &&
      value.replace(/\D/g, "").length > 10 &&
      (e.nativeEvent as InputEvent).inputType !== "deleteContentBackward"
    ) {
      return;
    }

    setFormData((data) => ({
      ...data,
      [name]: name === "appID" ? parseInt(value) : value,
    }));
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setFormErrors(initialFormErrors);
    let errors = await validate(formData);
    if (errors.email) {
      return setFormErrors(errors);
    } else {
      setFormErrors(initialFormErrors);
      try {
        setIsLoading(true);
        const res = await adminApi.createAffiliate({ ...formData });
        if (res) {
          setAlert({
            display: true,
            message: `${formData.name} was invited`,
            type: "success",
          });
        }
      } catch (err: any) {
        setAlert({
          display: true,
          message: err?.message || "There was an error inviting this team",
          type: "error",
        });
      } finally {
        fetchAffiliates();
        setIsLoading(false);
        setShowModal(false);
      }
    }
  };

  return (
    <>
      <div className="flex w-full flex-col items-center justify-between gap-2 px-24">
        <div className="w-full">
          <SelectInput
            name="appID"
            label="Application"
            placeholder="Application"
            value={formData.appID.toString()}
            onChange={handleChange}
            options={apps.map((app: App) => ({
              label: `${app.name} - (${app.id})`,
              value: app.id,
            }))}
          />
        </div>
        <div className="w-full">
          <TextInput
            name="name"
            label="Name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            error={formErrors.name}
            labelProps={{ className: "dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
          />
        </div>
        <div className="w-full">
          <TextInput
            name="email"
            label="Email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            error={formErrors.email}
            labelProps={{ className: "dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
          />
        </div>
        <div className="w-full">
          <TextInput
            name="phoneNumber"
            label="Phone"
            placeholder="Phone"
            value={parsePhone(formData.phoneNumber)}
            onChange={handleChange}
            error={formErrors.phoneNumber}
            labelProps={{ className: "dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
          />
        </div>
        <div className="w-full">
          <TextInput
            name="notes"
            label="Notes"
            placeholder="Notes"
            value={formData.notes}
            onChange={handleChange}
            labelProps={{ className: "dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
          />
        </div>
        <div className="mt-4">
          <ButtonBlock
            submitting={isLoading}
            submitLabel={"Invite"}
            submitIcon={faPaperPlane}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default NewAffiliateModal;
