import useAdminContext from "src/hooks/private/useAdminContext";

const Username = () => {
  const { admin } = useAdminContext();

  return (
    <>
      <button className="btn btn-ghost hover:bg-hover-back-light hover:dark:bg-hover-back-dark text-primary">
        {`${admin?.firstName} ${admin?.lastName}`}
      </button>
    </>
  );
}

export default Username;