import { capitalize } from "src/helpers/parseStrings";
import { useSwiper } from "swiper/react";

interface SwiperTitleDropdownProps {
  title: string;
  slides: any;
}

const SwiperTitleDropdown = ({ title, slides }: SwiperTitleDropdownProps) => {
  const swiper = useSwiper();

  const handleClick = (index: number) => {
    swiper.slideTo(index);
  };

  return (
    <>
      <div className="dropdown flex flex-col columns-1 items-center">
        <label
          tabIndex={0}
          className="btn btn-secondary btn-xs m-1 text-xl text-text-light font-bold text-center"
        >
          {capitalize(title)}
        </label>
        <ul
          tabIndex={0}
          className="top-[45px] dropdown-content border-2 border-secondary text-center menu p-2 bg-card-light dark:bg-card-dark rounded-box w-52 z-50 overflow-visible shadow-xl"
        >
          {slides.map((slide: any, index: number) => (
            <li key={index}>
              <button
                className="btn btn-ghost text-text-dark dark:text-text-light hover:bg-hover-card-light dark:hover:bg-hover-card-dark hover:text-secondary hover:dark:text-secondary"
                onMouseDown={() => handleClick(index)}
              >
                {slide.title}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SwiperTitleDropdown;
