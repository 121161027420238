import React, { ReactNode, useState } from "react";
import useModal from "src/hooks/useModal";
import Button from "../buttons/Button";
import ButtonLoader from "../loading/ButtonLoader";
import useTheme from "src/hooks/useTheme";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ButtonBlockProps {
  handleSubmit: any;
  submitLabel?: string | ReactNode;
  submitting?: boolean;
  disabled?: boolean;
  submitIcon?: IconProp;
  cancelLabel?: string;
  onCancel?: () => void;
}

const ButtonBlock = ({
  handleSubmit,
  submitLabel,
  submitting,
  disabled,
  submitIcon,
  cancelLabel = "Cancel",
  onCancel,
}: ButtonBlockProps) => {
  const { setShowModal } = useModal();

  const { tailwindTheme } = useTheme();

  const handleCancel = () => {
    setShowModal(false);
  };

  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <>
      <div className="flex items-center justify-center">
        <Button
          className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-hover-back-light dark:text-text-light hover:dark:bg-hover-card-dark"
          onClick={onCancel || handleCancel}
        >
          {cancelLabel}
        </Button>
        <Button
          className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg disabled:bg-hover-back-light disabled:text-gray-500 dark:text-text-light disabled:dark:bg-hover-card-dark disabled:dark:text-gray-800"
          onClick={!submitting ? handleSubmit : () => {}}
          disabled={disabled}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          icon={!submitting ? submitIcon : undefined}
        >
          {submitting ? (
            <ButtonLoader
              className="h-6 w-6 text-white"
              background={"card"}
              fill={isHovering ? tailwindTheme.theme3 : tailwindTheme.gradient1}
            />
          ) : (
            submitLabel ?? "Submit"
          )}
        </Button>
      </div>
    </>
  );
};

export default ButtonBlock;
