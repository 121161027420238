import React, { useState } from "react";
import { Token } from "src/interfaces/token";
import useViewport from "src/hooks/useViewport";
import TokensRow from "./TokensRow";
import isExpired from "src/helpers/isExpired";

interface TokensTableProps {
  tokens: Token[];
  fetchTokens: any;
}

const TokensTable = ({ tokens, fetchTokens }: TokensTableProps) => {
  const { isMobile } = useViewport();

  interface FormDataType {
    purpose: string;
    email: string;
  }

  const defaultFormData: FormDataType = {
    purpose: "-",
    email: "",
  };

  const [formData, setFormData] = useState<FormDataType>(defaultFormData);

  const [filterExpired, setFilterExpired] = useState<string>("All");

  const [filterUsed, setFilterUsed] = useState<string>("All");

  const handleExpiredClick = () => {
    switch (filterExpired) {
      case "All":
        return setFilterExpired("Open");
      case "Open":
        return setFilterExpired("Expired");
      case "Expired":
        return setFilterExpired("All");
    }
  };

  const handleUsedClick = () => {
    switch (filterUsed) {
      case "All":
        return setFilterUsed("Unused");
      case "Unused":
        return setFilterUsed("Used");
      case "Used":
        return setFilterUsed("All");
    }
  };

  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const filteredTokens = tokens.filter((token: Token) => {
    const tokenIsExpired = isExpired(token.expireAt);

    return (
      token.email.toLowerCase().includes(formData.email.toLowerCase()) &&
      (formData.purpose === "-" || token.purpose === formData.purpose) &&
      (filterExpired === "All" ||
        (filterExpired === "Open" && !tokenIsExpired) ||
        (filterExpired === "Expired" && tokenIsExpired)) &&
      (filterUsed === "All" ||
        (filterUsed === "Unused" && !token.consumed) ||
        (filterUsed === "Used" && token.consumed))
    );
  });
  const displayPurpose = (purpose: string) => {
    switch (purpose) {
      case "invite":
        return "User Invite";
      case "migrate":
        return "Migrate";
      case "recovery":
        return "Password Recovery";
      case "invite-to-v2":
        return "Team Invite";
      case "failed-payment":
        return "Failed Payment";
      case "invite-admin":
        return "Admin Invite";
      case "initial-invite":
        return "Create Password";
      case "demo-mode":
        return "Demo";
      default:
        return "";
    }
  };

  return (
    <>
      <table
        className={
          isMobile
            ? "table-compact table w-full shadow-xl"
            : "table w-full shadow-xl"
        }
      >
        <thead>
          <tr className="border-b-2 border-secondary text-secondary">
            <th className="select-none rounded-tl-lg bg-card-light text-left text-base dark:bg-card-dark">
              Purpose
            </th>
            <th className="select-none bg-card-light text-left text-base dark:bg-card-dark">
              Email
            </th>
            <th className="select-none bg-card-light text-left text-base dark:bg-card-dark">
              Expires
            </th>
            <th className="select-none bg-card-light text-left text-base dark:bg-card-dark">
              Used
            </th>
            <th className="select-none bg-card-light text-left text-base dark:bg-card-dark">
              Link
            </th>
            <th className="select-none bg-card-light text-left text-base dark:bg-card-dark">
              Send Email
            </th>
            <th className="select-none rounded-tr-lg bg-card-light text-left text-base dark:bg-card-dark">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="bg-white dark:bg-card-dark">
              <select
                className="select-secondary select w-full max-w-xs bg-back-light text-text-dark dark:bg-back-dark dark:text-text-light"
                title="Update Property Status"
                name="purpose"
                value={formData.purpose}
                onChange={handleSearch}
              >
                <option value={formData.purpose} className="text-black">
                  {displayPurpose(formData.purpose)}
                </option>
                {formData.purpose === "-" ? (
                  <>
                    <option value="migrate">Migrate</option>
                    <option value="invite">User Invite</option>
                    <option value="invite-to-v2">Team Invite</option>
                    <option value="invite-admin">Admin Invite</option>
                    <option value="recovery">Password Recovery</option>
                    <option value="failed-payment">Failed Payment</option>
                    <option value="initial-invite">Create Password</option>
                    <option value="demo-mode">Demo Mode</option>
                  </>
                ) : formData.purpose === "migrate" ? (
                  <>
                    <option value="-">-</option>
                    <option value="invite">User Invite</option>
                    <option value="invite-to-v2">Team Invite</option>
                    <option value="invite-admin">Admin Invite</option>
                    <option value="recovery">Password Recovery</option>
                    <option value="failed-payment">Failed Payment</option>
                    <option value="initial-invite">Create Password</option>
                    <option value="demo-mode">Demo Mode</option>
                  </>
                ) : formData.purpose === "demo-mode" ? (
                  <>
                    <option value="-">-</option>
                    <option value="invite">User Invite</option>
                    <option value="invite-to-v2">Team Invite</option>
                    <option value="invite-admin">Admin Invite</option>
                    <option value="recovery">Password Recovery</option>
                    <option value="failed-payment">Failed Payment</option>
                    <option value="initial-invite">Create Password</option>
                  </>
                ) : formData.purpose === "invite" ? (
                  <>
                    <option value="-">-</option>
                    <option value="migrate">Migrate</option>
                    <option value="invite-to-v2">Team Invite</option>
                    <option value="invite-admin">Admin Invite</option>
                    <option value="recovery">Password Recovery</option>
                    <option value="failed-payment">Failed Payment</option>
                    <option value="initial-invite">Create Password</option>
                    <option value="demo-mode">Demo Mode</option>
                  </>
                ) : formData.purpose === "failed-payment" ? (
                  <>
                    <option value="-">-</option>
                    <option value="migrate">Migrate</option>
                    <option value="invite">User Invite</option>
                    <option value="invite-to-v2">Team Invite</option>
                    <option value="invite-admin">Admin Invite</option>
                    <option value="recovery">Password Recovery</option>
                    <option value="initial-invite">Create Password</option>
                    <option value="demo-mode">Demo Mode</option>
                  </>
                ) : formData.purpose === "recovery" ? (
                  <>
                    <option value="-">-</option>
                    <option value="migrate">Migrate</option>
                    <option value="invite">User Invite</option>
                    <option value="invite-to-v2">Team Invite</option>
                    <option value="invite-admin">Admin Invite</option>
                    <option value="failed-payment">Failed Payment</option>
                    <option value="initial-invite">Create Password</option>
                    <option value="demo-mode">Demo Mode</option>
                  </>
                ) : formData.purpose === "invite-to-v2" ? (
                  <>
                    <option value="-">-</option>
                    <option value="migrate">Migrate</option>
                    <option value="failed-payment">Failed Payment</option>
                    <option value="recovery">Password Recovery</option>
                    <option value="invite">User Invite</option>
                    <option value="invite-admin">Admin Invite</option>
                    <option value="initial-invite">Create Password</option>
                    <option value="demo-mode">Demo Mode</option>
                  </>
                ) : formData.purpose === "invite-admin" ? (
                  <>
                    <option value="-">-</option>
                    <option value="migrate">Migrate</option>
                    <option value="failed-payment">Failed Payment</option>
                    <option value="recovery">Password Recovery</option>
                    <option value="invite">User Invite</option>
                    <option value="invite-to-v2">Team Invite</option>
                    <option value="initial-invite">Create Password</option>
                    <option value="demo-mode">Demo Mode</option>
                  </>
                ) : formData.purpose === "initial-invite" ? (
                  <>
                    <option value="-">-</option>
                    <option value="migrate">Migrate</option>
                    <option value="failed-payment">Failed Payment</option>
                    <option value="recovery">Password Recovery</option>
                    <option value="invite">User Invite</option>
                    <option value="invite-admin">Admin Invite</option>
                    <option value="invite-to-v2">Team Invite</option>
                    <option value="demo-mode">Demo Mode</option>
                  </>
                ) : (
                  <>
                    <option value="-">-</option>
                    <option value="migrate">Migrate</option>
                    <option value="failed-payment">Failed Payment</option>
                    <option value="recovery">Password Recovery</option>
                    <option value="invite">User Invite</option>
                    <option value="invite-to-v2">Team Invite</option>
                    <option value="invite-admin">Admin Invite</option>
                    <option value="demo-mode">Demo Mode</option>
                  </>
                )}
              </select>
            </td>
            <td className="bg-white dark:bg-card-dark">
              <input
                type="text"
                placeholder="Search Emails"
                name="email"
                className="input-bordered input-secondary input input-md w-full bg-back-light font-semibold text-text-dark placeholder:text-gray-500 dark:bg-back-dark dark:text-text-light dark:placeholder:text-gray-700"
                onChange={handleSearch}
                value={formData.email}
              />
            </td>
            <td className="bg-white dark:bg-card-dark">
              <button
                className="btn-sm btn w-20 bg-back-light text-black dark:bg-back-dark dark:text-white"
                onClick={handleExpiredClick}
              >
                {filterExpired}
              </button>
            </td>
            <td className="bg-white dark:bg-card-dark">
              <button
                className="btn-sm btn w-20 bg-back-light text-black dark:bg-back-dark dark:text-white"
                onClick={handleUsedClick}
              >
                {filterUsed}
              </button>
            </td>
            <td className="bg-white dark:bg-card-dark" colSpan={3}>
              <div className="flex w-full items-center justify-end pr-4 font-bold">
                <div className="text-zinc-500 dark:text-zinc-400">
                  {filteredTokens.length} Results
                </div>
              </div>
            </td>
          </tr>
          {filteredTokens?.map((token: Token, index: number) => (
            <TokensRow key={index} token={token} fetchTokens={fetchTokens} />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default TokensTable;
