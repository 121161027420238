import React from "react";
import { Offer } from "src/interfaces/offer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface OfferQuantityProps {
  offers: Offer[];
  isLoading: boolean;
}

const OfferQuantity = ({ offers, isLoading }: OfferQuantityProps) => {
  return (
    <>
      <div className="card h-1/2 bg-card-light px-4 shadow-lg">
        <div className="text-[40px] font-bold text-zinc-500">Quantity</div>
        <div className="text-[50px] font-bold text-accent">
          {isLoading ? (
            <Skeleton count={1} height={60} width={300} />
          ) : (
            offers.length
          )}
        </div>
      </div>
    </>
  );
};

export default OfferQuantity;
