import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";

class StripeApi extends ApiTokenSupport {
  
  async fetchSubscriptionData(data: {
    teamID: number;
    appID: number;
  }): Promise<any> {
    const resp = await axios.post<any>(
      "/api/stripe/subscription",
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  // async updatestripeData(data: UpdatestripeData): Promise<UpdatestripeData> {
  //   const resp = await axios.put<UpdatestripeData>(
  //     "/api/admin/stripeData",
  //     data,
  //     this.withAuthorization()
  //   );
  //   return resp.data;
  // }

  async updateStripeSubID(data: {
    teamID: number;
    subscriptionID: string;
  }): Promise<any> {
    const resp = await axios.put<any>(
      `/api/stripeAccount/subscriptionID`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new StripeApi();
