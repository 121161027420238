import React from "react";
import Log from "src/interfaces/log";
import ReactJson from "react-json-view";

interface LogDrawerProps {
  log: Log;
}

const LogDrawer = ({ log }: LogDrawerProps) => {
  return (
    <div className="container w-full rounded-lg bg-card-light px-2 py-2 text-text-dark dark:bg-card-dark dark:text-text-light">
      <div className="log-message mb-4">
        <strong>Message:</strong>
        <pre className="whitespace-pre-wrap break-words">{log.message}</pre>
      </div>
      <div className="log-context">
        <strong>Context:</strong>
        <pre className="whitespace-pre-wrap break-words">
          <ReactJson
            src={JSON.parse(log.context)}
            theme={"colors"}
            collapseStringsAfterLength={120}
          />
        </pre>
      </div>
    </div>
  );
};

export default LogDrawer;
