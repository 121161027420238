import { useState, useCallback } from 'react';
import { wrap } from "@popmotion/popcorn";

interface UseCarouselProps {
  totalSlides: number;
}

interface CarouselControls {
  currentPage: number;
  direction: number;
  nextPage: () => void;
  prevPage: () => void;
  goToPage: (page: number) => void;
  variants: {
    enter: (direction: number) => object;
    center: object;
    exit: (direction: number) => object;
  };
}

export const useCarousel = ({ totalSlides }: UseCarouselProps): CarouselControls => {
  const [[currentPage, direction], setPage] = useState([0, 0]);

  const nextPage = useCallback(() => {
    setPage((prev) => [wrap(0, totalSlides, prev[0] + 1), 1]);
  }, [totalSlides]);

  const prevPage = useCallback(() => {
    setPage((prev) => [wrap(0, totalSlides, prev[0] - 1), -1]);
  }, [totalSlides]);

  const goToPage = useCallback((newPage: number) => {
    const newDirection = newPage > currentPage ? 1 : -1;
    setPage([wrap(0, totalSlides, newPage), newDirection]);
  }, [currentPage, totalSlides]);

  const xOffset = 100;
  const variants = {
    enter: (direction: number) => ({
      x: direction > 0 ? xOffset : -xOffset,
      opacity: 0,
      transition: { duration: 0.1, ease: "easeInOut" }
    }),
    center: {
      x: 0,
      opacity: 1,
      transition: { delay: 0.1, ease: "easeInOut" }
    },
    exit: (direction: number) => ({
      x: direction > 0 ? -xOffset : xOffset,
      opacity: 0,
      transition: { duration: 0.1, ease: "easeInOut" }
    })
  };

  return { currentPage, direction, nextPage, prevPage, goToPage, variants };
};
