import React, { ChangeEvent, useState } from "react";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import copyTextToClipboard from "src/helpers/copyTextToClipboard";
import useAppContext from "src/hooks/private/useAppContext";
import { AffiliatesTable } from "src/pages/private/Affiliates";
import { App } from "src/interfaces/app";
import Toggle from "../fields/Toggle";
import { adminApi } from "src/api";

interface AffiliateRowProps {
  affiliate: AffiliatesTable;
  fetchAffiliates: () => void;
}

const AffiliateRow = ({ affiliate, fetchAffiliates }: AffiliateRowProps) => {
  const { apps } = useAppContext();

  const { appID, name, email, referralID, isActive, teamCount } = affiliate;

  const [isActiveAffiliate, setIsActive] = useState<boolean>(isActive);

  // const handleEditAffiliate = (aff: AffiliatesTable) => {};

  const getApp = (id: number): App | undefined => {
    return apps.find((app: App) => (app.id = id));
  };

  const handleActiveChange = async () => {
    const isCurrentlyActive = isActiveAffiliate;
    setIsActive(!isActiveAffiliate);
    try {
      const res = await adminApi.updateAffiliate({
        ...affiliate,
        isActive: !isCurrentlyActive,
      });
      setIsActive(res.isActive);
    } catch (err: any) {
      console.error(err);
    }
  };

  return (
    <tr className="my-0.5 bg-card-light text-base font-medium text-text-dark dark:bg-card-dark dark:text-text-light">
      <td className="bg-card-light dark:bg-card-dark">{getApp(appID)?.name}</td>
      <td className="bg-card-light dark:bg-card-dark">{name}</td>
      <td className="bg-card-light font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
        <button
          className=" btn-ghost btn-sm btn cursor-pointer normal-case"
          onClick={() => copyTextToClipboard(email)}
        >
          {email}
        </button>
      </td>
      <td className="bg-card-light dark:bg-card-dark">
        <button
          className="btn-secondary btn-sm btn gap-1 text-text-light dark:text-text-dark"
          onClick={() =>
            copyTextToClipboard(
              `${getApp(appID)?.url}/register-referral/${referralID}`
            )
          }
        >
          <FontAwesomeIcon icon={faLink} className="" />
          <div className="font-semibold">{referralID}</div>
        </button>
      </td>
      <td className="bg-card-light font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
        {teamCount}
      </td>
      <td className="bg-card-light font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
        <Toggle checked={isActiveAffiliate} handleChange={handleActiveChange} />
      </td>
    </tr>
  );
};

export default AffiliateRow;
