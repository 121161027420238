import React, { useState } from "react";
import dealioSitePlans, { SitePlan } from "src/data/DealioSitePlans";
import ButtonBlock from "./ButtonBlock";
import useModal from "src/hooks/useModal";
import { motion, AnimatePresence } from "framer-motion";
import { useCarousel } from "src/hooks/useCarousel";
import { realeflowApi } from "src/api";
import useAlert from "src/hooks/useAlert";

interface DashDealioModalProps {
  sitePlanID: number | null;
  teamID: number;
}

const DashDealioModal = ({ sitePlanID, teamID }: DashDealioModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const { currentPage, direction, nextPage, prevPage, variants } = useCarousel({
    totalSlides: 2,
  });

  const allowedPlans = dealioSitePlans.filter(
    (sitePlan: SitePlan) => sitePlan.hasDash
  );

  const handleSubmitForm = async () => {
    setIsSubmitting(true);
    try {
      const res = await realeflowApi.addOn({
        teamID: teamID,
        AddOnName: "Leadpipes AI",
        BillingFrequency: "ONE_TIME",
        AIZips: zoneType === "zipcode" ? numZones : 0,
        AIStates: zoneType === "state" ? numZones : 0,
        AICounties: zoneType === "county" ? numZones : 0,
      });

      if (res.Success) {
        setAlert({
          display: true,
          type: "success",
          message: "Successfully added zones",
        });
      } else {
        setAlert({
          display: true,
          type: "error",
          message: res.Error || "There was an error adding zones",
        });
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setShowModal(false);
      setIsSubmitting(false);
    }
  };

  const handleNavigation = () => {
    if (currentPage === 0) {
      nextPage();
    } else if (currentPage === 1) {
      handleSubmitForm();
    }
  };

  const handleCancel = () => {
    if (currentPage === 0) {
      setShowModal(false);
    } else {
      prevPage();
    }
  };

  type ZoneType = "zipcode" | "county" | "state";

  const [zoneType, setZoneType] = useState<ZoneType>("zipcode");

  const [numZones, setNumZones] = useState<number>(1);

  const maxZones = {
    zipcode: 5,
    county: 1,
    state: 1,
  };

  const handleZoneChange = (zone: ZoneType) => {
    setZoneType(zone);
    setNumZones(maxZones[zone]);
  };

  return (
    <div className="w-full overflow-hidden">
      <AnimatePresence initial={false} custom={direction} mode="wait">
        <motion.div
          key={currentPage}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          custom={currentPage > 0 ? 1 : -1}
          transition={{ type: "linear" }}
          className="h-64 w-full"
        >
          {currentPage === 0 && (
            <div>
              <p>
                Only certain Dealio Site Plan IDs are allowed to access Dash.
              </p>
              <br></br>
              <p>
                <span className="font-semibold">Paid Plans: </span>
                {allowedPlans.map((sitePlan: SitePlan) => (
                  <span key={sitePlan.id}>{sitePlan.id}, </span>
                ))}
              </p>
              <br></br>
              <p>
                If a team would like to upgrade to one of the above Site Plans
                please do this in Realeflow/Dealio and return to the Admin Panel
                after.
              </p>
              <br></br>
              <p>
                If a team would like to add LeadPipes AI as an AddOn to their
                896 API Access only Site Plan, please select the number of Zips,
                States, or Counties on the next page.
              </p>
            </div>
          )}
          {currentPage === 1 && (
            <div className="w-full">
              <div className="mb-4">
                <p>Choose a type of AI zone and number of zones</p>
              </div>
              <div className="flex w-full columns-1 flex-col items-center justify-center gap-2">
                <div className="flex flex-row items-center justify-center gap-2">
                  <div className="flex w-48 columns-1 flex-col items-start justify-center gap-2 py-1">
                    <div
                      className="flex w-36 cursor-pointer flex-row  gap-2"
                      onClick={() => handleZoneChange("zipcode")}
                    >
                      <input
                        type="radio"
                        name="radio-3"
                        className="radio-secondary radio"
                        defaultChecked
                        checked={zoneType === "zipcode"}
                      />
                      <label className="cursor-pointer ">Zipcode</label>
                    </div>
                    <div
                      className="flex w-36 cursor-pointer flex-row gap-2"
                      onClick={() => handleZoneChange("county")}
                    >
                      <input
                        type="radio"
                        name="radio-3"
                        className="radio-secondary radio"
                        checked={zoneType === "county"}
                      />
                      <label className="cursor-pointer ">County</label>
                    </div>
                    <div
                      className="flex w-36 cursor-pointer flex-row gap-2"
                      onClick={() => handleZoneChange("state")}
                    >
                      <input
                        type="radio"
                        name="radio-3"
                        className="radio-secondary radio"
                        checked={zoneType === "state"}
                      />
                      <label className="cursor-pointer ">State</label>
                    </div>
                  </div>

                  <div className="stats bg-card-light shadow dark:bg-back-dark">
                    <div className="stat">
                      <div className="stat-title text-zinc-500 dark:text-zinc-400">
                        Zones
                      </div>
                      <div className="stat-value  text-zinc-500 dark:text-zinc-400">
                        {numZones}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-4 w-96">
                  <input
                    type="range"
                    min={1}
                    max={maxZones[zoneType]}
                    onChange={(e) => setNumZones(Number(e.target.value))}
                    value={numZones}
                    className="range range-secondary"
                    step="1"
                  />
                  <div className="flex w-full justify-between px-2 text-xs">
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                    <span>|</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </motion.div>
      </AnimatePresence>
      <div className="mt-4">
        <ButtonBlock
          submitting={isSubmitting}
          handleSubmit={handleNavigation}
          onCancel={handleCancel}
          submitLabel={currentPage === 0 ? "Next" : "Submit"}
          cancelLabel={currentPage === 0 ? "Cancel" : "Back"}
        />
      </div>
    </div>
  );
};

export default DashDealioModal;
