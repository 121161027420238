import React from "react";

const SendFuseLogo = () => {
  return (
    <>
      {/* <SendFuseSVG /> */}
    </>
  );
};

export default SendFuseLogo;
