import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const SearchBar = () => {
  return (
    <div className="w-full">
      <div className=" w-96 rounded-lg pr-4 ">
        <div className="min-w-full rounded-lg bg-gradient-to-r from-gradient3 to-gradient4 p-[2px] shadow-md">
          <div className="flex h-full flex-col justify-between rounded-lg p-[.5px]">
            <label className="relative block rounded-lg">
              <span className="sr-only">Search</span>
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <FontAwesomeIcon
                  className="text-secondary"
                  icon={faMagnifyingGlass}
                />
              </span>
              <input
                type="text"
                name="search"
                placeholder="Search for anything..."
                className="text-slate-dark h-8 w-64 min-w-full rounded-md bg-back-light pl-9 font-medium placeholder:italic placeholder:text-gray-500 focus:outline-none dark:bg-back-dark dark:text-white dark:placeholder:text-gray-700"
                // value={value}
                // onChange={handleInput}
                // disabled={!ready}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
