import React, {
  useState,
  useCallback,
  useEffect,
  PropsWithChildren,
  createContext,
} from "react";
import { adminApi } from "src/api";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import { App } from "src/interfaces/app";

interface AppContextInterface {
  apps: App[];
  fetchApps: () => void;
}

const appContextDefaults: AppContextInterface = {
  apps: [],
  fetchApps: () => {},
};

export const AppContext =
  createContext<AppContextInterface>(appContextDefaults);

export const AppProvider = ({ children }: PropsWithChildren<{}>) => {
  const [apps, setApps] = useState<App[]>([]);

  const { handleError } = useErrorHandler();

  const fetchApps = useCallback(async () => {
    try {
      const applications = await adminApi.getApps();
      setApps(applications);
    } catch (err: any) {
      handleError(err);
    }
  }, [handleError]);

  useEffect(() => {
    fetchApps();
  }, [fetchApps]);

  return (
    <AppContext.Provider
      value={{
        apps,
        fetchApps,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
