import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { fipsMap } from "src/data/fips";
import { statesMap } from "src/data/statesMap";
import {
  AICredit,
  AITerritory,
  Credits,
  RealeflowAccountDetails,
  Zones,
  ZoneType,
} from "src/interfaces/realeflow";

interface ZoneTableProps {
  realeflowData: RealeflowAccountDetails;
}

const ZoneTable = ({ realeflowData }: ZoneTableProps) => {
  const convertToZones = (territories: AITerritory[]): Zones => {
    const zones: Zones = { zips: [], fips: [], states: [] };

    territories.forEach((territory) => {
      switch (territory.Type) {
        case "Zip":
          zones.zips.push(territory.Value);
          break;
        case "State":
          zones.states.push(territory.Value);
          break;
        case "FIPS":
          zones.fips.push(territory.Value);
          break;
      }
    });

    return zones;
  };

  const convertToCredits = (_credits: AICredit[]): Credits => {
    const credits: Credits = { zips: 0, fips: 0, states: 0 };

    _credits.forEach((credit) => {
      switch (credit.Type) {
        case "Zip":
          credits.zips = credit.Total - credit.Claimed;
          break;
        case "State":
          credits.states = credit.Total - credit.Claimed;
          break;
        case "FIPS":
          credits.fips = credit.Total - credit.Claimed;
          break;
      }
    });

    return credits;
  };

  const credits = convertToCredits(realeflowData?.AICredits);

  const zones = convertToZones(realeflowData?.AITerritories || []);

  const renderZones = (zone: string[], type: ZoneType): ReactNode => {
    return zone[0] === "*" ? (
      <span>All</span>
    ) : (
      <>
        {type === "Zip"
          ? zone.length
            ? zone.map((zip) => zip + ", ")
            : "-"
          : type === "State"
          ? zone.length
            ? zone.map((state) => statesMap.get(state)?.name + ", ")
            : "-"
          : zone.length
          ? zone.map((fips) => fipsMap.get(fips)?.name + ", ")
          : "-"}
      </>
    );
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th className="text-zinc-500 dark:text-zinc-400">Zone Type</th>
            <th className="text-zinc-500 dark:text-zinc-400">Unused Credits</th>
            <th className="text-zinc-500 dark:text-zinc-400">
              Purchased Zones
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-zinc-500 dark:text-zinc-400">Zipcodes</td>
            <td>
              <div className="flex gap-1">
                <span
                  className={`font-bold  ${
                    credits.zips > 0
                      ? "text-warning"
                      : "text-zinc-600 dark:text-zinc-400"
                  }`}
                >
                  {credits.zips}
                </span>
                <span>
                  {credits.zips > 0 && (
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="text-warning"
                    />
                  )}
                </span>
              </div>
            </td>
            <td className="text-zinc-500 dark:text-zinc-400">
              {renderZones(zones.zips, "Zip")}
            </td>
          </tr>
          <tr>
            <td className="text-zinc-500 dark:text-zinc-400">Counties</td>
            <td>
              <div className="flex gap-1">
                <span
                  className={`font-bold  ${
                    credits.fips > 0
                      ? "text-warning"
                      : "text-zinc-500 dark:text-zinc-400"
                  }`}
                >
                  {credits.fips}
                </span>
                <span>
                  {credits.fips > 0 && (
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="text-warning"
                    />
                  )}
                </span>
              </div>
            </td>
            <td className="text-zinc-500 dark:text-zinc-400">
              {renderZones(zones.fips, "FIPS")}
            </td>
          </tr>
          <tr>
            <td className="text-zinc-500 dark:text-zinc-400">States</td>
            <td>
              <div className="flex gap-1">
                <span
                  className={`font-bold  ${
                    credits.states > 0
                      ? "text-warning"
                      : "text-zinc-500 dark:text-zinc-400"
                  }`}
                >
                  {credits.states}
                </span>
                <span>
                  {credits.states > 0 && (
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="text-warning"
                    />
                  )}
                </span>
              </div>
            </td>
            <td className="text-zinc-500 dark:text-zinc-400">
              {renderZones(zones.states, "State")}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ZoneTable;
