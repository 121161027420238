import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ToggleSupportTicketDetailsProps {
  isToggled: boolean;
  onClick: () => void;
}

const ToggleSupportTicketDetails = ({
  isToggled,
  onClick,
}: ToggleSupportTicketDetailsProps) => {
  return (
    <>
      <button
        onClick={onClick}
        className="sm:btn sm:btn-ghost dark:text-text-dark"
      >
        <FontAwesomeIcon
          className={
            isToggled
              ? "text-[20px] md:text-[34px] text-secondary -rotate-90 duration-[380ms] "
              : "text-[20px] md:text-[34px] text-secondary duration-[380ms]"
          }
          icon={faCaretLeft}
        />
      </button>
    </>
  );
};

export default ToggleSupportTicketDetails