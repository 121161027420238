import React, { useState } from "react";
import Log from "src/interfaces/log";
import TogglePropertyDetails from "../buttons/TogglePropertyDetails";
import LogDrawer from "./LogDrawer";

interface LogRowProps {
  log: Log;
}

const LogRow = ({ log }: LogRowProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const truncateMessage = (message: string) => {
    const maxLength = 220;
    if (message.length > maxLength) {
      return message.slice(0, maxLength) + "...";
    }
    return message;
  };

  return (
    <>
      <tr>
        <td className=" bg-card-light text-sm font-medium text-text-dark dark:bg-card-dark dark:text-text-light">
          {new Date(log.createdAt as Date).toLocaleString()}
        </td>
        <td className=" bg-card-light text-sm font-medium text-text-dark dark:bg-card-dark dark:text-text-light">
          {log.origin}
        </td>
        <td className=" bg-card-light text-sm font-medium text-error dark:bg-card-dark ">
          {truncateMessage(log.message)}
        </td>
        <td className=" w-12 bg-card-light text-sm font-medium text-text-dark dark:bg-card-dark dark:text-text-light">
          <TogglePropertyDetails
            isToggled={isDrawerOpen}
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          />
        </td>
      </tr>
      {isDrawerOpen && (
        <tr
          key={`tr-dr-${log.id}`}
          className={isDrawerOpen ? "relative z-0 " : "absolute z-0 flex "}
        >
          <td
            colSpan={4}
            className="break-words break-keep rounded-b-lg border-x bg-back-light dark:border-x dark:border-card-dark dark:bg-back-dark"
          >
            <LogDrawer log={log} />
          </td>
        </tr>
      )}
    </>
  );
};

export default LogRow;
