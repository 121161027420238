import React from "react";

interface TableFooterProps {
  colSpan: number;
}

const TableFooter = ({ colSpan }: TableFooterProps) => {
  return (
    <>
      <tr className="relative">
        <td
          className="absolute -top-0 h-[1px] w-full rounded-b-lg bg-card-light dark:bg-card-dark"
          colSpan={colSpan}
        >
          <div className="absolute -top-[1px] left-0 h-[1.5px] w-full bg-secondary"></div>
        </td>
      </tr>
    </>
  );
};

export default TableFooter;
