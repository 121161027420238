import React from "react";
import { Offer } from "src/interfaces/offer";
import { addCommas } from "src/helpers/parseStrings";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface OfferVolumeProps {
  offers: Offer[];
  isLoading: boolean;
}

const OfferVolume = ({ offers, isLoading }: OfferVolumeProps) => {
  const totalVolume = offers.reduce((acc, offer) => {
    if (offer.isThreeTiered && offer.offer1 && offer.offer3) {
      return acc + offer.offer3;
    } else {
      return acc + offer.offer1;
    }
  }, 0);

  return (
    <>
      <div className="card h-1/2 bg-card-light px-4 shadow-lg">
        <div className="text-[40px] font-bold text-zinc-500">Volume </div>
        <div className="text-[50px] font-bold text-primary">
          {isLoading ? (
            <Skeleton count={1} height={60} width={300} />
          ) : (
            [`${addCommas(totalVolume)}`]
          )}
        </div>
      </div>
    </>
  );
};

export default OfferVolume;
