import React, { useState } from "react";
import FloatingInput from "../fields/FloatingInputText";
import useModal from "src/hooks/useModal";
import { regexEmail } from "src/helpers/constants";
import useAlert from "src/hooks/useAlert";
import AdminApi from "src/api/admin";
import ButtonBlock from "./ButtonBlock";

interface NewMigrationModalProps {
  fetchMigrations: () => {};
}

const NewMigrationModal = ({ fetchMigrations }: NewMigrationModalProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const [migrationEmail, setMigrationEmail] = useState<string>("");

  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const handleChange = (e: any) => {
    const { value } = e.target;
    setMigrationEmail(value);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const initialFormErrors = {
    email: "",
  };

  interface FormErrors {
    email: string;
  }

  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const validate: any = (email: string) => {
    const errors: {
      email?: string;
    } = {};
    if (!email.length) {
      errors.email = "Email is required";
    } else if (!regexEmail.test(email)) {
      errors.email = "Email is not a valid format";
    }
    return errors;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let errors = validate(migrationEmail);
    if (errors.email) {
      return setFormErrors(errors);
    } else {
      setOnSubmitting(true);
      try {
        let result = await AdminApi.addNewMigration(migrationEmail);
        if (result.success) {
          setAlert({
            display: true,
            message: "Data Migration successful, invitation email sent",
            type: "success",
          });
          setMigrationEmail("");
        } else {
          setAlert({
            display: true,
            message: result.message,
            type: "warning",
          });
        }
      } catch (e: any) {
        setAlert({
          display: true,
          message: e.message || "An error occurred trying to migrate this team",
          type: "error",
        });
      } finally {
        setOnSubmitting(false);
        setShowModal(false);
        fetchMigrations();
      }
    }
  };

  return (
    <>
      <div className="mx-4 mb-6 lg:mx-32">
        <div className="mb-2">
          <FloatingInput
            name={"email"}
            label={"Email"}
            type={"text"}
            onChange={handleChange}
            value={migrationEmail}
            error={formErrors.email}
          />
        </div>
      </div>
      <div className="mb-4">
        <p className="text-warning">
          WARNING: This action cannot be undone. Migrating a user will delete
          all their credits in V1 and email them an invite to join V2. Be sure
          this account is ready to migrate.
        </p>
      </div>
      <div className="mt-4">
        <ButtonBlock
          submitting={onSubmitting}
          handleSubmit={handleSubmit}
          onCancel={handleCancel}
          submitLabel={"Migrate"}
          disabled={onSubmitting}
        />
      </div>
    </>
  );
};

export default NewMigrationModal;
