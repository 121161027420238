import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "../tooltips/Tooltip";
import LoadingSpinner from "../loading/LoadingSpinner";


interface CircularActionButtonProps {
  icon: IconProp;
  onClick?: (id?: number | undefined) => void;
  isSmall?: boolean;
  isLoading?: boolean;
  tooltip?: string;
}

const CircularActionButton = ({
  icon,
  onClick,
  isSmall,
  isLoading,
  tooltip,
}: CircularActionButtonProps) => {

  const generateUUID: () => string = () => uuidv4();

  const _id = generateUUID();

  return (
    <div data-tip data-for={`tooltip-id-${_id}`} className="inline-block">
      <label
        className={
          isSmall
            ? "btn btn-circle btn-xs cursor-pointer border-none bg-gray-300 text-card-dark hover:bg-gray-400 hover:bg-hover-card-light dark:bg-back-dark  dark:text-gray-500 hover:dark:bg-black"
            : "btn btn-circle btn-sm  cursor-pointer border-none bg-gray-300 text-card-dark hover:bg-gray-400 hover:bg-hover-card-light dark:bg-back-dark  dark:text-gray-500 hover:dark:bg-black"
        }
        onClick={() => onClick && onClick()}
      >
        {isLoading ? (
          <LoadingSpinner className={"text-secondary dark:text-theme1"} />
        ) : (
          <FontAwesomeIcon icon={icon} />
        )}
      </label>
      {tooltip && (
        <Tooltip
          id={`tooltip-id-${_id}`}
          content={tooltip}
        />
      )}
    </div>
  );
};

export default CircularActionButton;
