import React, {
  useCallback,
  useState,
  PropsWithChildren,
  useEffect,
} from "react";
import useAuth from "src/hooks/useAuth";
import useAlert from "src/hooks/useAlert";
import adminApi from "src/api/admin";
import { Admin } from "src/interfaces/admin";

interface AdminContextInterface {
  admin: Admin | null | undefined;
  fetchAdmin: () => void;
  teams: any[] | null | undefined;
  fetchTeams: () => void;
  isLoading: boolean;
}

const AdminContextDefaults = {
  admin: null,
  fetchAdmin: () => {},
  teams: [],
  fetchTeams: () => {},
  isLoading: false,
};

export const AdminContext =
  React.createContext<AdminContextInterface>(AdminContextDefaults);

interface AdminProviderProps {}

export const AdminProvider = ({
  children,
}: PropsWithChildren<AdminProviderProps>) => {
  const { currentAdmin } = useAuth();
  const adminID = currentAdmin;
  const { setAlert } = useAlert();

  const [admin, setAdmin] = useState<Admin>();
  const [teams, setTeams] = useState<any[]>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleError = useCallback(
    (err: any) => {
      setAlert({
        display: true,
        message: err?.message || err,
        type: "error",
      });
    },
    [setAlert]
  );

  const fetchAdmin = useCallback(async () => {
    if (adminID) {
      try {
        const admin = await adminApi.getAdmin(adminID);
        setAdmin(admin);
      } catch (err) {
        handleError(err);
      }
    }
  }, [handleError, adminID]);

  const fetchTeams = useCallback(async () => {
    try {
      setIsLoading(true);
      const teams = await adminApi.getTeams();
      setTeams(teams);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  }, [handleError]);

  useEffect(() => {
    fetchAdmin();
  }, [fetchAdmin]);

  useEffect(() => {
    fetchTeams();
  }, [fetchTeams]);

  return (
    <AdminContext.Provider
      value={{
        admin,
        fetchAdmin,
        teams,
        fetchTeams,
        isLoading,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;
