import { PropsWithChildren, createContext } from "react";
import stripeJs, { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK as string);

export type StripeElementOptions = stripeJs.StripeCardNumberElementOptions | stripeJs.StripeCardCvcElementOptions | stripeJs.StripeCardExpiryElementOptions

interface StripeContextValue {
  options: StripeElementOptions
}


const options: StripeElementOptions  = {
  classes: {
    base: 'px-3 py-3 placeholder-gray-400 text-black dark:text-white font-bold placeholder:font-normal bg-white dark:bg-back-dark rounded text-sm shadow w-full',
    focus: 'ring ring-secondary',
  },
  style: {
    base: {
      color: "white",
      fontWeight: 'bold',
      letterSpacing: '0.025em',
      '::placeholder': {
        fontWeight: '400',
      }
    },
  },
}


export const StripeContext = createContext<StripeContextValue>({
  options
});

interface StripeContextProps {}

export const StripeProvider = ({
  children,
}: PropsWithChildren<StripeContextProps>) => {

  return (
    <StripeContext.Provider value={{ options }}>
      <Elements stripe={stripePromise}>
        {children}
      </Elements>
    </StripeContext.Provider>
  );
};
