import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TeamPageData } from "src/interfaces/TeamPageData";
import RealeflowImg from "../../assets/images/RealeflowTransparentLogo.png";
import CircularActionButton from "../buttons/CircularActionButton";
import {
  faArrowsRotate,
  faBan,
  faCircleExclamation,
  faCloudArrowUp,
  faFlaskVial,
  faPenToSquare,
  faPlus,
  faPowerOff,
  faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";
import IdDisplay from "./IdDisplay";
import ButtonLoader from "../loading/ButtonLoader";
import { realeflowApi } from "src/api";
import DisplayEditText from "./DisplayEditText";
import useModal from "src/hooks/useModal";
import EditRealeflowID from "../modals/EditRealeflowID";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parseMonthDayYear } from "src/helpers/parseEpochs";
import SearchRealeflowModal from "../modals/SearchRealeflowModal";
import CreateRealeflowModal from "../modals/CreateRealeflowModal";
import ChangeDealioSitePlanModal from "../modals/ChangeDealioSitePlanModal";
import useAdminContext from "src/hooks/private/useAdminContext";
import { RealeflowAccountDetails } from "src/interfaces/realeflow";
import DashDealioModal from "../modals/DashDealioModal";
import ZoneTable from "../realeflow/ZoneTable";
import LoadingSpinner from "../loading/LoadingSpinner";

interface RealeflowCardProps {
  team: TeamPageData;
  fetchTeam: () => void;
  dealioSitePlanIDGlobal: number | null;
  setDealioSitePlanIDGlobal: Dispatch<SetStateAction<number | null>>;
}

export interface UpdateRealeflowData {
  ExternalAccountId: number | null | undefined;
}

const RealeflowCard = ({
  team,
  fetchTeam,
  dealioSitePlanIDGlobal,
  setDealioSitePlanIDGlobal,
}: RealeflowCardProps) => {
  const [isLoadingRealeflowData, setIsLoadingRealeflowData] =
    useState<boolean>(true);

  const { openModalWith, setShowModal } = useModal();

  const { admin } = useAdminContext();

  interface RealeflowError {
    display: boolean;
    message: string;
  }

  const [realeflowError, setRealeflowError] = useState<RealeflowError>({
    display: false,
    message: "",
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [isSavingRealeflowData, setIsSavingRealeflowData] =
    useState<boolean>(false);

  const [realeflowData, setRealeflowData] =
    useState<RealeflowAccountDetails | null>(null);

  const [editedRealeflowData, setEditedRealeflowData] =
    useState<RealeflowAccountDetails | null>(null);

  const { realeflowID } = team.team;

  useEffect(() => {
    setEditedRealeflowData(realeflowData);
  }, [realeflowData]);

  const handleInputChange = (
    field: keyof RealeflowAccountDetails,
    value: string | number
  ) => {
    if (editedRealeflowData) {
      setEditedRealeflowData({ ...editedRealeflowData, [field]: value });
    }
  };

  const fetchRealeflowData = async () => {
    setRealeflowError({ display: false, message: "" });
    setIsLoadingRealeflowData(true);
    if (realeflowID) {
      try {
        const res = await realeflowApi.fetchRealeflowData(
          team.team.id,
          "email"
        );

        if (res) {
          setRealeflowData(res);
          setDealioSitePlanIDGlobal(res.SitePlanId);
          setRealeflowError({ display: false, message: "" });
          setIsLoadingRealeflowData(false);
        } else {
          console.error("error");
          setIsLoadingRealeflowData(false);
          setRealeflowError({
            display: true,
            message:
              "RealeflowID not connected to email or KeapID, please update",
          });
        }
      } catch (err) {
        console.error(err);
        setRealeflowError({
          display: true,
          message: "There was an error fetching Realeflow Data",
        });
      }
    }
  };

  useEffect(() => {
    fetchRealeflowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realeflowID]);

  const handleClickRealeflowID = () => {
    openModalWith({
      title: "Edit RealeflowID",
      body: <EditRealeflowID team={team} fetchTeam={fetchTeam} />,
      hideButtons: true,
    });
  };

  const handleEditRealeflow = () => {
    if (!realeflowError.display) {
      setIsEditing(!isEditing);
    } else {
      setRealeflowError({
        display: true,
        message: "Cannot edit until Realeflow Data is found",
      });
    }
  };

  const handleAddOn = () => {
    openModalWith({
      title: "Paid Dealio Account Required",
      body: (
        <DashDealioModal
          sitePlanID={dealioSitePlanIDGlobal}
          teamID={team.team.id}
        />
      ),
      hideButtons: true,
    });
  };

  const handleSaveRealeflowData = async () => {
    setIsSavingRealeflowData(true);
    try {
      await realeflowApi.updateRealeflowData({
        realeflowID,
        externalAccountID: Number(editedRealeflowData?.ExternalAccountId),
      });
      fetchRealeflowData();
      fetchTeam(); // Refresh team data if necessary
    } catch (err) {
      console.error(err);
    } finally {
      setIsSavingRealeflowData(false);
      setIsEditing(false);
    }
  };

  const handleTestRealeflow = () => {
    openModalWith({
      title: "Test Realeflow Accounts",
      body: <SearchRealeflowModal team={team} fetchTeam={fetchTeam} />,
      hideButtons: true,
    });
  };

  const handleCreateRealeflow = () => {
    openModalWith({
      title: "Create a Realeflow Account",
      body: <CreateRealeflowModal team={team} fetchTeam={fetchTeam} />,
      hideButtons: true,
    });
  };

  const handleChangeDealioSitePlan = () => {
    // if (realeflowData?.SitePlanId) {
    openModalWith({
      title: "Change Dealio Site PlanID",
      body: (
        <ChangeDealioSitePlanModal
          realeflowID={realeflowID}
          currentDealioSitePlanID={realeflowData?.SitePlanId || 0}
        />
      ),
      hideButtons: true,
    });
    // }
  };

  const updateAccountStatus = async () => {
    if (!realeflowData) return;
    try {
      await realeflowApi.updateAccountStatus(realeflowData?.AccountId);
      fetchRealeflowData();
    } catch (err) {
      console.error(err);
    } finally {
      setShowModal(false);
    }
  };

  const handleAccountStatusChange = async () => {
    openModalWith({
      title:
        realeflowData?.AccountStatus === "Active"
          ? "Deactivate Realeflow Account"
          : "Activate Realeflow Account",
      body:
        realeflowData?.AccountStatus === "Active" ? (
          <p>
            Are your sure you want to set this account status to Third Party
            Decline?
          </p>
        ) : (
          <p>Are you sure you want to reactivate this Realeflow Account?</p>
        ),
      hideButtons: false,
      onSubmit: updateAccountStatus,
    });
  };

  return (
    <>
      <div className="card w-full rounded-lg bg-white px-6 py-4 shadow-lg dark:bg-card-dark">
        <div className="flex w-full items-center justify-between">
          <div className="flex w-24 items-center">
            <img src={RealeflowImg} alt={"Realeflow Logo"} className="w-32" />
          </div>
          <IdDisplay
            ID={realeflowID}
            label={"RealeflowID"}
            onClick={handleClickRealeflowID}
            error={realeflowError.display}
          />
          <div className="flex w-24 items-center justify-end gap-2">
            <CircularActionButton
              icon={faArrowsRotate}
              onClick={fetchRealeflowData}
            />
            <CircularActionButton
              icon={
                realeflowData?.AccountStatus === "Active" ? faBan : faPowerOff
              }
              onClick={handleAccountStatusChange}
            />
            <CircularActionButton
              icon={faFlaskVial}
              onClick={handleTestRealeflow}
            />
            <CircularActionButton icon={faPuzzlePiece} onClick={handleAddOn} />
            {admin?.canUpdateDealioSitePlan && (
              <CircularActionButton
                icon={faCloudArrowUp}
                onClick={handleChangeDealioSitePlan}
              />
            )}
            <CircularActionButton
              icon={faPlus}
              onClick={handleCreateRealeflow}
            />
            <CircularActionButton
              icon={faPenToSquare}
              onClick={handleEditRealeflow}
            />
          </div>
        </div>
        <div className="mb-4 mt-0.5 flex w-full items-center justify-center">
          {isLoadingRealeflowData ? (
            <LoadingSpinner className="mt-2 h-8 w-8 text-[#89588C]" />
          ) : realeflowError.display ? (
            <div className="mt-4 flex items-center justify-center gap-2">
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className="h-4 w-4 text-error"
              />
              <p className="text-error">{realeflowError.message}</p>
            </div>
          ) : (
            <div className="flex w-full  gap-2">
              <div className="w-1/2">
                <DisplayEditText
                  label={"AccountID"}
                  name={"firstName"}
                  value={realeflowData?.AccountId || ""}
                  disabled={true}
                  error={realeflowData?.AccountId !== team.team.realeflowID}
                  success={realeflowData?.AccountId === team.team.realeflowID}
                />
                <DisplayEditText
                  label={"Contact Email"}
                  name="email"
                  value={realeflowData?.ContactEmail || ""}
                  disabled={true}
                />
                <DisplayEditText
                  label={"Account Status"}
                  name={"sendFuseTeamID"}
                  value={editedRealeflowData?.AccountStatus || ""}
                  disabled={true}
                  error={realeflowData?.AccountStatus !== "Active"}
                  success={realeflowData?.AccountStatus === "Active"}
                />
                <DisplayEditText
                  label={"KeapID"}
                  name="ExternalAccountId"
                  value={editedRealeflowData?.ExternalAccountId || ""}
                  disabled={!isEditing}
                  error={
                    Number(realeflowData?.ExternalAccountId) !==
                    team.team.keapID
                  }
                  success={
                    Number(realeflowData?.ExternalAccountId) ===
                    team.team.keapID
                  }
                  onChange={(e) =>
                    handleInputChange("ExternalAccountId", e.target.value)
                  }
                />
              </div>
              <div className="w-1/2">
                <DisplayEditText
                  label={"Contact"}
                  name="lastName"
                  value={realeflowData?.Contact || ""}
                  disabled={true}
                />
                <DisplayEditText
                  label={"Created"}
                  name="phoneNumber"
                  value={
                    parseMonthDayYear(realeflowData?.Created as string) || ""
                  }
                  disabled={true}
                />
                <DisplayEditText
                  label={"Site Plan Name"}
                  name="phoneNumber"
                  value={realeflowData?.SitePlanName || ""}
                  disabled={true}
                />

                <DisplayEditText
                  label={"Dealio Site Plan ID"}
                  name={"sendFuseSubscriptionID"}
                  value={editedRealeflowData?.SitePlanId || ""}
                  disabled={true}
                  // error={
                  //   realeflowData?.sendFuseSubscriptionID !==
                  //   team.team.subscriptionID
                  // }
                  // success={
                  //   realeflowData?.sendFuseSubscriptionID ===
                  //   team.team.subscriptionID
                  // }
                  // onChange={(e) =>
                  //   handleInputChange("sendFuseSubscriptionID", e.target.value)
                  // }
                />
              </div>
            </div>
          )}
        </div>
        <div>
          {isEditing && (
            <button
              onClick={handleSaveRealeflowData}
              className="btn-sm btn w-full bg-purple-500 text-white hover:bg-purple-800"
            >
              {isSavingRealeflowData ? (
                <ButtonLoader className="h-4 w-4" fill={"#89588C"} />
              ) : (
                "Save"
              )}
            </button>
          )}
        </div>
        {realeflowData && <ZoneTable realeflowData={realeflowData} />}
      </div>
    </>
  );
};

export default RealeflowCard;
