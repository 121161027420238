import React from "react";

interface JSONDataViewerProps {
  data: { [key: string]: any };
}

const JSONDataViewer: React.FC<JSONDataViewerProps> = ({ data }) => {
  return (
    <div className="json-data-viewer text-left">
      {Object.entries(data).map(([key, value], index) => (
        <div key={index} className="data-row">
          <strong>{key}:</strong>{" "}
          {typeof value === "object"
            ? JSON.stringify(value, null, 2)
            : value.toString()}
        </div>
      ))}
    </div>
  );
};

export default JSONDataViewer;
