import { TeamPageData } from "src/interfaces/TeamPageData";
import ActionStatistics from "../cards/ActionStatistics";

interface UsageTabProps {
  team: TeamPageData;
}

const UsageTab = ({ team }: UsageTabProps) => {
  return (
    <>
      <ActionStatistics team={team} />
    </>
  );
};

export default UsageTab;
