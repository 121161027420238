import React from "react";
import FuseCoinLight from "src/assets/images/FuseCoinLight.svg";
import FuseCoinDark from "src/assets/images/FuseCoinDark.svg";
import useTheme from "src/hooks/useTheme";

interface FuseCoinProps {
  props?: any;
}

const FuseCoin = ({ props }: FuseCoinProps) => {
  const { mode } = useTheme();

  return (
    <>
      {mode === "dark" ? (
        <img src={FuseCoinDark} alt="Fusecoin" className="w-[26px] z-[100]" />
      ) : (
        <img src={FuseCoinLight} alt="Fusecoin" className="w-[26px] z-[100]" />
      )}
    </>
  );
};

export default FuseCoin;
